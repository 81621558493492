import React from "react";

import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import { Button } from "../../../../../../components/Button";
import { getClassLabelOfState, getLabelOfState } from "../../../../../../utils/sessionNoteFormater";

import "../../styles/index.scss";

function SessionTitle({
  selected, toggle, showPdfPreview, showPdfAllPreview, hidePDFBtn = false,
}) {
  const {
    student: { studentName = "" } = {},
    service: { serviceTypeName = "", sessionLength = "", ratio = "" } = {},
    show,
  } = selected;
  const transformRatio = (ratio) => {
    switch (ratio) {
    case "": return "";
    case "G": return "group";
    case "1": return "1:1";
    case "I": return "indirect";
    default: return "unknown parameter";
    }
  };

  const checkState = (state) => ["A", "I"].indexOf(state) !== -1;

  return (
    <div className="session_notes_form_title" >
      <div className="session_notes_form_section-title">
        <div className="session_notes_form_section-title-container">
          <div className="session_notes_form_section-title-name">
            <h3 className="text">{`${studentName} ${serviceTypeName} ${transformRatio(ratio)} ${sessionLength} min`}</h3>
            <span className={`session_notes_form_section-title-label ${getClassLabelOfState(selected.state)}`}>{getLabelOfState(selected.state)}</span>
          </div>
          { !hidePDFBtn
            && <div className="session_notes_form__title-buttons ">
              <div className="create_session__button grey">
                <Button variant="outlined" color="grey" onClick={showPdfAllPreview}>
                  Session notes for month
                </Button>
              </div>
              <div className="create_session__button">
                <Button
                  variant={!checkState(selected.state) ? "contained" : "outlined"}
                  color={!checkState(selected.state) ? "primary" : "grey"}
                  onClick={showPdfPreview}
                >
                  Current session note
                </Button>
              </div>
            </div>
          }
        </div>
        <span className={`session_notes_form_dropdown ${!show ? "rotate" : ""}`} onClick={toggle}>
          <SvgMaker name="arrowUp" stroke="#142452"/>
        </span>
      </div>
    </div>
  );
}

export default SessionTitle;
