import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    MuiOutLinedInput: {
      root: {
        "& Mui-focused": {
          backgroundColor: "red",
        },
      },
    },
  },
  checked: {
    color: "#FF9500 !important",
  },
  error: {
    "& fieldset": {
      borderColor: "#ff5454 !important",
    },
  },
  errorText: {
    color: "#ff5454",
    fontSize: "0.75em",
  },
}));

export default function MyltiSelect({
  title, setSelected, selected, data, setValueChange = () => { }, error,
}) {
  const classes = useStyles();

  const onChangeHandler = (event, value) => {
    setValueChange(true);
    setSelected(value);
  };

  return (
    <div className={error ? classes.error : classes.root}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={data}
        value={selected}
        defaultValue={selected}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.title ?? ""}
        getOptionSelected={(option, value) => {
          if (!option || !option.id || !value) {
            return null;
          }

          return option?.id === value?.id;
        }}
        onChange={onChangeHandler}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              classes={{
                checked: classes.checked,
              }}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            variant='outlined'
            {...params}
            label={title}
          />
        )}
      />
      {error && <span className={classes.errorText}>{error}</span>}
    </div>
  );
}
