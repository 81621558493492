import * as Yup from "yup";

const paperHeaderConfig = [
  {
    id: 0,
    key: "Scheduled date",
    value: "scheduledDt",
  },
  {
    id: 1,
    key: "Scheduled start",
    value: "scheduledStartTime",
  },
  {
    id: 2,
    key: "Scheduled end",
    value: "scheduledEndTime",
  },
];

const actualDateConfig = [
  {
    id: 0,
    key: "Actual date",
    value: "actualDt",
  },
  {
    id: 1,
    key: "Actual start",
    value: "actualStartTime",
  },
  {
    id: 2,
    key: "Actual end",
    value: "actualEndTime",
  },
];

const fieldConfig = [
  {
    id: 0,
    name: "actualDt",
    label: "Actual date",
    placeholder: "Datepicker",
    required: true,
    type: "datefieldDefault",
  },
  {
    id: 33,
    name: "actualStartTime",
    label: "Actual start",
    required: true,
    placeholder: "-- : -- PM",
    step: 1,
    type: "timerange",
  },
  {
    id: 45,
    name: "actualEndTime",
    label: "Actual end",
    required: true,
    placeholder: "-- : -- PM",
    step: 1,
    type: "timerange",
  },

  {
    type: "empty",
  },
  {
    type: "empty",
  },
];

const formikInitialValues = {
  actualDt: "",
  actualStartTime: "",
  actualEndTime: "",
};

const yupObject = {
  actualDt: Yup.date("Invalid Date").required("required field"),
  actualStartTime: Yup.date("Invalid Date").required("required field"),
  actualEndTime: Yup.date("Invalid Date").required("required field"),
};

const validationSchema = Yup.object(yupObject);

export {
  fieldConfig,
  formikInitialValues,
  validationSchema,
  paperHeaderConfig,
  actualDateConfig,
};
