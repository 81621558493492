import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button";
import { DateField } from "../../../../../components/DateField";
import HeaderPage from "../../../../../components/HeaderPage";
import Pagination from "../../../../../components/Pagination/Pagination";
import { PaperModal } from "../../../../../components/modal/Modal";
import { SelectFilter } from "../../../../../components/Select";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import Table from "../../../../../components/Table/Table";
import {
  admin_create_student_assigment_url,
  admin_students_assigments_list_edit_url,
} from "../../../../../configs/router_urls";
import { dateFormat } from "../../../../../utils/dateFormater";
import tableFormater from "../../../../../utils/tableFormater";
import { headerConfig, info } from "./config";
import { getAssignmentList, deleteAssignment } from "./store";
import "./styles/index.scss";
import { getAllProvidersAction, getAllStudentsAction } from "../../../store";

function StudentAssignmentsList() {
  const dispatch = useDispatch();
  const table = useRef();
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [state, setState] = useState(0);
  const [modalWindow, setModalWindow] = useState({ status: false, idAssignment: null });
  const [period, setPeriod] = useState(`${dateFormat("mm-dd-yyyy")} to ${dateFormat("mm-dd-yyyy")}`);
  const [date, setDate] = useState({});
  const data = useSelector(
    ({ adminAssignmentsReducer }) => adminAssignmentsReducer.data,
  );
  const allProviders = useSelector(
    ({ adminReducer }) => adminReducer.allProviders.result,
  );
  const allStudents = useSelector(
    ({ adminReducer }) => adminReducer.allStudents.result,
  );
  const [activeState, setActiveState] = useState(["F"]);
  const [filters, setFilters] = useState({});
  const filterStudents = (type) => {
    setActiveState(type);
    table.current.scrollTop = 0;
  };
  useEffect(() => {
    if (Object.keys(date).length) {
      setFilters({
        ...filters,
        startDt: dateFormat("mm-dd-yyyy", date.startDate),
        endDt: dateFormat("mm-dd-yyyy", date.endDate),
      });
    }
  }, [date]);
  const createStudent = () => {
    history.push(admin_create_student_assigment_url);
  };
  const reset = () => {
    setCurrentIndex(0);
    setState(0);
  };

  const resetFilters = () => {
    setFilters({});
    setPeriod(`${dateFormat("mm-dd-yyyy")} to ${dateFormat("mm-dd-yyyy")}`);
    setDate({});
    setActiveState(["F"]);
    setState(0);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };

  const resetOneFilter = (obj) => {
    const newObj = { ...filters };
    delete newObj[obj];
    if (obj === "date") {
      setPeriod(`${dateFormat("mm-dd-yyyy")} to ${dateFormat("mm-dd-yyyy")}`);
      setDate({});
      delete newObj.startDt;
      delete newObj.endDt;
    }
    setFilters(newObj);
    setState(0);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };

  const paginationClick = (offset) => {
    dispatch(getAssignmentList({ status: activeState, ...filters }, offset));
    setState(0);
    table.current.scrollTop = 0;
  };

  const infoMessage = (message) => {
    if (message) {
      dispatch(snackbarOpen(message));
    }
  };

  const onEdit = (id) => {
    activeState.includes("F") || activeState.includes("S")
      ? history.push(`${admin_students_assigments_list_edit_url}/${id}`, id)
      : infoMessage(info);
  };

  useEffect(() => {
    dispatch(getAllStudentsAction());
    dispatch(getAllProvidersAction());
  }, []);

  useEffect(() => {
    dispatch(getAssignmentList({ status: activeState, ...filters }));
    setCurrentIndex(0);
  }, [activeState, filters]);

  return (
    <>
      <HeaderPage title="All Assignments" />
      <div className="admin-assignment-list">
        <div className="create-btn">
          <Button variant="contained" color="primary" onClick={createStudent}>
            Create
          </Button>
        </div>
        <div className="admin-assignment-list-header">
          <div className="admin-assignment-list__status">
            <Button
              color={activeState.includes("F") ? "primary" : "secondary"}
              onClick={() => { filterStudents(["F"]); reset(); }}
            >
              Active
            </Button>

            <Button
              color={activeState.includes("S") ? "primary" : "secondary"}
              onClick={() => { filterStudents(["S"]); reset(); }}
            >
              Pending
            </Button>

            <Button
              color={activeState.includes("C", "R", "A") ? "primary" : "secondary"}
              onClick={() => { filterStudents(["C", "R", "A"]); reset(); }}
            >
              Inactive
            </Button>
          </div>
          <div className="admin-assignment-list__filters">
            <div>
              <div className="admin-assignment-list__datefield">
                <DateField
                  name="date"
                  showIcon
                  isRange
                  setDate={setDate}
                  nativeClick={(event) => {
                    if (!event.target.value) return;
                    setPeriod(event.target.value);
                  }
                  }
                  value={period}
                />
                {Object.keys(date).length ? (
                  <div
                    className="select-reset"
                    onClick={() => resetOneFilter("date")}
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                        fill="#192A53"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
              <div className="admin-assignment-list__textfield">
                <SelectFilter
                  name="studentId"
                  placeholder="Student"
                  data={allStudents}
                  changeFilter={(value) => setFilters({ ...filters, studentId: value })}
                  filters={filters}
                  resetFilter={resetOneFilter}/>
              </div>
              <div className="admin-assignment-list__textfield">
                <SelectFilter
                  name="providerId"
                  placeholder="Provider"
                  data={allProviders}
                  changeFilter={(value) => setFilters({ ...filters, providerId: value })}
                  filters={filters}
                  resetFilter={resetOneFilter}/>
              </div>
            </div>
            <div className="reset-filter">
              <Button
                variant="outlined"
                color="secondary"
                onClick={resetFilters}
              >
                Refresh filters
              </Button>
            </div>
          </div>
        </div>
        <Table
          head={Object.values(headerConfig)}
          state={state}
          setState={setState}
          data={tableFormater(
            data.result.map((item) => ({
              ...item,
              studentName: <div className="itemBold">{item.studentName}</div>,
              birthDt: dateFormat("mm-dd-yyyy", item.birthDt),
              endDt: <div className="itemBold">{dateFormat("mm-dd-yyyy", item.endDt)}</div>,
              startDt: <div className="itemBold">{dateFormat("mm-dd-yyyy", item.startDt)}</div>,
              providerName: <div className="itemBold">{item.providerName}</div>,
            })),
            headerConfig,
          )}
          isContrast
          onDelete={(id) => setModalWindow({ status: true, idAssignment: id }) }
          onEdit={onEdit}
          tableRef={table}
        />
        <Pagination {...data.pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
      </div>

      <div className="modalWindowAssignment">
        <PaperModal headerTitle="Are you sure deleting the assignment?"
          open={modalWindow.status}
          onClose={() => {
            setModalWindow({ status: false, idAssignment: null });
          }}>
          <div style={{ marginTop: "20px" }}/>

          <Button variant="contained" color="primary" onClick={() => {
            setModalWindow({ status: false });
            dispatch(deleteAssignment(modalWindow.idAssignment, { status: activeState, ...filters }));
            setCurrentIndex(0);
          }}>
            Confirm
          </Button>
          <Button variant="dashed" color="primary"
            onClick={() => {
              setModalWindow({ status: false, idAssignment: null });
            }}>
            Cancel
          </Button>
        </PaperModal>
      </div>
    </>
  );
}

export default StudentAssignmentsList;
