import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button } from "../../../../../../components/Button";
import FormikSelect from "../../../../../../components/Select";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import FormikTextField from "../../../../../../components/TextField";
import { getLocationTitle, getVirtualMediumTitle } from "../../../../../../utils/sessionNoteFormater";
import { PaperModal } from "../../../../../../components/modal/Modal";
import ConfirmModal from "../../../../../../components/confirmModal";

import "../../styles/index.scss";

const FieldArrayModalContent = (props) => (
  <div className="field_array_modal-content">
    {props.children}
  </div>
);

function SessionInfo({
  location, virtualMedium, virtualMediumAddr, materialsTransferId, setSelected, infoDicts, selected, hideEdit,
}) {
  const [info, setInfo] = useState({
    location, virtualMedium, virtualMediumAddr, materialsTransferId,
  });

  const [hiddenFields, setHiddenFields] = useState({
    location: false,
    virtualMedium: true,
    virtualMediumAddr: true,
    materialsTransferId: true,
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isValueChange, setValueChange] = useState(false);
  const [classNameModalWindow, setClassNameModalWindow] = useState("");

  const checkState = (event) => {
    setValueChange(true);
    const { name, value } = event.target;
    if (name === "location") {
      value === "R" ? setHiddenFields({ ...hiddenFields, virtualMedium: false }) : setHiddenFields({
        ...hiddenFields,
        virtualMedium: true,
        virtualMediumAddr: true,
        materialsTransferId: true,
      });
    } else if (name === "virtualMedium") {
      if (value === "Z") {
        setHiddenFields({
          ...hiddenFields, virtualMedium: false, virtualMediumAddr: false, materialsTransferId: true,
        });
      }
      if (value === "P") {
        setHiddenFields({
          ...hiddenFields, virtualMedium: false, virtualMediumAddr: true, materialsTransferId: false,
        });
      }
      if (value === "O") {
        setHiddenFields({
          ...hiddenFields, virtualMedium: false, virtualMediumAddr: true, materialsTransferId: true,
        });
      }
    }
  };

  const onSave = (values) => {
    const {
      location, virtualMedium, virtualMediumAddr, materialsTransferId,
    } = values;

    const options = {
      location: !hiddenFields.location ? location : null,
      virtualMedium: !hiddenFields.virtualMedium ? virtualMedium : null,
      virtualMediumAddr: !hiddenFields.virtualMediumAddr ? virtualMediumAddr : null,
      materialsTransferId: !hiddenFields.materialsTransferId ? materialsTransferId : null,
    };

    setSelected({
      ...selected,
      options,
      ...options,
    });
    setInfo({
      ...options,
      materialsTransferId:
        options.materialsTransferId
        && infoDicts.materialsTransfer.find((m) => m.materialsTransferId === +materialsTransferId).title,
    });
    setOpen(false);
  };

  const [closeForm, setCloseForm] = useState(false);

  useEffect(() => {
    if (closeForm) {
      setHiddenFields({
        location: false,
        virtualMedium: true,
        virtualMediumAddr: true,
        materialsTransferId: true,
      });
      setCloseForm(false);
    }
  }, [closeForm]);

  const checkClosure = () => {
    setValueChange(false);
  };

  const checkOpen = (e) => {
    if (e.nativeEvent.path.find((el) => el.className === "grey-screen")) {
      if (isValueChange) {
        setClassNameModalWindow("hide");
        setConfirmModal(true);
      } else setOpen(false);
    } else if (e.nativeEvent.path.find((el) => el.className === "modal__closeicon")) {
      if (isValueChange) {
        setClassNameModalWindow("hide");
        setConfirmModal(true);
      } else setOpen(false);
    } else if (e.target.textContent === "Discard") setOpen(false);
    else setOpen(true);
  };

  const onSubmit = ({
    isValid, handleSubmit, values, setFieldTouched,
  }) => {
    if (isValid) {
      handleSubmit();
    } else {
      Object.keys(values).forEach((item) => {
        setFieldTouched(item, true, true);
      });
    }
  };

  return (
    <div className='session_notes_form_horizont-line' onClick={checkOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div>
          <div className="session_notes_form_section-content-info">
            <div className="session_notes_form_section-content-info_item">
              <span className="session_notes_form_section-content-info_item_label">
              Student location
              </span>
              <span className="session_notes_form_section-content-info_item_value">
                {selected?.location && getLocationTitle(selected?.location || info.location)}
              </span>
            </div>
            {selected?.virtualMedium && getVirtualMediumTitle(selected?.virtualMedium || info.virtualMedium)
              ? <div className="session_notes_form_section-content-info_item">
                <span className="session_notes_form_section-content-info_item_label">
              Virtual medium
                </span>
                <span className="session_notes_form_section-content-info_item_value">
                  {selected?.virtualMedium && getVirtualMediumTitle(selected?.virtualMedium || info.virtualMedium)}
                </span>
              </div> : null
            }
            {selected?.virtualMediumAddr || info.virtualMediumAddr ? <div className="session_notes_form_section-content-info_item">
              <span className="session_notes_form_section-content-info_item_label">
              Virtual medium ID
              </span>
              <span className="session_notes_form_section-content-info_item_value">
                {selected?.virtualMediumAddr || info.virtualMediumAddr}
              </span>
            </div> : null}
            {infoDicts.materialsTransfer?.find((el) => selected?.materialsTransferId === el.materialsTransferId)?.title || info.materialsTransferId ? <div className="session_notes_form_section-content-info_item">
              <span className="session_notes_form_section-content-info_item_label">
              Materials transferring method
              </span>
              <span className="session_notes_form_section-content-info_item_value">
                {infoDicts.materialsTransfer?.find((el) => selected?.materialsTransferId === el.materialsTransferId)?.title || info.materialsTransferId}
              </span>
            </div> : null}
            <div
              className={hideEdit && "hide"}>
              <SvgMaker name="editIcon" />
            </div>
          </div>
          <div className={classNameModalWindow}>
            {!confirmModal && !hideEdit && <PaperModal headerTitle="More options" open={open} onClose={() => checkClosure()}>
              <FieldArrayModalContent>
                <Formik initialValues={{
                  location: selected.location || location,
                  virtualMedium: selected.virtualMedium || virtualMedium,
                  virtualMediumAddr: selected.virtualMediumAddr || virtualMediumAddr,
                  materialsTransferId: selected.materialsTransferId || materialsTransferId,

                }}
                validationSchema={Yup.object({
                  location: Yup.string(),
                  virtualMedium: Yup.string().when("location", {
                    is: (location) => location === "R",
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                  }),
                  virtualMediumAddr: Yup.string().when("virtualMedium", {
                    is: (virtualMedium) => virtualMedium === "Z",
                    then: Yup.string().matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only").required().max(64, "Virtual medium ID max length should be 64 characters"),
                    otherwise: Yup.string(),
                  }),
                  materialsTransferId: Yup.string().when("virtualMedium", {
                    is: (virtualMedium) => virtualMedium === "P",
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                  }),
                })}
                onSubmit={(values) => onSave(values)}
                >
                  {({
                    handleSubmit, values, isValid, setFieldTouched,
                  }) => (
                    <div>
                      <div className='info-section' hidden={hiddenFields.location}>
                        <FormikSelect
                          data={infoDicts.locations.map((el) => ({ value: el, title: getLocationTitle(el) }))}
                          onChange={checkState}
                          name='location'
                          hidden={hiddenFields.location}
                          label='Student location'/>
                      </div>
                      {((selected.location === "R" && !hiddenFields.virtualMedium) || values.location === "R") && (
                        <div className='info-section'>
                          <FormikSelect
                            data={infoDicts.virtualMedium.map((el) => ({ value: el, title: getVirtualMediumTitle(el) }))}
                            onChange={checkState}
                            name='virtualMedium'
                            label='Virtual Medium'/>
                        </div>
                      )}
                      {((selected.virtualMedium === "Z" && !hiddenFields.virtualMediumAddr) || values.virtualMedium === "Z") && (
                        <div className='info-section' >
                          <FormikTextField
                            name='virtualMediumAddr'
                            label='Virtual medium id'/>
                        </div>
                      )}
                      {((selected.virtualMedium === "P" && !hiddenFields.materialsTransferId) || values.virtualMedium === "P") && (
                        <div className='info-section'>
                          <FormikSelect
                            data={infoDicts.materialsTransfer.map((el) => ({ value: el.materialsTransferId, title: el.title }))}
                            name='materialsTransferId'
                            label='Materials transferring method'/>
                        </div>
                      )}
                      <div className='info-section'>
                        <Button
                          variant='contained'
                          onClick={() => onSubmit({
                            isValid, handleSubmit, values, setFieldTouched,
                          })}>
                        Save
                        </Button>
                      </div>
                    </div>
                  )}
                </Formik>
              </FieldArrayModalContent>
            </PaperModal>}
          </div>
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => { setConfirmModal(false); setValueChange(false); setOpen(false); setClassNameModalWindow(""); }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); setClassNameModalWindow(""); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
      </div>
    </div>
  );
}

export default SessionInfo;
