import moment from "moment";

export const getCurrentSessionStatus = (status, endtime) => {
  if (moment().valueOf() < moment(endtime).valueOf()) {
    return <div className='calendar-day-event-status calendar-day-event-status-not-signed'>Not signed</div>;
  }

  switch (status) {
  case "S":
    return <div className='calendar-day-event-status calendar-day-event-status-not-completed'>Not completed</div>;
  case "A":
    return <div className='calendar-day-event-status calendar-day-event-status-signed'>Session signed</div>;
  case "I":
    return <div className='calendar-day-event-status calendar-day-event-status-invoiced'>Session invoiced</div>;
  default:
    return <div className='calendar-day-event-status calendar-day-event-status-not-signed'>Not signed</div>;
  }
};

export const getSessionStatusForSelect = (status) => {
  switch (status) {
  case "S":
    return "Not completed";
  case "A":
    return "Session signed";
  case "I":
    return "Session invoiced";
  default:
    return "Not signed";
  }
};
