export const sign_in_url = "/sign-in";
export const home_url = "/";
export const admmin_students_url = "/all/students";
export const admmin_sessions_url = "/all/sessions";
export const admmin_students_profile_url = "/all/students/profile";
export const admmin_create_student_url = "/all/students/create-student";
export const admmin_students_edit_profile_url = "/all/students/edit-profile";

export const admmin_students_services_url = "/all/students/services";
export const admin_students_add_new_services_url = "/all/students/services/:id/add-new-service";
export const admin_students_edit_services_url = "/all/students/services/:id/edit-service/:serviceId";

export const admin_students_assigments_list_url = "/all/student-assignments-list";
export const admin_students_assigments_list_edit_url = "/all/student-assignments-list/edit";
export const admin_create_student_assigment_url = `${admin_students_assigments_list_url}/create`;

export const admin_provider_calls_url = "/all/provider-calls-log";
export const admin_students_documents_url = "/all/students/documents";
export const admin_provider_documents_url = "/all/providers/profile/documents";

export const admin_providers_url = "/all/providers";
export const create_session_url = "/calendar/session/create";
export const edit_session_url = "/calendar/session/edit";
export const session_notes_url = "/calendar/session/scheduled";
export const admin_create_providers_url = "/all/providers/create-provider";
export const admin_provider_profile_url = "/all/providers/profile";
export const admin_provider_edit_profile_url = "/all/providers/edit-profile";
export const admin_provider_students_url = "/all/provider/students/:id";

export const admmin_students_goals_url = "/all/students/goals";
export const admmin_students_add_new_goal_url = "/all/students/goals/:id/add-new-goal";
export const admin_students_gererate_goal_url = "/all/students/goals/:id/generate-goal";
export const admin_students_edit_goal_url = "/all/students/goals/:id/edit-goal";
export const admmin_students_edit_goal_url = "/all/students/goals/:id/edit-goal/:goalId";

export const banks_of_values_url = "/banks-of-values";
export const banks_of_values_school_url = "/banks-of-values/schools";
export const banks_of_values_school_representatives_url = "/banks-of-values/school-representatives";
export const banks_of_values_goals_url = "/banks-of-values/goals";
export const banks_of_values_add_new_goal_url = "/banks-of-values/goals/add-new-goal";
export const banks_of_values_edit_goal_url = "/banks-of-values/goals/edit-goal";

export const admin_invoices_url = "/all/invoices";
export const admin_email_preview_url = "/all/invoices/email-preview";
export const admin_invoice_view_url = "/all/invoices/view/:id";

export const admin_system_users_url = "/admin-system-users";
export const admin_system_roles_url = "/admin-system-roles";
export const admin_system_roles_edit_url = "/admin-system-roles/edit";
export const admin_messages_url = "/all/providers-messages";

export const admin_reports_url = "/all/reports";
export const admin_reports_create_gusto_csv_url = "/all/reports/create-gusto";
export const admin_reports_create_quickbook_csv_url = "/all/reports/create-quickbook";
export const admin_reports_create_report_for_admin_url = "/all/reports/create-report-for-admin";

export const calendar_url = "/calendar";

export const provider_profile_url = "/provider/profile";
export const provider_documents_url = "/provider/profile/documents";
export const provider_edit_profile_url = "/provider/profile/edit-profile";

export const provider_students_assigment_url = "/provider/assignments";
export const provider_students_assigment_view_url = "/provider/assignments/assignments-view/:id";
export const student_calls_log_url = "/provider/assignments/student-calls-log/:id";
export const provider_student_profile_url = "/provider/assignments/profile/:id";
export const provider_student_documents_url = "/provider/assignments/documents/:id";
export const provider_progress_report_list_url = "/provider/assignments/progress-reports/:id";
export const provider_create_progress_report_url = "/provider/assignments/progress-reports/create/:id";
export const provider_student_invoices_url = "/provider/assignments/student-invoices";
export const provider_student_invoice_preview_url = "/provider/assignments/student-invoices/view/:id";

export const provider_progress_report_view_url = "/provider/assignments/progress-reports/view/:id";
export const provider_update_progress_report_url = "/provider/assignments/progress-reports/update/:id";
export const provider_invoices_url = "/provider/invoices";
export const provider_invoice_view_url = "/provider/invoices/view/:id";
export const provider_student_invoices_validation_url = "/provider/assignments/student-invoices-validation";
export const signer_signature_upload_url = "/signer-signature-upload";
export const provider_calls_log_url = "/all/provider-calls-log";
export const provider_sessions_url = "/all/provider-sessions";
export const provider_enter_session_url = "/provider/enter-session";

export const tutorials = "/tutorials";
export const help_url = "/help";
export const settings_url = "/settings";
export const personal_documents_url = "personal_documents";
export const internal_company_contacts_url = "/internal_company_contacts";

export const config = {
  sign_in_url: {
    key: "sign_in_url",
    url: sign_in_url,
  },
  home: {
    key: "home_url",
    url: home_url,
    permittedRoles: [1, 2, 4],
  },
  students: {
    key: "students_url",
    url: admmin_students_url,
    permittedRoles: [1, 2, 4],
  },
  sessions: {
    key: "sessions_url",
    url: admmin_sessions_url,
    permittedRoles: [1, 2],
  },
  create_students: {
    key: "create_students",
    url: admmin_create_student_url,
    permittedRoles: [1, 2],
  },
  students_goals: {
    key: "students_goals",
    url: `${admmin_students_goals_url}/:id`,
    permittedRoles: [1, 2, 4],
  },
  students_profile: {
    key: "students_profile",
    url: `${admmin_students_profile_url}/:id`,
    permittedRoles: [1, 2, 4],
  },
  students_edit_profile: {
    key: "students_edit_profile",
    url: `${admmin_students_edit_profile_url}/:id`,
    permittedRoles: [1, 2],
  },
  students_services: {
    key: "students_services",
    url: `${admmin_students_services_url}/:id`,
    permittedRoles: [1, 2, 4],
  },
  admin_students_assigments_list: {
    key: "admin_students_assigments_list",
    url: `${admin_students_assigments_list_url}`,
    permittedRoles: [1, 2],
  },
  admin_create_students_assigment: {
    key: "admin_create_students_assigment",
    url: admin_create_student_assigment_url,
    permittedRoles: [1, 2],
  },
  admin_students_assigments_list_edit: {
    key: "admin_students_assigments_list_edit",
    url: `${admin_students_assigments_list_edit_url}/:id`,
    permittedRoles: [1, 2],
  },
  personal_documents: {
    key: "personal_documents_url",
    url: personal_documents_url,
    permittedRoles: [1, 2, 4],
  },
  calendar: {
    key: "calendar_url",
    url: calendar_url,
    permittedRoles: [1, 2, 4],
  },
  providers: {
    key: "providers",
    url: admin_providers_url,
    permittedRoles: [1, 2],
  },
  admin_messages: {
    key: "/messages",
    url: admin_messages_url,
    permittedRoles: [1, 2],
  },
  create_provider: {
    key: "create_provider",
    url: admin_create_providers_url,
    permittedRoles: [1, 2],
  },
  provider_profile: {
    key: "provider_profile",
    url: `${admin_provider_profile_url}/:id`,
    permittedRoles: [1, 2, 4],
  },
  provider_edit_profile: {
    key: "provider_edit_profile",
    url: `${admin_provider_edit_profile_url}/:id`,
    permittedRoles: [1, 2],
  },
  provider_students: {
    key: "provider_students",
    url: admin_provider_students_url,
    permittedRoles: [1, 2],
  },
  provider_students_assigment: {
    key: "provider_students_assigment",
    url: `${provider_students_assigment_url}`,
    permittedRoles: [4],
  },
  provider_students_assigment_view: {
    key: "provider_students_assigment",
    url: `${provider_students_assigment_view_url}`,
    permittedRoles: [4],
  },
  provider_create_progress_report: {
    key: "create_progress_report_url",
    url: provider_create_progress_report_url,
    permittedRoles: [4],
  },
  provider_progress_report_view: {
    key: "progress_report_view_url",
    url: provider_progress_report_view_url,
    permittedRoles: [1, 4],
  },
  provider_update_progress_report: {
    key: "update_progress_report_url",
    url: provider_update_progress_report_url,
    permittedRoles: [1, 4],
  },
  provider_progress_report_list: {
    key: "provider_progress_report_list",
    url: provider_progress_report_list_url,
    permittedRoles: [4],
  },
  provider_invoices: {
    key: "provider_invoices",
    url: provider_invoices_url,
    permittedRoles: [4],
  },
  provider_student_invoices: {
    key: "provider_student_invoices",
    url: `${provider_student_invoices_url}/:id`,
    permittedRoles: [4],
  },
  provider_student_invoices_validation: {
    key: "provider_student_invoices_validation",
    url: `${provider_student_invoices_validation_url}/:id/:date`,
    permittedRoles: [4],
  },
  tutorials: {
    key: "tutorials_url",
    url: tutorials,
    permittedRoles: [1, 2, 4],
  },
  help: {
    key: "help_url",
    url: help_url,
    permittedRoles: [1, 2, 4],
  },
  settings: {
    key: "settings_url",
    url: settings_url,
    permittedRoles: [1, 2, 4],
  },
  internal_company_contacts: {
    key: "internal_company_contacts_url",
    url: internal_company_contacts_url,
    permittedRoles: [1, 2, 4],
  },
  create_session: {
    key: "create_session_url",
    url: `${create_session_url}`,
    permittedRoles: [1, 2, 4],
  },
  edit_session: {
    key: "edit_session_url",
    url: `${edit_session_url}`,
    permittedRoles: [1, 2, 4],
  },
  session_notes: {
    key: "session_notes_url",
    url: `${session_notes_url}/:id`,
    permittedRoles: [4],
  },
  admin_reports: {
    key: "reports_url",
    url: admin_reports_url,
    permittedRoles: [1, 2],
  },
  admin_reports_create_gusto_csv: {
    key: "reports_create_gusto_csv_url",
    url: admin_reports_create_gusto_csv_url,
    permittedRoles: [1, 2],
  },
  admin_reports_create_quick_csv: {
    key: "reports_create_quickbook_csv_url",
    url: admin_reports_create_quickbook_csv_url,
    permittedRoles: [1, 2],
  },
  admin_reports_create_report_for_admin: {
    key: "admin_reports_create_report_for_admin_url",
    url: admin_reports_create_report_for_admin_url,
    permittedRoles: [1, 2],
  },
  add_new_goal: {
    key: "add_new_goal",
    url: admmin_students_add_new_goal_url,
    permittedRoles: [1, 2],
  },
  generate_goal: {
    key: "generate_goal",
    url: admin_students_gererate_goal_url,
    permittedRoles: [1, 2],
  },
  edit_goal: {
    key: "edit_goal",
    url: admin_students_edit_goal_url,
    permittedRoles: [1, 2],
  },
  add_new_services: {
    key: "add_new_services",
    url: admin_students_add_new_services_url,
    permittedRoles: [1, 2],
  },
  edit_service: {
    key: "edit_service",
    url: admin_students_edit_services_url,
    permittedRoles: [1, 2],
  },
  banks_of_values: {
    key: "banks_of_values",
    url: banks_of_values_url,
    permittedRoles: [1, 2],
  },
  banks_of_values_school: {
    key: "banks_of_values_school",
    url: banks_of_values_school_url,
    permittedRoles: [1, 2],
  },
  banks_of_values_school_representatives: {
    key: "banks_of_values_school_representatives",
    url: banks_of_values_school_representatives_url,
    permittedRoles: [1, 2],
  },
  banks_of_values_goals: {
    key: "banks_of_values_goals",
    url: banks_of_values_goals_url,
    permittedRoles: [1, 2],
  },
  banks_of_values_add_new_goal: {
    key: "banks_of_values_add_new_goal",
    url: `${banks_of_values_add_new_goal_url}/:id`,
    permittedRoles: [1, 2],
  },
  banks_of_values_edit_goal: {
    key: "banks_of_values_edit_goal",
    url: `${banks_of_values_edit_goal_url}/:id`,
    permittedRoles: [1, 2],
  },
  admin_system_users: {
    key: "admin_system_users",
    url: admin_system_users_url,
    permittedRoles: [1, 2],
  },
  admin_system_roles: {
    key: "admin_system_roles",
    url: admin_system_roles_url,
    permittedRoles: [1, 2],
  },
  admin_system_roles_edit: {
    key: "admin_system_roles_edit",
    url: `${admin_system_roles_edit_url}/:roleName/:roleId`,
    permittedRoles: [1, 2],
  },
  admin_students_documents: {
    key: "admin_students_documents",
    url: `${admin_students_documents_url}/:id`,
    permittedRoles: [1, 2],
  },
  provider_documents: {
    key: "provider_documents",
    url: `${provider_documents_url}/:id`,
    permittedRoles: [4],
  },
  admin_provider_documents_url: {
    key: "admin_provider_documents",
    url: `${admin_provider_documents_url}/:id`,
    permittedRoles: [1, 2],
  },
  provider_profile_url: {
    key: "provider_profile",
    url: `${provider_profile_url}`,
    permittedRoles: [4],
  },
  provider_edit_profile_url: {
    key: "provider_edit_profile",
    url: `${provider_edit_profile_url}`,
    permittedRoles: [4],
  },
  signer_signature_upload: {
    key: "signer_signature_upload",
    url: `${signer_signature_upload_url}/:id`,
  },
  admin_invoices: {
    key: "admin_invoices",
    url: admin_invoices_url,
    permittedRoles: [1, 2],
  },
  admin_email_preview: {
    key: "admin_email_preview",
    url: `${admin_email_preview_url}/:invoicesId/:date`,
    permittedRoles: [1, 2],
  },
  admin_invoice_view: {
    key: "admin_invoice_view",
    url: admin_invoice_view_url,
    permittedRoles: [1, 2],
  },
  student_calls_log: {
    key: "student-calls-log",
    url: student_calls_log_url,
    permittedRoles: [4],
  },
  provider_calls_log: {
    key: "provider-calls-log",
    url: provider_calls_log_url,
    permittedRoles: [4],
  },
  provider_sessions: {
    key: "provider_sessions",
    url: provider_sessions_url,
    permittedRoles: [4],
  },
  provider_student_profile: {
    key: "provider_student_profile",
    url: provider_student_profile_url,
    permittedRoles: [4],
  },
  provider_student_documents: {
    key: "provider_student_documents",
    url: provider_student_documents_url,
    permittedRoles: [4],
  },
  provider_student_invoice_preview: {
    key: "provider_student_invoice_preview",
    url: provider_student_invoice_preview_url,
    permittedRoles: [4],
  },
  provider_invoice_view: {
    key: "provider_invoice_view",
    url: provider_invoice_view_url,
    permittedRoles: [4],
  },
  provider_enter_session: {
    key: "provider_enter_session",
    url: provider_enter_session_url,
    permittedRoles: [4],
  },
  admin_provider_calls: {
    key: "admin_provider_calls",
    url: admin_provider_calls_url,
    permittedRoles: [1, 2],
  },
};
