import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

export default function Pdf({ file }) {
  const [numPages, setNumPages] = useState(1);

  const getPages = () => {
    let content = [];
    for (let i = 0; i < numPages; i++) {
      content.push(<div key={i} style={{ padding: "20px" }}><Page pageNumber={i + 1} /></div>);
    }
    return content;
  };

  const onDocumentLoadSuccess = (d) => {
    setNumPages(d.numPages);
  };

  return (
    <>
      <div className="react-pdf__background"/>
      <div className="react-pdf__body">
        <div>
          {file && <Document
            file={file}
            options={{ useWorkerFetch: false }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {getPages()}
          </Document>}
        </div>
      </div>
    </>
  );
}
