import React from "react";
import PropTypes from "prop-types";

import TableConfigTd from "./TableRowConfigTd";
import { Button } from "../../Button";
import { CheckBox } from "../../Checkbox";

const TableRow = ({
  cols, id,
  modalData, onDelete,
  onEdit, state,
  setState, isContext,
  contextData, setModal,
  index, hasAcceptButton,
  onAccept, onReject,
  withoutOption, isButtonShow,
  showData, customData,
  onClick, isTableForClick,
  hasCheckboxes, isAllChecked,
  checkCheckboxes, checkboxes,
  showPreview, isService, modalHeight,
}) => (
  <div className="table__row-wrapper">
    <div
      className={`table__row ${isTableForClick ? "isTableForClick" : null}`}
      data-index={index}
      onClick={() => (isTableForClick ? onClick(id) : null)}
    >
      {cols && cols.map(({ id, text }) => (
        <div key={id} className="table__td ">
          {text}
        </div>
      ))}
      {hasAcceptButton
        && <div className="table__td" style={{ minWidth: "190px" }}>
          <span style={{ display: "flex" }}>
            <Button color="primary" variant="contained" onClick={() => onAccept(id)}>Accept</Button>
            <span style={{ width: "8px" }}/>
            <Button color="secondary" variant="contained" onClick={() => onReject(id)}>Reject</Button>
          </span>
        </div>
      }
      {hasCheckboxes && <div className="table__td" style={{ maxWidth: "60px" }}>
        <CheckBox checked={checkboxes[id]} nativeChange={(e) => checkCheckboxes(id, e.target.checked)}/>
      </div>}
    </div>
    <TableConfigTd
      hasAcceptButton={hasAcceptButton}
      id={id}
      modalData={modalData}
      onDelete={onDelete}
      onEdit={onEdit}
      state={state}
      setState={setState}
      isContext={isContext}
      contextData={contextData}
      setModal={setModal}
      withoutOption={withoutOption}
      isButtonShow={isButtonShow}
      showData={showData}
      customData={customData}
      showPreview={showPreview}
      isService={isService}
      modalHeight={modalHeight}
    />
  </div>
);
TableRow.propTypes = {
  cols: PropTypes.array,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  isContext: PropTypes.bool,
  contextData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(undefined),
  ]),
  state: PropTypes.number,
  setState: PropTypes.func,
  setModal: PropTypes.func,
  index: PropTypes.number,
  hasAcceptButton: PropTypes.bool,
  isButtonShow: PropTypes.bool,
  showData: PropTypes.func,
  onClick: PropTypes.func,
  hasCheckboxes: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  checkCheckboxes: PropTypes.func,
  showPreview: PropTypes.bool,
  modalHeight: PropTypes.number,
};

export default TableRow;
