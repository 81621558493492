import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Router from "./routing";
import Authorization from "./core/Authorization";
import store from "./store";
import "./styles/index.scss";
import Snackbar from "./components/Snackbar/Snackbar";
import AppVersion from "./components/AppVersion";

function App() {
  return (
    <BrowserRouter getUserConfirmation={() => { }}>
      <Provider store={store}>
        <Authorization>
          <Snackbar />
          <Router />
          <AppVersion />
        </Authorization>
      </Provider>
    </BrowserRouter>

  );
}

export default App;
