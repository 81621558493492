import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const providerInvoicesListSlice = createSlice({
  name: "providerInvoicesListSlice",
  initialState: {
    data: {
      invoices: [],
      pagination: {},
    },
    checkedInvoices: [],
    invoiceEmails: [],
    invoice: {},
  },
  reducers: {
    setInvoices: (state, { payload }) => {
      state.data = payload;
    },
    setCheckedInvoices: (state, { payload }) => {
      state.checkedInvoices = payload;
    },
    setInvoiceEmails: (state, { payload }) => {
      state.invoiceEmails = payload;
    },
    setInvoice: (state, { payload }) => {
      state.invoice = payload;
    },
  },
});

export default providerInvoicesListSlice.reducer;

export const { setInvoices } = providerInvoicesListSlice.actions;

export const getInvoicesList = (data, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...data, limit, offset,
    });
    const response = await axios(`/reports/invoices-list?${params}`);
    dispatch(setInvoices(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}
