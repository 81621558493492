import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  documents: [],
};

const getDocumentsSlice = createSlice({
  name: "documents",
  initialState: {
    documents: [],
  },
  reducers: {
    getDocuments(state, { payload }) {
      state.documents = payload;
    },
    resetState() {
      return initialState;
    },
  },
});

const { getDocuments, resetState } = getDocumentsSlice.actions;

export const reset = () => async (dispatch) => {
  dispatch(resetState());
};

export const getProviderStudentDocuments = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/student/documents-list-by-student/${id}`);
    dispatch(getDocuments(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
