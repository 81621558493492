import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import Table from "../../../../../components/Table/Table";
import { deleteStudentServiceAction, getStudentServicesAction } from "./store";
import { getStudentByIdAction } from "../studentProfile/store";
import tableFormater from "../../../../../utils/tableFormater";
import { transformServicesData, transformValueField } from "./helpers/transformServicesData";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import Pagination from "../../../../../components/Pagination/Pagination";

import "./styles/index.scss";

import {
  admin_students_add_new_services_url,
  admin_students_edit_services_url,
  admmin_students_profile_url,
  admmin_students_url,
} from "../../../../../configs/router_urls";
import { headerConfig, info } from "./config";

function StudentServices() {
  const table = useRef();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { result: data, pagination } = useSelector(({ studentServiceReducer }) => studentServiceReducer.services);
  const student = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
  const assignmentList = useSelector(({ adminAssignmentsReducer }) => adminAssignmentsReducer.data.result);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [serviceID, setServiceID] = useState();
  const [state, setState] = useState(0);
  const [deletedId, setDeleted] = useState(null);

  const openConfirmWindow = (id) => {
    setModalDelete(!modalDelete);
    setServiceID(id);
  };

  const isDel = (id) => {
    const assignmentListByStudent = assignmentList.filter((el) => el.studentId === +params.id);
    const serviceList = assignmentListByStudent.map((el) => el.serviceTypeNo);
    const ser = data.find((el) => +el.serviceId === +id);
    return serviceList.includes(Number(ser.serviceTypeNo));
  };

  const deleteService = (id, studentID) => {
    setConfirm(!confirm);
    setModalDelete(!modalDelete);
    const res = isDel(id);
    res ? dispatch(snackbarOpen({
      isOpen: true, timeout: 2000, text: "Assigned services cannot be deleted", type: "error",
    }))
      : dispatch(deleteStudentServiceAction(id, studentID));

    setDeleted(id);
    setCurrentIndex(0);
  };

  const paginationClick = (offset) => {
    dispatch(getStudentServicesAction(params.id, offset));
    table.current.scrollTop = 0;
    setState(0);
  };

  const infoMessage = (message) => {
    if (message) {
      dispatch(snackbarOpen(message));
    }
  };

  useEffect(() => {
    dispatch(getStudentServicesAction(params.id));
    dispatch(getStudentByIdAction(params.id));
  }, []);

  const getTargetService = (id) => {
    const [baseUrlServices, editServices] = admin_students_edit_services_url.split(":id");
    const targetService = data.filter((item) => item.serviceId === id);
    if (targetService[0].assign) {
      infoMessage(info);
    } else if (!targetService[0].assign) {
      history.push(`${baseUrlServices}${params.id}${editServices.split(":")[0]}${id}`);
    }
  };

  return (
    <div className='student-service'>
      <HeaderPage
        title={`Manage Student Services ${student?.studentName !== undefined ? student.studentName : ""}`}
        rootTitleName='All Students'
        rootPathName={admmin_students_url}
        childrenTitleNames={["Student profile", "Manage Student Services"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`]}
      />
      <div className='student-service__title'>
        <div className='student-service__button'>
          <Button variant='contained' color='primary' onClick={() => {
            const [baseUrlServices, addNewServices] = admin_students_add_new_services_url.split(":id");
            history.push(`${baseUrlServices}${params.id}${addNewServices}`, student);
          }}>
            Add new service
          </Button>
        </div>

      </div>
      <div className="student-service__content">
        <ConfirmModal open={modalDelete} headerTitle="Are you sure deleting the service?">
          <Button variant='contained' color='primary' onClick={() => deleteService(serviceID, params.id)}>Yes, delete</Button>
          <Button variant='outlined' color='default' onClick={() => setModalDelete(!modalDelete)}>Cancel</Button>
        </ConfirmModal>
        <Table
          head={Object.values(headerConfig)}
          state={state}
          setState={setState}
          data={tableFormater(transformServicesData(transformValueField(data)), headerConfig)}
          onDelete={(id) => { openConfirmWindow(id); }}
          onEdit={(id) => { getTargetService(id); }}
          tableRef={table}
          deletedId={deletedId}
        />
        <Pagination
          {...pagination}
          onClick={paginationClick}
          currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}
        />
      </div>
    </div>
  );
}

export default StudentServices;
