import React, { useEffect, useRef, useState } from "react";
// import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";

import { admin_system_roles_url } from "../../../../configs/router_urls";
import HeaderPage from "../../../../components/HeaderPage";
import RouterPrompt from "../../../../components/RouterPromptWindow/RouterPrompt";
import { clearPermissions, getPermissionForRoleAction, updatePermissionForRoleAction } from "../../store";
import FormikButton from "../../../../components/Button";
import FormikCheckBox from "../../../../components/Checkbox";
import { snackbarOpen } from "../../../../components/Snackbar/store/snackbar.slice";
import { info } from "./config";

function Superadmin() {
  const formRef = useRef();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const permissions = useSelector(({ systemRolesReducer }) => systemRolesReducer.permissions);

  const [formikValues, setFormikValues] = useState({});
  const [valueIsChange, setValueIsChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [hidePrompt, setHidePrompt] = useState(false);

  useEffect(() => {
    dispatch(clearPermissions());
    dispatch(getPermissionForRoleAction(params.roleId));
  }, []);

  useEffect(() => {
    setFormikValues(permissions.reduce((a, b) => ({ ...a, [b.roleId]: b.isRoleEnabled }), {}));
  }, [permissions]);

  const onSubmit = (value) => {
    setShowPrompt(false);
    const data = permissions.map((item) => ({
      roleId: item.roleId,
      roleName: item.roleName,
      isRoleEnabled: value[item.roleId],
    }));
    dispatch(updatePermissionForRoleAction(params.roleId, data));
    infoMessage(info);
  };

  const infoMessage = (message) => {
    if (message) {
      dispatch(snackbarOpen(message));
    }
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  return (
    <>
      <HeaderPage
        title={`${params.roleName} edit permissions`}
        rootPathName={admin_system_roles_url}
        rootTitleName="System role"
        childrenTitleNames={[params.roleName]}
      />

      <div className=''>
        {!!Object.keys(formikValues).length && <Formik
          initialValues={formikValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({
            dirty,
          }) => (
            <React.Fragment>
              <RouterPrompt
                hidePrompt={hidePrompt}
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div style={{ marginLeft: "auto", width: "196px", marginBottom: "10px" }} onClick={() => setHidePrompt(true)}>
                <FormikButton history={history} onClick={true} variant='contained' color='primary'>
                  Save
                </FormikButton>
              </div>
              <div style={{ background: "#ffffff" }} className="grid-wrapper">
                {permissions.map((item) => (
                  <div className='provider_profile__field' key={item.roleId}>
                    <FormikCheckBox name={`${item.roleId}`} label={item.roleName} />
                  </div>
                ))}
              </div>
              {dirty === true ? setValueIsChange(true) : null}
            </React.Fragment>
          )}
        </Formik>}
      </div>
    </>
  );
}

export default Superadmin;
