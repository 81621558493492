export const headerConfig = {
  studentName: {
    title: "Name",
    value: "firstName",
    order: 1,
  },
  osisNo: {
    title: "OSIS",
    value: "osisNo",
    order: 2,
  },
  birthDt: {
    title: "DOB",
    value: "birthDt",
    order: 3,
  },
  pRPPhone: {
    title: "Phone",
    value: "pRPPhoneNo",
    order: 4,
  },
  schoolName: {
    title: "School",
    value: "schoolName",
    order: 5,
  },
  typeName: {
    title: "Service type",
    value: "serviceTypeName",
    order: 6,
  },
  startDt: {
    title: "Service Begins",
    value: "startDt",
    order: 7,
  },
  endDt: {
    title: "Service Ends",
    value: "endDt",
    order: 8,
  },
  providerName: {
    title: "Provider Name",
    value: "providerName",
    order: 9,
  },

};

export const info = {
  isOpen: true,
  timeout: 3000,
  text: "Assignments with interactions cannot be edited",
  type: "error",
};
