import clsx from "clsx";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import SvgMaker from "./svgMaker/SvgMaker";

function FormikCheckBox({ name, ...props }) {
  const {
    touched, errors, values, handleChange,
  } = useFormikContext();
  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";
  return <CheckBox error={fieldTouched} name={name} checked={values[name]} nativeChange={handleChange(name)} {...props}/>;
}

function CheckBox({
  checked = false,
  disabled,
  error = "",
  onClick = null,
  label,
  name,
  checkboxRef = null,
  nativeChange = () => {},
  profile,
}) {
  const nativeCheckBoxRef = useRef(null);
  const onCheckBoxClick = () => {
    // onClick(event);
    nativeCheckBoxRef.current.click();
  };
  return (
    <div className={clsx(profile ? "checkbox_profile" : "checkbox")} onClick={disabled ? () => {} : onCheckBoxClick}>
      <div ref={checkboxRef} name={name} className={clsx("checkbox__box", { checkbox__checked: checked }, { checkbox__disabled: disabled })}>
        {checked && <SvgMaker name='tick' fill='#ffffff' width={10} height={8}/>}
      </div>
      <div className={clsx("checkbox__label", { "checkbox__label-error": !!error })}>
        {label}
      </div>
      <input type='checkbox' checked={checked} name={name} ref={nativeCheckBoxRef} style={{ display: "none" }} onChange={onClick || nativeChange}/>
    </div>
  );
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  checkboxRef: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  nativeChange: PropTypes.func,
};

FormikCheckBox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  nativeChange: PropTypes.func,
};

export default FormikCheckBox;
export {
  CheckBox,
};
