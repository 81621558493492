import axios from "axios";

export const deleteEventByIdAction = (id, cb) => async () => {
  try {
    await axios.delete(`/session/event/${id}`);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
