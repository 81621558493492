import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import HeaderPage from "../../../../../../components/HeaderPage";
import Paper from "../../../../../../components/Paper";
import { SelectFilter } from "../../../../../../components/Select";
import FormikDateField from "../../../../../../components/DateField";
import FormikButton, { Button } from "../../../../../../components/Button";

import { admin_reports_url } from "../../../../../../configs/router_urls";
import { fieldConfig, formikInitialValues, validationSchema } from "./configs";

import "./styles/index.scss";
import { getAssignmentListAuthNo, getAssignmentListOsisNo } from "./store";

function CreateAdminReport() {
  const dispatch = useDispatch();

  const allStudents = useSelector(({ adminReducer }) => adminReducer.allStudents.result);
  const dataOsisNo = useSelector(({ adminReportReducer }) => adminReportReducer.osisNo.result);
  const dataAuthNo = useSelector(({ adminReportReducer }) => adminReportReducer.authNo.result);

  const [filters, setFilters] = useState({});
  const [osises, setOsises] = useState([]);
  const [authNos, setAuthNos] = useState([]);

  useEffect(() => {
    dispatch(getAssignmentListOsisNo({ status: ["F"] }, null, 1000));
    dispatch(getAssignmentListAuthNo({ status: ["F"] }, null, 1000));
  }, []);

  useEffect(() => {
    const resultOsis = [];
    dataOsisNo.forEach((a, i) => {
      if (!resultOsis.find((r) => r.value === a.osisNo)) resultOsis.push({ id: i, value: a.osisNo, title: a.osisNo });
    });
    setOsises(resultOsis);
  }, [dataOsisNo]);

  useEffect(() => {
    const resultAuth = [];
    dataAuthNo.forEach((a, i) => {
      if (!resultAuth.find((r) => r.value === a.authorizationNo)) resultAuth.push({ id: i, value: a.authorizationNo, title: a.authorizationNo });
    });
    setAuthNos(resultAuth);
  }, [dataAuthNo]);

  const resetOneFilter = (obj) => {
    const newObj = { ...filters };
    delete newObj[obj];
    setFilters(newObj);
  };

  const templateFilter = (item, data) => (
    <div key={item.id + item.name} className="reportForAdmin__filters-input">
      <div>
        <SelectFilter
          {...item}
          data={data}
          filters={filters}
          changeFilter={(value) => setFilters({ ...filters, [item.name]: value })}
          resetFilter={resetOneFilter}
        />
      </div>
    </div>);

  const formatterFilters = (data) => Object.keys(data).reduce((a, b) => {
    if (data[b].length || Number.isInteger(data[b])) return `${a}${b}[]=${data[b]}&`;
    return a;
  }, "");

  const formatterDate = (data) => {
    if (data.mandateTime) {
      const date = data.mandateTime.split(" to ");
      return `&startDate=${moment(date[0]).format("YYYY-MM-DD")}T00:00:00%2B00:00&endDate=${moment(date[1]).format("YYYY-MM-DD")}T23:59:59%2B00:00`;
    }
    if (data.schoolYear) {
      return `&startDate=${+data.schoolYear - 1}-07-01T00:00:00%2B00:00&endDate=${data.schoolYear}-08-31T23:59:59%2B00:00`;
    }
    return "";
  };

  const generation = async (values) => {
    formatterDate(values);
    const { data } = await axios.get(`/reports/report-for-administrator?${formatterFilters(filters)}${formatterDate(values)}`, {
      responseType: "arraybuffer",
    });
    const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/")[url.split("/").length - 1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isValid = (values) => !Object.keys(values).find((key) => values[key]) && !Object.keys(filters).find((key) => filters[key]);

  return (
    <div>
      <HeaderPage
        title="Create Admin Department Report #1"
        rootPathName={admin_reports_url}
        rootTitleName='Reports'
        childrenTitleNames={["Create Admin Department Report #1"]}
      />
      <Paper>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={validationSchema}
          onSubmit={generation}
        >
          {({ values, resetForm }) => (
            <div>
              <div className="reportForAdmin">
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "filterStudent": {
                    return templateFilter(item, allStudents);
                  }
                  case "filterOsis": {
                    return templateFilter(item, osises);
                  }
                  case "filterAuthorizNum": {
                    return templateFilter(item, authNos);
                  }
                  case "datefield": {
                    let disabled = false;
                    if (item.name === "schoolYear") disabled = !!values.mandateTime;
                    if (item.name === "mandateTime") disabled = !!values.schoolYear;
                    return (
                      <div className={item.class} key={item.id + item.name}>
                        <FormikDateField {...item} disabled={disabled}/>
                      </div>
                    );
                  }
                  case "empty": {
                    return (
                      <div key={item.id + item.name}/>
                    );
                  }
                  default: return "";
                  }
                })}
              </div>
              <div className="reportForAdmin__btn">
                <div>
                  <Button variant="dashed" color="primary" onClick={() => {
                    resetForm();
                    setFilters({});
                  }}>
                    Refresh filters
                  </Button>
                </div>
                <div>
                  <FormikButton variant="contained" color="primary" disabled={isValid(values)}>
                    Generate
                  </FormikButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default CreateAdminReport;
