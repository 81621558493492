import React from "react";
import { useHistory } from "react-router-dom";
import { create_session_url } from "../../../configs/router_urls";
import { Button } from "../../../components/Button";

import "./index.scss";

function CalendarHeader(props) {
  const { viewMode, setViewMode } = props;
  const history = useHistory();
  const createSession = () => history.push(create_session_url);

  return (
    <div className="calendar__nav">
      <div className="calendar__nav_mode">
        <Button
          color={viewMode === "day" ? "primary" : "secondary"}
          onClick={() => setViewMode("day")}
        >
          1 day
        </Button>
        <Button
          color={viewMode === "month" ? "primary" : "secondary"}
          onClick={() => setViewMode("month")}
        >
          1 month
        </Button>
      </div>
      <div className="calendar__button">
        <Button
          variant="contained"
          color="primary"
          onClick={createSession}
        >
            Schedule session
        </Button>
      </div>
    </div>
  );
}

export default CalendarHeader;
