import React from "react";
import Snackbar from "../components/Snackbar/Snackbar.js";
import Drawer from "./Drawer/index.jsx";
import Header from "./header";
import Laoyut from "./Layout";

function Main({ children, roles }) {
  return (
    <div className="main">
      <div className="main__header">
        <Header />
      </div>
      <div className="main__content">
        <Drawer roles={roles} />
        <Laoyut>{children}</Laoyut>
      </div>

      <Snackbar/>
    </div>
  );
}

export default Main;
