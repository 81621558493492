import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "groupName",
    label: "Title",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 2,
    name: "groupCd",
    label: "Code",
    placeholder: "",
    required: false,
    type: "textfield",
  },
];

const formikInitialValues = {
  groupName: "",
  groupCd: "",
};

const validationSchema = {
  groupName: Yup.string().max(128, "Group name max length should be 128 characters.").required("Required field"),
  groupCd: Yup.string().nullable().max(16, "Code name max length should be 16 characters."),
};

const info = {
  isOpen: true,
  timeout: 3000,
  text: "Select Group first, please",
  type: "information",
};

export {
  fieldConfig, formikInitialValues, validationSchema, info,
};
