/* eslint-disable keyword-spacing */
import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderPage from "../../../../components/HeaderPage";
import { Button } from "../../../../components/Button";
import {
  createNewGroup,
  deleteGoalById,
  deleteGroupById,
  findAllSubgroup,
  getDictionaryGoalsAction,
  getGroupIds,
  getIdActiveGroup,
  getInfoForGoalById,
  getInfoForGroupById,
  getSubGroupIds,
  getTitleGroup,
  moveGoalToGroup,
  moveGroupToAnotherGroup,
  updateGroup,
} from "./store";
import SvgMaker from "../../../../components/svgMaker/SvgMaker";
import { PaperModal } from "../../../../components/modal/Modal";
import FormikTextField from "../../../../components/TextField";
import ModalWindow from "./components/ModalWindow";
import SubGroup from "./components/subGroup";
import { snackbarOpen } from "../../../../components/Snackbar/store/snackbar.slice";

import "./style/index.scss";

import {
  fieldConfig, formikInitialValues, info, validationSchema,
} from "./config";
import {
  banks_of_values_add_new_goal_url,
  banks_of_values_edit_goal_url,
  banks_of_values_url,
} from "../../../../configs/router_urls";

import { addSpaces, transform } from "./utils/parseString";

function GoalsDictionaries() {
  const dispatch = useDispatch();
  const history = useHistory();

  const refCreateGroup = useRef();
  const refUpdateGroup = useRef();

  const {
    dictionaryGoals, infoGroup, idActiveGroup, infoGoal, arrayGroupIds, titleGroup, parentGroupId, subGroupIds, groupIds,
  } = useSelector(({ dictionaryGoalsReducer }) => dictionaryGoalsReducer);

  const [modalWindowForDelete, setModalWindowForDelete] = useState(false);
  const [modalWindowForMove, setModalWindowForMove] = useState(false);
  const [modalWindowForCreate, setModalWindowForCreate] = useState(false);
  const [modalWindowForEdit, setModalWindowForEdit] = useState(false);
  const [discardWindowForCreate, setDiscardWindowForCreate] = useState(false);
  const [discardWindowForEdit, setDiscardWindowForEdit] = useState(false);
  const [discardWindowForMove, setDiscardWindowForMove] = useState(false);
  const [windowForDeleteGoal, setWindowForDeleteGoal] = useState({ status: false, goalId: null, goalGroupId: null });
  const [arrayIdForMove, setArrayIdForMove] = useState([]);
  const [arrayIdForCreate, setArrayIdForCreate] = useState([]);
  const [arrayIdForEdit, setArrayIdForEdit] = useState([]);
  const [style, setStyle] = useState(false);
  const [type, setType] = useState("");

  const allGroup = [];

  useEffect(() => {
    if (idActiveGroup !== 0) {
      dispatch(findAllSubgroup(dictionaryGoals, idActiveGroup));
    }
  }, [idActiveGroup]);

  useEffect(() => {
    dispatch(getDictionaryGoalsAction());
  }, []);

  useEffect(() => {
    const collGroup = [];
    groupIds.forEach((el) => collGroup.push(document.getElementById(el)));
    collGroup.forEach((el) => {
      if (el !== null) {
        el.dataset.status = "true";
        [...el?.children].forEach((el) => el.style.display = "block");
        el.parentElement.firstElementChild.firstElementChild.style.transform = "rotate(90deg)";
      }
    });
    let collSubGroup = [];
    subGroupIds.forEach((el) => collSubGroup.push(document.getElementById(el)));
    collSubGroup.forEach((el) => {
      if (el !== null) {
        el.firstElementChild.dataset.status = "true";
        [...el?.parentElement?.children].forEach((e) => {
          e.firstElementChild.style.display = "block";
          e.style.height = "auto";
        });
        el.firstElementChild.style.transform = "rotate(90deg)";
      }
    });
    if (idActiveGroup !== 0) {
      document.getElementById(idActiveGroup).className = "activeGroupGoals";
    }
  }, []);

  useEffect(() => {
    if (idActiveGroup) {
      dispatch(getInfoForGroupById(idActiveGroup));
    }
  }, [idActiveGroup]);

  useEffect(() => {
    dictionaryGoals.forEach((group) => allGroup.push(group.id));
    if (parentGroupId !== null && allGroup.includes(parentGroupId)) {
      const group = document.getElementById(`${parentGroupId}`);
      if (group.dataset.status === "true") {
        [...group.children].forEach((el) => { el.style.display = "block"; });
      }
    }
    if (parentGroupId !== null && !allGroup.includes(parentGroupId)) {
      const subGroup = document.getElementById(`${parentGroupId}`);
      if (subGroup?.firstElementChild.dataset.status === "true") {
        subGroup.firstElementChild.style.transform = "rotate(90deg)";
        [...subGroup.parentElement.children].forEach((el) => (!el.firstElementChild.style.display ? el.firstElementChild.style.display = "block" : null));
      }
    }
  }, [dictionaryGoals]);

  const getInfoGoal = (id) => {
    dispatch(getInfoForGoalById(id));
  };

  const addNewGoal = () => {
    idActiveGroup !== 0
      ? history.push(`${banks_of_values_add_new_goal_url}/${idActiveGroup}`)
      : dispatch(snackbarOpen(info));
  };

  const editGroup = () => {
    refUpdateGroup.current.setErrors({});
    refUpdateGroup.current.setValues({ groupName: titleGroup.name, groupCd: titleGroup.code });
    idActiveGroup !== 0
      ? setModalWindowForEdit(true)
      : dispatch(snackbarOpen(info));
  };

  const deleteGroup = () => {
    idActiveGroup !== 0
      ? setModalWindowForDelete(true)
      : dispatch(snackbarOpen(info));
    setType("");
  };

  const openParent = (event, idGroup, name, code) => {
    const elem = document.getElementById(idGroup);
    if (elem.className === "activeGroupGoals") {
      elem.style.height = "0px";
      event.currentTarget.childNodes[0].style.transform = "rotate(0deg)";
      dispatch(getGroupIds(idGroup, "close"));
      elem.dataset.status = "false";
      if (event.currentTarget.nextSibling.childNodes[0]) {
        event.currentTarget.parentElement.style.marginBottom = "8px";
        event.currentTarget.nextSibling.childNodes.forEach((el) => {
          el.style.display = "none";
        });
      }
      elem.className = "inActiveGroupGoals";
    } else {
      dispatch(getIdActiveGroup(idGroup));
      dispatch(getTitleGroup({ name, code }));
      dispatch(getGroupIds(idGroup, "open"));
      elem.className = "activeGroupGoals";
      elem.style.height = "auto";
      elem.dataset.status = "true";
      event.currentTarget.childNodes[0].style.transform = "rotate(90deg)";
      if (event.currentTarget.nextSibling.childNodes[0]) {
        event.currentTarget.parentElement.style.marginBottom = "20px";
        event.currentTarget.nextSibling.childNodes.forEach((el) => {
          el.style.display = "block";
        });
      }
    }
  };

  const openChild = (event, className, id, code) => {
    event.currentTarget.childNodes[0].dataset.status = "false";
    const elements = document.getElementsByClassName(className);
    const elem = document.getElementById(id);
    if (elem.className === "activeGroupGoals") {
      dispatch(getSubGroupIds(id, "close"));
      [...elements].forEach((elem) => {
        elem.style.height = "0px";
        event.currentTarget.childNodes[0].style.transform = "rotate(0deg)";
        event.currentTarget.childNodes[0].dataset.status = "false";
        [...elements].forEach((el) => el.firstElementChild.style.display = "none");
      });
      elem.className = "inActiveGroupGoals";
    } else {
      dispatch(getIdActiveGroup(id));
      dispatch(getTitleGroup({ name: className.split("/")[1], code }));
      dispatch(getSubGroupIds(id, "open"));
      const coll = document.getElementsByClassName("activeGroupGoals");
      [...coll].map((el) => el.className = "inActiveGroupGoals");
      elem.className = "activeGroupGoals";
      [...elements].forEach((elem) => {
        elem.style.height = "auto";
        event.currentTarget.childNodes[0].style.transform = "rotate(90deg)";
        event.currentTarget.childNodes[0].dataset.status = "true";
        [...elements].forEach((el) => el.firstElementChild.style.display = "block");
      });
      if (!elements.length) {
        event.currentTarget.childNodes[0].dataset.status = "true";
      }
    }
  };

  return (
    <>
      <HeaderPage
        title="Goals"
        rootPathName={banks_of_values_url}
        rootTitleName="Banks of Values"
        childrenTitleNames={["Goals"]}
      />
      <div className="goalsDictionaries">
        <div className="goalsDictionaries__btn">
          <div className="goalsDictionaries__btn__left">
            <div>
              <Button variant="contained" onClick={() => setModalWindowForCreate(true)}>
                Add new group
              </Button>
            </div>
          </div>

          <div className="goalsDictionaries__btn__right">
            <div>
              <Button variant="contained" onClick={addNewGoal}>
                Add goal
              </Button>
            </div>

            <div>
              <Button variant="outlined" color="grey" onClick={editGroup}>
                Edit group
              </Button>
            </div>

            <div>
              <Button variant="outlined" color="grey" onClick={deleteGroup}>
                Delete group
              </Button>
            </div>
          </div>
        </div>
        <div className="goalsDictionaries__info">
          <div className="goalsDictionaries__info__sidebar custom-scroll" >
            {dictionaryGoals.map((group) => (
              <div className="goalsGroup" key={group.id + group.name}>
                <div style={{ display: "flex", paddingBottom: "12px", alignItems: "center" }}
                  className={`${idActiveGroup === group.id ? "activeGroupGoals" : "inActiveGroupGoals"}`}
                  onClick={(e) => openParent(e, group.id, group.name, group.groupCd)}>
                  <div className="goalsGroup__icon" key={group.id + group.name}>
                    {group.subGroups.length
                      ? <SvgMaker width='18' height='18' name='arrowRightDict' fill="#495057" />
                      : <SvgMaker width='18' height='18' name='dotIcon'/>
                    }
                  </div>
                  <div className="goalsGroup__title">
                    <span>{`${group.name} ${group.groupCd || ""}`}</span>

                    <span>({group.goals.length} goals)</span></div>
                </div>
                <div id={group.id} key={group.id + group.name} data-status="false">
                  {group.subGroups.map((subgroup) => (
                    <SubGroup key={subgroup.id + subgroup.name} {...subgroup} open={openChild} activeGroup={idActiveGroup}/>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="goalsDictionaries__info__container custom-scroll">
            <div className="groupName">{titleGroup.name} {titleGroup.code}</div>
            {!infoGroup.length ? null : infoGroup.map((el, i) => (
              <div className="goalsDictionaries__info__goals" key={el.goalId + el.goalTitle}>
                <div>
                  <div className="info">
                    <div>{el.goalTitle} {el.goalCd}</div>

                    <div>{el.goalBody.split("\n").map((it) => <p>{it}</p>)}</div>
                  </div>
                  <div>
                    <div onClick={() => { getInfoGoal(el.goalId); setModalWindowForMove(true); }}>
                      <SvgMaker width='20' height='20' name="moveGoal" />
                    </div>

                    <div onClick={() => history.push(`${banks_of_values_edit_goal_url}/${el.goalId}`)}>
                      <SvgMaker width='20' height='20' name='editIcon' />
                    </div>

                    <div onClick={() => setWindowForDeleteGoal({ status: true, goalId: el.goalId, goalGroupId: el.goalGroupId })}>
                      <SvgMaker width='20' height='20' name='deleteIconDoc' />
                    </div>
                  </div>
                </div>
                {i === infoGroup.length - 1 ? null : <div className="line"/>}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Are you sure deleting the group of goals?"
          open={modalWindowForDelete}
          onClose={() => setModalWindowForDelete(false)}>
          <div className="modalWindowGoals__text">
            All groups and goals under this group will be deleted.
          </div>

          <div onClick={() => {
            dispatch(deleteGroupById(idActiveGroup));
            setModalWindowForDelete(false);
          }}>
            <Button variant="contained" color="primary">
                Yes, Delete
            </Button>
          </div>
          <Button variant="dashed" color="primary" onClick={() => setModalWindowForDelete(false)}>
            Cancel
          </Button>
        </PaperModal>
      </div>

      <ModalWindow
        val={arrayIdForMove}
        setVal={setArrayIdForMove}
        headerTitle={`Move ${infoGoal.goalTitle}`}
        buttonTitle="Move here"
        open={modalWindowForMove}
        onCancel={() => {
          if (arrayIdForMove.length) {
            setDiscardWindowForMove(true);
            setModalWindowForMove(false);
          } else {
            setModalWindowForMove(false);
          }
        }}
        dictionaryGoals={dictionaryGoals}
        onClose={() => {
          if (arrayIdForMove.length) {
            setDiscardWindowForMove(true);
            setModalWindowForMove(false);
          } else {
            setModalWindowForMove(false);
          }
        }}
        infoGoal={infoGoal}
        onConfirm={() => {
          dispatch(moveGoalToGroup(
            { goalId: infoGoal.goalId, newGoalGroupId: arrayIdForMove[arrayIdForMove.length - 1] },
            idActiveGroup,
          ));
          setModalWindowForMove(false);
          setArrayIdForMove([]);
        }}>
        <div/>
      </ModalWindow>

      <Formik
        initialValues={formikInitialValues}
        validationSchema={Yup.object(validationSchema)}
        innerRef={refCreateGroup}
        onSubmit={(values) => {
          setType("");
          setType(type);
          const newGroupId = arrayIdForCreate[arrayIdForCreate.length - 1];
          const data = typeof newGroupId === "number"
            ? { ...values, parentGroupId: arrayIdForCreate[arrayIdForCreate.length - 1] }
            : values;
          dispatch(createNewGroup(
            {
              ...data,
              groupName: data.groupName.trim(),
              groupCd: data.groupCd.trim(),
            }, () => {
              setModalWindowForCreate(false);
              refCreateGroup.current.resetForm();
              setArrayIdForCreate([]);
            },
          ));
        }
        }
      >
        {({ values, resetForm }) => (
          <>
            <ModalWindow
              val={arrayIdForCreate}
              setVal={setArrayIdForCreate}
              type={type}
              setType={setType}
              headerTitle="Create goals group"
              buttonTitle="Create"
              withFormik
              isCreate
              isHide={style}
              text="Create"
              open={modalWindowForCreate}
              onCancel={() => {
                if (values.groupName !== "" || values.groupCd !== "" || arrayIdForCreate.length) {
                  setDiscardWindowForCreate(true);
                  setStyle(true);
                } else {
                  setModalWindowForCreate(false);
                }
              }}
              dictionaryGoals={dictionaryGoals}
              onClose={() => {
                if (values.groupName !== "" || values.groupCd !== "" || arrayIdForCreate.length) {
                  setDiscardWindowForCreate(true);
                  setStyle(true);
                } else {
                  setModalWindowForCreate(false);
                  resetForm();
                }
              }}
              infoGoal={infoGoal}
              onConfirm={() => {}}>
              <div style={{ display: "flex", gap: "10px" }}>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
              </div>
            </ModalWindow>
          </>
        )}
      </Formik>

      <Formik
        enableReinitialize={true}
        innerRef={refUpdateGroup}
        initialValues={{ groupName: titleGroup.name, groupCd: titleGroup.code }}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={(values) => {
          setType("");
          setType(type);
          dispatch(updateGroup(
            {
              groupName: values.groupName.trim(),
              groupCd: values.groupCd !== null ? values.groupCd.trim() : "",
              goalGroupId: idActiveGroup,
            }, idActiveGroup,
          ));
          dispatch(getTitleGroup({ name: values.groupName, code: values.groupCd }));
          if (arrayIdForEdit.length !== 0) {
            const goalGroupId = arrayIdForEdit[arrayIdForEdit.length - 1];
            const data = typeof goalGroupId === "number"
              ? { goalGroupId: idActiveGroup, newGoalGroupId: arrayIdForEdit[arrayIdForEdit.length - 1] }
              : { goalGroupId: idActiveGroup, newGoalGroupId: null };
            dispatch(moveGroupToAnotherGroup(data));
            dispatch(getTitleGroup({ name: values.groupName, code: values.groupCd }));
          }
          setModalWindowForEdit(false);
          setArrayIdForEdit([]);
        }
        }
      >
        {({ dirty }) => (
          <>
            <ModalWindow
              val={arrayIdForEdit}
              setVal={setArrayIdForEdit}
              type={type}
              setType={setType}
              headerTitle="Update goals group"
              buttonTitle="Update"
              withFormik
              isUpdate
              text="Move"
              open={modalWindowForEdit}
              arrayGroupIds={arrayGroupIds}
              onCancel={() => {
                if (dirty || arrayIdForEdit.length) {
                  setDiscardWindowForEdit(true);
                  setModalWindowForEdit(false);
                } else {
                  setModalWindowForEdit(false);
                }
              }}
              dictionaryGoals={dictionaryGoals}
              onClose={() => {
                if (dirty || arrayIdForEdit.length) {
                  setDiscardWindowForEdit(true);
                  setModalWindowForEdit(false);
                } else {
                  setModalWindowForEdit(false);
                }
              }}
              infoGoal={infoGoal}
              onConfirm={() => {}}>
              <div style={{ display: "flex", gap: "10px" }}>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
              </div>
            </ModalWindow>
          </>
        )}
      </Formik>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Discard unsaved changes?"
          open={discardWindowForCreate}
          onClose={() => {
            setStyle(false);
            setDiscardWindowForCreate(false);
          }}>
          <div style={{ marginTop: "20px" }}/>
          <div onClick={() => {
            setDiscardWindowForCreate(false);
            refCreateGroup.current.values.groupCd = "";
            refCreateGroup.current.values.groupName = "";
            setModalWindowForCreate(false);
            setStyle(false);
            setArrayIdForCreate([]);
          }}>
            <Button variant="contained" color="primary">
            Discard
            </Button>
          </div>
          <Button variant="dashed" color="primary"
            onClick={() => {
              setStyle(false);
              setDiscardWindowForCreate(false);
            }}>
            Cancel
          </Button>
        </PaperModal>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Discard unsaved changes?"
          open={discardWindowForEdit}
          onClose={() => {
            setModalWindowForEdit(true);
            setDiscardWindowForEdit(false);
          }}>
          <div style={{ marginTop: "20px" }}/>
          <div onClick={() => {
            setDiscardWindowForEdit(false);
            setArrayIdForEdit([]);
          }}>
            <Button variant="contained" color="primary">
            Discard
            </Button>
          </div>
          <Button variant="dashed" color="primary"
            onClick={() => {
              setModalWindowForEdit(true);
              setDiscardWindowForEdit(false);
            }}>
            Cancel
          </Button>
        </PaperModal>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Discard unsaved changes?"
          open={discardWindowForMove}
          onClose={() => {
            setModalWindowForMove(true);
            setDiscardWindowForMove(false);
          }}>
          <div style={{ marginTop: "20px" }}/>
          <div onClick={() => {
            setDiscardWindowForMove(false);
            setArrayIdForMove([]);
          }}>
            <Button variant="contained" color="primary">
            Discard
            </Button>
          </div>
          <Button variant="dashed" color="primary"
            onClick={() => {
              setModalWindowForMove(true);
              setDiscardWindowForMove(false);
            }}>
            Cancel
          </Button>
        </PaperModal>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Are you sure deleting the goal?"
          open={windowForDeleteGoal.status}
          onClose={() => setWindowForDeleteGoal({ ...windowForDeleteGoal, status: false })}>
          <div onClick={() => {
            setWindowForDeleteGoal({ ...windowForDeleteGoal, status: false });
            dispatch(deleteGoalById(windowForDeleteGoal.goalId, windowForDeleteGoal.goalGroupId));
          }}>
            <Button variant="contained" color="primary">
            Yes, Delete
            </Button>
          </div>
          <Button variant="dashed" color="primary" onClick={() => {
            setWindowForDeleteGoal({ ...windowForDeleteGoal, status: false });
          }}>
            Cancel
          </Button>
        </PaperModal>
      </div>

    </>
  );
}

export default GoalsDictionaries;
