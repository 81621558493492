import moment from "moment";

export const dateFormat = (format, date = moment()) => {
  switch (format) {
  case "mmm-d-dot-lt":
    return moment(date).format("MMM D [,] LT");
  case "mm-slash-d-slash-yyyy":
    return moment(date).format("MM[/]D[/]YYYY");
  case "mmmm-do-dot-yyyy":
    return moment(date).format("MMMM Do[,] YYYY");
  case "mmm-d-dot-yyyy":
    return moment(date).format("MMM D[,] YYYY");
  case "d-mmm-yyyy":
    return moment(date).format("D MMM YYYY");
  case "yyyy-mm-dd":
    return moment(date).format("YYYY-MM-DD");
  case "mm-dd-yyyy": {
    return moment(date).format("MM-DD-YYYY");
  }
  case "mm": {
    return moment(date).format("MM");
  }
  case "LT": {
    return moment(date).format("LT");
  }
  default: return moment().format();
  }
};
