import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import Table from "../../../../../components/Table/Table";
import ConfirmModal from "../../../../../components/confirmModal";
import { TextField } from "../../../../../components/TextField";
import {
  headerConfig,
} from "./configs";
import "./styles/index.scss";
import { deleteGoalById, fetchStudentsGoalList } from "./store";
import {
  admmin_students_profile_url, admmin_students_add_new_goal_url, admin_students_edit_goal_url, admmin_students_url,
} from "../../../../../configs/router_urls";

import tableFormater from "../../../../../utils/tableFormater";
import { getStudentByIdAction } from "../studentProfile/store";
import { transformGoalFields } from "./helpers/transformGoalData";

function CreateStudentGoal() {
  const [value, setValue] = useState("");
  const [state, setState] = useState(0);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [goalId, setGoalId] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(({ studentsGoalReducer }) => studentsGoalReducer.studentsGoal.result);
  const student = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  const deleteHandler = (id) => {
    dispatch(deleteGoalById(id, params.id));
    setOpenModalDelete(false);
  };

  const editHandler = (id) => {
    const [goal, editGoal] = admin_students_edit_goal_url.split(":id");
    history.push(`${goal}${params.id}${editGoal}`, { ...data.find(({ goalId }) => +goalId === +id), studentName: student.studentName, goalId: id });
  };

  const onDelete = (id) => {
    setOpenModalDelete(true);
    setGoalId((data?.find((el) => el.goalId === id))?.goalId);
  };
  useEffect(() => {
    if (value === "" || value?.length < 3) {
      dispatch(fetchStudentsGoalList(params.id));
    } else if (value?.length >= 3) {
      dispatch(fetchStudentsGoalList(params.id, value));
    }
  }, [value]);
  useEffect(() => {
    dispatch(getStudentByIdAction(params.id));
  }, []);

  const addNewGoal = () => {
    const [goalUrl, addNewGoalUrl] = admmin_students_add_new_goal_url.split(":id");
    history.push(`${goalUrl}${params.id}${addNewGoalUrl}`, student);
  };

  return (
    <div>
      <HeaderPage
        title={`${student?.studentName} Goals`}
        rootTitleName='All Students'
        rootPathName={admmin_students_url}
        childrenTitleNames={["Student Profile", "Student Goals"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`]}
      />
      <div className='student-goal__nav'>
        <div className='student-goal__button'>
          <Button variant='contained' color='primary'
            onClick={addNewGoal}
          >
            Add new goal
          </Button>
        </div>
        <div className='student-goal__textfield'>
          <TextField
            name='goalSearch'
            value={value}
            placeholder='Search'
            onChange={onChangeHandler}
            startIcon={{ name: "searchIcon" }}
          />
        </div>
      </div>
      <Table
        className='first-full'
        isContrast
        state={state}
        setState={setState}
        head={Object.values(headerConfig)}
        data={tableFormater(transformGoalFields(data.map((el) => ({
          ...el,
          goalBody: el.goalBody.split("\n").map((el, i) => <p key={el.goalId + i}>{el}</p>),
        }))), headerConfig)}
        onDelete={onDelete}
        onEdit={(id) => editHandler(id)}
      />
      <ConfirmModal
        headerTitle="Are you sure deleting the goal?"
        open={openModalDelete}
      >
        <Button variant="contained" color="primary" onClick={() => deleteHandler(goalId) }>
          Yes, Delete
        </Button>
        <Button
          variant="dashed"
          color="primary"
          onClick={() => {
            setOpenModalDelete(false);
          }}
        >
          Cancel
        </Button>
      </ConfirmModal>
    </div>
  );
}

export default CreateStudentGoal;
