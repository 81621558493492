import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import DatePicker from "../../../components/DatePicker";
import { dateFormat } from "../../../utils/dateFormater";
import { getCurrentSessionStatus } from "../getCurrentSessionStatus";
import { getDate, getUnix } from "../../../functions/calendar_func";
import { getSessionNotesAction } from "../session/sessionForm/store";

import "./style.scss";

import { edit_session_url, session_notes_url } from "../../../configs/router_urls";

const CalendarDayTable = ({ events, currentDate, setCurrentDate }) => {
  const fixEvents = Object.keys(events).reduce((result, key) => {
    result[getDate(key, "MM-DD-YYYY")] = events[key];
    return result;
  }, {});

  const dispatch = useDispatch();
  const history = useHistory();
  const currentEvent = fixEvents[dateFormat("mm-dd-yyyy", currentDate)] || undefined;

  const selectDay = (val) => {
    setCurrentDate(val);
  };

  const newArr = currentEvent
    ?.slice()
    .sort((a, b) => getUnix(a.actualStartDt) - getUnix(b.actualStartDt));

  const goToNotes = (item) => {
    if (item.state === "R") {
      history.push(edit_session_url, item);
    } else {
      dispatch(getSessionNotesAction(
        item.eventId,
        () => { history.push(`${session_notes_url}/${item.eventId}`, item); },
      ));
    }
  };

  return (
    <div className='calendar-day-wrapper'>
      {!currentEvent && <div className="calendar-day-empty">Empty</div>}
      {currentEvent && <div className="calendar-day-events custom-scroll">
        {newArr.map((item) => (
          <div
            key={item.eventId}
            className='calendar-day-event'
            onClick={() => goToNotes(item)}
          >
            <div className='calendar-day-event-time'>
              {dateFormat("LT", item.actualStartDt)} - {dateFormat("LT", item.actualEndDt)}
            </div>

            <div className='calendar-day-event-type'>
              {item.sertviceType}
            </div>

            {item.sessions.map((elem, index) => (
              <div key={elem.sessionId} className='calendar-day-event-student'>
                <div className="calendar-day-event-student-name">{elem.studentName}</div>

                <div className="calendar-day-event-container">{getCurrentSessionStatus(item.sessions[index].state, item.actualEndDt)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>}

      <div className="calendar-day-datePicker">
        {
          currentDate
          && <DatePicker
            isCalendar={true}
            defaultMode='day'
            startDate={currentDate}
            updateStartDate={selectDay}
          />
        }
      </div>

    </div>
  );
};

export default CalendarDayTable;
