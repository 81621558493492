const getLabelClassInvoices = (state) => {
  switch (state) {
  case "C":
    return "default";
  case "E":
    return "primary";
  case "R":
    return "danger";
  case "P":
  case "D":
  case "S":
  case "A":
    return "success";
  default:
    return "default";
  }
};

export default getLabelClassInvoices;
