import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import HeaderPage from "../../../../components/HeaderPage";
import Table from "../../../../components/Table";
import tableFormater from "../../../../utils/tableFormater";
import { dateFormat } from "../../../../utils/dateFormater";
import PeriodPanel from "../../../../components/PeriodPanel";
import { getInvoicesList, getInvoicesMonthsByAssignmentId, getInvoiceValidationInformationById } from "./store";
import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";
import { signersFormatter } from "../../../../utils/signersFormatter";

import "./styles/index.scss";

import {
  provider_students_assigment_url,
  provider_student_invoices_validation_url,
  provider_student_invoice_preview_url,
} from "../../../../configs/router_urls";
import { headerConfig } from "./configs";

function StudentInvoicesList() {
  const history = useHistory();
  const params = useParams();

  const dispatch = useDispatch();

  const student = useSelector(
    ({ providerStudentsListReducer }) => providerStudentsListReducer.student,
  );
  const invoices = useSelector(({ studentInvoicesReducer }) => studentInvoicesReducer.invoicesList);
  const invoicesMonths = useSelector(({ studentInvoicesReducer }) => studentInvoicesReducer.invoicesMonths);
  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);

  useEffect(() => {
    dispatch(getInvoicesMonthsByAssignmentId(params.id));
    dispatch(getAssignmentByIdAction(params.id));
    dispatch(getInvoicesList(params.id));
  }, []);

  const getInvoiceState = (state) => {
    switch (state) {
    case "C":
      return <span className="studentInvoicesList__status pending">Emails pending</span>;
    case "E":
      return <span className="studentInvoicesList__status sent">Sent to signers</span>;
    case "R":
      return <span className="studentInvoicesList__status rejected">Rejected</span>;
    default:
      return <span className="studentInvoicesList__status signed">Signed</span>;
    }
  };

  const onInvoice = (date) => {
    dispatch(getInvoiceValidationInformationById(params.id, date));
    history.push(`${provider_student_invoices_validation_url}/${params.id}/${date}`);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <HeaderPage
          title={`${student?.studentName || assignment?.studentName} Invoices`}
          rootPathName={provider_students_assigment_url}
          rootTitleName="Students"
          childrenTitleNames={["Student invoices"]}
        />
        <PeriodPanel
          data={invoicesMonths.filter((item) => !item.error).map((item) => item.invoicingMonth)}
          onInvoice={onInvoice}/>
      </div>
      <div className="studentInvoicesList">
        <Table
          head={Object.values(headerConfig)}
          data={tableFormater(invoices.result.map((item) => ({
            ...item,
            state: getInvoiceState(item.state),
            creationDate: dateFormat("mmm-d-dot-yyyy", item.invoiceDt),
            pendingSignatures: <div className="flex-column">{item.pendingSignatures.map((signer) => signersFormatter(signer))}</div>,
            appliedSignatures: <div className="flex-column">{item.appliedSignatures.map((signer) => signersFormatter(signer))}</div>,
          })), headerConfig, "invoiceId")}
          isContrast={true}
          isContext={true}
          typeOfTable="Student invoices"
          contextData={[
            {
              title: "View",
              link: provider_student_invoice_preview_url.split("/:")[0],
            },
          ]}
        />
      </div>
    </>
  );
}

export default StudentInvoicesList;
