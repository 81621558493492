import React from "react";

import SvgMaker from "../../svgMaker";
import {
  getNextDayDate,
  getPrevDayDate,
  getNextMonthDate,
  getPrevMonthDate,
  getNextYear,
  getPrevYear,
  year_table_range,
} from "../../../functions/calendar_func";

import "./style.scss";

function DatePickerHeader({
  setCurrentDate,
  displayDate,
  updateYearRangeStart,
  mode,
  isCalendar = false,
}) {
  /**
   *
   */
  function goNext() {
    if (mode === "oneDay") {
      setCurrentDate((currentDate) => getNextDayDate(currentDate));
      return;
    }
    if (mode === "month") {
      if (isCalendar) {
        setCurrentDate((currentDate) => getNextMonthDate(currentDate));
      } else {
        setCurrentDate((currentDate) => getNextYear(currentDate));
      }
      return;
    }
    if (mode === "year") {
      updateYearRangeStart((currentRangeStart) => getNextYear(currentRangeStart, "", year_table_range));
    }
    setCurrentDate((currentDate) => getNextMonthDate(currentDate));
  }

  /**
   *
   */
  function goPrevious() {
    if (mode === "oneDay") {
      setCurrentDate((currentDate) => getPrevDayDate(currentDate));
      return;
    }

    if (mode === "month") {
      if (isCalendar) {
        setCurrentDate((currentDate) => getPrevMonthDate(currentDate));
      } else {
        setCurrentDate((currentDate) => getPrevYear(currentDate));
      }
      return;
    }
    if (mode === "year") {
      updateYearRangeStart((currentRangeStart) => getPrevYear(currentRangeStart, "", year_table_range));
    }
    setCurrentDate((currentDate) => getPrevMonthDate(currentDate));
  }

  return (
    <div className='date_picker-header'>
      <div className='date_picker-month_switcher'>
        <div className='date_picker-month_switcher-previous' onClick={goPrevious}>
          <SvgMaker name='leftArrow' />
        </div>
        <div className='date_picker-month_switcher-next' onClick={goNext}>
          <SvgMaker name='rightArrow' />
        </div>
      </div>
      <h4 className='date_picker-header--current_month_year'>{displayDate}</h4>
    </div>
  );
}

export default DatePickerHeader;
