import React, { useEffect, useState, useRef } from "react";

function TextPlaceholder({
  value = "",
  label = "",
  required,
  name = "",
  type = "text",
  style,
  onChange = () => { },
}) {
  const [error, setError] = useState("");

  const inputRef = useRef();
  useEffect(() => {
    if (value?.length > 50) {
      setError("Max length should be 50 characters ");
      inputRef.current.style.border = "1px solid #ff5454";
    }
    if (value?.length < 50 && value.length !== 0) {
      setError("");
      inputRef.current.style.border = "1px solid #757F98";
    }
  }, [value?.length]);

  return (
    <div className='input'>
      {label && <span className="textfield__label">{required ? "* " : ""}{label}</span>}
      <input
        ref={inputRef}
        className="textfield"
        name={name}
        type={type}
        onChange={onChange}
        style={style}
      />
      {value?.length > 50 ? <span style={{ display: "inline" }} className='textfield__errortext'>{error}</span> : null}
    </div>
  );
}

export default TextPlaceholder;
