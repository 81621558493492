export default function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    switch (b) {
    case "studentsFilter":
    case "providersFilter":
    case "status":
    case "sessionsState":
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}${b}[]=${d}&`, "");
      return `${a}${b}[]=&`;
    default:
      if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
      return a;
    }
  }, "");
}
