import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dateFormat } from "../../../../../../utils/dateFormater";

const editStudentServiceSlice = createSlice({
  name: "editStudentService",
  initialState: {
    data: null,
  },
  reducers: {
    setStudentService(state, { payload }) {
      state.data = payload;
    },
    cleanStudentService(state) {
      state.data = null;
    },
  },
});

export const { setStudentService, cleanStudentService } = editStudentServiceSlice.actions;

export default editStudentServiceSlice.reducer;

export const getStudentServiceByIdAction = (id) => async (dispatch) => {
  try {
    const response = await axios(`/service/service-by-id/${id}`);
    dispatch(setStudentService({
      ...response.data,
      authorizationEndDt: dateFormat("mm-dd-yyyy", response.data.authorizationEndDt),
      authorizationStartDt: dateFormat("mm-dd-yyyy", response.data.authorizationStartDt),
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
