import clsx from "clsx";
import { Formik } from "formik";
import React, { useState } from "react";
import FormikButton, { Button } from "../Button";
import ConfirmModal from "../confirmModal";
import FormikDateField from "../DateField";
import FormikRadioButton from "../RadioButton";
import FormkikSelect from "../Select";
import FormikTextField from "../TextField";
import "./styles/index.scss";

import SvgMaker from "../svgMaker/SvgMaker";
import Typography from "../Typography";

function PaperModal({
  headerTitle, open, onClose, children, isGoalSection,
}) {
  if (!open) return null;

  return (
    <>
      <div className="grey-screen" onClick={onClose}/>
      <div className="modal custom-scroll" style={isGoalSection ? { minWidth: "400px", maxHeight: "100vh" } : {}}>
        <div className="modal__closeicon" onClick={onClose}>
          <SvgMaker name="closeIcon" />
        </div>
        <div className="modal__wrapper">
          <div style={{ paddingBottom: 20 }}>
            <Typography variant="h1"><span style={{ color: "#192A53" }}>{headerTitle}</span></Typography>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

function ServiceModal({
  headerTitle,
  formikInitialValues,
  open,
  onClose,
  save = () => {},
  fieldConfig,
  validationSchema,
}) {
  const [confirmModal, setConfirmModal] = useState(false);

  const setDefault = () => {
    setConfirmModal(false);
    onClose();
  };

  const closeModal = (values) => {
    if (Object.values(values).some((item) => item)) {
      return setConfirmModal(true);
    }
    setDefault();
  };
  return open ? (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => save(values)}
    >
      {({ errors, touched }) => (
        <React.Fragment>
          <ConfirmModal
            headerTitle="Discard unsaved changes?"
            open={confirmModal}
          >
            <div>
              <Button variant="contained" color="primary" onClick={setDefault}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => setConfirmModal(false)}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
          <PaperModal
            headerTitle={headerTitle}
            onClose={closeModal}
            open={confirmModal}
          >
            <div>
              <div>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormkikSelect {...item} />
                      </div>
                    );
                  }
                  case "radio": {
                    return (
                      <div className="modal__wrapper-field" key={item.id}>
                        <div
                          className={clsx("modal__wrapper-label", {
                            "modal__wrapper-error":
                                touched[item.name] && !!errors[item.name],
                          })}
                          id={item.name}
                        >
                            * {item.label}
                        </div>
                        <div
                          className="modal__wrapper-radiogroup"
                          role="group"
                          aria-labelledby={item.name}
                        >
                          <FormikRadioButton
                            type="radio"
                            name={item.name}
                            value="true"
                            label="Yes"
                          />
                          <FormikRadioButton
                            type="radio"
                            name={item.name}
                            value="false"
                            label="No"
                          />
                        </div>
                        {touched[item.name] && !!errors[item.name] && (
                          <div className="modal__wrapper-errortext">
                            {" "}
                            {errors[item.name]}{" "}
                          </div>
                        )}
                      </div>
                    );
                  }
                  case "datefields": {
                    return (
                      <div className="modal__wrapper-field" key={item.id}>
                        <span
                          name="label"
                          className={`${
                            (touched.startDt || touched.endDt)
                              && (errors.startDt || errors.endDt)
                              ? "error"
                              : ""
                          }`}
                        >
                          {item.required ? `* ${item.label}` : item.label}
                        </span>
                        <div className="modal__wrapper-pickers">
                          {item.pickers.map((el) => (
                            <div className="picker__wrapper">
                              <FormikDateField {...el} />
                            </div>
                          ))}
                          <div className="picker__hr">
                            <hr/>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  default:
                    return "default";
                  }
                })}
                <div className="content__wrapper-field">
                  <FormikButton variant="contained" color="primary">
                    Save
                  </FormikButton>
                </div>
              </div>
            </div>
          </PaperModal>
        </React.Fragment>
      )}
    </Formik>
  ) : (
    <> </>
  );
}

export { PaperModal };
export default ServiceModal;
