const headerConfig = {
  student: {
    id: 1,
    title: "Student",
    value: "student",
    order: 1,
  },
  provider: {
    id: 2,
    title: "Provider",
    value: "provider",
    order: 2,
  },
  callDt: {
    id: 3,
    title: "Call date",
    value: "callDt",
    order: 3,
  },
  note: {
    id: 4,
    title: "Call notes",
    value: "note",
    order: 4,
  },
};

export {
  headerConfig,
};
