import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const providerCallLogSlice = createSlice({
  name: "providerCallLog",
  initialState: {
    data: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setCallLog(state, { payload }) {
      state.data = payload;
    },
  },
});

export default providerCallLogSlice.reducer;

const { setCallLog } = providerCallLogSlice.actions;

export const getProviderCallLog = (id, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      offset, limit,
    });
    const response = await axios.get(`/provider/all-provider-calls/${id}?${params}`);
    dispatch(setCallLog(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}${b}[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}

export const addNewCallLog = (data, id) => async (dispatch) => {
  try {
    await axios.post("/provider/parent-call", data);
    dispatch(getProviderCallLog(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const editCallLogById = (data, id) => async (dispatch) => {
  try {
    await axios.patch("/provider/update-parent-call", data);
    dispatch(getProviderCallLog(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteCallLogById = (parentCallId, id) => async (dispatch) => {
  try {
    await axios.delete(`/provider/parent-call/${parentCallId}`);
    dispatch(getProviderCallLog(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
