import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const providerHomePageSlice = createSlice({
  name: "providerHomePage",
  initialState: {
    allMessages: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setAllMessages(state, { payload }) {
      state.allMessages = payload;
    },
  },
});

const { setAllMessages } = providerHomePageSlice.actions;

export const getNewMessages = (id, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const { data: providerId } = await axios.get(`/provider/provider-id/${id}`);
    const response = await axios.get(`/provider/all-admin-messages/${providerId}?onlyUnreaded=true`, { params: { offset, limit } });
    dispatch(setAllMessages(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getAllMessages = (id, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const { data: providerId } = await axios.get(`/provider/provider-id/${id}`);
    const response = await axios.get(`/provider/all-admin-messages/${providerId}`, { params: { offset, limit } });
    dispatch(setAllMessages(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const makeMessageReaded = (id) => async (dispatch) => {
  try {
    await axios.patch(`/provider/make-message-readed-unreaded/${id}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default providerHomePageSlice.reducer;
