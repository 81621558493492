import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const ndAddNewGoalSlice = createSlice({
  name: "ndAddNewGoal",
  initialState: {
    goals: [],
  },
  reducers: {
    getGoals(state, { payload }) {
      state.goals = payload;
    },
  },
});

export default ndAddNewGoalSlice.reducer;

const { getGoals } = ndAddNewGoalSlice.actions;

export const getGoalForCreationGoalAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/goals/get-goals-and-group");
    dispatch(getGoals(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
