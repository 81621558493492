import React from "react";
import { useSelector } from "react-redux";
import Logo from "./components/Logo";
import Profile from "./components/Profile";
import Subscribe from "./components/Subscribe";
import Time from "./components/Time";
import "./styles/index.scss";
import { home_url } from "../../configs/router_urls";
import logo from "./logo.svg";

const Header = ({ isLogin }) => {
  const userData = useSelector(({ authorizationReducer }) => authorizationReducer.user);
  return (
    <div className="header">
      <Logo logoHref={`${home_url}`} logoImgSrc={logo} />
      {!isLogin && <> <Time dateFormatString="mmm-d-dot-lt" />
        <Subscribe subscribeLink="#" />
        <Profile
          profileName={userData?.name}
          profileId={userData?.userId}
        /> </>}
    </div>
  );
};

export default Header;
