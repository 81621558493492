import moment from "moment";

export const week_days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const week_days_full = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const weeks_in_month = 5;
export const days_in_week = 7;
export const weeks_in_month_arr = Array.from(Array(weeks_in_month).keys());
export const days_in_week_arr = Array.from(Array(days_in_week).keys());
export const year_table_range = 11;

export const getUnix = (date) => moment(date).valueOf();

export const getCurrentDate = (date_format) => moment().format(date_format);

export const getDate = (date, date_format) => moment(date).format(date_format);

export const getNextDayDate = (date, date_format) => moment(date).add(1, "days").format(date_format);

export const getPrevDayDate = (date, date_format) => moment(date).subtract(1, "days").format(date_format);

export const getNextMonthDate = (date, date_format) => moment(date).add(1, "M").format(date_format);

export const getPrevMonthDate = (date, date_format) => moment(date).subtract(1, "M").format(date_format);

export const getNextYear = (date, date_format, step = 1) => moment(date).add(step, "Y").format(date_format);

export const addTimeToDate = (date, time, time_unit, date_format) => moment(date).add(time, time_unit).format(date_format);

export const getPrevYear = (date, date_format, step = 1) => moment(date).subtract(step, "Y").format(date_format);

export const getStartOfMonth = (date, date_format) => moment(date).startOf("month").format(date_format);

export const getEndOfMonth = (date, date_format) => moment(date).endOf("month").format(date_format);

export const getNoOfDays = (date) => moment(date, "YYYY-MM").daysInMonth();

export const isDateBetween = (date, start_date, end_date) => moment(date).isBetween(start_date, end_date);

export const isEqualsDates = (first_date, second_date) => moment(first_date).isSame(second_date);

export const isDateBefore = (first_date, second_date) => moment(first_date).isBefore(second_date);

export const isDateSameOrAfter = (first_date, second_date) => moment(first_date).isSameOrAfter(second_date);

export const isDateSameOrBefore = (first_date, second_date) => moment(first_date).isSameOrBefore(second_date);

export const getWeeksInMonthCount = (date, offset) => Math.ceil((moment(date).daysInMonth() + offset) / days_in_week);

export const getStartOfDay = (date, date_format) => moment(date).startOf("day").format(date_format);

export const getDateMatrix = (date) => {
  const matrix = [];
  const firstWeekOffset = week_days.indexOf(getStartOfMonth(date, "dd"));
  const weekStartIndex = 0;
  const weekEndIndex = getWeeksInMonthCount(date, firstWeekOffset);
  const weekStartDayIndex = (week) => (week === 0 ? 1 - firstWeekOffset : 1);
  const weekEndDayIndex = (week) => (week === 0 ? 7 - firstWeekOffset : 7);
  for (let week = weekStartIndex; week < weekEndIndex; week++) {
    matrix.push([]);
    for (
      let day = weekStartDayIndex(week);
      day <= weekEndDayIndex(week);
      day++
    ) {
      const day_number = week ? week * 7 + (day - firstWeekOffset) : day;
      matrix[week].push({
        key: day_number,
        date: moment(date).set("date", day_number).format(),
      });
    }
  }
  return matrix;
};

export const getMonthMatrix = (date) => {
  const matrix = [];
  const rowsAmount = 4;
  const columnsAmount = 3;
  for (let row = 0; row < rowsAmount; row++) {
    matrix.push([]);
    for (let column = 0; column < columnsAmount; column++) {
      const month_number = columnsAmount * row + column;
      matrix[row].push({
        key: month_number,
        date: moment(date).set("month", month_number).format(),
      });
    }
  }

  return matrix;
};

export const getYearMatrix = (date) => {
  const matrix = [];
  const rowsAmount = 4;
  const columnsAmount = 3;
  for (let row = 0; row < rowsAmount; row++) {
    matrix.push([]);
    for (let column = 0; column < columnsAmount; column++) {
      const year_number = columnsAmount * row + column;
      matrix[row].push({
        key: year_number,
        date: moment(date).add(year_number, "Y"),
      });
    }
  }

  return matrix;
};
