import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import HeaderPage from "../../../../../../components/HeaderPage";
import FormikTextField, { TextField } from "../../../../../../components/TextField";
import FormikButton, { Button } from "../../../../../../components/Button";
import { createNewGoalToGroup, getListPlaceholder } from "../../store";
import { PaperModal } from "../../../../../../components/modal/Modal";
import IntOfIntPlaceholder from "../../../../../../entities/admin/student/pages/ngGenerateGoal/components/IntOfIntPlaceholder";
import PersentPlaceholder from "../../../../../../entities/admin/student/pages/ngGenerateGoal/components/PersentPlaceholder";
import TextPlaceholder from "../../../../../../entities/admin/student/pages/ngGenerateGoal/components/TextPlaceholder";
import SelectPlaceholder from "../../../../../../entities/admin/student/pages/ngGenerateGoal/components/SelectPlaceholder";

import "./styles/index.scss";

import { banks_of_values_url, banks_of_values_goals_url } from "../../../../../../configs/router_urls";
import { fieldConfig, formikInitialValues, yupObject } from "./config";

function CreateNewGoal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const ref = useRef();

  const [valueBody, setValueBody] = useState({});
  const [valuePreview, setValuePreview] = useState({ preview: "" });
  const [modalWindow, setModalWindow] = useState(false);
  const [error, setError] = useState("");
  const [values, setValues] = useState({});
  const [selectValues, setSelectValues] = useState({});

  const infoPlaceholders = useSelector(({ dictionaryGoalsReducer }) => dictionaryGoalsReducer.infoPlaceholders);

  useEffect(() => {
    dispatch(getListPlaceholder());
  }, []);

  useEffect(() => {
    if (Object.values(valueBody)[0]?.length < 65536 && Object.keys(valueBody).length) {
      setError("");
      ref.current.style.border = "1px solid #ced4da";
    }
    if (Object.keys(valueBody) && Object.values(valueBody)[0] === "") {
      setError("Required field");
      ref.current.style.border = "1px solid #ff5454";
    }
    if (Object.values(valueBody)[0]?.length > 65535 && Object.keys(valueBody).length) {
      setError("Goal max length should be 65535 characters.");
      ref.current.style.border = "1px solid #ff5454";
    }
  }, [Object.keys(valueBody), Object.values(valueBody)]);

  const deletePlaceholder = () => {
    const el = ref.current;
    const start = ref.current.selectionStart;
    const end = ref.current.selectionEnd;
    const substr = el.value.substring(0, start);
    const arr = substr.split(" ");
    const name = arr[arr.length - 1];
    if (name === "~Name~" || name === "~String~" || name === "~X of x~" || name === "~Independency~"
    || name === "~Percent~" || name === "~Measurement~" || name === "~Grade~") {
      arr.splice(arr.length - 1, 1);
      const newSubstr = arr.join(" ");
      const newStr = ` ${newSubstr}${el.value.substring(end)} `;
      el.value = newStr.trim();
      ref.current.selectionStart = start - name.length - 1;
      ref.current.selectionEnd = end - name.length - 1;
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      deletePlaceholder();
    }
  };

  const onChange = (e) => {
    setValueBody({ ...valueBody, text: e.target.value });
    setValuePreview({ ...valuePreview, preview: e.target.value });
  };

  const onChangePreview = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const parsePreview = (str) => {
    const arr = str.split(" ");
    return arr?.map((el, i) => {
      switch (el) {
      case "~Name~": {
        return (
          <span key={i + el} style={{ fontWeight: "600" }}>
              *Student name*
            <span> </span>
          </span>
        );
      }
      case "~X": {
        return (
          <span key={i + el}>
            <IntOfIntPlaceholder name={i + 100} value={values[i + 100]} onChange={onChangePreview} />
            <span> out</span>
            <span> </span>
          </span>
        );
      }
      case "x~": {
        return (
          <span key={i + el}>
            <IntOfIntPlaceholder name={i + 100} value={values[i + 100]} onChange={onChangePreview} />
            <span> </span>
          </span>
        );
      }
      case "~Percent~": {
        return (
          <span key={i + el} className="persent">
            <PersentPlaceholder name={i + 200} value={values[i + 200]} onChange={onChangePreview}/>
            <span> % accuracy</span>
          </span>
        );
      }
      case "~String~": {
        return (
          <span key={i + el} className="long">
            <TextPlaceholder name={i + 300} value={values[i + 300]} onChange={onChangePreview} isValid={values[i + 300]}/>
            <span> </span>
          </span>
        );
      }
      case "~Grade~": {
        return (
          <span key={i + el} className="long">
            <TextPlaceholder name={i + 300} value={values[i + 300]} onChange={onChangePreview} isValid={values[i + 300]}/>
            <span> </span>
          </span>
        );
      }
      case "~Independency~": {
        return (
          <span key={i + el} className="askAnswer">
            <SelectPlaceholder name={i + 400}
              value={selectValues[i + 400]}
              data={infoPlaceholders?.find((el) => el.name === "Teacher support")?.field.map((el, i) => ({ id: i, title: el, value: el }))}
              onNativeChange={(e) => setSelectValues({ ...selectValues, [e.target.name]: e.target.value })}
            />
            <span> </span>
          </span>
        );
      }
      case "~Measurement~": {
        return (
          <span key={i + el} className="askAnswer">
            <SelectPlaceholder name={i + 400}
              value={selectValues[i + 400]}
              data={infoPlaceholders?.find((el) => el.name === "Measurement")?.field.map((el, i) => ({ id: i, title: el, value: el }))}
              onNativeChange={(e) => setSelectValues({ ...selectValues, [e.target.name]: e.target.value })}
            />
            <span> </span>
          </span>
        );
      }
      default: return <span key={i + el}>{el}<span> </span></span>;
      }
    });
  };

  return (
    <div>
      <HeaderPage
        title="Create goal"
        rootPathName={banks_of_values_url}
        rootTitleName="Banks of Values"
        childrenTitleNames={["Goals", "Create goal"]}
        childrenPathNames={[`${banks_of_values_goals_url}`]}
      />
      <div className="container">
        <div style={{ display: "flex" }}>
          <div className="addNewGoal" >
            <Formik
              initialValues={formikInitialValues}
              validationSchema={Yup.object(yupObject)}
              onSubmit={(values) => {
                if (Object.keys(valueBody).length && Object.values(valueBody)[0].length < 65536) {
                  dispatch(createNewGoalToGroup(
                    {
                      ...values,
                      goalBody: valueBody.text.trim(),
                      goalGroupId: +params.id,
                    }, +params.id, history.push(banks_of_values_goals_url),
                  ));
                }
              }}
            >
              {({ dirty }) => (
                <>
                  {fieldConfig.map((item, key) => {
                    switch (item.type) {
                    case "textfield": {
                      return (
                        <div key={item.id + item.name} className="addNewGoal__title">
                          <FormikTextField {...item}/>
                        </div>
                      );
                    }
                    case "textfieldCode": {
                      return (
                        <div key={item.id + item.name} className="addNewGoal__code">
                          <FormikTextField {...item}/>
                        </div>
                      );
                    }
                    case "textfieldText": {
                      return (
                        <>
                          <div key={item.id + item.name} className="addNewGoal__text">
                            <TextField {...item} onKeyDown={onKeyDown} inputRef={ref} onChange={onChange}/>
                            <span style={{ color: "#ff5454", fontSize: "11px", paddingLeft: "10px" }}>{error}</span>
                          </div>
                        </>
                      );
                    }
                    case "empty": {
                      return <div key={item.id.toString()} />;
                    }
                    default: return "default";
                    }
                  })}
                  <Button variant="outlined" color="grey" onClick={() => {
                    dirty || Object.values(valueBody).length ? setModalWindow(true) : history.push(`${banks_of_values_goals_url}`);
                  }}>
                    Cancel
                  </Button>
                  <FormikButton variant='contained' color='primary' isGoal onClick={true} handleClick={() => {
                    if (!Object.keys(valueBody).length || !Object.values(valueBody)[0].length) {
                      setError("Required field");
                      ref.current.style.border = "1px solid #ff5454";
                    }
                    if (Object.keys(valueBody).length && Object.values(valueBody)[0].length > 65535) {
                      setError("Goal max length should be 65535 characters.");
                      ref.current.style.border = "1px solid #ff5454";
                    }
                  }}>
                    Save
                  </FormikButton>
                </>
              )}
            </Formik>
          </div>
          <div className="placeholders">
            <div>Placeholders</div>

            {infoPlaceholders.map((el) => (
              <div style={{ margin: "10px 20px" }} key={el.id + el.name}>
                <Button variant="outlined" color="grey" onClick={() => {
                  const elem = ref.current;
                  const start = ref.current.selectionStart;
                  const end = ref.current.selectionEnd;
                  let newStr = `${elem.value.substring(0, start)} ${el.code} ${elem.value.substring(end)}`;
                  elem.value = newStr;
                  setValueBody({ ...valueBody, text: newStr });
                  setValuePreview({ ...valuePreview, preview: newStr });
                  ref.current.selectionStart = start + el.code.length + 2;
                  ref.current.selectionEnd = end + el.code.length + 2;
                  elem.focus();
                }}>
                  {el.name}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="preview">
        <div className="preview__title">Preview</div>
        <div className="preview__content">
          {valuePreview.preview.split("\n").map((it) => <p>{parsePreview(it)}</p>)}
        </div>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Discard unsaved changes?" open={modalWindow} onClose={() => setModalWindow(false)}>
          <div style={{ marginTop: "20px" }}></div>

          <div onClick={() => history.push(`${banks_of_values_goals_url}`)}>
            <Button variant="contained" color="primary">
            Discard
            </Button>
          </div>

          <Button variant="dashed" color="primary" onClick={() => setModalWindow(false)}>
            Cancel
          </Button>
        </PaperModal>
      </div>
    </div>
  );
}

export default CreateNewGoal;
