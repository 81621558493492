/** returns the status title location
 * @name getLocationTitle
 * @param {"S"|"H"|"R"} value - value
 * @return ("School"|"Home"|"Remote")
 * @throws Error
 * */
export function getLocationTitle(value) {
  switch (value) {
  case "S":
    return "School";
  case "H":
    return "Home";
  case "R":
    return "Remote";
  default:
    error("getLocationTitle", value, "\"S\"|\"H\"|\"R\"");
  }
}

/** returns the status title location
 * @name getVirtualMediumTitle
 * @param {"Z"|"P"|"O"} value - value
 * @return ("Zoom"|"Phone"|"Other")
 * @throws Error
 * */
export function getVirtualMediumTitle(value) {
  switch (value) {
  case "Z":
    return "Zoom";
  case "P":
    return "Phone";
  case "O":
    return "Other";
  default:
    error("getVirtualMediumTitle", value, "\"Z\"|\"P\"|\"O\"");
  }
}

/** returns the label of state
 * @name getLabelOfState
 * @param {"A"|"I"} value - value
 * @return ("Session signed"|"Session invoiced")
 * @throws Error
 * */
export function getLabelOfState(value) {
  switch (value) {
  case "A":
    return "Session signed";
  case "I":
    return "Session invoiced";
  default:
    return "";
  }
}

export function getClassLabelOfState(value) {
  switch (value) {
  case "A":
    return "signed";
  case "I":
    return "invoiced";
  default:
    return "";
  }
}

function error(functionName, value, example) {
  throw new Error(` ${functionName} something wrong.
    The value must be, a string and one of the following values: ${(example) && example}. 
    Your value: ${value}`);
}
