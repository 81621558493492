import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HeaderPage from "../../../../components/HeaderPage";
import Paper from "../../../../components/Paper";
import Typography from "../../../../components/Typography";
import { provider_progress_report_list_url, provider_students_assigment_url, provider_update_progress_report_url } from "../../../../configs/router_urls";
import ProgressReportInfo from "../components/progressInfo";
// import { getStudentById } from "../ProgressReportList/store";
import "./styles/index.scss";
import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";

const initialReportItem = {
  id: 1,
  title: "Title + text",
  header: {
    mode: "text",
    title: "Reading / Writing",
  },
  subtitles: [
    {
      id: 1,
      title: {
        text: "Specify Decoding Abilities",
        mode: "text",
      },
      desc: {
        text: "Chaya reads slower than the average student, mispronouncing words.",
        mode: "text",
      },
    },
    {
      id: 2,
      title: {
        text: "Specify Reading Comprehension Skills (literal and inferential comprehension):",
        mode: "text",
      },
      desc: {
        text: `poor literal comprehension, will not follow a basic story on grade level
        -inferential comprehension is far beyond current skill set
        Very low level, has a hard time comprehending the basic story lines of grade level texts. Analyzing, Comparing, participating in discussion is not something that Chaya Mushka is able to do. Has a hard time processing auditory information as well, very often won’t hear or understand and needs repetition`,
        mode: "text",
      },
    },
  ],
};

function ProgressReportView() {
  const studentName = "";
  const [report] = useState([initialReportItem]);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const student = useSelector(({ progressReportListReducer }) => progressReportListReducer.student);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);
  useEffect(() => {
    // dispatch(getStudentById(student.studentId));
    dispatch(getAssignmentByIdAction(params.id));
  }, []);
  return (
    <div>
      <HeaderPage
        title={`View progress report ${student.studentName || assignment?.studentName || studentName}`}
        rootPathName={provider_students_assigment_url}
        rootTitleName='Students'
        childrenTitleNames={["List of reports", "View"]}
        childrenPathNames={[`${provider_progress_report_list_url.split("/:")[0]}/${params.id}`]}
      />
      <div style={{ backgroundColor: "#ffffff" }}>
        <Paper
          iconName="editIcon"
          papertitle='Information'
          wrapperStyles={{ padding: "24px 20px 0px 20px" }}
          contentTitleStyles={{ paddingBottom: "0px" }}
          // changeMode={() => history.push("/provider/update-progress-report")}
          changeMode={() => history.push(`${provider_update_progress_report_url.split("/:")[0]}/${params.id}`, location.state.reportId)}
        >

        </Paper>
        <ProgressReportInfo
          name={student.studentName || assignment?.studentName}
          date={moment(student.birthDt || assignment?.birthDt).format("MM-DD-YYYY")}
          osis={student.osisNo || assignment?.osisNo}
        />
      </div>
      <Paper>
        <div className='reportview'>
          {report.map((item, index) => (
            <div className='reportview__creation' key={item.id + index}>
              <div className='reportview__creation-header'>
                <Typography variant='h1'>
                  {item.header.title}
                </Typography>
              </div>
              <div className='reportview__creation-info'>
                {item.subtitles.map((el) => (
                  <div style={{ display: "flex", justifyContent: "space-between" }} key={el.title.text}>
                    <div className='reportview__creation-abilities'>
                      <div style={{ paddingBottom: 8 }}>
                        <Typography variant='h4'>
                          {el.title.text}
                        </Typography>
                      </div>
                      <div >
                        <div style={{ paddingBottom: 8 }}>
                          <Typography variant='subtitle2' style={{ lineHeight: "19.5px" }}>
                            {el.desc.text}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </div>
  );
}

export default ProgressReportView;
