import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import HeaderPage from "../../../components/HeaderPage";
import { admin_invoices_url, admin_email_preview_url } from "../../../configs/router_urls";
import "./styles/index.scss";
import { infoConfig } from "./config";
import SvgMaker from "../../../components/svgMaker";
import { getInvoice, changePaidInvoicesStatus, changeStatusToPaid } from "../store";
import InvoiceViewPDF from "../../../components/PDF/InvoiceViewPDF";
import Loader from "../../../components/LoadingIndicator";
import { Button } from "../../../components/Button";
import DatePicker from "../../../components/DatePicker";
import { dateFormat } from "../../../utils/dateFormater";
import getLabelTitleInvoices from "../../../utils/getLabelTitleInvoices";
import getLabelClassInvoices from "../../../utils/getLabelClassInvoices";
import { signersFormatter } from "../../../utils/signersFormatter";
import { monthNames } from "../../../constants/monthNames";
import { getCurrentDate, getStartOfDay } from "../../../functions/calendar_func";

function InvoiceView() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const invoice = useSelector(({ adminInvoicesListReducer }) => adminInvoicesListReducer.invoice);
  const [file, setFile] = useState();
  const [fileWithOutSign, setFileWithOutSign] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [startDate, setStartDate] = useState(getCurrentDate());
  useEffect(() => {
    if (params?.id) dispatch(getInvoice(params.id));
  }, []);

  useEffect(async () => {
    if (invoice?.invoicePdfLink) {
      const response = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${invoice.invoicePdfLink}`, {
        responseType: "blob",
      });
      const withOutSign = await axios(`/reports/invoice-preview/${invoice.assignmentId}?invoiceStartDate=${moment(invoice.invoiceMonth).format("YYYY-MM-DD")}`,
        {
          method: "get",
          responseType: "arraybuffer",
        });

      setFile(response.data);
      setFileWithOutSign(withOutSign.data);
    }
  }, [invoice]);

  const paidByDOE = (date) => {
    dispatch(changeStatusToPaid({ invoiceIds: [params.id], paidDate: getStartOfDay(date) }, () => {
      dispatch(getInvoice(params.id));
    }));
  };

  const getInvoiceInfo = (key) => {
    if (key === "paidDt") {
      return invoice[key] ? dateFormat("mm-slash-d-slash-yyyy", invoice[key]) : "-";
    } if (key === "pendingSignatures" || key === "givenSignatures") {
      const last = invoice[key]?.length - 1;
      return invoice[key]?.length ? invoice[key].map((signer, index) => signersFormatter(signer, index, last)) : "-";
    }
    if (key === "invoiceMonth") {
      return `${monthNames[moment(invoice[key]).month()]} ${moment(invoice[key]).year()}`;
    }
    return invoice[key];
  };
  const changeInvoiceStatus = () => {
    dispatch(changePaidInvoicesStatus(params.id, () => {
      dispatch(getInvoice(params.id));
    }));
  };
  const sendToDOE = () => {
    history.push(`${admin_email_preview_url}/${[params.id]}/${invoice.invoiceMonth}`);
  };

  const getContent = (state) => {
    switch (state) {
    case "S":
      return (
        <div className="content">
          <div className="content__wrapper">
            <Button variant="contained" onClick={() => sendToDOE()}>Send</Button>
          </div>
        </div>
      );
    case "P":
      return (
        <div className="content">
          <div className="content__wrapper">
            <Button variant="outlined" color="grey" onClick={() => changeInvoiceStatus()}>Return to sent</Button>
          </div>
        </div>
      );
    case "D":
      return (
        <div className="content">
          <div className="content__wrapper">
            <div style={{ height: "24px" }}/>
            <div className="calendar_popup">
              <DatePicker
                updateStartDate={setStartDate}
                startDate={startDate}
                isCalendar
              />
              <div className="calendar_popup-buttons">
                <Button disabled={!startDate} variant="contained" onClick={() => paidByDOE(startDate)}>
                  Paid by DOE
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return "";
    }
  };

  return (
    <div>
      <div className="invoice_view">
        {showPreview
        && <InvoiceViewPDF
          file={file}
          fileWithOutSign={fileWithOutSign}
          hidePreview={() => setShowPreview(false)}/>
        }
        <div className="invoice_view__left">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <HeaderPage
              title="View Invoice" // for Admin login
              rootPathName={admin_invoices_url}
              rootTitleName="Invoices"
              childrenTitleNames={["View Invoice"]}
            />
            <div className={`invoice_view__label ${getLabelClassInvoices(invoice.state)}`}>{getLabelTitleInvoices(invoice.state)}</div>
          </div>
          <div className="content">
            <div className="content__wrapper">
              <span className="text__h1">{invoice?.studentName}</span>
              <div className="invoice_view__container">
                <div className="invoice_view__pdf_preview">
                  <div className="invoice_view__pdf_preview__content">
                    <div className="invoice_view__pdf_preview__header">
                      <div
                        className="invoice_view__pdf_preview__open_icon"
                        onClick={() => setShowPreview(true)}>
                        <SvgMaker name="tv"/>
                      </div>
                    </div>
                    <div className="invoice_view__pdf_preview__body">
                      {file ? <Document
                        file={file}
                        options={{ useWorkerFetch: false }}
                      >
                        <Page pageNumber={1}/>
                      </Document> : <Loader/>
                      }
                    </div>
                  </div>
                  <div
                    className="invoice_view__pdf_preview__footer"
                    onClick={() => setShowPreview(true)}
                  >
                    <p>View on fullscreen</p>
                  </div>
                </div>
                <div className="invoice_view__pdf_info">
                  {infoConfig.map((el) => (
                    <div className={`invoice_view__pdf_info__item ${el.key === "pendingSignatures" || el.key === "givenSignatures" ? "flex" : ""}`}>
                      <span className="text__subtitle1-bold">{el.title}: </span>
                      <span style={{ marginLeft: "8px" }}>{getInvoiceInfo(el.key)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="invoice_view__right">
          {getContent(invoice.state)}
        </div>
      </div>
    </div>
  );
}

export default InvoiceView;
