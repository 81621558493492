const headerConfig = {
  firstName: {
    id: 1,
    title: "Name",
    value: "firstName",
    order: 1,
  },
  email: {
    id: 2,
    title: "Email",
    value: "email",
    order: 2,
  },
  cellPhoneNo: {
    id: 3,
    title: "Cell phone",
    value: "cellPhoneNo",
    order: 3,
  },
  ssNo: {
    id: 4,
    title: "SSN",
    value: "ssNo",
    order: 4,
  },
};

export {
  headerConfig,
};
