export const headerConfig = {
  studentName: {
    id: 1,
    title: "Name",
    value: "studentName",
    order: 1,
  },
  birthDt: {
    id: 2,
    title: "DOB",
    value: "birthDt",
    order: 2,
  },
  primaryPhone: {
    id: 3,
    title: "Phone",
    value: "homePhoneNo",
    order: 3,
  },
  schoolName: {
    id: 4,
    title: "School",
    value: "schoolId",
    order: 4,
  },
};
