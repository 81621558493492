import clsx from "clsx";
import React from "react";

function SelectPlaceholder({
  data = [],
  value = "",
  placeholder = "",
  name = "",
  onNativeChange = () => { },
  disabled,
}) {
  return (
    <div className='select'>
      <div className={clsx("select__wrapper", { "select__wrapper-disabled": disabled })}>
        <select
          value={value}
          disabled={disabled}
          name={name}
          onChange={onNativeChange}
        >
          <option name={name} value=''>
            {placeholder}
          </option>
          {data.map((item, key) => (
            <option key={key} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectPlaceholder;
