import axios from "axios";
import createDownloadUrl from "../../../../../../../utils/createDownloadUrl";

export const createGustoCsvAction = (data, callback) => async (dispatch) => {
  const response = await axios.post("/reports/generate-gusto-report", data);
  const url = createDownloadUrl(response.data, "text/csv", true);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    "gusto.csv",
  );

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
  callback();
};
