import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getProvidersByIdAction } from "../../../entities/admin/provider/pages/providerProfile/store";

const authorizationSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    isLogout: false,
  },
  reducers: {
    setUserData(state, { payload }) {
      state.user = payload;
    },
    logout(state) {
      state.user = {};
    },
  },
});

export default authorizationSlice.reducer;

export const { setUserData, logout } = authorizationSlice.actions;

export const setUserDataAction = (data) => async (dispatch) => {
  try {
    const { role, ...props } = jwt_decode(data);
    const user = { ...props, role: [role] };
    dispatch(setUserData(user));
    if (user.role[0] === 4) {
      const { data: providerId } = await axios.get(`/provider/provider-id/${user.userId}`);
      dispatch(getProvidersByIdAction(providerId));
      dispatch(setUserData({ ...user, providerId }));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const signInAction = ({ isRememberMe, ...data }) => async (dispatch) => {
  try {
    const response = await axios.post("/auth/sign-in", data);
    if (!isRememberMe) {
      sessionStorage.setItem("@token", response.data.token);
    } else {
      localStorage.setItem("@token", response.data.token);
    }
    dispatch(setUserDataAction(response.data.token));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
