import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import FormikButton, { Button } from "../../../../../components/Button";
import FormikDateField from "../../../../../components/DateField";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import FormikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import { createStudentGoalAction, updateStudentGoalAction } from "./store";
import { dateFormat } from "../../../../../utils/dateFormater";
import { getCurrentDate } from "../../../../../functions/calendar_func";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";

import "./styles/index.scss";

import {
  admmin_students_add_new_goal_url,
  admmin_students_goals_url,
  admmin_students_profile_url,
  admmin_students_url,
} from "../../../../../configs/router_urls";

function NdEditGoal() {
  const history = useHistory();
  const location = useLocation();
  const [dateValue, setDateValue] = useState(moment(location.state.schedule).format());
  const params = useParams();
  const dispatch = useDispatch();

  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  return (
    <div>
      <HeaderPage
        title={`Edit a Goal for ${location.state.studentName}`}
        rootTitleName="All Students"
        rootPathName={admmin_students_url}
        childrenTitleNames={["Student Profile", "Student Goals", history.location.state.addNewGoal ? "Add new goal" : "Edit a Goal for Student"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`, `${admmin_students_goals_url}/${params.id}`]}
      />
      <Paper>
        <div style={{ width: 175, marginBottom: !history.location.state.addNewGoal ? 0 : 24 }}>
          {!history.location.state.addNewGoal ? null : <Button onClick={() => {
            const [goal, addNewGoal] = admmin_students_add_new_goal_url.split(":id");
            history.push(`${goal}${params.id}${addNewGoal}`, { ...location.state });
          }} variant='contained' color='primary'>
            Choose another goal
          </Button>}
        </div>
        <Formik initialValues={{
          goalBody: location.state.goalBody,
          schedule: location.state.schedule ? dateFormat("mm-dd-yyyy", location.state.schedule) : dateFormat("mm-dd-yyyy"),
          state: location.state.state || "A",
          percentsCompleted: 1,
        }}
        validationSchema={Yup.object({
          goalBody: Yup
            .string()
            .strict()
            .min(3, "Goal must be at least 3 characters")
            .max(2048, "Goal must be at most 2048 characters")
            .required("required field"),
          state: Yup.string().required("required field"),
          schedule: Yup.date().required("required field"),
        })}
        onSubmit={(values) => {
          setShowPrompt(false);
          if (location.state.schedule) {
            dispatch(updateStudentGoalAction({ ...values, goalId: location.state.goalId }, () => {
              history.push(`${admmin_students_goals_url}/${params.id}`);
            }));
          } else {
            dispatch(createStudentGoalAction({ ...values, studentId: params.id }, () => {
              history.push(`${admmin_students_goals_url}/${params.id}`);
            }));
          }
        }}
        >
          {({ dirty }) => <>
            <RouterPrompt
              when={showPrompt}
              headerTitle='Discard unsaved changes?'
              onOK={() => true}
              onCancel={() => false}
              isChange={valueIsChange}
            />
            <div>
              <div style={{ width: "60%" }}>
                <div className='textarea-container'>
                  <FormikTextField name='goalBody' textArea />
                </div>

                <div className='time-container' >
                  <div style={{ width: "30%", marginRight: 20 }}>
                    <FormikDateField
                      required
                      name='schedule'
                      label='Target date'
                      showIcon={true}
                      setDate={setDateValue}
                      defaultDate={dateValue}
                      placeholder={getCurrentDate("DD-MM-YYYY")} value={getCurrentDate("DD-MM-YYYY")}
                    />
                  </div>

                  <div style={ { width: "30%" } }
                  >
                    <FormikSelect
                      required
                      label='Status'
                      name='state'
                      data={[
                        { value: "A", title: "Active" },
                        { value: "C", title: "Completed" },
                        { value: "O", title: "Overdue" },
                      ]}
                    />
                  </div>

                </div>

              </div>
              <div style={{ width: 104, marginLeft: "auto" }}>
                <FormikButton variant='contained' color='primary' >
                Save
                </FormikButton>
              </div>
              {dirty === true ? setValueIsChange(true) : null}
            </div>

          </>
          }
        </Formik>
      </Paper>
    </div>
  );
}

export default NdEditGoal;
