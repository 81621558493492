import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const calendarSlice = createSlice({
  name: "calendarReducer",
  initialState: {
    events: null,
  },
  reducers: {
    getEvents(state, { payload }) {
      state.events = payload;
    },

  },
});

export default calendarSlice.reducer;

const { getEvents } = calendarSlice.actions;

export const getEventsAction = (startDate, endDate, cb) => async (dispatch, getState) => {
  try {
    const id = getState().authorizationReducer.user.userId;
    const { data } = await axios.get(`/provider/provider-id/${id}`);
    const response = await axios(`/session/calendar-events/${data}`, {
      params: {
        limit: 1000,
        startDt: startDate,
        endDt: endDate,
      },
    });
    cb && cb(response.data.result);
    dispatch(getEvents(response.data.result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
