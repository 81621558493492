import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

function FormikRadioButton({ name, ...props }) {
  const {
    touched, errors, values, handleChange,
  } = useFormikContext();
  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";
  return <RadioButton name={name} {...props} onClick={handleChange} error={fieldTouched} checked={values[name]} />;
}

function RadioButton({
  onClick, label, name, error, value, checked,
}) {
  return (
    <div className="radiobutton">
      <input className='custom-radio' id={`${name}-${label}`} error={error} type="radio" checked={value === String(checked)} name={name} value={value} onChange={onClick} />
      <label className={clsx({ error })} htmlFor={`${name}-${label}`}>{label}</label>
    </div>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  radioButtonRef: PropTypes.object,
  onClick: PropTypes.func,
};

export default FormikRadioButton;
export {
  RadioButton,
};
