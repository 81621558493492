import React from "react";
import PropTypes from "prop-types";

const Subscribe = ({ subscribeLink }) => (
  <a href={subscribeLink} className="header__subscribe">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9997 29.3333C17.4663 29.3333 18.6663 28.1333 18.6663 26.6666H13.333C13.333 27.3738 13.614 28.0521 14.1141 28.5522C14.6142 29.0523 15.2924 29.3333 15.9997 29.3333ZM23.9997 21.3333V14.6666C23.9997 10.5733 21.813 7.14659 17.9997 6.23992V5.33325C17.9997 4.22659 17.1063 3.33325 15.9997 3.33325C14.893 3.33325 13.9997 4.22659 13.9997 5.33325V6.23992C10.173 7.14659 7.99967 10.5599 7.99967 14.6666V21.3333L5.33301 23.9999V25.3333H26.6663V23.9999L23.9997 21.3333Z" fill="#828282"/>
    </svg>
  </a>
);

Subscribe.propTypes = {
  subscribeLink: PropTypes.string,
};

export default Subscribe;
