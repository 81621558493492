import React from "react";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import Typography from "../../../../../../components/Typography";
import "./styles/index.scss";

function PaperModalGoals({
  headerTitle, open, onClose, children, isHide,
}) {
  if (!open) return null;
  return (
    <>
      <div className="grey-screen" onClick={onClose}/>
      <div className="modalGoals custom-scroll" style={isHide ? { display: "none" } : { display: "block" }}>
        <div className="modalGoals__closeicon" onClick={onClose}>
          <SvgMaker name="closeIcon" />
        </div>
        <div className="modalGoals__wrapper">
          <div style={{ paddingBottom: 20 }}>
            <Typography variant="h1"><span style={{ color: "#495057" }}>{headerTitle}</span></Typography>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default PaperModalGoals;
