import React from "react";
import "./styles/index.scss";
import Logo from "../../../core/header/components/Logo";
import logo from "../../../core/header/logo.svg";
import Time from "../../../core/header/components/Time";

const WrapperHeader = () => (
  <div className="header">
    <Logo logoHref="/" logoImgSrc={logo}/>
    <Time dateFormatString="mmm-d-dot-lt"/>
  </div>
);

export default WrapperHeader;
