import axios from "axios";

export const studentsAssignmentPushAction = (data, callback) => async () => {
  if (!data.note) delete data.note;
  try {
    await axios.post("/assignment/assign-provider", {
      ...data,
    });
    callback();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
