import React from "react";
import SvgMaker from "../svgMaker";
import Typography from "../Typography";
import "./styles/index.scss";

function Paper({
  children,
  papertitle,
  iconName,
  isIconDelete,
  changeMode,
  deleteAction = () => { },
  wrapperStyles = {},
  contentTitleStyles = {},
}) {
  return (
    <div className="content">
      <div className="content__wrapper" style={wrapperStyles}>
        {papertitle && (
          <div className="content__title" style={contentTitleStyles}>
            <Typography>{papertitle}</Typography>
            {iconName && (
              <div className="content__icon" onClick={changeMode}>
                <SvgMaker name={iconName} />
              </div>
            )}
            {isIconDelete && <div className="content__icon" onClick={deleteAction}>
              <SvgMaker name="deleteForeverIcon" />
            </div>}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

function ButtonPaper({ children }) {
  return (
    <div className="buttons">
      <div className="buttons__wrapper">{children}</div>
    </div>
  );
}

export { ButtonPaper };

export default Paper;
