import React from "react";
import { Button } from "../../../../../components/Button";

function SignaturePreview({ children, onConfirm, onDesline }) {
  return (
    <>
      <div className="react-pdf__header">
        <div></div>
        <div className='button-preview'>
          <Button variant="outlined" onClick={onDesline}>
                        Decline
          </Button>
          <Button variant="contained" onClick={onConfirm}>
                        Confirm
          </Button>
        </div>
      </div>
      <div>
        <div className="react-pdf__background" />
        <div className="react-pdf__body">
          <div style={{
            position: "absolute", zIndex: "10", top: "56px", width: "100vw", left: "0", display: "flex", justifyContent: "center",
          }} >
            <div style={{ width: "800px", height: "100%", position: "relative" }}>
              {children}
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignaturePreview;
