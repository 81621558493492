export const dowloadNewFile = (path, fileName) => {
  fetch(path)
    .then((response) => response.blob())
    .then((data) => {
      const ext = fileName.split(".");
      let blob = new Blob([data], {
        type: ext[ext.length - 1],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
