import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dateFormat } from "../../../../../../utils/dateFormater";

const studentProfileReducer = createSlice({
  name: "studentProfile",
  initialState: {
    student: null,
  },
  reducers: {
    setStudentProfile(state, { payload }) {
      state.student = payload;
    },
  },
});

export const { setStudentProfile } = studentProfileReducer.actions;

export default studentProfileReducer.reducer;

export const getStudentByIdAction = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/student/student-by-id/${id}`);
    const dataWithoutNull = Object.keys(response.data).reduce((a, c) => {
      if (response.data[c] === null) a[c] = "";
      else a[c] = response.data[c];
      return a;
    }, {});
    dispatch(setStudentProfile({
      ...dataWithoutNull,
      addrCityId: `${dataWithoutNull.addrCityId}-${dataWithoutNull.addrStateId}`,
      addrStateId: `${dataWithoutNull.addrStateId}-${dataWithoutNull.addrCityId}`,
      birthDt: dateFormat("mm-dd-yyyy", dataWithoutNull.birthDt),
      dueIepDt: dateFormat("mm-dd-yyyy", dataWithoutNull.dueIepDt),
      dueStartDt: dateFormat("mm-dd-yyyy", dataWithoutNull.dueStartDt),
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const deleteStudentById = (id, cb) => async () => {
  try {
    const res = await axios.delete(`student/delete-student/${id}`);
    // eslint-disable-next-line no-unused-expressions
    res?.status === 200 ? cb() : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
