import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { Button } from "../Button";
import { TextField } from "../TextField";
import SvgMaker from "../svgMaker";
import Pdf from "./pdf";
import { snackbarOpen } from "../Snackbar/store/snackbar.slice";

import "./styles/index.scss";

function SecureCode({
  setShowSecureCodeModal, assignmentId, invoicePeriod, getUrlPDF,
}) {
  const invoiceStartDate = moment(invoicePeriod).format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const signInvoice = async () => {
    if (assignmentId && invoicePeriod) {
      try {
        const response = await axios.post("/reports/sign-invoice-by-provider",
          { securityCode: value, invoiceStartDate, assignmentId: +assignmentId });
        if (response.status > 199 && response.status < 300) {
          setShowSecureCodeModal(false);
          dispatch(snackbarOpen({
            isOpen: true,
            timeout: 6000,
            text: "Invoice is created, signed, and sent to the signers",
            type: "success",
          }));
        }
        if (response?.data?.docPath) {
          getUrlPDF(response.data.docPath);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  return (
    <div className="react-pdf__modal">
      <div className="react-pdf__modal-header"/>
      <div className="react-pdf__modal-container">
        <span className="react-pdf__modal-container_title">
            Enter Your Security Code
        </span>
        <div className="react-pdf__modal-container_input">
          <TextField
            placeholder='Enter security code'
            value={value}
            onChange={handleChange}
            isPassword
          />
        </div>
        <div className='secure-code-buttons'>
          <Button variant='contained' onClick={signInvoice}>
            Confirm
          </Button>

          <Button variant='outlined' onClick={() => setShowSecureCodeModal(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function InvoiceValidationPDF({
  setShowPreview, assignmentId, invoicePeriod, data,
}) {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [showSecureCodeModal, setShowSecureCodeModal] = useState(false);
  const [signed, setSigned] = useState(false);
  const [docSigned, setDocSigned] = useState("");
  const [blob, setBlob] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (invoicePeriod && assignmentId && data) {
      const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
      setFileName(url.split("/")[url.split("/").length - 1]);
      setBlob(data);
      setFile(data);
    }
  }, []);

  async function downloadPDF(url) {
    setSigned(true);
    const pdf = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${url}`, {
      responseType: "blob",
    });
    setFile(pdf.data);
    setDocSigned(pdf.data);
  }

  const downloadWithOutSigned = () => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/")[url.split("/").length - 1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadSignedFile = () => {
    if (docSigned) {
      const url = window.URL.createObjectURL(new Blob([docSigned], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/")[url.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const signSent = async () => {
    setShowSecureCodeModal(true);
  };

  return (
    <>
      <div className="react-pdf__header">
        {showSecureCodeModal
        && <SecureCode
          getUrlPDF={downloadPDF}
          assignmentId={assignmentId}
          setShowPreview={setShowPreview}
          invoicePeriod={invoicePeriod}
          setShowSecureCodeModal={setShowSecureCodeModal}/>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }} onClick={setShowPreview}>
            <SvgMaker name='leftArrow' fill='#ffffff'/>
          </div>
          <div style={{ paddingLeft: 10, color: "#ffffff" }}>{fileName}.pdf</div>
        </div>
        <div className='button-preview'>
          <Button variant="outlined" onClick={() => { !signed ? downloadWithOutSigned() : downloadSignedFile(); } }>
            Download
          </Button>
          {!!invoicePeriod
          && <Button
            variant={!signed ? "contained" : "outlined"}
            onClick={() => (!signed ? signSent() : downloadWithOutSigned())}
          >
            {!signed ? "Sign and send" : "Download without signature"}
          </Button>}
        </div>

      </div>
      <Pdf file={file}/>
    </>
  );
}
