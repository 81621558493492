import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";

import CustomRecurrenceDialog from "../customRecurrence";
import { formikFieldFactory } from "../../../../services/formikService";
import HeaderPage from "../../../../components/HeaderPage";
import "./styles/index.scss";
import { Button } from "../../../../components/Button";
import Paper from "../../../../components/Paper";
import {
  createSessionAction, getSessionNotesAction, getStudentOptionsAction,
} from "./store";
import { getCurrentDate, getStartOfDay } from "../../../../functions/calendar_func";
import RouterPrompt from "../../../../components/RouterPromptWindow/RouterPrompt";

import { calendar_url, session_notes_url } from "../../../../configs/router_urls";
import {
  formikInitialValues,
  validationSchema,
  fieldConfigStudents,
  fieldConfigDate,
} from "./configs";

const repeatOptions = {
  week: "W",
  day: "D",
};

const weekDayValues = {
  Su: 0,
  Mo: 1,
  Tu: 2,
  We: 3,
  Th: 4,
  Fr: 5,
  Sa: 6,
};

function CreateSession() {
  const dispatch = useDispatch();
  const history = useHistory();
  const studentOptions = useSelector(({ sessionReducer }) => sessionReducer.studentOptions);

  const [config, setConfig] = useState(fieldConfigStudents);
  const [selectedDate, handleDateChange] = useState({
    start_time: moment().hour(0).minute(0).second(0),
    end_time: moment().hour(0).minute(0).second(0),
  });
  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [repeatOn, updateRepeatOn] = useState([]);
  const [endChecked, updateEndChecked] = useState("after");
  const [endOn, setEndOn] = useState(moment().format("MM-DD-YYYY"));
  const [occurrences, setOccurrences] = useState(1);
  const [repeat, setRepeat] = useState({ title: "day", value: 1 });
  const [dateValue, setDateValue] = useState("");
  const [endOnValue, setEndOnValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState([]);
  const [diffDate, setDiffDate] = useState({ current: getCurrentDate("MM-DD-YYYY"), otherDate: null });
  const [hideModal, setHideModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [students, setStudents] = useState([]);

  function filterStudents(studentOptions) {
    if (!selected.length) return studentOptions;

    return studentOptions.filter((el) => {
      if (selected.find((s) => s.id === el.assignmentId)) return true;
      if (selected[0]?.ratio !== "G") {
        return el.assignmentId === selected[0].id;
      }
      return el.ratio === "G"
        && el.typeName === selected[0].typeName
        && !selected.find((s) => s.studentId === el.studentId);
    });
  }

  useEffect(() => {
    window.addEventListener("click", (e) => {
      e.target.textContent === "Create" || e.target.textContent === "Completed" ? setShowPrompt(false) : setShowPrompt(true);
    });
  }, [showPrompt]);

  useEffect(() => {
    setEndOnValue(dateValue);
    setEndOn(diffDate.otherDate);
  }, [dateValue, diffDate]);

  const validation = () => {
    if (!selected.length) {
      setErrorMsg("required field");
      return false;
    }
    if (selectedDate?.start_time >= selectedDate?.end_time) {
      return false;
    }
    setErrorMsg("");
    return true;
  };

  const goToNotes = async (v) => {
    setSubmit(true);
    if (!validation()) return;

    const startDt = moment(`${v.date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
    const endDt = moment(`${v.date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();

    dispatch(createSessionAction({
      startDt,
      endDt,
      assignmentList: selected.map((s) => s.assignmentId),
      periodLength: repeatOptions[repeat.title],
      periodMultiplier: repeat.value,
      weekDays: repeatOn.map((el) => weekDayValues[el]),
      endCondition: endChecked === "on" ? "O" : "A",
      endConditionDt: moment(endOn).format("YYYY-MM-DD"),
      endConditionOccurNumber: Number(occurrences),
      isScheduled: true,
    }, (id) => {
      if (!id) return;
      dispatch(getSessionNotesAction(id, () => {
        history.push(`${session_notes_url}/${id}`, {
          ...v,
          startDt,
          endDt,
          assignmentList: selected,
          periodLength: repeatOptions[repeat.title],
          periodMultiplier: repeat.value,
          weekDays: repeatOn.map((el) => weekDayValues[el]),
          afterCount: occurrences,
          isScheduled: true,
        });
      }));
    }));
  };

  const goToCalendar = async (v) => {
    setSubmit(true);
    setShowPrompt(false);

    if (!validation()) return;

    const startDt = moment(`${v.date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
    const endDt = moment(`${v.date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();

    dispatch(createSessionAction({
      startDt,
      endDt,
      assignmentList: selected.map((s) => s.assignmentId),
      periodLength: repeatOptions[repeat.title],
      periodMultiplier: repeat.value,
      weekDays: repeatOn.map((el) => weekDayValues[el]),
      endCondition: endChecked === "on" ? "O" : "A",
      endConditionDt: moment(endOn).format("YYYY-MM-DD"),
      endConditionOccurNumber: Number(occurrences),
      isScheduled: false,
    }, (id) => {
      if (!id) return;
      history.push(calendar_url);
    }));
  };

  function transformData(studentOptions) {
    return studentOptions.map((el) => ({
      ...el,
      title: el.formattedStr,
      value: el.assignmentId,
      id: el.assignmentId,
    }));
  }

  useEffect(async () => {
    await setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
  }, [selected]);
  useEffect(() => {
    dispatch(getStudentOptionsAction());
  }, []);
  useEffect(() => {
    setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
  }, [studentOptions]);

  useEffect(() => {
    if (history.location.state) {
      if (studentOptions.length) {
        const { state } = history.location;
        handleDateChange({
          start_time: moment(state.actualStartDt),
          end_time: moment(state.actualEndDt),
        });
        const weeks = Object.keys(weekDayValues).reduce((a, c) => {
          a[weekDayValues[c]] = c;
          return a;
        }, {});
        setRepeat({ title: state.seRecurrenceSchema.periodLength === "W" ? "week" : "day", value: state.seRecurrenceSchema.periodMultiplier });
        // eslint-disable-next-line no-empty
        if (state.seRecurrenceSchema.weekDays) {
          updateRepeatOn(() => state.seRecurrenceSchema.weekDays.split(",").map((el) => weeks[+el]));
        }
        updateEndChecked(() => {
          if (state.seRecurrenceSchema.endConditionDt) {
            setEndOn(moment(state.seRecurrenceSchema.endConditionDt).format("MM-DD-YYYY"));
            return "on";
          }
          setOccurrences(state.seRecurrenceSchema.endConditionOccurNumber);
          return "after";
        });
      }
    }
  }, [studentOptions]);

  function updateConfig() {
    if (selected.length) setErrorMsg("");
    setConfig(() => config.map((item) => {
      switch (item.name) {
      case "student":
        return {
          ...item,
          selected,
          setSelected,
          data: students,
          error: errorMsg,
        };
      default:
        return item;
      }
    }));
  }

  useEffect(() => {
    updateConfig();
  }, [students, errorMsg]);

  return (
    <div>
      <HeaderPage
        title="Create Calendar Entries"
        rootPathName={calendar_url}
        rootTitleName="Calendar"
        childrenTitleNames={["Create calendar entries"]}
      />
      <Paper>
        <Formik
          enableReinitialize={true}
          initialValues={formikInitialValues}
          validationSchema={validationSchema}
          onSubmit={goToCalendar}
        >
          {({ values, dirty }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className="create_session__fields">
                {formikFieldFactory(config, "create_session__field", {}, selected)}
                {formikFieldFactory(fieldConfigDate, "create_session__field", {
                  selectedDate, handleDateChange, setDateValue, dateValue, setDiffDate, diffDate, sessionForm: true,
                }, {}, submit, setSubmit)}
                <div
                  className='create_session__field'
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: 130,
                      padding: "10px 12px",
                    }}
                    onClick={() => setHideModal(true)}
                  >
                    <CustomRecurrenceDialog
                      repeatOn={repeatOn}
                      updateRepeatOn={updateRepeatOn}
                      endChecked={endChecked}
                      updateEndChecked={updateEndChecked}
                      repeat={repeat}
                      setRepeat={setRepeat}
                      occurrences={occurrences}
                      setOccurrences={setOccurrences}
                      endOn={endOn}
                      setEndOn={setEndOn}
                      diffDate={diffDate}
                      hideModal={hideModal}
                      setHideModal={setHideModal}
                      setDate={setEndOnValue}
                      defaultDate={endOnValue}
                    />
                  </div>
                  <div style={{ height: 131, borderRadius: 4, border: "1px solid #CED4DA" }}>
                    <div style={{
                      paddingTop: 26, paddingLeft: 12, display: "flex", flexDirection: "column",
                    }}>
                      <span style={{
                        color: "#495057", fontSize: 16, lineHeight: "19px", fontWeight: 500, display: "inline-block", paddingBottom: 10,
                      }}>
                              Recurrence
                      </span>
                      <span style={{ fontSize: 13, lineHeight: "19px" }}>
                                Ends: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}>{endChecked} {endChecked === "on" ? endOn : `${occurrences} occurrences`}</span>
                      </span>
                      <span style={{ fontSize: 13, lineHeight: "19px" }}>
                              Repeat every: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}> {repeat.value} {repeat.title} </span>
                      </span>
                      {repeatOn.length ? <span style={{
                        fontSize: 13, lineHeight: "19px", display: "inline-block", paddingTop: 10,
                      }}>
                                Repeat on: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}>{repeatOn.map((item) => item).join(", ")}</span>
                      </span> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="create_session__buttons">
                <div className="create_session__button-first">
                </div>
                <div className="create_session__button">
                  <Button
                    disabled={moment().utc().valueOf() < moment(`${values.date} ${moment(selectedDate.end_time).format("HH:MM")}`).valueOf()}
                    variant="contained"
                    color="primary"
                    onClick={() => goToNotes(values)}
                  >
                    Completed
                  </Button>
                </div>
                <div className="create_session__button">
                  <Button variant="contained" color="primary" onClick={() => goToCalendar(values)}>
                    Create
                  </Button>
                  {dirty === true ? setValueIsChange(true) : null}
                </div>
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default CreateSession;
