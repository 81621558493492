import React, { useEffect, useState, useRef } from "react";

function IntOfIntPlaceholder({
  value = "",
  label = "",
  required,
  name = "",
  type = "string",
  style,
  onChange = () => { },
  onKeyDown = () => { },
}) {
  const [error, setError] = useState("");

  const inputRef = useRef();
  useEffect(() => {
    if (!value.match(/^\d+$/) && value.length) {
      setError("This field must contain only integer value ");
      inputRef.current.style.border = "1px solid #ff5454";
    }
    if (value === "") {
      setError("");
      inputRef.current.style.border = "1px solid #757F98";
    }
    if (value.match(/^\d+$/)) {
      setError("");
      inputRef.current.style.border = "1px solid #757F98";
    }
  }, [value?.length]);

  return (
    <div className='input'>
      {label && <span className="textfield__label">{required ? "* " : ""}{label}</span>}
      <input
        ref={inputRef}
        className="textfield"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={style}
      />
      {!value.match(/^\d+$/) && value.length ? <span style={{ display: "inline" }} className='textfield__errortext'>{error}</span> : null}
    </div>
  );
}

export default IntOfIntPlaceholder;
