const getLabelTitleInvoices = (state) => {
  switch (state) {
  case "C":
  case "E":
    return "Signature pending";
  case "S":
    return "Signed";
  case "D":
    return "Sent";
  case "P":
    return "Paid";
  default:
    return state;
  }
};
export default getLabelTitleInvoices;
