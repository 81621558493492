import React from "react";
import Paper from "../../../../../components/Paper";
import Typography from "../../../../../components/Typography";
import "./styles/index.scss";

function ProgressReportInfo({
  data, iconName, changeMode, name, date, osis,
}) {
  return (
    <React.Fragment>
      <Paper papertitle='Info' iconName={iconName} changeMode={changeMode}>
        <div className='report__info'>
          <div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  Student Name:
              </Typography>
              <Typography variant='subtitle1-bold'>
                {name}
              </Typography>
            </div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                Grade:
              </Typography>
              <Typography variant='subtitle1-bold'>
                  3
              </Typography>
            </div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                Date:
              </Typography>
              <Typography variant='subtitle1-bold'>
                {date}
              </Typography>
            </div>
          </div>
          <div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  SETSS provider name:
              </Typography>
              <Typography variant='subtitle1-bold'>
                  Value
              </Typography>
            </div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  Contact information:
              </Typography>
              <Typography variant='subtitle1-bold'>
                  Value
              </Typography>
            </div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  OSIS#:
              </Typography>
              <Typography variant='subtitle1-bold'>
                {osis}
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
      <Paper papertitle='Grade levels'>
        <div className='report__info'>
          <div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  Student Name:
              </Typography>
              <Typography variant='subtitle1-bold'>
                {name}
              </Typography>
            </div>
          </div>
          <div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  Reading comprehension:
              </Typography>
              <Typography variant='subtitle1-bold'>
                  Grade 5
              </Typography>
            </div>
            <div className='report__info-text'>
              <Typography variant='subtitle1' style={{ paddingRight: 5 }}>
                  Contact information:
              </Typography>
              <Typography variant='subtitle1-bold'>
                  Grade 6
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default ProgressReportInfo;
