import * as Yup from "yup";

export const fieldConfig = [
  {
    id: 1,
    name: "dateRange",
    label: "Date period",
    placeholder: "",
    type: "datefield",
    isRange: true,
    required: true,
  },
];
export const formikInitialValues = {
  dateRange: "",
};
export const validationSchema = Yup.object({
  dateRange: Yup.string().required("required field"),
});
