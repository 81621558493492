import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import HeaderPage from "../../../../components/HeaderPage";
import { provider_students_assigment_url, provider_student_documents_url } from "../../../../configs/router_urls";
import { setStudentProfile, getStudentByIdAction } from "../../../admin/student/pages/studentProfile/store";
import { fetchDocumentsList } from "../../../admin/student/pages/documents/store";
import { Button } from "../../../../components/Button";
import Paper from "../../../../components/Paper";
import Typography from "../../../../components/Typography";
import Loader from "../../../../components/LoadingIndicator";
import { ButtonPaper } from "../../../../components/Paper/Paper";
import { dataConfig } from "./config";
import { formaterGender, formaterClassType, formaterPrimaryResponsiblePerson } from "../../../../utils/studentProfileFormater";

function ProviderStudentProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const numberDocuments = useSelector(({ documentsReducer }) => documentsReducer.documents);
  const data = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
  useEffect(() => {
    if (!data || +data?.studentId !== +params.id || history.location.state?.update) {
      dispatch(setStudentProfile([]));
      dispatch(getStudentByIdAction(params.id));
    }
    dispatch(fetchDocumentsList(params.id));
  }, []);
  const changeData = () => {
    const mutableData = {};
    Object.keys(data).forEach((el) => {
      if (mutableData[dataConfig[el]?.id]) return;
      mutableData[dataConfig[el]?.id] = {
        group: Object.keys(dataConfig)
          .filter((item) => dataConfig[item].id === dataConfig[el]?.id)
          .map((item, index) => {
            if (item === "gender") return { id: index, value: formaterGender(data[item]), label: dataConfig[item].name };
            if (item === "primaryResponsiblePerson") return { id: index, value: formaterPrimaryResponsiblePerson(data[item]), label: dataConfig[item].name };
            if (item === "classType") return { id: index, value: formaterClassType(data[item]), label: dataConfig[item].name };
            return { id: index, value: data[item], label: dataConfig[item].name };
          }),
      };
    });
    return Object.values(mutableData);
  };
  const goToDocuments = () => history.push(`${provider_student_documents_url.split("/:")[0]}/${params.id}`);
  return (
    <div>
      <HeaderPage
        title="Student Profile"
        rootPathName={provider_students_assigment_url}
        rootTitleName='All Students'
        childrenTitleNames={["Student Profile"]}
      />
      {data && <div className='student_profile'>
        <div className='student_profile__container'>
          <Paper papertitle='Personal information'>
            {data?.addrCityId || data?.addrStateId ? (
              <div className='info'>
                {changeData().map(({ group }) => (
                  <div className='info__container'>
                    {group.map((item) => (
                      <div className='info__container-data' style={{ marginRight: "50px" }}>
                        <Typography variant='subtitle2'>
                          {item.label}:
                        </Typography>
                        <Typography variant='subtitle2-bold'>
                          {item.value || "N/A"}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <Loader/>
            )}
          </Paper>
        </div>
        <ButtonPaper>
          <div className='buttons__wrapper-field'>
            <Button variant='contained' color='primary' onClick={goToDocuments} counter={numberDocuments.length}>
              {numberDocuments.length > 0
                ? <></> : null
              }
              Documents
            </Button>
          </div>
        </ButtonPaper>
      </div>
      }
    </div>
  );
}

export default ProviderStudentProfile;
