const infoConfig = [
  {
    id: 0,
    key: "caseNo",
    title: "Case number",
    value: "",
  },
  {
    id: 1,
    key: "serviceType",
    title: "Service type",
    value: "",
  },
  {
    id: 2,
    key: "invoiceMonth",
    title: "Month",
    value: "",
  },
  {
    id: 3,
    key: "minutesAmount",
    title: "Invoiced hours",
    value: "",
  },
  {
    id: 4,
    key: "billingRate",
    title: "Provider rate",
    value: "",
  },
  {
    id: 5,
    key: "pendingSignatures",
    title: "Pending signatures",
    value: "",
  },
  {
    id: 6,
    key: "givenSignatures",
    title: "Given signatures",
    value: "",
  },
  {
    id: 7,
    key: "paidDt",
    title: "Paid by DOE",
    value: "",
  },
];

export {
  infoConfig,
};
