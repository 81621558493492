import React from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";

import moment from "moment";
import FormikDateField, { DateField } from "../components/DateField";
import FormikSelect from "../components/Select";
import FormikTextField from "../components/TextField";
import SvgMaker from "../components/svgMaker";
import { Button } from "../components/Button";
import MyltiSelect from "../pages/calendar/session/sessionNotesForm/sections/components/AutoComplete";

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#FF9500",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#FF9500",
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        border: "14px solid #FF9500",
      },
      pointer: {
        backgroundColor: "#FF9500",
      },
      noPoint: {
        backgroundColor: "#FF9500",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#192A53",
      },
    },
  },
});

export const formikFieldFactory = (fieldConfig, classname, e, studentArray, isSubmit, setSubmit = () => { }) => fieldConfig.map((item, key) => {
  const {
    diffDate, setDiffDate, sessionForm, initValue,
  } = e;

  switch (item.type) {
  case "textfield": {
    return (
      <div
        className={classname}
        key={item.id + item.name}
      >
        <FormikTextField {...item} />
      </div>
    );
  }
  case "datefield": {
    const { edit, eventDt } = e;

    return (
      <div
        className={classname}
        key={item.id + item.name}
      >
        <FormikDateField
          sessionForm={sessionForm}
          diffDate={diffDate}
          setDiffDate={setDiffDate}
          showIcon
          {...item}
          edit={edit}
          setDate={e.setDateValue}
          eventDt={eventDt}
          defaultDate={e.dateValue ?? eventDt}
          date={initValue?.date}
        />
      </div>
    );
  }
  case "datefieldDefault": {
    return (
      <div
        className={classname}
        key={item.id + item.name}
      >
        <DateField
          value={e.selectedDate[item.name]}
          nativeClick={(event) => e.handleDateChange({ [item.name]: event.target.value })}
          showIcon
          {...item}
        />
      </div>
    );
  }
  case "select": {
    return (
      <div
        className={`${classname} ${item.class ? item.class : ""}`}
        key={item.id + item.name}
      >
        <FormikSelect {...item} studentArray={studentArray}/>
      </div>
    );
  }
  case "timerange": {
    const start = moment(e?.selectedDate?.start_time).format();
    const end = moment(e?.selectedDate?.end_time).format();
    let error = false;
    if (item.name === "end_time") {
      if (start === end && isSubmit) {
        error = true;
      } else if (start > end && isSubmit) {
        error = true;
      }
    }

    return (
      <div
        className={classname}
        key={item.id}
      >
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className='timepicker-container'>
              <span style={{
                display: "inline-block",
                paddingBottom: 5,
                fontSize: 13,
                color: "#192A53",
                fontWeight: 500,
              }}
              >
                * {item.label}
              </span>

              <div style={{ position: "absolute", right: 10, bottom: 5 }}>
                <SvgMaker name='timePickerIcon'/>
              </div>

              <div style={error ? {
                border: "1px solid red",
                borderRadius: "5px",
              } : {}}>
                <TimePicker
                  fullWidth
                  value={e?.selectedDate && e.selectedDate[item.name]}
                  onChange={(event) => {
                    setSubmit(false);
                    e.handleDateChange({ ...e.selectedDate, [item.name]: event });
                  }}
                  minutesStep={item.step}
                  inputVariant='outlined'
                  size='small'
                  input={{
                    color: "#ff0000",
                    cssUnderline: {
                      "&:after": {
                        borderBottomColor: "#333333",
                      },
                    },
                    cssLabel: {
                      "&$cssFocused": {
                        color: "#333333",
                      },
                    },
                  }}
                />
              </div>

            </div>
            {item.name === "start_time" ? <div className="create_session__divider">-</div> : null}
            {error && <span className="create_session__error">End should be greater than start time</span>}
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
    );
  }
  case "button": {
    return (
      <div
        className={`${classname} ${item.class ? item.class : ""}`}
        key={item.id + item.name}
      >
        <Button variant="outlined" color="grey" onClick={item.onClick}>{item.title}</Button>
      </div>
    );
  }
  case "empty": {
    return (
      <div key={key} className={classname}/>
    );
  }
  case "myltiSelect": {
    return (
      <div
        className={`${classname} ${item.class ? item.class : ""}`}
        key={item.id + item.name}
      >
        <span className={`datefield__label${item.error ? " error" : ""}`}>{item.required ? "* " : ""}{item.label}</span>
        <MyltiSelect
          {...item}
        />
      </div>
    );
  }
  default:
    return "default";
  }
});
