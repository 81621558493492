import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";

import { Button } from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import { getListPlaceholder } from "../../../../../pages/dict/components/goals/store";
import IntOfIntPlaceholder from "./components/IntOfIntPlaceholder";
import PersentPlaceholder, { arr_100 } from "./components/PersentPlaceholder";
import TextPlaceholder from "./components/TextPlaceholder";
import SelectPlaceholder from "./components/SelectPlaceholder";
import { addSpaces } from "../../../../../pages/dict/components/goals/utils/parseString";

import "./styles/index.scss";

import {
  admin_students_edit_goal_url,
  admmin_students_goals_url,
  admmin_students_profile_url,
  admmin_students_url,
} from "../../../../../configs/router_urls";

function NdGenerateGoal() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const buttonRef = useRef();
  const dispatch = useDispatch();

  const [values, setValues] = useState({});
  const [independencyValues, setIndependencyValues] = useState({});
  const [measurementValues, setMeasurementValues] = useState({});
  const [status, setStatus] = useState(false);

  let countPercent = 0;

  const infoPlaceholders = useSelector(({ dictionaryGoalsReducer }) => dictionaryGoalsReducer.infoPlaceholders);

  useEffect(() => {
    dispatch(getListPlaceholder());
  }, []);

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const parse = (str, index) => {
    const name = location.state.studentName;
    let newStr = str.replaceAll("~Name~", name);
    let arr = newStr.split(" ");
    for (let i = 0; i < arr.length; i++) {
      let elem = arr[i].trim();
      if (elem === "~Percent~") {
        countPercent += 1;
        arr.splice(i, 1, <span className='generate-goal'>
          <PersentPlaceholder value={values[`~Percent~/${index}`]} name={`~Percent~/${index}`}
            onChange={onChange} onKeyDown={onKeyDown}
          />
          <span> % accuracy</span></span>);
      }
      if (elem === "~X") {
        arr.splice(i, 1, <span className='generate-goal'>
          <IntOfIntPlaceholder value={values[`~X/${index}`]} name={`~X/${index}`}
            onChange={onChange} onKeyDown={onKeyDown}
          />
          <span> out </span></span>);
      }
      if (elem === "x~") {
        arr.splice(i, 1, <span className='generate-goal'>
          <IntOfIntPlaceholder value={values[`x~/${index}`]} name={`x~/${index}`}
            onChange={onChange} onKeyDown={onKeyDown}
          />
        </span>);
      }
      if (elem === "~Grade~") {
        arr.splice(i, 1, <span className='generate-goal text50'>
          <TextPlaceholder value={values[`~Grade~/${index}`]} name={`~Grade~/${index}`}
            onChange={onChange} isValid={values[`~Grade~/${index}`]} clickGenerate={status}
          />
        </span>);
      }
      if (elem === "~String~") {
        arr.splice(i, 1, <span className='generate-goal text50'>
          <TextPlaceholder value={values[`~Text50~/${index}`]} name={`~Text50~/${index}`}
            onChange={onChange} isValid={values[`~Text50~/${index}`]} clickGenerate={status}
          />
        </span>);
      }
      if (elem === "~Independency~") {
        arr.splice(i, 1, <span className='generate-goal enumAskAnswer'>
          <SelectPlaceholder
            value={independencyValues[`~Independency~/${index}`]}
            name={`~Independency~/${index}`}
            data={infoPlaceholders?.find((el) => el.name === "Teacher support")?.field.map((el, i) => ({ id: i, title: el, value: el }))}
            onNativeChange={(e) => setIndependencyValues({ ...independencyValues, [e.target.name]: e.target.value })}
          />
        </span>);
      }
      if (elem === "~Measurement~") {
        arr.splice(i, 1, <span className='generate-goal enumAskAnswer'>
          <SelectPlaceholder
            value={measurementValues[`~Measurement~/${index}`]}
            name={`~Measurement~/${index}`}
            data={infoPlaceholders?.find((el) => el.name === "Measurement")?.field.map((el, i) => ({ id: i, title: el, value: el }))}
            onNativeChange={(e) => setMeasurementValues({ ...measurementValues, [e.target.name]: e.target.value })}
          />
        </span>);
      }
    }
    return arr;
  };

  const generate = () => {
    setStatus(true);
    const name = location.state.studentName;
    let newStr = addSpaces(location.state.goalBody).replaceAll("~Name~", name);
    let arr = newStr.split(" ");
    const arrayValues = Object.entries(values);
    const arrXFirst = arrayValues?.filter((el) => (el[0].split("/")[0] === "~X"));
    const arrXSecond = arrayValues?.filter((el) => (el[0].split("/")[0] === "x~"));
    const arrPercent = arrayValues?.filter((el) => (el[0].split("/")[0] === "~Percent~"));
    const arrText50 = arrayValues?.filter((el) => (el[0].split("/")[0] === "~Text50~"));
    const arrGrade = arrayValues?.filter((el) => (el[0].split("/")[0] === "~Grade~"));
    const arrIndependency = Object.entries(independencyValues);
    const arrMeasurement = Object.entries(measurementValues);

    const valuesXFirst = [];
    const valuesXSecond = [];
    const valuesPercent = [];
    const valuesText50 = [];
    const valuesIndependency = [];
    const valuesMeasurement = [];
    const valuesGrade = [];

    arrXFirst.map((el) => valuesXFirst.push({ value: el[1], id: el[0] }));
    arrXSecond.map((el) => valuesXSecond.push({ value: el[1], id: el[0] }));
    arrPercent.map((el) => valuesPercent.push({ value: el[1], id: el[0] }));
    arrText50.map((el) => valuesText50.push({ value: el[1], id: el[0] }));
    arrGrade.map((el) => valuesGrade.push({ value: el[1], id: el[0] }));
    arrIndependency.map((el) => valuesIndependency.push({ value: el[1], id: el[0] }));
    arrMeasurement.map((el) => valuesMeasurement.push({ value: el[1], id: el[0] }));
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "~X") {
        if (valuesXFirst.length) {
          arrayValues.forEach((item) => {
            if (item[0] === valuesXFirst.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, `${valuesXFirst.find((el) => el.id === item[0])?.value} out`);
            }
          });
        } else arr.splice(i, 1, "out");
      } else if (arr[i] === "x~") {
        if (valuesXSecond.length) {
          arrayValues.forEach((item) => {
            if (item[0] === valuesXSecond.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, `${valuesXSecond.find((el) => el.id === item[0])?.value} `);
            }
          });
        } else arr.splice(i, 1, "");
      } else if (arr[i] === "~Percent~") {
        if (valuesPercent.length) {
          arrayValues.forEach((item) => {
            if (item[0] === valuesPercent.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, `${valuesPercent.find((el) => el.id === item[0])?.value}% accuracy`);
            }
          });
        } else arr.splice(i, 1, "% accuracy");
      } else if (arr[i] === "~String~") {
        if (valuesText50.length) {
          arrayValues.forEach((item) => {
            if (item[0] === valuesText50.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, valuesText50.find((el) => el.id === item[0])?.value);
            }
          });
        } else arr.splice(i, 1, "");
      } else if (arr[i] === "~Grade~") {
        if (valuesGrade.length) {
          arrayValues.forEach((item) => {
            if (item[0] === valuesGrade.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, valuesGrade.find((el) => el.id === item[0])?.value);
            }
          });
        } else arr.splice(i, 1, "");
      } else if (arr[i] === "~Independency~") {
        if (valuesIndependency.length) {
          arrIndependency.forEach((item) => {
            if (item[0] === valuesIndependency.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, valuesIndependency.find((el) => el.id === item[0])?.value);
            }
          });
        } else arr.splice(i, 1, "");
      } else if (arr[i] === "~Measurement~") {
        if (valuesMeasurement.length) {
          arrMeasurement.forEach((item) => {
            if (item[0] === valuesMeasurement.find((el) => el.id === item[0])?.id) {
              arr.splice(i, 1, valuesMeasurement.find((el) => el.id === item[0])?.value);
            }
          });
        } else arr.splice(i, 1, "");
      }
    }
    newStr = arr.join(" ");
    const [o, t] = admin_students_edit_goal_url.split(":id");

    const resPercent = [];
    const resText = [];
    const resGrade = [];
    const resXFirst = [];
    const resXSecond = [];
    valuesPercent.forEach((el) => resPercent.push(el.value));
    valuesText50.forEach((el) => resText.push(el.value));
    valuesGrade.forEach((el) => resGrade.push(el.value));
    valuesXFirst.forEach((el) => resXFirst.push(el.value));
    valuesXSecond.forEach((el) => resXSecond.push(el.value));

    const checkValidation = () => {
      const isValid = [];
      if (resGrade.length) {
        resGrade.map((el) => (el.length <= 50 || el === "" ? isValid.push(true) : isValid.push(false)));
      }
      if (resXFirst.length) {
        resXFirst.map((el) => (el.match(/^\d+$/) || el === "" ? isValid.push(true) : isValid.push(false)));
      }
      if (resXSecond.length) {
        resXSecond.map((el) => (el.match(/^\d+$/) || el === "" ? isValid.push(true) : isValid.push(false)));
      }
      if (resText.length) {
        resText.map((el) => (el.length <= 50 || el === "" ? isValid.push(true) : isValid.push(false)));
      }
      if (countPercent) {
        isValid.push(resPercent.every((a) => arr_100.includes(a) || a === ""));
      } else {
        isValid.push(true);
      }
      return !isValid.includes(false);
    };
    if (checkValidation()) {
      history.push(`${o}${params.id}${t}`, {
        ...location.state,
        goalBody: `${newStr}`,
        addNewGoal: true,
      });
    }
  };

  return (
    <div>
      <HeaderPage
        title={`Generate a Goal for ${location.state.studentName}`}
        rootPathName={admmin_students_url}
        rootTitleName="All Students"
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`, `${admmin_students_goals_url}/${params.id}`]}
        childrenTitleNames={["Student Profile", "Student Goals", "Generate a Goal for Student"]}
      />
      <Paper>
        <div style={{ width: 175, marginBottom: 24 }}>
          <Button onClick={() => history.goBack()} variant='contained' color='primary'>
            Choose another goal
          </Button>
        </div>
        <div >
          <div className='content'>
            {location.state.goalBody
              .split("\n")
              .map((it, index) => <p>{parse(it, index)?.map((el) => <><span>{el}</span><span> </span></>)}</p>)}
          </div>

          <div style={{ marginLeft: "auto", width: 115, marginTop: "20px" }}>
            <Button onClick={generate} variant='contained' color='primary' buttonRef={buttonRef}>
              Generate
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default NdGenerateGoal;
