import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";

import FormikButton from "../../../../../components/Button";
import FormikCheckBox from "../../../../../components/Checkbox";
import FormikDateField from "../../../../../components/DateField";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import FormikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import { getInfoForAssignment, updateStudentsAssignment, getInfoForService } from "./store";
import { getStudentServicesAction } from "../studentServices/store";
import { dateFormat } from "../../../../../utils/dateFormater";
import { getProvidersByIdAction } from "../../../provider/pages/providerProfile/store";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";

import "../studentAssignment/styles/index.scss";

import { admin_students_assigments_list_url } from "../../../../../configs/router_urls";
import { fieldConfig, validationShema } from "../studentAssignment/config";
import { getAllProvidersAction } from "../../../store";

function EditStudentAssignment() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);
  const allProviders = useSelector(({ adminReducer }) => adminReducer.allProviders.result);
  const allStudents = useSelector(({ adminReducer }) => adminReducer.allStudents.result);
  const data = useSelector(({ studentServiceReducer }) => studentServiceReducer.services.result);
  const assignmentData = useSelector(({ editAssignmentReducer }) => editAssignmentReducer.data);
  const ser = useSelector(({ editAssignmentReducer }) => editAssignmentReducer.service);
  const provider = useSelector(({ providersProfileReducer }) => providersProfileReducer.data);

  const didMount = useRef(false);
  const providerId = useRef(assignmentData.providerId);
  const changeProvider = useRef(true);

  const [formikConfig, setFormikConfig] = useState(fieldConfig);
  const [initialState, setInitialState] = useState(null);
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    dispatch(getInfoForAssignment(params.id));
    dispatch(getAllProvidersAction("A"));
  }, []);

  useEffect(() => {
    if (didMount.current) {
      providerId.current = assignmentData.providerId;
      setInitialState(initialData(assignmentData));
    }
    didMount.current = true;
  }, [assignmentData]);

  useEffect(() => {
    if (data.find((d) => d.serviceId === +serviceId)) {
      dispatch(getInfoForService(serviceId));
    }
  }, [serviceId]);

  const transformData = (data) => {
    const {
      altProviderId, substitute, ...props
    } = data;
    const newData = { ...props, substitute };
    if (substitute && altProviderId) newData.altProviderId = altProviderId;
    return newData;
  };

  const save = (values) => {
    setShowPrompt(false);
    const payrollRate = +values.payrollRate;
    const sessionAmount = +values.sessionAmount;
    const data = {
      ...transformData(values),
      sessionAmount,
      payrollRate: payrollRate.toFixed(2),
      startDt: moment(values.timePeriod.split(" to ")[0]).format("YYYY-MM-DD"),
      endDt: moment(values.timePeriod.split(" to ")[1]).format("YYYY-MM-DD"),
      note: values.note.trim(),
    };
    dispatch(
      updateStudentsAssignment(
        history.location.state,
        data,
        () => history.push(admin_students_assigments_list_url),
      ),
    );
    if (data.state === "F") {
      history.push(admin_students_assigments_list_url);
    }
  };

  const infoMessage = (message) => {
    if (message) {
      dispatch(snackbarOpen(message));
    }
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  useEffect(() => {
    if (allStudents.length && allProviders.length && data.length) {
      setFormikConfig(formikConfig.map((el) => {
        switch (el.name) {
        case "studentId":
        case "providerId":
        case "altProviderId":
          return {
            ...el,
            data: el.name === "studentId" ? allStudents : allProviders,
          };
        case "serviceId":
          // eslint-disable-next-line no-case-declarations
          const { info, disabled, ...props } = el;
          return {
            ...props,
            data: data.map((el) => ({
              value: el.serviceId, title: el.serviceTypeName, id: el.serviceId, ...el,
            })),
          };
        default:
          return el;
        }
      }));
    } else if (!data.length && assignmentData?.studentId) {
      dispatch(getStudentServicesAction(assignmentData.studentId));
    }
  }, [allProviders, allStudents, data]);

  function initialData(initialState) {
    if (!initialState) return;
    let {
      startDt, endDt, note, serviceId, altProviderId, sessionAmountAssignment, status, supervisorId, ...props
    } = initialState;
    let period = "";
    if (data) {
      const service = data.find((el) => el.serviceId === serviceId);
      if (typeof service === "object") {
        const { sessionLength, sessionWeekly } = service;
        period = `${sessionWeekly} x ${sessionLength}`;
      }
    }
    const timePeriod = `${dateFormat("mm-dd-yyyy", startDt)} to ${dateFormat("mm-dd-yyyy", endDt)}`;
    note = note === null ? "" : note;
    const mandate = `Weekly mandate: ${period}`;
    altProviderId = altProviderId === null ? "" : altProviderId;
    const sessionAmount = sessionAmountAssignment === null ? "" : sessionAmountAssignment;
    return {
      ...props, timePeriod, note, serviceId, mandate, sessionAmount, altProviderId,
    };
  }

  function checkRequired(item, values, setValues) {
    if (providerId.current !== values.providerId) {
      providerId.current = values.providerId;
      changeProvider.current = false;
      dispatch(getProvidersByIdAction(values.providerId));
    }
    if (+providerId.current === provider?.providerId) {
      if (!changeProvider.current) {
        changeProvider.current = true;
        setValues({ ...values, payrollRate: provider?.payrollRate || assignmentData.payrollRate });
      }
    }
    if (item.name !== "altProviderId") return !!item.required;
    if (typeof item.required !== "undefined") return !!item.required;
    return values.substitute;
  }

  const onChangeSelect = (item, values) => {
    if (item.name === "serviceId") setServiceId(values.serviceId);
  };

  return (
    <div>
      <HeaderPage
        title='Edit The Assignment'
        rootPathName={admin_students_assigments_list_url}
        rootTitleName='All Assignments'
        childrenTitleNames={["Edit The Assignment"]}
      />
      <Paper>
        {initialState && <Formik
          initialValues={initialState}
          validationSchema={validationShema}
          onSubmit={save}>
          {({ values, setValues, dirty }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className='student-assignment__fields' style={{ position: "relative" }}>
                <span style={{
                  position: "absolute", right: 110, top: 175, fontSize: 13,
                }}>
                </span>
                {formikConfig.map((item, key) => {
                  switch (item.type) {
                  case "textfield": {
                    if (item.textArea) {
                      return (
                        <div className='student-assignment__field halfWidth' key={item.id + item.name}>
                          <FormikTextField {...item} />
                        </div>
                      );
                    }
                    const { partial, serviceId, sessionAmount } = values;
                    if (item.name === "sessionAmount") {
                      if (!partial && serviceId !== "") {
                        const currentService = data.find((d) => d.serviceId === +serviceId);
                        if (currentService && sessionAmount !== currentService.sessionAmount) {
                          setValues({
                            ...values,
                            sessionAmount: currentService.sessionAmount,
                          });
                        }
                      }
                    }
                    return (
                      <div className='student-assignment__field' key={item.id + item.name} onClick={() => values.sessionsPerWeek || values.sessionsDuration || infoMessage(item.info)}>
                        <FormikTextField
                          {...item}
                          disabled={(item.name === "sessionAmount" && !values.partial)}/>
                      </div>

                    );
                  }
                  case "datefield": {
                    const service = data.find((d) => d.serviceId === values.serviceId);
                    return (
                      <div className='student-assignment__field' key={item.id + item.name}>
                        <FormikDateField
                          {...item}
                          availableStartDate={service ? service.authorizationStartDt : ser.authorizationStartDt}
                          availableEndDate={service ? service.authorizationEndDt : ser.authorizationEndDt}/>
                      </div>
                    );
                  }
                  case "select": {
                    let newData = item.data;
                    if ((item.name === "providerId" || item.name === "altProviderId") && ser?.serviceTypeNo) {
                      newData = item.data.filter((i) => i.services && i.services.find((s) => s.serviceTypeNo === ser.serviceTypeNo));
                    }
                    return (
                      <div className='student-assignment__field' key={item.id + item.name} onClick={() => values.serviceId || infoMessage(item.info)}>
                        <FormikSelect
                          { ...{ ...item, data: newData } }
                          onChange={() => onChangeSelect(item, values)}
                          disabled={item.name === "serviceId" ? !values.studentId : (item.name === "altProviderId") ? !values.substitute : false }
                          required={checkRequired(item, values, setValues)}/>
                      </div>
                    );
                  }
                  case "checkbox": {
                    const {
                      substitute, altProviderId, serviceId, partial, sessionAmount,
                    } = values;
                    if (item.name === "partial" && !partial && serviceId === "" && sessionAmount !== "") {
                      setValues({ ...values, sessionAmount: "" });
                    }
                    if (item.name === "substitute" && !substitute && altProviderId !== "") {
                      setValues({ ...values, altProviderId: "" });
                    }
                    return (
                      <div style={{ paddingTop: 40 }} className={`student-assignment__field ${item.name === "substitute" ? "active" : ""}`} key={item.id + item.name}>
                        <FormikCheckBox name={item.name} {...item} />
                      </div>
                    );
                  }
                  case "text": {
                    let service = null;
                    let sessionWeeklyLength = "";
                    if (item.name === "mandate") {
                      service = data.find((el) => el.serviceId === Number(values.serviceId));
                      if (service) {
                        const { sessionLength, sessionWeekly } = service;
                        sessionWeeklyLength = (sessionLength && sessionWeekly) ? ` ${sessionWeekly} x ${sessionLength}` : "";
                      }
                      if (!service) {
                        sessionWeeklyLength = "";
                      }

                      return <div className='student-assignment__field' key={item.id}>
                        <p style={{ paddingTop: 30, fontSize: 13 }}>
                          {sessionWeeklyLength.length ? `${item.template}${sessionWeeklyLength}` : `${item.template}`}
                        </p>
                      </div>;
                    }
                    return <div className='student-assignment__field' key={item.id}>
                      <p style={{ paddingTop: 30, fontSize: 13 }}>
                        {item.text}
                      </p>
                    </div>;
                  }
                  case "button": {
                    return (
                      <div className='student-assignment__field' key={item.id}>
                        <FormikButton variant='contained'>Save</FormikButton>
                      </div>
                    );
                  }
                  case "empty": {
                    return <div key={key} className={`student-assignment__field_${item.class ? item.class : ""}`}/>;
                  }
                  default: return "default";
                  }
                })}
                {dirty === true ? setValueIsChange(true) : null}
              </div>
            </>
          )}
        </Formik>}
      </Paper>
    </div>
  );
}

export default EditStudentAssignment;
