import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "./Typography";

function BreadCrumbs({
  rootPathName, childrenPathNames, rootTitleName, childrenTitleNames = [],
}) {
  const history = useHistory();
  if (!rootPathName) return null;
  return (
    <div>
      <Typography 
        onClick={() => history.push(rootPathName)} 
        style={{ paddingRight: "5px", cursor: "pointer" }} 
        variant='subtitle1'>
          {rootTitleName}
        </Typography>
          {childrenTitleNames.map((item, i, arr) => <Typography style={{ cursor: arr.length - 1 === i ? "default" : "pointer" }} key={i} onClick={() => (arr.length - 1 === i ? () => {} : history.push(childrenPathNames[i]))} variant='subtitle1' className="last_breadcrumbs"> / {item}</Typography>)}
    </div>
  );
}

export default BreadCrumbs;
