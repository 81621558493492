import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const editGoalSlice = createSlice({
  name: "editGoal",
  initialState: {
    studentGoal: {
    },
  },
  reducers: {
    getStudentGoal(state, { payload }) {
      state.studentGoal = payload;
    },
  },
});

export default editGoalSlice.reducer;

export const createStudentGoalAction = (data, cb) => async () => {
  try {
    await axios.post("/goals/create-student-goal", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateStudentGoalAction = (data, cb) => async () => {
  try {
    await axios.patch("/goals/update-student-goal", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
