import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import formatterParams from "../../../utils/formatterParams";

const systemUsersSlice = createSlice({
  name: "systemUsersSlice",
  initialState: {
    systemUsers: {
      users: [],
      pagination: {},
    },
    providerInformation: {
      name: "",
      email: "",
      phone: "",
      state: "",
      login: "",
    },
  },
  reducers: {
    setSystemUsers: (state, { payload }) => {
      state.systemUsers = payload;
    },
    setProviderInformation: (state, { payload }) => {
      state.providerInformation = payload;
    },
    clearProviderInformation: (state) => {
      state.providerInformation = {
        name: "",
        email: "",
        phone: "",
        state: "",
        login: "",
      };
    },
  },
});

export default systemUsersSlice.reducer;

export const { setSystemUsers, setProviderInformation, clearProviderInformation } = systemUsersSlice.actions;

export const getSystemUsersAction = (offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      limit, offset,
    });
    const response = await axios.get(`/admin-service/users?${params}`);
    dispatch(setSystemUsers(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const addNewUserAction = (values, cb = () => { }) => async (dispatch) => {
  try {
    const response = await axios.post("/admin-service/create-user", values);
    if (response.status > 199 && response.status < 300) {
      cb();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const editUserAction = (values, cb = () => { }) => async (dispatch) => {
  try {
    const response = await axios.patch("/admin-service/update-user", values);
    if (response.status > 199 && response.status < 300) {
      cb();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteUserAction = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/admin-service/delete-user/${id}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    dispatch(getSystemUsersAction());
  }
};
