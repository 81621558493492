import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import FormikButton from "../../../../../components/Button";
import FormikCheckBox from "../../../../../components/Checkbox";
import FormikDateField from "../../../../../components/DateField";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import FormkikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import { studentsAssignmentPushAction } from "./store";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import { getProvidersByIdAction } from "../../../provider/pages/providerProfile/store";
import { getStudentServiceByIdAction } from "../editStudentService/store";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";
import { getAllProvidersAction } from "../../../store";

import "./styles/index.scss";

import { admin_students_assigments_list_url } from "../../../../../configs/router_urls";
import {
  fieldConfig, formikInitialValues, validationShema,
} from "./config";

function StudentAssignment() {
  const dispatch = useDispatch();
  const history = useHistory();

  const allProviders = useSelector(({ adminReducer }) => adminReducer.allProviders.result);
  const allStudents = useSelector(({ adminReducer }) => adminReducer.allStudents.result);
  const data = useSelector(({ studentServiceReducer }) => studentServiceReducer.services.result);
  const provider = useSelector(({ providersProfileReducer }) => providersProfileReducer.data);
  const service = useSelector(({ editStudentServiceReducer }) => editStudentServiceReducer.data);

  const [formikConfig, setFormikConfig] = useState(fieldConfig);
  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);

  const providerId = useRef("");
  const serviceId = useRef("");
  const changeProvider = useRef(false);
  const changeService = useRef(false);

  const transformData = (data) => {
    const {
      altProviderId, substitute, note, ...props
    } = data;
    const newData = { ...props, substitute };
    if (substitute && altProviderId) newData.altProviderId = altProviderId;
    if (note) newData.note = note;
    return newData;
  };
  useEffect(() => {
    dispatch(getAllProvidersAction("A"));
  }, []);

  const save = (values) => {
    setShowPrompt(false);
    const payrollRate = +values.payrollRate;
    const data = {
      ...transformData(values),
      payrollRate: payrollRate.toFixed(2),
      startDt: moment(values.timePeriod.split(" to ")[0]).format("YYYY-MM-DD"),
      endDt: moment(values.timePeriod.split(" to ")[1]).format("YYYY-MM-DD"),
      note: values.note.trim(),
    };

    dispatch(studentsAssignmentPushAction(data, afterSave));
  };
  const afterSave = () => {
    history.push(admin_students_assigments_list_url);
  };

  const infoMessage = (message) => {
    if (message) {
      dispatch(snackbarOpen(message));
    }
  };

  useEffect(() => {
    if (allStudents.length && allProviders.length && data) {
      setFormikConfig(formikConfig.map((el) => {
        if (el.name === "studentId") {
          return { ...el, data: allStudents };
        }
        if (el.name === "providerId") {
          return { ...el, data: allProviders.filter((p) => p.state !== "I") };
        }
        if (el.name === "altProviderId") {
          return { ...el, data: allProviders.filter((p) => p.state !== "I") };
        }
        if (el.name === "serviceId") {
          return {
            ...el,
            data: data.map((el) => ({
              value: el.serviceId, title: el.serviceTypeName, id: el.serviceId, ...el,
            })),
          };
        }
        return el;
      }));
    }
  }, [allProviders, allStudents, data]);

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  function checkRequired(item, values, setValues) {
    if (providerId.current !== values.providerId) {
      providerId.current = values.providerId;
      changeProvider.current = false;
      dispatch(getProvidersByIdAction(values.providerId));
    }
    if (serviceId.current !== values.serviceId && !!values.serviceId) {
      serviceId.current = values.serviceId;
      changeService.current = false;
      dispatch(getStudentServiceByIdAction(values.serviceId));
    }
    if (+providerId.current === provider?.providerId) {
      if (!changeProvider.current) {
        changeProvider.current = true;
        setValues({ ...values, payrollRate: provider.payrollRate });
      }
    }
    if (+serviceId.current === service?.serviceId && !values.partial) {
      if (!changeService.current) {
        changeService.current = true;
        setValues({ ...values, sessionAmount: service.sessionAmount });
      }
    }
    if (item.name !== "altProviderId") return !!item.required;
    if (typeof item.required !== "undefined") return !!item.required;
    return values.substitute;
  }

  return (
    <div>
      <HeaderPage
        title='Assign Student To Provider'
        rootPathName={admin_students_assigments_list_url}
        rootTitleName='All Assignments'
        childrenTitleNames={["Assign Student To Provider"]}
      />
      <Paper>
        {<Formik
          initialValues={formikInitialValues}
          validationSchema={validationShema}
          onSubmit={save}>
          {({ values, setValues, dirty }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className='student-assignment__fields' style={{ position: "relative" }}>
                <span style={{
                  position: "absolute", right: 110, top: 175, fontSize: 13,
                }}>
                </span>
                {formikConfig.map((item, key) => {
                  switch (item.type) {
                  case "textfield": {
                    if (item.textArea) {
                      return (
                        <div
                          className='student-assignment__field halfWidth'
                          key={item.id + item.name}
                          style={{ zIndex: 5 }}
                        >
                          <FormikTextField {...item} />
                        </div>
                      );
                    }
                    const { partial, serviceId, sessionAmount } = values;
                    if (item.name === "sessionAmount") {
                      if (!partial && serviceId !== "") {
                        const currentService = data.find((d) => d.serviceId === +serviceId);
                        if (currentService && sessionAmount !== currentService.sessionAmount) {
                          setValues({
                            ...values,
                            sessionAmount: currentService.sessionAmount,
                          });
                        }
                      }
                    }
                    return (
                      <div className='student-assignment__field' key={item.id + item.name} onClick={() => values.sessionsPerWeek || values.sessionsDuration || infoMessage(item.info)}>
                        <FormikTextField {...item} type={ item.textfieldType || item.type } disabled={item.name === "sessionAmount" ? !values.partial : false} />
                      </div>

                    );
                  }
                  case "datefield": {
                  // eslint-disable-next-line
                  const service = data.find((service) => +service.serviceId === +values.serviceId);
                    const { authorizationStartDt, authorizationEndDt } = (service) || {};
                    item.availableStartDate = (authorizationStartDt) || new Date();
                    item.availableEndDate = (authorizationEndDt) || new Date();
                    return (
                      <div className='student-assignment__field' key={item.id + item.name}>
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    let newData = item.data;
                    if ((item.name === "providerId" || item.name === "altProviderId") && service?.serviceTypeNo) {
                      newData = item.data.filter((i) => i.services && i.services.find((s) => s.serviceTypeNo === service.serviceTypeNo));
                    }
                    return (
                      <div className='student-assignment__field' key={item.id + item.name} onClick={() => values.studentId || infoMessage(item.info)}>
                        <FormkikSelect onChange={({ sessionLength, sessionWeekly }) => {
                          const sessionWeeklyLength = (sessionLength && sessionWeekly) ? ` ${sessionWeekly} x ${sessionLength}` : "";
                          setFormikConfig(formikConfig.map((item) => (item.name === "mandate" ? ({ ...item, text: sessionWeeklyLength ? `${item.template}${sessionWeeklyLength}` : item.text }) : item)));
                        }} { ...{ ...item, data: newData } }
                        disabled={item.name === "serviceId" ? !values.studentId : (item.name === "altProviderId") ? !values.substitute : false }
                        required={checkRequired(item, values, setValues)}/>
                      </div>
                    );
                  }
                  case "checkbox": {
                    const {
                      substitute, altProviderId, serviceId, partial, sessionAmount,
                    } = values;
                    if (item.name === "partial" && !partial && serviceId === "" && sessionAmount !== "") {
                      setValues({ ...values, sessionAmount: "" });
                    }
                    if (item.name === "substitute" && !substitute && altProviderId !== "") {
                      setValues({ ...values, altProviderId: "" });
                    }
                    return (
                      <div style={{ paddingTop: 40 }} className={`student-assignment__field ${item.name === "substitute" ? "active" : ""}`} key={item.id + item.name}>
                        <FormikCheckBox name={item.name} {...item}/>
                      </div>
                    );
                  }
                  case "text": {
                    let service = null;
                    let sessionWeeklyLength = "";
                    if (item.name === "mandate") {
                      service = data.find((el) => el.serviceId === Number(values.serviceId));
                      if (service) {
                        const { sessionLength, sessionWeekly } = service;
                        sessionWeeklyLength = (sessionLength && sessionWeekly) ? ` ${sessionWeekly} x ${sessionLength}` : "";
                      }
                      if (!service) {
                        sessionWeeklyLength = "";
                      }

                      return <div className='student-assignment__field' key={item.id}>
                        <p style={{ paddingTop: 30, fontSize: 13 }}>
                          {sessionWeeklyLength.length ? `${item.template}${sessionWeeklyLength}` : `${item.template}`}
                        </p>
                      </div>;
                    }
                    return <div className='student-assignment__field' key={item.id}>
                      <p style={{ paddingTop: 30, fontSize: 13 }}>
                        {item.text}
                      </p>
                    </div>;
                  }
                  case "button": {
                    return (
                      <div className='student-assignment__field' key={item.id}>
                        <FormikButton variant='contained'>Save</FormikButton>
                      </div>
                    );
                  }
                  case "empty": {
                    return <div key={key} className='student-assignment__field'/>;
                  }
                  default: return "default";
                  }
                })}
                {dirty === true ? setValueIsChange(true) : null}
              </div>
            </>
          )}

        </Formik>}
      </Paper>
    </div>
  );
}

export default StudentAssignment;
