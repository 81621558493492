import React, { useEffect, useState } from "react";

import SvgMaker from "../svgMaker";
import { Button } from "../Button";
import Pdf from "./pdf";

export default function InvoiceViewPDF({
  file, hidePreview, fileWithOutSign,
}) {
  const [fileName, setFileName] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
    setFileName(url.split("/")[url.split("/").length - 1]);
  }, []);

  const download = () => {
    if (file) {
      const url = window.URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/")[url.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadWithOutSign = () => {
    if (fileWithOutSign) {
      const url = window.URL.createObjectURL(new Blob([fileWithOutSign], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/")[url.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <div className="react-pdf__header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }} onClick={hidePreview}>
            <SvgMaker name='leftArrow' fill='#ffffff'/>
          </div>
          <div style={{ paddingLeft: 10, color: "#ffffff" }}>{fileName}.pdf</div>
        </div>
        <div className='button-preview'>
          <Button variant="outlined" onClick={download}>
            Download
          </Button>
          <Button variant="outlined" onClick={downloadWithOutSign}>
            Download without signature
          </Button>
        </div>

      </div>
      <Pdf file={file}/>
    </>
  );
}
