import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import ProviderProfileComponent from "../../components/ProviderProfileComponent";
import { createProviderAction } from "./store";
import { getAllProvidersAction } from "../../../store";
import { getServiceTypes } from "../store";

import "./styles/index.scss";

import { fieldConfig, validationShema, formikInitialValues } from "../configs";
import { admin_providers_url, admin_provider_profile_url, admin_system_users_url } from "../../../../../configs/router_urls";

function CreateProvider() {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const serviceTypesData = useSelector(({ adminProviderReducer }) => adminProviderReducer.serviceTypesData);
  const dictionaries = useSelector(({ adminReducer }) => adminReducer.providerDict);
  const cities = useSelector(({ adminProviderReducer }) => adminProviderReducer.citiesList);
  const {
    name, email, phone, login, state,
  } = useSelector(({ systemUsersReducer }) => systemUsersReducer.providerInformation);

  const [config, setConfig] = useState(fieldConfig);
  const [formikValues, setFormikValues] = useState(formikInitialValues);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (!serviceTypesData.length) {
      dispatch(getServiceTypes(fieldConfig));
    }
  }, []);

  useEffect(() => {
    setFormikValues({
      ...formikValues, firstName: name, email, cellPhoneNo: phone, login, state,
    });
    setValueIsChange(true);
  }, [name, email, phone, config]);

  const saveProvider = (values) => {
    let { annualRate, payrollRate, ...props } = values;
    const data = { ...props };
    data.payrollRate = Number(payrollRate).toFixed(2);
    if (annualRate !== "") data.annualRate = Number(annualRate).toFixed(2);
    setShowPrompt(false);
    const serviceTypesArr = [];

    Object.keys(values).forEach((item) => {
      if (+item && values[item]) {
        serviceTypesArr.push({
          id: +item,
          state: true,
        });
      }
    });

    dispatch(createProviderAction({
      name: name.trim() || data.firstName.trim(),
      email: email || data.email,
      phone: phone || data.cellPhoneNo,
      login: login || data.email,
      roles: "Provider",
      state: state || "E",
    }, {
      ...data,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      prProviderServices: serviceTypesArr,
      addrCityId: values.addrCityIdPrv,
      addrStateId: values.addrStateIdPrv,
      address: values.address.trim(),
      note: values.note.trim(),
      state: "",
    }, (id) => {
      if (!name) {
        history.push(`${admin_provider_profile_url}/${id}`);
        dispatch(getAllProvidersAction());
      } else history.push(admin_system_users_url);
    }));
  };

  useEffect(() => {
    if (serviceTypesData.length) {
      setFormikValues(() => {
        const obj = { ...formikValues };
        const serviceTypes = serviceTypesData.reduce((a, c) => {
          a[c.serviceTypeNo] = true;
          return a;
        }, {});
        serviceTypesData.forEach((item) => {
          obj[item.name] = !!serviceTypes[item.name];
        });
        return obj;
      });
    }
  }, [serviceTypesData]);

  useEffect(() => {
    if (Object.keys(dictionaries) && serviceTypesData.length) {
      const leftSide = fieldConfig.leftSide.map((item) => {
        if (dictionaries[item.beName]) return ({ ...item, data: dictionaries[item.beName] });
        if (cities[item.beName]) return ({ ...item, data: cities[item.beName] });
        return item;
      });
      const rightSide = fieldConfig.rightSide.map((item) => {
        if (item.type === "checkboxesContainer") return ({ ...item, data: serviceTypesData });
        if (dictionaries[item.beName]) return ({ ...item, data: dictionaries[item.beName] });
        return item;
      });
      setConfig(
        {
          ...fieldConfig,
          leftSide,
          rightSide,
        },
      );
    }
  }, [dictionaries, serviceTypesData, cities]);

  useEffect(() => {
    window.addEventListener("click", () => { setShowPrompt(true); });
  }, [showPrompt]);

  return (
    <div>
      <HeaderPage
        title='Create Provider Profile'
        rootPathName={history.location.state === "providers" ? admin_providers_url : admin_system_users_url }
        rootTitleName={history.location.state === "providers" ? "All Providers" : "System users"}
        childrenTitleNames={["Create Provider Profile"]}
      />
      <div className='provider_profile__container'>
        <div>
          <Paper>
            {!!serviceTypesData.length
              && <ProviderProfileComponent
                formRef={formRef}
                validationShema={validationShema}
                fieldConfig={config}
                formikInitialValues={formikValues}
                onSubmit={saveProvider}
                valueIsChange={valueIsChange}
                setValueIsChange={setValueIsChange}
                showPrompt={showPrompt}
              />}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default CreateProvider;
