import React, { useEffect, useState } from "react";

import SvgMaker from "../svgMaker";
import DateTable from "./DateTable";
import MonthTable from "./MonthTable";
import YearTable from "./YearTable";
import DatePickerHeader from "./DatePickerHeader";
import {
  getCurrentDate,
  getDate,
  getNextYear,
  year_table_range,
} from "../../functions/calendar_func";

import "./style.scss";

const DatePicker = (props) => {
  const {
    defaultDate = getCurrentDate(),
    isCalendar,
    isRange = false,
    startDate = null,
    endDate = null,
    updateStartDate,
    updateEndDate,
    isBirthDay,
    availableStartDate,
    availableEndDate,
    finish,
    isTableYear,
  } = props;

  const [currentDate, setCurrentDate] = useState(defaultDate);
  const [yearRangeStart, updateYearRangeStart] = useState(defaultDate);
  const [mode, tooggleMode] = useState(isBirthDay ? "year" : "day");
  function handleReset() {
    updateYearRangeStart(defaultDate);
    tooggleMode(mode);
  }

  useEffect(() => {
    handleReset();

    if (isCalendar) {
      setCurrentDate(startDate);
    }
  }, [startDate]);

  const getDisplayDate = () => {
    switch (mode) {
    case "year":
      return (
        <div className="date_picker-header--current_month_year">
          <div
            className="date_picker-header--back_arrow"
            onClick={() => tooggleMode("month")}
          >
            <SvgMaker width="19px" height="19px" name="leftArrow" />
          </div>
          <span>{getDate(yearRangeStart, "YYYY")}</span>-
          <span>{getNextYear(yearRangeStart, "YYYY", year_table_range)}</span>
        </div>
      );
    case "month":
      return (
        <div className="date_picker-header--current_month_year">
          { finish !== "month" ? <div
            className="date_picker-header--back_arrow"
            onClick={() => tooggleMode("day")}
          >
            <SvgMaker width="19px" height="19px" name="leftArrow" />
          </div> : null}
          <span onClick={() => tooggleMode("year")}>
            {getDate(currentDate, "YYYY")}
          </span>
        </div>
      );
    case "day":
      return (
        <div className="date_picker-header--current_month_year">
          <span onClick={() => tooggleMode("month")}>
            {getDate(currentDate, "MMMM")}
          </span>
            &nbsp;
          <span onClick={() => tooggleMode("year")}>
            {getDate(currentDate, "YYYY")}
          </span>
        </div>
      );
    default:
      return (
        <div className="date_picker-header--current_month_year">
          <div
            className="date_picker-header--back_arrow"
            onClick={() => tooggleMode("month")}
          >
            <SvgMaker width="19px" height="19px" name="leftArrow" />
          </div>
          <span onClick={() => tooggleMode("month")}>
            {getDate(currentDate, "MMMM")}
          </span>
            &nbsp;
          <span onClick={() => tooggleMode("year")}>
            {getDate(currentDate, "YYYY")}
          </span>
        </div>
      );
    }
  };

  const getTable = () => {
    switch (mode) {
    case "month":
      return (
        <MonthTable
          tooggleMode={tooggleMode}
          isBirthDay={isBirthDay}
          setCurrentDate={setCurrentDate}
          currentDate={currentDate}
          startDate={startDate}
          updateStartDate={updateStartDate}
          finish={finish}
        />
      );
    case "year":
      return (
        <YearTable
          isBirthDay={isBirthDay}
          tooggleMode={tooggleMode}
          startDate={startDate}
          setCurrentDate={setCurrentDate}
          isCalendar={isCalendar}
          yearRangeStart={yearRangeStart}
        />
      );
    default:
      return (
        <DateTable
          isCalendar={isCalendar}
          currentDate={currentDate}
          isRange={isRange}
          isBirthDay={isBirthDay}
          startDate={startDate}
          endDate={endDate}
          updateStartDate={updateStartDate}
          updateEndDate={updateEndDate}
          availableStartDate={availableStartDate}
          availableEndDate={availableEndDate}
        />
      );
    }
  };

  const getTableYear = () => (
    <YearTable
      isBirthDay={isBirthDay}
      tooggleMode={tooggleMode}
      setCurrentDate={setCurrentDate}
      yearRangeStart={yearRangeStart}
    />
  );

  return (
    <div className="date_picker">
      <DatePickerHeader
        mode={mode}
        displayDate={getDisplayDate()}
        setCurrentDate={setCurrentDate}
        yearRangeStart={yearRangeStart}
        updateYearRangeStart={updateYearRangeStart}
        startDate={startDate}
        currentDate={currentDate}
      />
      <div className="date_picker-calendar">{isTableYear ? getTableYear() : getTable()}</div>
    </div>
  );
};

export default DatePicker;
