const getProviderLabelTitleInvoices = (state) => {
  switch (state) {
  case "C":
    return "Emails pending";
  case "E":
    return "Sent to signers";
  case "R":
    return "Rejected";
  case "S":
  case "A":
  case "D":
  case "P":
    return "Signed";
  default:
    return state;
  }
};
export default getProviderLabelTitleInvoices;
