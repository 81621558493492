import React, { useEffect } from "react";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import HeaderPage from "../../components/HeaderPage";
import SystemRoleItem from "./components/SystemRoleItem";
import { getSystemRolesAction } from "./store";

function SystemRole() {
  const dispatch = useDispatch();
  const systemRoles = useSelector(({ systemRolesReducer }) => systemRolesReducer.systemRoles);

  useEffect(() => {
    dispatch(getSystemRolesAction());
  }, []);

  return (
    <div>
      <HeaderPage title="System role" />
      <div className="systemRole">
        {systemRoles.map((item) => <SystemRoleItem key={item.groupId} groupName={item.groupName} groupId={item.groupId} />)}
      </div>
    </div>
  );
}

export default SystemRole;
