import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

import WrapperHeader from "./components/WrapperHeader";
import PanelUpload from "./components/PanelUpload";
import PanelHasSignature from "./components/PanelHasSignature";
import WrapperContent from "./components/WrapperContent";
import { Button } from "../../components/Button";
import { snackbarOpen } from "../../components/Snackbar/store/snackbar.slice";

import "./style.scss";

function SignerSignaturePage() {
  const params = useParams();

  const dispatch = useDispatch();

  const inputFile = useRef(null);
  const imgSignature = useRef();
  const documentPdf = useRef(null);

  const [signed, setSigned] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [file, setFile] = useState("");
  const [signature, setSignature] = useState("");
  const [info, setInfo] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [newSignature, setNewSignature] = useState(false);

  useEffect(async () => {
    const { data } = await axios("/reports/get-invoices-signer", {
      method: "get",
      headers: {
        Authorization: params.token,
      },
    });
    const { invoicePdf, ...props } = data;
    if (props) await setInfo(props);
    if (props.signaturePath) {
      const response = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${props.signaturePath}`, {
        responseType: "blob",
      });
      setSignature(response.data);
      setConfirm(true);
      imgSignature.current.src = URL.createObjectURL(response.data);
    }
    if (invoicePdf) setFile(data);
  }, []);

  useEffect(() => {
    if (showPreview) imgSignature.current.classList.remove("hide");
    else imgSignature.current.classList.add("hide");
  }, [showPreview]);

  useEffect(() => {
    if (info) {
      imgSignature.current.classList.add(getClassSignature(info));
    }
  }, [info]);

  const getClassSignature = (info) => {
    const { billingType, signerType, signers } = info;
    if (billingType === "I") {
      if (signerType === "A") return "ihoiu_parent";
      if (signerType === "S") return "ihoiu_school";
    }
    if (billingType === "E") {
      if (signers === "B") {
        if (signerType === "A") return "both_enhanced_parent";
        if (signerType === "S") return "both_enhanced_school";
      }
      if (signers !== "B") return "enhanced";
    }
    return "default_signature";
  };

  const onFileChange = (e) => {
    const formData = new FormData();
    formData.append("doc", e.target.files[0], e.target.files[0].name);
    imgSignature.current.classList.add(getClassSignature(info));
    imgSignature.current.src = URL.createObjectURL(inputFile.current.files[0]);
    setConfirm(true);
    if (inputFile?.current?.files[0]) {
      setNewSignature(true);
      setSignature(inputFile.current.files[0]);
    }
  };

  const onDecline = () => {
    setConfirm(false);
    setShowPreview(false);
  };

  const onConfirm = async () => {
    const form = new FormData();
    if (newSignature) form.append("file", signature);
    const { status, data } = await axios("/reports/sign-invoice-by-signer", {
      method: "post",
      headers: {
        Authorization: params.token,
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
      data: form,
    });
    imgSignature.current.src = "";
    if (status > 199 && status < 300) {
      dispatch(snackbarOpen({
        isOpen: true,
        timeout: 6000,
        text: "The signature is sent to the CLL office.",
        type: "success",
      }));
      setFile(data);
      setSigned(true);
    } else {
      setSigned(false);
    }
    setConfirm(false);
    setShowPreview(false);
  };

  const onPreviewSignedDoc = () => {
    setShowPreview(true);
  };

  const downloadFile = () => {
    // eslint-disable-next-line no-buffer-constructor
    const url = window.URL.createObjectURL(new Blob([new Buffer(file.invoicePdf ? file?.invoicePdf.data : file)], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/")[url.split("/").length - 1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const RightPanel = !signed && !confirm
    ? <PanelUpload inputFile={inputFile} onFileChange={onFileChange} />
    : confirm && !showPreview
      ? <PanelHasSignature onSign={onPreviewSignedDoc} imgSignature={URL.createObjectURL(signature)} onDelete={onDecline} />
      : null;

  return (
    <React.Fragment>
      <WrapperHeader />
      <div className="horizont_button_bar">
        { showPreview ? <>
          <Button variant="contained" onClick={onDecline} style={{
            width: "230px",
            background: "rgba(0, 0, 0, 0.0)",
            border: "2px solid #FFFFFF",
          }}>
            Decline
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            style={{
              width: "230px",
              marginLeft: "16px",
            }}
          >
            Confirm
          </Button>
        </> : <Button variant="contained" style={{
          width: "230px",
          background: "rgba(0, 0, 0, 0.0)",
          border: "2px solid #FFFFFF",
        }} onClick={downloadFile}>
          Download
        </Button>}
        { signed
        && <Button
          variant="contained"
          color="success"
          style={{
            width: "230px",
            marginLeft: "16px",
            cursor: "default",
            opacity: 1,
          }} >
          <div style={{ display: "flex" }}>
            <svg style={{ marginRight: "10px" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* eslint-disable-next-line max-len */}
              <path d="M10.0013 1.66675C5.4063 1.66675 1.66797 5.40508 1.66797 10.0001C1.66797 14.5951 5.4063 18.3334 10.0013 18.3334C14.5963 18.3334 18.3346 14.5951 18.3346 10.0001C18.3346 9.06175 18.1713 8.16192 17.8838 7.31942L16.5345 8.6687C16.622 9.0987 16.668 9.54425 16.668 10.0001C16.668 13.6759 13.6771 16.6667 10.0013 16.6667C6.32547 16.6667 3.33464 13.6759 3.33464 10.0001C3.33464 6.32425 6.32547 3.33341 10.0013 3.33341C11.3621 3.33341 12.6279 3.74499 13.6846 4.44832L14.8776 3.25529C13.5043 2.26029 11.8221 1.66675 10.0013 1.66675ZM17.7454 2.74422L9.16797 11.3217L6.42383 8.57756L5.24544 9.75594L9.16797 13.6785L18.9238 3.92261L17.7454 2.74422Z" fill="white"/>
            </svg>
            Documents are signed
          </div>
        </Button>
        }
      </div>
      <div className="signer_signature__content">
        <WrapperContent
          documentPdf={documentPdf}
          file={file.invoicePdf ?? file}
          imgSignature={imgSignature}
        />
        <div className="signer_signature__right">
          {RightPanel}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignerSignaturePage;
