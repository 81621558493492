import React, { useEffect } from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import FormikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import FormikDateField from "../../../../../components/DateField";
import FormikButton from "../../../../../components/Button";
import { admmin_students_goals_url, admmin_students_profile_url } from "../../../../../configs/router_urls";
import { fieldConfig, formSchema } from "../addNewStudentGoal/configs";
import "../addNewStudentGoal/styles/index.scss";

import { cleanStudentGoal, getStudentGoalByIdAction } from "./store";

function EditStudentGoal() {
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();
  const data = useSelector(({ editStudentGoalReducer }) => editStudentGoalReducer.data);

  useEffect(() => {
    dispatch(getStudentGoalByIdAction(params.goalId));
    return () => {
      dispatch(cleanStudentGoal());
    };
  }, []);

  return (
    <div>
      <HeaderPage
        title="Edit goal"
        rootPathName={`${admmin_students_profile_url}/${params.id}`}
        rootTitleName="Enter student profile"
        childrenPathNames={[`/all/students/goals/${params.id}`]}
        childrenTitleNames={["Goals", "Edit goal"]}
      />
      {data && <Paper>
        <Formik
          initialValues={data}
          validationSchema={formSchema}
          onSubmit={() => {
            history.push(`${admmin_students_goals_url}/${params.id}`);
          }}
        >
          <div className="addNewStudentGoal">
            {/* eslint-disable-next-line array-callback-return */}
            {fieldConfig.map((item) => {
              // eslint-disable-next-line default-case
              switch (item.type) {
              case "selectField": {
                return (
                  <div key={item.id}>
                    <FormikSelect {...item} />
                  </div>
                );
              }
              case "textAreaField": {
                return (
                  <div key={item.id} className="addNewStudentGoal__field">
                    <FormikTextField {...item} />
                  </div>
                );
              }
              case "textAreaFieldRight": {
                return (
                  <div key={item.id} className="addNewStudentGoal__fieldRight">
                    <FormikTextField {...item} />
                  </div>
                );
              }
              case "dateField": {
                return (
                  <div key={item.id}>
                    <FormikDateField {...item} />
                  </div>
                );
              }
              case "empty": {
                return <div key={item.id} {...item} />;
              }
              }
            })}
            <FormikButton variant="contained">Save</FormikButton>
          </div>
        </Formik>
      </Paper>}
    </div >
  );
}

export default EditStudentGoal;
