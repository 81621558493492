import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import formatterParams from "../../../../../../../utils/formatterParams";

const adminReportSlice = createSlice({
  name: "adminReport",
  initialState: {
    osisNo: {
      result: [],
      pagination: {},
    },
    authNo: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setOsisNo: (state, { payload }) => {
      state.osisNo = payload;
    },
    setAuthNo: (state, { payload }) => {
      state.authNo = payload;
    },
  },
});

const { setOsisNo, setAuthNo } = adminReportSlice.actions;

export default adminReportSlice.reducer;

export const getAssignmentListOsisNo = (data, offset = null, limit = 10) => async (dispatch) => {
  const response = await getData(data, offset, limit);
  if (response.data) dispatch(setOsisNo(response.data));
};

export const getAssignmentListAuthNo = (data, offset = null, limit = 10) => async (dispatch) => {
  const response = await getData(data, offset, limit);
  if (response.data) dispatch(setAuthNo(response.data));
};

async function getData(data, offset, limit) {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...data, limit, offset,
    });
    return await axios(`/assignment/assignment-list?${params}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}
