import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button } from "../../../../components/Button";
import HeaderPage from "../../../../components/HeaderPage";
import Pagination from "../../../../components/Pagination/Pagination";
import Table from "../../../../components/Table";
import { provider_invoice_view_url } from "../../../../configs/router_urls";
import { monthNames } from "../../../../constants/monthNames";
import tableFormater from "../../../../utils/tableFormater";
import { headerConfig } from "./configs";
import { getInvoicesList } from "./store";
import "./styles/index.scss";

function InvoicesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeState, setActiveState] = useState(["E"]);
  const [tabName, setTabName] = useState("sent");
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = useSelector(({ providerInvoicesListReducer }) => providerInvoicesListReducer.data);
  useEffect(() => {
    dispatch(getInvoicesList({ status: activeState }));
  }, [activeState]);
  const changeState = (state, name) => {
    setActiveState(state);
    setTabName(name);
  };
  const paginationClick = (offset) => {
    dispatch(getInvoicesList({ status: activeState }, offset));
  };
  const showData = (id) => {
    history.push(`${provider_invoice_view_url.split("/:id")[0]}/${id}`);
  };
  return (
    <>
      <HeaderPage
        title="My invoices"
      />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
      }}
      >
        <div className='invoices__list-status'>
          <Button
            color={tabName === "created" ? "primary" : "secondary"}
            onClick={() => changeState(["C"], "created")}
            className='whitespace'
          >
            Created
          </Button>
          <Button
            color={tabName === "sent" ? "primary" : "secondary"}
            onClick={() => changeState(["E"], "sent")}
            className='whitespace'
          >
            Sent to signers
          </Button>
          <Button
            color={tabName === "signed" ? "primary" : "secondary"}
            onClick={() => changeState(["S"], "signed")}
            className='whitespace'
          >
            Completely signed
          </Button>
          <Button
            color={tabName === "inDOE" ? "primary" : "secondary"}
            onClick={() => changeState(["D", "P"], "inDOE")}
            className='whitespace'
          >
            Sent to DOE
          </Button>
        </div>
      </div>
      <Table
        head={Object.values(headerConfig)}
        data={tableFormater(data.invoices.map((item) => ({
          ...item,
          studentName: <div className="itemBold">{item.studentName}</div>,
          providerName: <div className="itemBold">{item.providerName}</div>,
          invoiceMonth: <div>{`${monthNames[moment(item.invoiceMonth).month()]} ${moment(item.invoiceMonth).year()}`}</div>,
          billingRate: <div>{`$${item.billingRate}`}</div>,
        })), headerConfig, "invoiceId")}
        showPreview
        showData={showData}
      />
      <Pagination {...data.pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
    </>
  );
}

export default InvoicesList;
