import SvgMaker from "../../../../components/svgMaker/SvgMaker";

const headerConfig = {
  docName: {
    id: 1,
    title: "Name",
    value: "docName",
    order: 1,
  },
  uploadDt: {
    id: 2,
    title: "Upload time",
    value: "uploadDt",
    order: 2,
  },
  actions: {
    id: 3,
    title: "Actions",
    value: "actions",
    order: 3,
  },
};

const iconDoc = {
  A: <SvgMaker name="imageDocument"/>,
  D: <SvgMaker name="videoDocument"/>,
  V: <SvgMaker name="musicDocument"/>,
  I: <SvgMaker name="fileDoument"/>,
};

export { headerConfig, iconDoc };
