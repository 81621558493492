import React from "react";
import { Button } from "../../../components/Button";

const PanelUpload = ({ inputFile, onFileChange }) => (
  <div className="content">
    <div className="content__wrapper">
      <p>Upload the signature, please</p>
      <Button variant="contained" onClick={() => inputFile.current.click() }>
        Upload signature
      </Button>
      <label htmlFor="files"/>
      <input ref={inputFile} id="files" className="uploadFile" type="file" onChange={onFileChange}/>
    </div>
  </div>
);

export default PanelUpload;
