import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import HeaderPage from "../../../components/HeaderPage";
import { TextField } from "../../../components/TextField";
import { admin_invoices_url } from "../../../configs/router_urls";
import "../styles/index.scss";
import { getInvoiceEmail, sendEmailsToDOE, uploadDocument } from "../store";
import { ButtonPaper } from "../../../components/Paper/Paper";
import { Button } from "../../../components/Button";
import Typography from "../../../components/Typography";
import SvgMaker from "../../../components/svgMaker/SvgMaker";
import { getCurrentDate } from "../../../functions/calendar_func";

function EmailPreview() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const ref = useRef();
  const [values, setValues] = useState([]);
  const invoiceEmails = useSelector(({ adminInvoicesListReducer }) => adminInvoicesListReducer.invoiceEmails);
  const date = getCurrentDate();

  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (params?.invoicesId) {
      const ids = params?.invoicesId.split(",");
      dispatch(getInvoiceEmail({ invoiceIds: ids }));
    }
  }, []);
  useEffect(() => {
    setValues(JSON.parse(JSON.stringify(invoiceEmails)));
  }, [invoiceEmails]);

  const addNewFile = (index) => {
    ref.current.index = index;
    if (ref.current.index === index) {
      ref.current.click();
    }
  };

  const validationFile = (file) => {
    if (!file) return false;
    if (file.size && file.size > 1024 * 1024 * 3) {
      setErrorMsg("The file cannot be larger than 3MB");
      return false;
    }
    if (file.type !== "application/pdf") {
      setErrorMsg("The file should be type .pdf");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const onFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target?.files[0]);
    if (!validationFile(e.target?.files[0])) return;
    const newValues = values.map((item) => ({ ...item }));
    dispatch(uploadDocument(formData, (data) => {
      newValues[ref.current.index].attached.push({ docName: data.docName, docPath: data.docPath });
      setValues(newValues);
    }));
  };
  const deleteEmail = (id) => {
    setValues(values.filter((item) => id !== item.emailGroupId));
  };
  const deleteAttachment = (parentId, childId) => {
    const newValues = values.map((item) => ({ ...item }));
    newValues[parentId].attached.splice(childId, 1);
    setValues(newValues);
  };

  const sendEmails = () => {
    const ids = params?.invoicesId.split(",").map((el) => +el);
    dispatch(sendEmailsToDOE(values, { invoiceIds: ids }, date, () => {
      history.push(`${admin_invoices_url}`);
    }));
  };

  function change(e, index) {
    const { name, value } = e;
    setValues(() => values.map((item, i) => {
      if (i === index) {
        if (name === "receiversEmail") {
          item[name] = value.split(",");
        } else item[name] = value;
      }
      return item;
    }));
  }
  return (
    <>
      <HeaderPage
        title="Emails Preview"
        rootPathName={admin_invoices_url}
        rootTitleName="Invoices"
        childrenTitleNames={["Emails Preview"]} />
      <div className="email-preview_content">
        <div className="email-preview leftSideOptions">
          {values.map((item, index) => (
            <div className="email-preview_wrapper">
              <div className="email-preview_info row">
                <Typography variant="subtitle2-bold" className="email-preview_input-placeholder">To:</Typography>
                <div className="email-preview_input-wrapper">
                  <TextField name="mainReceiverEmail" value={item.mainReceiverEmail} onChange={(e) => change(e.target, index)}/>
                </div>
                <Typography variant="subtitle2-bold" className="email-preview_input-placeholder">CC:</Typography>
                <div className="email-preview_input-wrapper">
                  <TextField name="receiversEmail" value={item.receiversEmail.join()} onChange={(e) => change(e.target, index)}/>
                </div>
              </div>
              <div className="email-preview_info row">
                <Typography variant="subtitle2-bold" className="email-preview_input-placeholder">Subject:</Typography>
                <div className="email-preview_input-wrapper">
                  <TextField name="subject" value={item.subject} onChange={(e) => change(e.target, index)}/>
                </div>
              </div>
              <div className="email-preview_info row">
                <TextField name="template" value={item.template} onChange={(e) => change(e.target, index)} textArea/>
              </div>
              <div className="email-preview_info">
                {errorMsg ? <p className="email-preview_info-error">{errorMsg}</p> : null}
                <Typography variant="subtitle2-bold">Attachments:</Typography>
                <div className="email-preview_attachments">
                  {item.attached.map((doc, i) => <div>
                    <div className="email-preview_attachments-item">
                      <SvgMaker name="fileIcon"/>
                      <span>{doc.docName}</span>
                    </div>
                    <div className="email-preview_icon" onClick={() => deleteAttachment(index, i)}>
                      <SvgMaker fill="#495057" name="deleteIconDoc" />
                    </div>
                  </div>)}
                </div>
                <div className="email-preview_add-attachment-button">
                  <Button variant="outlined" color="grey" startIcon={{ name: "downloadDocument" }} onClick={() => addNewFile(index) }>
                    Add new file
                  </Button>
                  <input ref={ref} id="files" className="uploadFile" type="file" onChange={(e) => onFileChange(e, index)}/>
                </div>
              </div>
              {values.length !== 1 ? <div className="email-preview_delete-email-button">
                <Button variant="outlined" color="grey" onClick={() => deleteEmail(item.emailGroupId)}>
                Delete this email
                </Button>
              </div> : null }
            </div>
          ))}
        </div>
        <div className='rightSideOptions'>
          <ButtonPaper>
            <div>
              <Button variant='contained' color='primary' onClick={() => sendEmails()}>
                Send
              </Button>
              <div className="provider_profile__container-error"><span>{""}</span></div>
            </div>
            <Button className='rightSideOptions-btn' variant='outlined' color='grey' onClick={() => history.push(`${admin_invoices_url}`)}>
              Cancel
            </Button>
          </ButtonPaper>
        </div>
      </div>
    </>
  );
}

export default EmailPreview;
