import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import HeaderPage from "../../../../../components/HeaderPage";
import FormikTextField from "../../../../../components/TextField";
import FormikDateField from "../../../../../components/DateField";
import FormikSelect from "../../../../../components/Select";
import FormikButton from "../../../../../components/Button";
import Paper from "../../../../../components/Paper";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";
import { createStudentAction, getStudentDictionariesAction } from "./store";
import CityInput from "../../../../../components/CityInput";

import { admmin_students_profile_url, admmin_students_url } from "../../../../../configs/router_urls";
import {
  fieldConfig,
  formikInitialValues,
  yupObject,
  changeValidationSchema,
} from "./configs";

import "./styles/index.scss";

function CreateStudent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const {
    dictionaries,
    principalList,
    schoolList,
    languageList,
    districtList,
    stateList,
    citiesList,
    alternateAgenciesList,
  } = useSelector(
    ({ createStudentReducer }) => createStudentReducer,
  );

  const [config, setConfig] = useState({
    fieldConfig,
    validationSchema: yupObject,
  });
  const [valueIsChange, setValueIsChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  const save = (values) => {
    setShowPrompt(false);
    dispatch(
      createStudentAction(
        {
          ...values,
          studentName: values.studentName.trim(),
          address: values.address.trim(),
          motherName: values.motherName?.trim(),
          fatherName: values.fatherName?.trim(),
        },
        (id) => history.push(`${admmin_students_profile_url}/${id}`),
      ),
    );
  };

  useEffect(() => {
    dispatch(getStudentDictionariesAction());
  }, []);

  useEffect(() => {
    if (Object.keys(citiesList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (item.name === "addrCityId") {
            return { ...item, data: citiesList };
          }
          return item;
        }),
      });
    }
  }, [citiesList]);

  useEffect(() => {
    if (Object.keys(languageList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (item.name === "languageId") {
            return { ...item, data: languageList };
          }
          if (item.name === "familyLanguageId") {
            return { ...item, data: languageList };
          }
          if (item.name === "districtId") {
            return { ...item, data: districtList };
          }
          if (item.name === "schoolId") {
            return { ...item, data: schoolList };
          }
          if (item.name === "addrStateId") {
            return { ...item, data: stateList };
          }
          if (item.name === "alternateAgencyId") {
            return { ...item, data: alternateAgenciesList };
          }
          return item;
        }),
      });
    }
  }, [languageList, districtList, schoolList, stateList]);

  useEffect(() => {
    if (Object.keys(principalList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (item.name === "schoolRepresentativeId") {
            return {
              ...item,
              data: principalList.map((el) => ({ ...el, title: `${el.title} ${el.email}` })),
            };
          }
          return item;
        }),
      });
    }
  }, [principalList]);

  useEffect(() => {
    if (Object.keys(dictionaries)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (dictionaries[item.beName]) {
            return { ...item, data: dictionaries[item.beName] };
          }

          return item;
        }),
      });
    }
  }, [dictionaries]);

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  const changeResponsiblePerson = (value) => {
    setConfig({
      ...config,
      fieldConfig: config.fieldConfig.map((item) => {
        if ("key" in item) {
          if (item.key === value) {
            return { ...item, required: true };
          }
          return { ...item, required: false };
        }
        return item;
      }),
      validationSchema: changeValidationSchema(value, yupObject),
    });
  };

  return (
    <div>
      <HeaderPage
        title='Create Student'
        rootPathName={admmin_students_url}
        rootTitleName='All Students'
        childrenTitleNames={["Create Student"]}
      />
      <Paper>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={Yup.object(config.validationSchema)}
          onSubmit={(values) => save(values)}
          innerRef={formRef}
        >
          {({ values, dirty }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className='createstudent__fields'>
                {config.fieldConfig.map((item, key) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div className='createstudent__field' key={item.id + item.name}>
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div className='createstudent__field' key={item.id + item.name}>
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    if (item.name === "primaryResponsiblePerson") {
                      return (
                        <div
                          className={item.name === "schoolRepresentativeId" ? "createstudent__big_field" : "createstudent__field"}
                          key={item.id + item.name}>
                          <FormikSelect
                            {...item}
                            onChange={(event) => changeResponsiblePerson(event.target.value)}
                            disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                          />
                        </div>
                      );
                    }

                    return (
                      <div
                        className={item.name === "schoolRepresentativeId" ? "createstudent__big_field" : "createstudent__field"}
                        key={item.id + item.name}>
                        <FormikSelect
                          {...item}
                          disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                        />
                      </div>
                    );
                  }
                  case "search": {
                    return (
                      <CityInput createStudent={true} {...item} />
                    );
                  }
                  case "empty": {
                    return <div key={key} className='createstudent__field' />;
                  }
                  default: return "default";
                  }
                })}
              </div>
              <div className='createstudent__buttons'>
                <div className='createstudent__field' />
                <div className='createstudent__field' />
                <div className='createstudent__field' />
                <div className='createstudent__field' />
                <div className='createstudent__field'>
                  <FormikButton variant='contained' color='primary'>
                    Save
                  </FormikButton>
                </div>
              </div>
              {dirty === true ? setValueIsChange(true) : null}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default CreateStudent;
