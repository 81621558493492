import React from "react";
import PropTypes from "prop-types";
import WrapperHeader from "./WrapperHeader";
import WrapperContent from "./WrapperContent";
import "./styles/index.scss";

const Wrapper = ({
  headerTitle,
  headerText,
  validationSchema,
  formikInitialValues,
  fieldConfig,
  btnText,
  forgotBtnText,
  forgotBtnOnClick,
  forgotBtnColor,
  forgotBtnImage,
  handleKeyPress,
}) => (
  <div className="login__wrapper">
    <WrapperHeader
      headerTitle={headerTitle}
      headerText={headerText}
    />
    <WrapperContent
      validationSchema={validationSchema}
      formikInitialValues={formikInitialValues}
      fieldConfig={fieldConfig}
      btnText={btnText}
      forgotBtnText={forgotBtnText}
      forgotBtnOnClick={forgotBtnOnClick}
      forgotBtnColor={forgotBtnColor}
      forgotBtnImage={forgotBtnImage}
      handleKeyPress={handleKeyPress}
    />
  </div>
);

Wrapper.propTypes = {
  headerTitle: PropTypes.string,
  headerText: PropTypes.string,
  validationSchema: PropTypes.object,
  formikInitialValues: PropTypes.object,
  fieldConfig: PropTypes.array,
  btnText: PropTypes.string,
  forgotBtnText: PropTypes.string,
  forgotBtnOnClick: PropTypes.func,
  handleKeyPress: PropTypes.func,
  forgotBtnColor: PropTypes.string,
  forgotBtnImage: PropTypes.string,
};

export default Wrapper;
