import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getCurrentDate,
  getDate,
  getEndOfMonth,
  getStartOfMonth,
} from "../../functions/calendar_func";
import HeaderPage from "../../components/HeaderPage";
import Paper from "../../components/Paper";
import CalendarHeader from "./calendarHeader";
import DatePickerHeader from "../../components/DatePicker/DatePickerHeader";
import CalendarMonthTable from "./calendarMonthTable";
import { getEventsAction } from "./store";
import { getSessionNotesAction } from "./session/sessionForm/store";
import CalendarDayTable from "./calendarDayTable";

import "./index.scss";

import { session_notes_url } from "../../configs/router_urls";

function Calendar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [viewMode, setViewMode] = useState("month");

  const events = useSelector(({ calendarReducer }) => calendarReducer.events);
  const startDate = getStartOfMonth(currentDate);
  const endDate = getEndOfMonth(currentDate);
  const displayDate = (
    <div className="date_picker-header--current_month_year">
      <span>{getDate(currentDate, "MMMM")}&nbsp;</span>
      {viewMode === "day" && <span>{getDate(currentDate, "DD")},&nbsp;</span>}
      <span>{getDate(currentDate, "YYYY")} </span>
    </div>
  );

  useEffect(() => {
    dispatch(getEventsAction(startDate, endDate));
  }, [startDate, endDate]);

  const goToNotes = (id) => {
    dispatch(getSessionNotesAction(id, () => {
      history.push(session_notes_url);
    }));
  };

  const selectDay = (day) => {
    setViewMode("day");
    setCurrentDate(day);
  };

  return (
    <div className="calendar_page_wrapper">
      <HeaderPage title="Calendar" />
      <CalendarHeader viewMode={viewMode} setViewMode={setViewMode}/>
      <Paper
        className='calendar-paper'
        style={viewMode === "month" ? { backgroundColor: "#fff" } : {}}>
        <div className="calendar_date-toogler">
          <DatePickerHeader
            isCalendar={true}
            setCurrentDate={setCurrentDate}
            displayDate={displayDate}
            mode={viewMode === "month" ? "month" : "oneDay"}
          />
        </div>
        {(events && viewMode === "day")
          && <CalendarDayTable
            events={events}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
        }
        {(events && viewMode === "month")
          && <CalendarMonthTable
            currentDate={currentDate}
            selectDay={selectDay}
            events={events}
            goToNotes={goToNotes}
          />
        }
      </Paper>
    </div>
  );
}

export default Calendar;
