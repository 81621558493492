import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { getCitiesAllByStateId, getCitiesByStateId } from "../../entities/admin/student/pages/createStudent/store";
import usePrevious from "../../utils/usePrevious";
import { snackbarOpen } from "../Snackbar/store/snackbar.slice";

import "./style.scss";
import { getCitiesByState } from "../../entities/admin/provider/pages/store";

const INFO = {
  isOpen: true,
  timeout: 3000,
  text: "Enter state first, please",
  type: "information",
};

export default function CityInput(props) {
  const dispatch = useDispatch();

  const {
    touched,
    errors,
    values,
    values: {
      addrStateId,
      addrCityId,
      addrStateIdPrv,
      addrCityIdPrv,
    },
    setFieldValue,
  } = useFormikContext();

  const {
    label,
    required,
    name,
    data,
    createStudent,
    providerForm,
    edit,
  } = props;

  const [mask, setMask] = useState("");
  const [drop, setDrop] = useState(false);
  const [changedCity, setChangedCity] = useState(false);
  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";
  const editCity = edit ? addrCityId.split("-")?.[0] : null;

  const prevState = usePrevious(values.addrStateId);
  const prevStatePrv = usePrevious(values.addrStateIdPrv);

  useEffect(() => {
    if (createStudent) {
      if (values.addrStateId && mask?.trim()) {
        dispatch(getCitiesByStateId(edit ? values.addrStateId.split("-")?.[0] : values.addrStateId, mask.trim()));
      }
    }

    if (providerForm) {
      if (prevStatePrv) {
        if (prevStatePrv !== values.addrStateIdPrv) {
          setMask("");
          setFieldValue("addrCityIdPrv", "");
        }
      }

      if (values.addrStateIdPrv && mask) {
        dispatch(getCitiesByState(values.addrStateIdPrv, mask));
        setChangedCity(true);
      }
    }

    if (prevState) {
      if (prevState !== values.addrStateId) {
        setMask("");
        setFieldValue("addrCityId", "");
      }
    }

    if (values.addrStateId && mask === "") {
      if (changedCity && mask === "") {
        setFieldValue("addrCityIdPrv", "");
      }
    }

    if (values.addrStateId && !mask) {
      setFieldValue("addrCityId", "");
    }

    if (edit) {
      if (providerForm) {
        dispatch(getCitiesAllByStateId(values.addrStateIdPrv.split("-")?.[0]));
        setFieldValue("addrStateIdPrv", editCity);
      }
      dispatch(getCitiesAllByStateId(values.addrStateId.split("-")?.[0]));
      setFieldValue("addrCityId", editCity);
      setChangedCity(true);

      if (changedCity && mask === "") {
        setFieldValue("addrCityId", "");
      }
    }
  }, [mask, values.addrStateId, values.addrStateIdPrv, edit]);

  useEffect(() => {
    if (!mask) {
      setMask(data.find((item) => item.addrCityId === Number(editCity))?.cityName);

      if (providerForm) {
        setMask(data.find((item) => item.value === addrCityIdPrv)?.title);
      }
    }
  }, [data]);

  const infoMessage = (message) => {
    dispatch(snackbarOpen(message));
  };

  const renderDrop = useCallback((dataArr) => {
    if (
      !dataArr?.length
        || !mask?.length
        || !drop
    ) {
      return null;
    }

    if (!mask.length) {
      return null;
    }

    if (providerForm) {
      return (
        <div className={"drop"}>
          {data.map((item) => (
            <div
              className="item"
              onClick={() => {
                setMask(item.title);
                setFieldValue("addrCityIdPrv", `${item.value}`);
                setDrop(false);
              }}>
              {item.title}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={"drop"}>
        {data.map((item) => (
          <div
            className="item"
            onClick={() => {
              setMask(item.cityName);
              setFieldValue("addrCityId", `${item.addrCityId}`);
              setDrop(false);
            }}>
            {item.cityName}
          </div>
        ))}
      </div>
    );
  }, [data, mask]);

  function renderLabelError() {
    if (edit) {
      return !editCity && !mask ? <span className={"city_label_error"}>{`* ${label}`}</span> : <span className={"select__label"}>{`* ${label}`}</span>;
    }

    return label
        && <span className={clsx("select__label", { "select__label-error": fieldTouched })}>{required ? `* ${label}` : label}</span>;
  }

  if (edit) {
    return (
      <div className="wrapper">
        {renderLabelError()}

        <div
          className="input__wrapper"
          onClick={() => {
            if (!(addrStateId?.length > 0)) {
              infoMessage(INFO);
            }
          }}
        >
          <input
            disabled={!(addrStateId?.length > 0)}
            className={!editCity && !mask ? "city__input-error" : "city__input"}
            value={mask}
            onChange={(event) => {
              setMask(event.target.value);

              setDrop(true);
            }}
          />

          {renderDrop(data)}
        </div>

        {!editCity && !mask
          ? <div className={"city_error"}>{"required field"}</div>
          : null
        }
      </div>
    );
  }

  if (providerForm) {
    if (edit) {
      return (
        <div className="providerWrapper">
          {renderLabelError()}

          <div
            className="input__wrapper"
            onClick={() => {
              if (!(addrStateId?.length > 0)) {
                infoMessage(INFO);
              }
            }}
          >
            <input
              disabled={!(addrStateIdPrv?.length > 0)}
              className={!editCity && !mask ? "city__input-error" : "city__input"}
              value={mask}
              onChange={(event) => {
                setMask(event.target.value);

                setDrop(true);
              }}
            />

            {renderDrop(data)}
          </div>

          {!editCity && !mask
            ? <div className={"city_error"}>{"required field"}</div>
            : null
          }
        </div>
      );
    }

    return (
      <div
        className="providerWrapper"
        onClick={() => {
          if (!(addrStateIdPrv)) {
            infoMessage(INFO);
          }
        }}
      >
        {renderLabelError()}

        <div className="input__wrapper">
          <input
            disabled={!(addrStateIdPrv)}
            className={fieldTouched ? "city__input-error" : "city__input"}
            value={mask}
            onChange={(event) => {
              setMask(event.target.value);

              setDrop(true);
            }}
          />

          {renderDrop(data)}
        </div>

        {fieldTouched
          ? <div className={"city_error"}>{fieldTouched}</div>
          : null
        }
      </div>
    );
  }

  return (
    <div className="wrapper">
      {renderLabelError()}

      <div
        className="input__wrapper"
        onClick={() => {
          if (!(addrStateId?.length > 0)) {
            infoMessage(INFO);
          }
        }}
      >
        <input
          disabled={!(addrStateId?.length > 0)}
          className={fieldTouched ? "city__input-error" : "city__input"}
          value={mask}
          onChange={(event) => {
            setMask(event.target.value);

            setDrop(true);
          }}
        />

        {renderDrop(data)}
      </div>

      {fieldTouched
        ? <div className={"city_error"}>{fieldTouched}</div>
        : null
      }
    </div>
  );
}
