import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loadingShow(state, { payload }) {
      state = payload;
      return state;
    },
    loadingHide(state, { payload }) {
      state = payload;
      return state;
    },
  },
});

export default loadingSlice.reducer;

export const { loadingShow, loadingHide } = loadingSlice.actions;
