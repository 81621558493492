/* eslint-disable max-len */
import PropTypes from "prop-types";

const TableContext = ({
  id, modalData, setModal, hasAcceptButton, modalHeight,
}) => {
  const screenHeight = window.screen.height;
  const buttonClockHandler = (event) => {
    if (screenHeight - event.clientY < 300) {
      if (modalHeight > 150) {
        setModal({
          open: true, id, modalData, top: (event.clientY - 60) + modalHeight / 2 - 300,
        });
      } else {
        setModal({
          open: true, id, modalData, top: (event.clientY + 80) - modalHeight / 2 - 200,
        });
      }
    } else if (modalHeight > 150) {
      setModal({
        open: true, id, modalData, top: event.clientY - modalHeight / 2,
      });
    } else {
      setModal({
        open: true, id, modalData, top: (event.clientY + 80) + modalHeight / 2 - 100,
      });
    }
  };
  return (
    <>
      <button
        onClick={buttonClockHandler}
        className="table__btn table__btn_shadow"
        style={{ background: hasAcceptButton && "transparent" }}
      >
        <svg
          className="table__btn-dots"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0007 6.66671C10.9173 6.66671 11.6673 5.91671 11.6673 5.00004C11.6673 4.08337 10.9173 3.33337 10.0007 3.33337C9.08398 3.33337 8.33398 4.08337 8.33398 5.00004C8.33398 5.91671 9.08398 6.66671 10.0007 6.66671ZM10.0007 8.33337C9.08398 8.33337 8.33398 9.08337 8.33398 10C8.33398 10.9167 9.08398 11.6667 10.0007 11.6667C10.9173 11.6667 11.6673 10.9167 11.6673 10C11.6673 9.08337 10.9173 8.33337 10.0007 8.33337ZM10.0007 13.3334C9.08398 13.3334 8.33398 14.0834 8.33398 15C8.33398 15.9167 9.08398 16.6667 10.0007 16.6667C10.9173 16.6667 11.6673 15.9167 11.6673 15C11.6673 14.0834 10.9173 13.3334 10.0007 13.3334Z"
            fill="#192A53"
          />
        </svg>
      </button>
    </>
  );
};

TableContext.propTypes = {
  id: PropTypes.number,
  setModal: PropTypes.func,
  hasAcceptButton: PropTypes.bool,
  trRef: PropTypes.oneOfType([
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default TableContext;
