import React from "react";
import PropTypes from "prop-types";

const Logo = ({ logoHref, logoImgSrc }) => (
  <a className="header__logo" href={logoHref}>
    <img src={logoImgSrc} alt="logo"/>
  </a>
);

Logo.propTypes = {
  logoHref: PropTypes.string,
  logoImgSrc: PropTypes.string,
};

export default Logo;
