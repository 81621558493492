import React from "react";

import { getDate } from "../../../../../functions/calendar_func";
import { getCurrentSessionStatus } from "../../../getCurrentSessionStatus";

import "./style.scss";

const EventTooltip = (props) => {
  const {
    data,
    show,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <div
      className={"event_tooltip-info"}
    >
      <div className="event_tooltip-info-time_range">
        {getDate(data.actualStartDt, "hh:mm A")} -{" "}
        {getDate(data.actualEndDt, "hh:mm A")}
      </div>
      <div className="event_tooltip-info-sub_title">{data.sertviceType}</div>
      <div className="event_tooltip-info-description">
        {data.sessions.map((elem, index) => (
          <div key={index} className='calendar-day-event-student'>
            <div className="calendar-day-event-student-name">{elem.studentName}</div>
            {getCurrentSessionStatus(elem.state, data.actualEndDt)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventTooltip;
