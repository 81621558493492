import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const schoolsSlice = createSlice({
  name: "schoolSlice",
  initialState: {
    schools: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setSchools: (state, { payload }) => {
      state.schools = payload;
    },
  },
});

export default schoolsSlice.reducer;

const { setSchools } = schoolsSlice.actions;

export const getSchoolsAction = (offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const response = await axios("/dictionaries/schools", { params: { offset, limit } });
    dispatch(setSchools(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const editSchoolAction = (data) => async (dispatch) => {
  try {
    await axios.put("/dictionaries/school", data);
    dispatch(getSchoolsAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    dispatch(getSchoolsAction());
  }
};

export const addNewSchoolAction = (data, cb = () => {}) => async (dispatch) => {
  try {
    const res = await axios.put("/dictionaries/school", data);
    // eslint-disable-next-line no-unused-expressions
    res?.status === 201 ? cb() : null;
    dispatch(getSchoolsAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(getSchoolsAction());
  } finally {
    dispatch(getSchoolsAction());
  }
};

export const deleteSchoolAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/dictionaries/school/${id}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    dispatch(getSchoolsAction());
  }
};

export const clearedSchoolAction = () => async (dispatch) => {
  try {
    dispatch(setSchools({ result: [], pagination: {} }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
