const headerConfig = {
  studentName: {
    id: 1,
    title: "Name",
    value: "studentName",
    order: 1,
  },
  birthDt: {
    id: 2,
    title: "DOB",
    value: "birthDt",
    order: 2,
  },
  pRPPhone: {
    id: 3,
    title: "Phone",
    value: "pRPPhoneNo",
    order: 3,
  },
  schoolName: {
    id: 4,
    title: "School",
    value: "schoolName",
    order: 4,
  },
  typeName: {
    id: 5,
    title: "Service type",
    value: "serviceTypeName",
    order: 5,
  },
  startDt: {
    id: 7,
    title: "Service Begins",
    value: "startDt",
    order: 6,
  },
  endDt: {
    id: 8,
    title: "Service Ends",
    value: "endDt",
    order: 7,
  },
};
export { headerConfig };
