import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";

import { Button } from "../../../../components/Button";
import HeaderPage from "../../../../components/HeaderPage";
import Paper, { ButtonPaper } from "../../../../components/Paper/Paper";
import SvgMaker from "../../../../components/svgMaker";
import { TextField } from "../../../../components/TextField";
import Typography from "../../../../components/Typography";
import ProgressReportInfo from "../components/progressInfo";

import {
  provider_progress_report_view_url,
  provider_students_assigment_url,
  provider_progress_report_list_url,
} from "../../../../configs/router_urls";

import { idGenerator } from "../../../../utils/idGenerator";

import { updateProgressReportAction } from "../providerStudentsList/store";
import { getStudentById } from "../ProgressReportList/store";
import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";

const subtitleItem = {
  title: {
    text: "Specify Decoding Abilities",
    mode: "text",
  },
  desc: {
    text: "Chaya reads slower than the average student, mispronouncing words.",
    mode: "text",
  },
  mode: "text",
};

const initialReportItem = {
  id: 1,
  title: "Title + text",
  header: {
    mode: "text",
    title: "Reading / Writing",
  },
  subtitles: [
    {
      id: 1,
      title: {
        text: "Specify Decoding Abilities",
        mode: "text",
      },
      desc: {
        text: "Chaya reads slower than the average student, mispronouncing words.",
        mode: "text",
      },
    },
    {
      id: 2,
      title: {
        text: "Specify Reading Comprehension Skills (literal and inferential comprehension):",
        mode: "text",
      },
      desc: {
        text: `poor literal comprehension, will not follow a basic story on grade level
        -inferential comprehension is far beyond current skill set
        Very low level, has a hard time comprehending the basic story lines of grade level texts. Analyzing, Comparing, participating in discussion is not something that Chaya Mushka is able to do. Has a hard time processing auditory information as well, very often won’t hear or understand and needs repetition`,
        mode: "text",
      },
    },
  ],
};

function UpdateProgressReport() {
  const [report, setReport] = useState([initialReportItem]);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const student = useSelector(({ progressReportListReducer }) => progressReportListReducer.student);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);
  useEffect(() => {
    dispatch(getAssignmentByIdAction(params.id, (data) => {
      dispatch(getStudentById(data.studentId));
    }));
  }, []);
  const save = () => {
    dispatch(updateProgressReportAction({
      assignmentId: +params.id,
      reportId: location.state,
      reportType: "P",
      grade: "5",
      data: report.map((item) => ({
        header: item.header.title,
        subtitles: item.subtitles.map((sub) => ({
          description: sub.desc.text,
          title: sub.title.text,
        })),
      })),
    }, () => {
      // eslint-disable-next-line no-console
      console.log("success");
    }));
    history.push(`${provider_progress_report_list_url.split("/:")[0]}/${params.id}`);
  };
  const addNewReportItem = () => {
    setReport(() => [
      ...report,
      {
        ...initialReportItem,
        id: idGenerator(12),
      },
    ]);
  };

  const removeReportItem = (id) => {
    setReport(report.filter((item) => item.id !== id));
  };

  const changeMode = (id, elem) => {
    setReport((prevReport) => prevReport.map((el) => (el.id === id ? ({
      ...el,
      [elem]: {
        ...el[elem],
        mode: "edit",
      },
    }) : el)));
  };

  const onChangeHandler = (event, id, elem) => {
    setReport((prevReport) => prevReport.map((el) => (el.id === id ? ({
      ...el,
      [elem]: {
        ...el[elem],
        title: event.target.value,
      },
    }) : el)));
  };

  const onBlurHandler = (event, id) => {
    setReport((prevReport) => prevReport.map((el) => (el.id === id ? ({
      ...el,
      header: {
        ...el.header,
        mode: "text",
      },
    }) : el)));
  };

  const addNewSubtitle = (id) => {
    setReport(report.map((item) => (item.id === id ? ({ ...item, subtitles: [...item.subtitles, { ...subtitleItem, id: idGenerator(12) }] }) : item)));
  };

  const changeModeForSubTitleItems = (event, id, subId, elem, mode = "edit") => {
    setReport(() => report.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          subtitles: item.subtitles.map((el) => {
            if (el.id === subId) {
              return {
                ...el,
                [elem]: {
                  ...el[elem],
                  mode,
                },
              };
            }
            return el;
          }),
        };
      }
      return item;
    }));
  };

  const onChangeHadlerForSunTitleItems = (event, id, subId, elem) => {
    setReport(() => report.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          subtitles: item.subtitles.map((el) => {
            if (el.id === subId) {
              return {
                ...el,
                [elem]: {
                  ...el[elem],
                  text: event.target.value,
                },
              };
            }
            return el;
          }),
        };
      }
      return item;
    }));
  };

  const removeSubTitleItem = (id, subId) => {
    setReport(() => report.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          subtitles: item.subtitles.filter((el) => el.id !== subId),
        };
      }
      return item;
    }));
  };

  return (
    <div>
      <HeaderPage
        title={`Update progress report ${assignment?.studentName || ""}`}
        rootPathName={provider_students_assigment_url}
        rootTitleName='Students'
        childrenTitleNames={["List of reports", "Edit"]}
        childrenPathNames={[`${provider_progress_report_list_url.split("/:")[0]}/${params.id}`]}
      />
      <div className='report'>
        <div className='report__container'>
          <ProgressReportInfo
            iconName="closeIcon"
            changeMode={() => {
              history.push(`${provider_progress_report_view_url.split("/:")[0]}/${params.id}`, students.find((el) => el.studentId === student.studentId));
            }}
            name={student.studentName}
            date={moment(student.birthDt).format("MM-DD-YYYY")}
            osis={student.osisNo}
          />
          <Paper>
            {report.map((item) => (
              <div className='report__creation' key={item.id}>
                <div className='report__creation-title'>
                  <Typography variant='subtitle1' style={{ color: "#495057" }}>
                    {item.title}
                  </Typography>
                  {report.length > 1 ? <div className='report__creation-delete' onClick={() => removeReportItem(item.id)}>
                    <SvgMaker name='deleteIcon'/>
                  </div> : null}
                </div>
                <div className='report__creation-header' onClick={() => {
                  changeMode(item.id, "header");
                }}>
                  {item.header.mode === "text" ? <Typography variant='h1'>
                    {item.header.title}
                  </Typography>
                    : <TextField
                      style={{ border: "none" }}
                      value={item.header.title}
                      onChange={(event) => onChangeHandler(event, item.id, "header")}
                      onBlur={(event) => onBlurHandler(event, item.id, "header")}
                    />}
                </div>
                <div className='report__creation-info'>
                  {item.subtitles.map((el, index) => (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div key={el.id + index} className='report__creation-abilities'>
                        <div onClick={(event) => changeModeForSubTitleItems(event, item.id, el.id, "title")} style={{ paddingBottom: 8 }}>
                          {el.title.mode === "text" ? <Typography variant='h4'>
                            {el.title.text}
                          </Typography>
                            : <TextField
                              style={{ border: "none" }}
                              value={el.title.text}
                              onChange={(event) => onChangeHadlerForSunTitleItems(event, item.id, el.id, "title")}
                              onBlur={(event) => changeModeForSubTitleItems(event, item.id, el.id, "title", "text")}
                            />
                          }
                        </div>
                        <div onClick={(event) => changeModeForSubTitleItems(event, item.id, el.id, "desc")}>
                          <div onClick={(event) => changeModeForSubTitleItems(event, item.id, el.id, "desc")} style={{ paddingBottom: 8 }}>
                            {el.desc.mode === "text" ? <Typography variant='subtitle2' style={{ lineHeight: "19.5px" }}>
                              {el.desc.text}
                            </Typography>
                              : <TextField
                                style={{ border: "none", height: "100px" }}
                                value={el.desc.text}
                                textArea
                                onChange={(event) => onChangeHadlerForSunTitleItems(event, item.id, el.id, "desc")}
                                onBlur={(event) => changeModeForSubTitleItems(event, item.id, el.id, "desc", "text")}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      {item.subtitles.length > 1 ? <div className='report__creation-delete' onClick={() => removeSubTitleItem(item.id, el.id)}>
                        <SvgMaker name='deleteIcon'/>
                      </div> : null}
                    </div>

                  ))}
                  <div style={{ marginTop: 32, display: "flex", justifyContent: "flex-end" }}>
                    <div className='buttons__wrapper-field' style={{ width: "33%" }}>
                      <Button variant='contained' color='primary' onClick={() => addNewSubtitle(item.id)}>
                        Add new subtitle
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div style={{ marginTop: 32, display: "flex", justifyContent: "flex-end" }}>
              <div className='buttons__wrapper-field' style={{ width: "33%" }}>
                <Button variant='contained' color='primary' onClick={addNewReportItem}>
              Add new Title+text
                </Button>
              </div>
            </div>

          </Paper>
        </div>
        <ButtonPaper>
          <div className='buttons__wrapper-field'>
            <Button variant='contained' color='primary' onClick={save}>
                    Save
            </Button>
          </div>
        </ButtonPaper>
      </div>
    </div>
  );
}

export default UpdateProgressReport;
