import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { searchGroup, getChildIds } from "./helpers/findListId";

const dictionaryGoalsSlice = createSlice({
  name: "dictionaryGoals",
  initialState: {
    dictionaryGoals: [],
    infoGroup: [],
    infoGoal: {},
    arrayGroupIds: [],
    idActiveGroup: 0,
    infoPlaceholders: [],
    titleGroup: { name: "", code: "" },
    parentGroupId: null,
    groupIds: [],
    subGroupIds: [],
  },
  reducers: {
    setDictionaryGoals: (state, { payload }) => {
      state.dictionaryGoals = payload;
    },
    setInfoGroup: (state, { payload }) => {
      state.infoGroup = payload;
    },
    setIdActiveGroup: (state, { payload }) => {
      state.idActiveGroup = payload;
    },
    setInfoGoal: (state, { payload }) => {
      state.infoGoal = payload;
    },
    setInfoPlaceholders: (state, { payload }) => {
      state.infoPlaceholders = payload;
    },
    setArrayGroupIds: (state, { payload }) => {
      state.arrayGroupIds = payload;
    },
    setTitleGroup: (state, { payload }) => {
      state.titleGroup = payload;
    },
    setParentGroupId: (state, { payload }) => {
      state.parentGroupId = payload;
    },
    setGroupIds: (state, { payload }) => {
      if (!state.groupIds.includes(payload)) {
        state.groupIds.push(payload);
      }
    },
    setSliceGroupIds: (state, { payload }) => {
      const index = state.groupIds.findIndex((el) => el === payload);
      if (index !== -1) {
        state.groupIds.splice(index, 1);
      }
    },
    setSubGroupIds: (state, { payload }) => {
      if (!state.subGroupIds.includes(payload)) {
        state.subGroupIds.push(payload);
      }
    },
    setSliceSubGroupIds: (state, { payload }) => {
      const index = state.subGroupIds.findIndex((el) => el === payload);
      if (index !== -1) {
        state.subGroupIds.splice(index, 1);
      }
    },
  },
});

export default dictionaryGoalsSlice.reducer;

const {
  setDictionaryGoals, setInfoGroup, setIdActiveGroup, setInfoGoal, setInfoPlaceholders, setArrayGroupIds,
  setTitleGroup, setParentGroupId, setGroupIds, setSubGroupIds, setSliceSubGroupIds, setSliceGroupIds,
} = dictionaryGoalsSlice.actions;

export const getTitleGroup = (data) => async (dispatch) => {
  try {
    dispatch(setTitleGroup(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getGroupIds = (id, status) => async (dispatch) => {
  try {
    if (status === "open") {
      dispatch(setGroupIds(id));
    }
    if (status === "close") {
      dispatch(setSliceGroupIds(id));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getSubGroupIds = (id, status) => async (dispatch) => {
  try {
    if (status === "open") {
      dispatch(setSubGroupIds(id));
    }
    if (status === "close") {
      dispatch(setSliceSubGroupIds(id));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getIdActiveGroup = (id) => async (dispatch) => {
  dispatch(setIdActiveGroup(id));
};

export const getDictionaryGoalsAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/goals/get-goals-and-group");
    dispatch(setDictionaryGoals(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getInfoForGroupById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/goals/info-group/${id}`);
    dispatch(setInfoGroup(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getInfoForGoalById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/goals/info-goal/${id}`);
    dispatch(setInfoGoal(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteGoalById = (id, idGroup) => async (dispatch) => {
  try {
    await axios.delete(`/goals/delete-goal/${id}`);
    dispatch(getDictionaryGoalsAction());
    dispatch(getInfoForGroupById(idGroup));
    dispatch(getDictionaryGoalsAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const moveGoalToGroup = (data, id) => async (dispatch) => {
  try {
    await axios.patch("/goals/move-goal-to-group/", data);
    dispatch(getInfoForGroupById(id));
    dispatch(getDictionaryGoalsAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createNewGoalToGroup = (data, id, cb = () => { }) => async (dispatch) => {
  try {
    await axios.post("/goals/create-goal", data);
    dispatch(getInfoForGroupById(id));
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const deleteGroupById = (id) => async (dispatch) => {
  try {
    await axios.delete(`/goals/delete-goal-group/${id}`);
    dispatch(getDictionaryGoalsAction());
    dispatch(getTitleGroup({ name: "", code: "" }));
    dispatch(getIdActiveGroup(0));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateGoalById = (data, idGroup, cb) => async (dispatch) => {
  try {
    await axios.patch("/goals/update-goal", data);
    dispatch(getInfoForGroupById(idGroup));
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createNewGroup = (data, cb) => async (dispatch) => {
  dispatch(setParentGroupId(null));
  try {
    await axios.post("/goals/create-group-goals/", data);
    dispatch(setParentGroupId(data.parentGroupId));
    dispatch(getDictionaryGoalsAction());
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getListPlaceholder = () => async (dispatch) => {
  try {
    const response = await axios.get("/goals/placeholders");
    dispatch(setInfoPlaceholders(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateGroup = (data, id) => async (dispatch) => {
  try {
    await axios.patch("/goals/update-goal-group", data);
    dispatch(getDictionaryGoalsAction());
    dispatch(getInfoForGroupById(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const moveGroupToAnotherGroup = (data) => async (dispatch) => {
  dispatch(setParentGroupId(null));
  try {
    await axios.patch("/goals/move-goal-group", data);
    dispatch(setParentGroupId(data.newGoalGroupId));
    dispatch(getDictionaryGoalsAction());
    dispatch(getInfoForGroupById(data.goalGroupId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const findAllSubgroup = (data, id) => async (dispatch) => {
  try {
    const response = await axios.get("/goals/get-goals-and-group");
    dispatch(setDictionaryGoals(response.data));
    let group = searchGroup(data, id);
    let arrIds = getChildIds(group);
    dispatch(setArrayGroupIds(arrIds));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
