import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import HeaderPage from "../../../components/HeaderPage";
import FormikButton, { Button } from "../../../components/Button";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import tableFormater from "../../../utils/tableFormater";
import { PaperModal } from "../../../components/modal/Modal";
import ConfirmModal from "../../../components/confirmModal/ConfirmModal";
import FormikSelect from "../../../components/Select";
import FormikTextField from "../../../components/TextField";
import FormikCheckBox from "../../../components/Checkbox";
import {
  getMessagesList, sendMessagesToProviders, editOneMessage, deleteOneMessage,
} from "./store";
import { dateFormat } from "../../../utils/dateFormater";

import "./styles/index.scss";

import { headerConfig, validationSchema, fieldConfig } from "./config";

function Messages() {
  const dispatch = useDispatch();

  const allProviders = useSelector(
    ({ adminReducer }) => adminReducer.allProviders.result,
  ).filter((item) => item.state !== "I");
  const data = useSelector(
    ({ adminMessagesReducer }) => adminMessagesReducer.data,
  );

  const [currentMessage, setCurrentMessage] = useState(0);
  const [messageToEdit, setMessageToEdit] = useState("");
  const [state, setState] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [modal, setModal] = useState("");

  useEffect(() => {
    dispatch(getMessagesList());
  }, []);

  const onClose = (modal) => {
    valueIsChange
      ? setModal(`discard ${modal}`)
      : setModal("");
  };

  const paginationClick = (offset) => {
    dispatch(getMessagesList(offset));
    setState(0);
  };

  const checkSpaces = (str) => !!str.replace(/\s/g, "").length;

  const editMessage = (values) => {
    dispatch(editOneMessage(
      { textOfMessage: values.message, messageId: currentMessage },
      () => {
        setModal("");
        setState(0);
        setValueIsChange(false);
      },
    ));
  };

  const deleteMessage = () => {
    dispatch(
      deleteOneMessage(
        currentMessage,
        currentIndex * 10,
        () => {
          setModal("");
          setState(0);
        },
      ),
    );
  };

  const sendNewMessage = (values) => {
    let { message, providerId, isAllProviders } = values;
    if (isAllProviders) {
      dispatch(sendMessagesToProviders({ message, providers: [] },
        () => { setModal(""); setValueIsChange(false); }));
    } else {
      const providers = [providerId];
      dispatch(sendMessagesToProviders({ message, providers },
        () => { setModal(""); setValueIsChange(false); }));
    }
  };

  const openModal = (modal) => {
    switch (modal) {
    case "add":
      return (
        <PaperModal
          headerTitle="Add new Message"
          open={modal === "add"}
          onClose={() => onClose("add")}>
          <Formik
            initialValues={{ message: "", providerId: [], isAllProviders: false }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (checkSpaces(values.message)) {
                sendNewMessage(values);
                setCurrentIndex(0);
              }
            }}
          >
            {({ values, dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfieldNote": {
                    return (
                      <div className="messages_textfield">
                        <FormikTextField
                          {...item}
                        />
                      </div>
                    );
                  }
                  default:
                    return "default";
                  }
                })}

                {!values.isAllProviders && <div className="messages_textfield">

                  <FormikSelect
                    name="providerId"
                    label="Provider"
                    required={!values.isAllProviders}
                    data={allProviders}
                    isFilter
                  />

                </div>}

                <div className="messages_textfield">

                  <FormikCheckBox name="isAllProviders" label="To all"/>

                </div>

                <div className="messages_button">

                  <FormikButton
                    variant='contained'
                    color='primary'
                    disabled={!dirty}
                  >
                    Save
                  </FormikButton>

                </div>
                {dirty === true ? setValueIsChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModal>
      );
    case "edit":
      return (
        <PaperModal
          headerTitle="Edit Message"
          open={modal === "edit"}
          onClose={() => onClose("edit")}
        >
          <Formik
            initialValues={{ message: messageToEdit }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (checkSpaces(values.message)) {
                editMessage(values);
                setCurrentIndex(0);
              }
            }}
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfieldNote": {
                    return (
                      <div className="messages_textfield">
                        <FormikTextField
                          {...item}
                        />
                      </div>
                    );
                  }
                  default:
                    return "default";
                  }
                })}

                <div className="messages_button">
                  <FormikButton
                    variant='contained'
                    color='primary'
                    disabled={!dirty}
                  >
                    Save
                  </FormikButton>
                </div>

                {dirty === true ? setValueIsChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModal>);
    case "discard add":
    case "discard edit":
      return (
        <ConfirmModal headerTitle="Discard unsaved changes?" open={modal === "discard edit" || modal === "discard add"}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setModal(""); setValueIsChange(false); }}
            >
            Discard
            </Button>
            <Button
              variant="default"
              color="secondary"
              onClick={() => setModal(modal.includes("add") ? "add" : "edit")}
            >
            Cancel
            </Button>
          </div>
        </ConfirmModal>
      );
    case "delete":
      return (
        <ConfirmModal headerTitle="Are you sure deleting message?" open={modal === "delete"}>
          <Button variant='contained' color='primary' onClick={deleteMessage}>
            Yes, Delete
          </Button>
          <Button variant='dashed' color='primary' onClick={() => setModal("")}>
            Cancel
          </Button>
        </ConfirmModal>
      );
    default:
      return "";
    }
  };

  return (
    <>
      <HeaderPage title="Messages for Providers" />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
        paddingRight: "30px",
      }}
      >
        <div style={{ marginRight: "auto", width: "149px" }}>
          <Button
            variant='contained'
            onClick={() => {
              setModal("add");
              setState(0);
            }}
          >
            Create new
          </Button>
        </div>
      </div>

      <div className="adminMessages">
        <Table
          head={Object.values(headerConfig)}
          data={tableFormater(data.messages.map((item) => ({
            ...item,
            sendDt: <div className="adminMessages__item">{dateFormat("mm-dd-yyyy", item.sendDt)}</div>,
            providers: <div className="adminMessages__item">{item.providers}</div>,
            message: <div className="adminMessages__item">{item.message}</div>,
          })), headerConfig, "messageId")}
          onEdit={(id) => {
            setModal("edit");
            setCurrentMessage(id);
            setMessageToEdit(data.messages?.find((el) => el.messageId === id)?.message);
            setState(0);
          }}
          onDelete={(id) => { setModal("delete"); setCurrentMessage(id); setState(0); }}
          state={state}
          setState={setState}
        />
        {openModal(modal)}
      </div>
      <Pagination
        {...data.pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </>
  );
}

export default Messages;
