import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";

import Paper from "../../../../../components/Paper";
import HeaderPage from "../../../../../components/HeaderPage";
import FormikTextField from "../../../../../components/TextField";
import FormikSelect from "../../../../../components/Select";
import FormikCheckBox from "../../../../../components/Checkbox";
import FormikDateField from "../../../../../components/DateField";
import FormikButton from "../../../../../components/Button";
import { createStudentServiceAction } from "../studentServices/store";
import { fetchServiceTypes } from "./store";
import { getStudentRatio, getBillingTypes, getServiceTypeForAddEdit } from "../../../../../utils/serviceType";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";

import "./styles/index.scss";

import { fieldConfig, formikInitialValues, validationSchema } from "./configs";
import { admmin_students_services_url, admmin_students_profile_url, admmin_students_url } from "../../../../../configs/router_urls";

function AddNewStudentServices() {
  const params = useParams();

  const location = useLocation();
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [config, setConfig] = useState([]);
  const [date, setDate] = useState({});
  const dispatch = useDispatch();
  const { result: data } = useSelector(({ serviceTypesReducer }) => serviceTypesReducer.serviceTypes);
  const studentName = `for ${location.state.studentName}` || "";
  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);

  useEffect(() => {
    dispatch(fetchServiceTypes());
  }, []);

  useEffect(() => {
    if (data) {
      setConfig(fieldConfig.map((item) => (item.name === "serviceTypeNo" ? ({
        ...item,
        data: getServiceTypeForAddEdit(data).map((el) => ({ value: el.serviceTypeNo, title: el.typeName, id: el.serviceTypeNo })),
      }) : item)));
    }
  }, [data]);

  function onChange(e, values) {
    const selectName = e.target.name;
    const service = data.find((s) => {
      if (selectName === "serviceTypeNo") {
        return s.serviceTypeNo === +e.target.value;
      }
      if (values.serviceTypeNo) {
        return s.serviceTypeNo === +values.serviceTypeNo;
      }
      return false;
    });
    setConfig(fieldConfig.map((item) => {
      if (item.name === "serviceTypeNo") {
        return { ...item, data: getServiceTypeForAddEdit(data).map((el) => ({ value: el.serviceTypeNo, title: el.typeName, id: el.serviceTypeNo })) };
      }
      if (service) {
        if (item.name === "ratio") {
          return { ...item, data: getStudentRatio(service.typeName) };
        }
        if (item.name === "billingType") {
          return { ...item, data: getBillingTypes(service.typeName) };
        }
      }
      return item;
    }));
  }

  return (
    <div>
      <HeaderPage
        title={`Add New Authorization ${studentName}`}
        rootTitleName="All Students"
        rootPathName={admmin_students_url}
        childrenTitleNames={["Student Profile", "Manage Student Services", "Add New Authorization"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`, `${admmin_students_services_url}/${params.id}`, `/all/students/services/${params.id}`]}
      />
      <Paper>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setShowPrompt(false);
            const {
              billingRate, authorizationNo, caseNo, note, ...props
            } = values;
            if (billingRate) props.billingRate = billingRate;
            if (typeof authorizationNo === "number") props.authorizationNo = authorizationNo.toString();
            if (typeof caseNo === "number") props.caseNo = caseNo.toString();
            if (note !== "") props.note = note;
            delete props.timePeriod;
            dispatch(createStudentServiceAction(
              {
                ...props,
                authorizationStartDt: moment(date.startDate).format("YYYY-MM-DD"),
                authorizationEndDt: moment(date.endDate).format("YYYY-MM-DD"),
                studentId: params.id,
                note: values.note.trim() || null,
              }, () => {
                history.push(`${admmin_students_services_url}/${params.id}`);
              },
            ));
          }}
        >
          {({
            values, dirty,
          }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className="addNewAuthorization">
                {config.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikTextField { ...item} type={item.textfieldType || item.type} />
                      </div>
                    );
                  }
                  case "textfieldNote": {
                    return (
                      <div
                        style={{ gridColumn: "1/3" }}
                        key={item.id + item.name}
                      >
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikSelect
                          {...item}
                          onChange={(event) => onChange(event, values)}
                          disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                        />
                      </div>
                    );
                  }
                  case "checkbox": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <span style={{ color: "#192A53", fontSize: "13px" }}>
                          {item.label}
                        </span>
                        <div className='addNewAuthorization-checkbox-wrapper'>
                          <FormikCheckBox name={item.name} {...item} label=''/>
                        </div>
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div className='modal__wrapper__field double-field' key={item.id + item.name}>
                        <FormikDateField showIcon {...item} format={"mm-dd-yyyy"} separator={" to "} setDate={setDate} />
                      </div>
                    );
                  }
                  case "empty": {
                    return <div/>;
                  }
                  default:
                    return "default";
                  }
                })}
                <FormikButton variant="contained">Save</FormikButton>
                {dirty === true ? setValueIsChange(true) : null}
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default AddNewStudentServices;
