const fieldConfig = [
  {
    id: 1,
    name: "studentName",
    title: "Student: ",
    value: "",
  },
  {
    id: 2,
    name: "serviceTypeName",
    title: "Service: ",
    value: "",
  },
  {
    id: 3,
    name: "providerName",
    title: "Provider: ",
    value: "",
  },
  {
    id: 4,
    name: "weeklyMandate",
    title: "Weekly mandate: ",
    value: "",
  },
  {
    id: 5,
    title: "",
  },
  {
    id: 6,
    name: "timePeriod",
    title: "Time period: ",
    value: "",
  },
  {
    id: 7,
    name: "makeupAllowed",
    title: "Makeup sessions allowed",
    value: "",
  },
  {
    id: 8,
    title: "",
  },
  {
    id: 9,
    name: "sessionAmountAssignment",
    title: "Amount of session: ",
    value: "",
  },
  {
    id: 10,
    name: "partial",
    title: "Partial",
    value: "",
  },
  {
    id: 11,
    name: "payrollRate",
    title: "Provider payroll rate: ",
    value: "",
  },
  {
    id: 12,
    title: "",
  },
  {
    id: 13,
    title: "",
  },
  {
    id: 14,
    title: "",
  },
  {
    id: 15,
    title: "",
  },
  {
    id: 16,
    name: "substitute",
    title: "Substitute",
    value: "",
  },
  {
    id: 17,
    name: "temp",
    title: "Allowable session: ",
    value: "",
  },
  {
    id: 18,
    title: "",
  },
  {
    id: 19,
    title: "",
  },
  {
    id: 20,
    title: "",
  },
  {
    id: 21,
    name: "note",
    title: "Comments: ",
    value: "",
  },
  {
    id: 22,
    title: "",
  },
  {
    id: 23,
    title: "",
  },
  {
    id: 24,
    title: "",
  },
  {
    id: 25,
    title: "",
  },
  {
    id: 26,
    type: "button",
  },
];

export default fieldConfig;
