export const signersFormatter = (signer, index, last) => {
  switch (signer) {
  case "A":
    return <div>Parent{index !== last ? ";" : ""}</div>;
  case "S":
    return <div>School representative{index !== last ? ";" : ""}</div>;
  case "R":
    return <div>Provider{index !== last ? ";" : ""}</div>;
  default: return "";
  }
};
