import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import moment from "moment";

import HeaderPage from "../../../../components/HeaderPage";
import { Button } from "../../../../components/Button";
import Paper from "../../../../components/Paper";
import SessionGoals from "./sections/goalsSection";
import SessionSkills from "./sections/skillsSection";
import SessionActivities from "./sections/activitiesSection";
import SessionMaterials from "./sections/materialsSection";
import SessionPrompts from "./sections/promptsSection";
import SessionInfo from "./sections/infoSection";
import SessionComments from "./sections/commentSection";
import {
  clearWarningMessage,
  createSessionNote,
  getSessionData,
  getSessionDictAction,
  getSessionNotesAction,
} from "../sessionForm/store";
import PfdPreviewAll from "../../../../components/PDF/PdfPReviewAll";
import SessionTitle from "./sections/titleSection";
import SessionDate from "./sections/dateSection";
import { snackbarOpen } from "../../../../components/Snackbar/store/snackbar.slice";
import SessionNotePDF from "../../../../components/PDF/sessionNotePDF";
import { deleteEventByIdAction } from "./store";
import ConfirmModal from "../../../../components/confirmModal";

import "./styles/index.scss";

import {
  fieldConfig,
  formikInitialValues,
  validationSchema,
  paperHeaderConfig,
} from "./configs";
import { calendar_url, edit_session_url } from "../../../../configs/router_urls";

function SessionNotes() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const sessionData = useSelector(({ sessionReducer }) => sessionReducer.sessionData);
  const { warningMessage } = useSelector(({ sessionReducer }) => sessionReducer.warningMessage);
  const sessionDicts = useSelector(({ sessionReducer }) => sessionReducer.sessionDicts);
  const providerId = useSelector(({ authorizationReducer }) => authorizationReducer.user.providerId);

  const didMount = React.useRef(false);

  const [currentSession, setCurrentSession] = useState({});
  const [error, setError] = useState(false);
  const [config] = useState({
    fieldConfig,
    validationSchema,
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState([{
    sessionGoals: [],
    skills: [],
    activities: [],
    materials: [],
    prompts: [],
    note: "",
    options: {},
    show: true,
    showPreview: false,
    showPreviewDocs: false,
  }]);
  const [selectedDate, handleDateChange] = useState({
    actualDt: moment(sessionData.eventDates.actualStartTime).format("MM-DD-YYYY"),
    actualStartTime: sessionData.eventDates.actualStartTime,
    actualEndTime: sessionData.eventDates.actualEndTime,
  });
  const [errorDate, setErrorDate] = useState(false);

  const showPdfPreview = (s) => {
    updateSelected(s.sessionId, { ...s, showPreview: true });
  };
  const showPdfAllPreview = (s) => {
    updateSelected(s.sessionId, { ...s, showPreviewDocs: true });
  };

  const hidePdfPreview = (s) => {
    updateSelected(s.sessionId, { ...s, showPreview: false });
  };
  const hidePdfAllPreview = (s) => {
    updateSelected(s.sessionId, { ...s, showPreviewDocs: false });
  };

  const validation = (sessionId) => {
    const session = selected.find((s) => s.sessionId === sessionId);
    if (!session) return showError("SessionId do not found");
    const {
      sessionGoals, location, virtualMedium, virtualMediumAddr, materialsTransferId, skills, activities, materials, prompts,
    } = session;
    if (!sessionGoals.length) return showError("Please enter goals first");
    if (!skills.length) return showError("Please enter skills first");
    if (!activities.length) return showError("Please enter activities first");
    if (!materials.length) return showError("Please enter materials first");
    if (!prompts.length) return showError("Please enter prompt types and levels please");
    if (!location) return showError("Please enter student location first");
    if (location === "R" && !virtualMedium) return showError("Please choose the virtual medium when the session is remote");
    if (virtualMedium === "Z" && !virtualMediumAddr) return showError("Please enter the virtual medium id when the virtual medium is Zoom");
    if (virtualMedium === "P" && !materialsTransferId) return showError("Please choose the materials transferring method when the virtual medium is a phone");
    return true;
  };

  function showError(text) {
    dispatch(snackbarOpen({
      isOpen: true,
      timeout: 4000,
      text,
      type: "error",
    }));
    return false;
  }

  useEffect(() => {
    dispatch(getSessionData({
      eventDates: {},
      sessions: [],
    }));

    if (!sessionDicts.goals?.length) {
      dispatch(getSessionDictAction());
    }

    if (warningMessage) {
      dispatch(snackbarOpen({
        isOpen: true,
        timeout: 3000,
        text: warningMessage,
        type: "primary",
      }));
      dispatch(clearWarningMessage());
      return false;
    }
  }, []);

  useEffect(() => {
    if (selectedDate.actualStartTime && sessionData?.sessions.length) {
      if (didMount.current) {
        // https://confluence.telesens.ua/display/CA/E.2.2+Provider+calendar
        const error = selected.find(({ state }) => (state === "A" || state === "I"));
        if (error) return;
        dispatch(createSessionNote(sessionData.eventId, selected, selectedDate));
      } else {
        didMount.current = true;
        window.scrollTo(0, 0);
      }
    }
  }, [selected, selectedDate]);

  const getScheduleDate = (i) => {
    if (!history.location?.state?.eventId) {
      return i.value === "scheduledDt"
        ? moment(sessionData.eventDates.scheduledStartTime).format("MM-DD-YYYY")
        : moment(`${sessionData.eventDates[i.value]}`).format("hh:mm A");
    }
    const state = { ...history.location.state };
    state.scheduledDt = history.location.state.recurreceSchema?.startTime;
    state.scheduledStartTime = history.location.state.recurreceSchema?.startTime;
    state.scheduledEndTime = history.location.state.recurreceSchema?.endTime;

    return i.value === "scheduledDt"
      ? moment(state[i.value]).format("MM-DD-YYYY")
      : moment(state[i.value]).format("hh:mm A");
  };

  useEffect(() => {
    if (sessionData.eventDates.actualEndTime) {
      setSelected(sessionData.sessions.map((session) => ({
        ...session,
        options: {
          location: session.location,
          materialsTransferId: session.materialsTransferId,
          virtualMedium: session.virtualMedium,
          virtualMediumAddr: session.virtualMediumAddr,
        },
        show: sessionData.sessions.length === 1,
        showPreview: false,
      })));
      handleDateChange({
        actualDt: moment(sessionData.eventDates.actualStartTime).format("MM-DD-YYYY"),
        actualStartTime: sessionData.eventDates.actualStartTime,
        actualEndTime: sessionData.eventDates.actualEndTime,
      });
    } else if (!sessionData?.sessions.length && params.id) {
      dispatch(getSessionNotesAction(params.id));
    }
  }, [sessionData]);

  const toggleForm = (sessionId) => {
    setSelected((oldState) => oldState.map((session) => {
      if (session.sessionId === sessionId) {
        return {
          ...session,
          show: !session.show,
        };
      }
      return session;
    }));
  };

  const triming = (value) => (typeof value === "string" ? value.trim() : value);

  const updateSelected = (sessionId, newSession) => {
    setSelected((oldState) => oldState.map((session) => {
      if (session.sessionId === sessionId) {
        return {
          ...session,
          ...newSession,
          virtualMediumAddr: triming(newSession.virtualMediumAddr),
          note: triming(newSession.note),
        };
      }
      return session;
    }));
  };

  const onReschedule = () => {
    if (history.location.state) {
      history.push(edit_session_url, {
        ...history.location.state,
        eventId: sessionData.eventId,
        fromNotes: true,
      });
    } else {
      history.push(edit_session_url, {
        ...sessionData,
        startDt: sessionData.eventDates.scheduledStartTime,
        endDt: sessionData.eventDates.scheduledEndTime,
        fromNotes: true,
      });
    }
  };

  function notEdit(state) {
    return ["A", "I"].indexOf(state) !== -1;
  }

  function stateAllSession(sessions) {
    return !sessions.find((s) => s.state === "S" || s.state === "C");
  }

  function notEditOneOf(sessions) {
    return !!sessions.find(({ state }) => notEdit(state));
  }

  const deleteEvent = (id) => {
    setConfirmModal(false);
    dispatch(deleteEventByIdAction(id, () => history.push(calendar_url)));
  };

  const onSign = (sessionId) => {
    setSelected((oldState) => oldState.map((session) => {
      if (session.sessionId === sessionId) {
        return {
          ...session,
          state: "A",
        };
      }
      return session;
    }));
  };

  const validationDate = (e) => {
    if (moment(e.actualStartTime) > moment(e.actualEndTime)) setErrorDate(true);
    else {
      setErrorDate(false);
      handleDateChange(e);
    }
  };

  return (
    <div>
      <HeaderPage
        title="Session notes"
        rootPathName={calendar_url}
        rootTitleName="Calendar"
        childrenTitleNames={["Scheduled"]}
      />
      <Paper wrapperStyles={{ padding: "24px 0" }}>
        <div className="session_notes_form__header">
          {paperHeaderConfig.map((i) => (
            <div key={i.id} className="session_notes_form__header-item">
              <span className="session_notes_form__header-item_label">
                {i.key}:{" "}
              </span>

              <span className="session_notes_form__header-item_value">
                {getScheduleDate(i)}
              </span>
            </div>
          ))}
        </div>
        <Formik
          initialValues={{
            ...formikInitialValues,
            actualDt: moment(sessionData.eventDates.actualStartTime).format("MM-DD-YYYY"),
            actualStartTime: sessionData.eventDates.actualStartTime,
            actualEndTime: sessionData.eventDates.actualEndTime,
          }}
          validationSchema={config.validationSchema}
          onSubmit={() => {}}
        >
          {() => <div>
            <SessionDate
              sessionData={sessionData}
              selectedDate={selectedDate}
              handleDateChange={validationDate}
              hideEdit={notEditOneOf(selected)}
              isError={errorDate}
            />
            {selected.map((s, index) => <div key={index}>
              {setCurrentSession(s)}
              {s.showPreview && <SessionNotePDF
                providerId={providerId}
                setShowPreview={() => hidePdfPreview(s)}
                sessionId={s.sessionId}
                validation={validation}
                stateSigned={notEdit(s.state)}
                changeState={() => onSign(s.sessionId)}
              />}
              {s.showPreviewDocs && <PfdPreviewAll
                providerId={providerId}
                setShowPreview={() => hidePdfAllPreview(s)}
                assignmentId={s.assignmentId}
                stateSigned={stateAllSession(s.sessionsByAssignment)}
                changeState={() => onSign(s.sessionId)}
              />}
              <SessionTitle
                selected={s}
                showPdfPreview={() => showPdfPreview(s)}
                toggle={() => toggleForm(s.sessionId)}
                showPdfAllPreview={() => showPdfAllPreview(s)}
              />
              { s.show
                  && <>
                    <SessionGoals
                      selected={s}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      hideEdit={notEdit(s.state)}
                      id={params.id}
                    />

                    <SessionSkills
                      selected={s.skills}
                      skillDict={sessionDicts.skills}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      hideEdit={notEdit(s.state)}
                    />

                    <SessionActivities
                      selected={s.activities}
                      activitiesDict={sessionDicts.activities}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      hideEdit={notEdit(s.state)}
                    />

                    <SessionMaterials
                      selected={s.materials}
                      materialsDict={sessionDicts.materials}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      hideEdit={notEdit(s.state)}
                    />

                    <SessionPrompts
                      promptsDict={sessionDicts.prompts.map((el) => ({ title: el.attrValue, value: el.attributeId }))}
                      promptLevelDict={sessionDicts.promptLevel.map((el) => ({ title: el, value: el }))}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      selected={s.prompts}
                      hideEdit={notEdit(s.state)}
                    />

                    <SessionInfo
                      error={error}
                      selected={s}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      infoDicts={{ locations: sessionDicts.location, materialsTransfer: sessionDicts.materialsTransfer, virtualMedium: sessionDicts.virtualMedium }}
                      hideEdit={notEdit(s.state)}
                    />

                    <SessionComments
                      selected={s.note}
                      setSelected={(session) => updateSelected(s.sessionId, session)}
                      hideEdit={notEdit(s.state)}
                    />
                  </>
              }
            </div>)}
            <div className="session_notes_form__buttons ">
              <div className="session_notes_form__button grey column-3" >
                <Button
                  variant="outlined"
                  color="grey"
                  onClick={() => {
                    setConfirmModal(true);
                  }}
                >
                    Delete
                </Button>
              </div>
              {(currentSession.state === "C" || currentSession.state === "S")
                && <div className="create_session__button grey">
                  <Button
                    variant="outlined"
                    color="grey"
                    disabled={currentSession.state === "R"}
                    onClick={onReschedule}
                  >
                    Reschedule
                  </Button>
                </div>}
              <div className="session_notes_form__button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setError(false);
                    history.push(calendar_url);
                  }}
                >
                    Back to calendar
                </Button>
              </div>
            </div>
          </div>
          }
        </Formik>
      </Paper>

      <ConfirmModal headerTitle="Are you sure deleting event?" open={confirmModal}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteEvent(sessionData.eventId)}
          >
            Yes, delete
          </Button>

          <Button variant="default"
            color="secondary"
            onClick={() => { setConfirmModal(false); }}
          >
            Cancel
          </Button>
        </div>
      </ConfirmModal>
    </div>
  );
}

export default SessionNotes;
