import React from "react";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";

function SubGroup({
  subGroups, name, id, open, goals, groupCd, activeGroup,
}) {
  return (
    <div className="goalsSubGroup">
      <div id={id} style={{ display: "flex", paddingBottom: "12px", alignItems: "center" }}
        className={`${activeGroup === id ? "activeGroupGoals" : "inActiveGroupGoals"}`}
        onClick={(event) => open(event, `${id}/${name}`, id, groupCd || "")}>
        <div className="goalsSubGroup__icon" data-status="false">
          {!subGroups.length
            ? <SvgMaker width='20' height='21' name='dotIcon' />
            : <SvgMaker width='20' height='20' name='arrowRightDict' fill="#495057"/>
          }
        </div>
        <div className="goalsSubGroup__title">
          <span>{`${name} ${groupCd || ""}`}</span>
          <span>({goals.length} goals)</span></div>
      </div>
      {!subGroups.length ? null
        : subGroups.map((subgroup) => <div className={`${id}/${name}`} key={subgroup.id + subgroup.name}>
          <SubGroup activeGroup={activeGroup} {...subgroup} open={open}/>
        </div>)}
    </div>
  );
}

export default SubGroup;
