import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  documents: [],
};

const getDocumentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    getDocuments(state, { payload }) {
      state.documents = payload;
    },
    resetState() {
      return initialState;
    },
  },
});

const { getDocuments, resetState } = getDocumentsSlice.actions;

export const reset = () => async (dispatch) => {
  dispatch(resetState());
};

export const fetchDocumentsList = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/student/documents-list-by-student/${id}`);
    dispatch(getDocuments(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const deleteDocument = (id, studentId) => async (dispatch) => {
  try {
    await axios.delete(`/student/delete-document/${id}`);
    dispatch(fetchDocumentsList(studentId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateAccessField = (id, value, studentId) => async (dispatch) => {
  try {
    await axios.patch(`/student/change-document-access-by-document/${id}`, { access: value });
    dispatch(fetchDocumentsList(studentId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const uploadNewFile = (id, data) => async (dispatch) => {
  try {
    await axios.post(`/student/upload-document-by-student/${id}`, data);
    dispatch(fetchDocumentsList(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    dispatch(fetchDocumentsList(id));
  }
};

export default getDocumentsSlice.reducer;
