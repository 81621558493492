import * as Yup from "yup";

const headerConfig = {
  serviceTypeNo: {
    id: 1,
    title: "Service type",
    value: "serviceTypeNo",
    order: 1,
  },
  ratio: {
    id: 2,
    title: "Student ratio",
    value: "ratio",
    order: 2,
  },
  compensatory: { // need to rebuild
    id: 3,
    title: "Compensatory",
    value: "compensatory",
    order: 3,
  },
  sessionWeekly: {
    id: 4,
    title: "Weekly mandate",
    value: "sessionWeekly",
    order: 4,
  },
  sessionLength: {
    id: 5,
    title: "Mandated session length",
    value: "sessionLength",
    order: 5,
  },
  authorizationPeriod: { // need to rebuild
    id: 6,
    title: "Authorization period",
    value: "authorizationPeriod",
    order: 6,
  },
  sessionAmount: { // need to rebuild
    id: 7,
    title: "Amount of sessions",
    value: "sessionAmount",
    order: 7,
  },
  billingType: {
    id: 8,
    title: "Billing Type",
    value: "billingType",
    order: 8,
  },
  authorizationNo: {
    id: 9,
    title: "Authorization number",
    value: "authorizationNo",
    order: 9,
  },
  caseNo: {
    id: 10,
    title: "Hearing/Case number",
    value: "caseNo",
    order: 10,
  },
  billable: { // need to rebuild
    id: 11,
    title: "Billable",
    value: "billable",
    order: 11,
  },
  payable: { // need to rebuild
    id: 12,
    title: "Payable",
    value: "payable",
    order: 12,
  },
  billingRate: {
    id: 13,
    title: "Rate",
    value: "billingRate",
    order: 13,
  },
  note: {
    id: 14,
    title: "Notes",
    value: "note",
    order: 14,
  },
};

const fieldConfig = [
  {
    id: 1,
    name: "serviceTypeNo",
    label: "service type",
    placeholder: "input value",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "SETSS",
        value: 1,
      },
      {
        id: 2,
        title: "SEIT",
        value: 2,
      },
    ],
  },
  {
    id: 2,
    name: "ratio",
    label: "Student ratio",
    placeholder: "input value",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "1:1",
        value: "1",
      },
      {
        id: 2,
        title: "Group",
        value: "G",
      },
      {
        id: 3,
        title: "Indirect",
        value: "I",
      },
    ],
  },
  {
    id: 3,
    name: "compensatory",
    label: "Compensatory",
    required: true,
    mylti: true,
    type: "radio",
  },
  {
    id: 4,
    name: "sessionWeekly",
    label: "Weekly mandate",
    placeholder: "input value",
    required: true,
    type: "textfield",
  },
  {
    id: 5,
    name: "sessionLength",
    label: "Mandated session Length",
    placeholder: "input value",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "30",
        value: "30",
      },
      {
        id: 2,
        title: "45",
        value: "45",
      },
      {
        id: 3,
        title: "60",
        value: "60",
      },
    ],
  },
  {
    id: 6,
    type: "datefields",
    label: "Authorization period",
    required: true,
    pickers: [
      {
        id: 126,
        name: "authorizationStartDt",
        placeholder: "input value",
        required: true,
      },
      {
        id: 127,
        name: "authorizationEndDt",
        placeholder: "input value",
        required: true,
      },
    ],
  },
  {
    id: 7,
    name: "sessionAmount",
    label: "Amount of sessions",
    placeholder: "input value",
    required: true,
    type: "textfield",
  },
  {
    id: 8,
    name: "billingType",
    label: "Bill type",
    placeholder: "input value",
    // disabled: true,
    type: "select",
    required: true,
    data: [
      {
        id: 1,
        title: "E",
        value: "E",
      },
      {
        id: 2,
        title: "I",
        value: "I",
      },
      {
        id: 3,
        title: "V",
        value: "V",
      },
    ],
  },
  {
    id: 9,
    name: "caseNo",
    label: "Hearing /Case number",
    placeholder: "input value",
    // disabled: true,
    type: "textfield",
  },
  {
    id: 10,
    name: "authorizationNo",
    label: "Authorization number",
    placeholder: "input value",
    // disabled: true,
    type: "textfield",
  },
  {
    id: 11,
    name: "billingRate",
    label: "Rate",
    placeholder: "input value",
    required: true,
    type: "textfield",
  },
  {
    id: 12,
    name: "note",
    label: "Notes",
    placeholder: "input value",
    type: "textfield",
    textArea: true,
  },
  {
    id: 13,
    name: "billable",
    label: "Billable",
    required: true,
    type: "radio",
  },
  {
    id: 14,
    name: "payable",
    label: "Payble",
    required: true,
    type: "radio",
  },
];

const formikInitialValues = {
  sessionAmount: "",
  authorization: "",
  authorizationNo: "",
  authorizationStartDt: "",
  authorizationEndDt: "",
  billable: "",
  billingType: "",
  compensatory: "",
  caseNo: "",
  sessionLength: "",
  note: "",
  payable: "",
  serviceTypeNo: "",
  ratio: "",
  sessionWeekly: "",
  billingRate: "",
};

const validationSchema = Yup.object({
  sessionAmount: Yup.string().required("required field"),
  authorizationStartDt: Yup.string().required("required field"),
  authorizationEndDt: Yup.string().required("required field"),
  authorizationNo: Yup.string(),
  billable: Yup.string().required("required field"),
  billingType: Yup.string().required("required field"),
  compensatory: Yup.string().required("required field"),
  caseNo: Yup.string(),
  sessionLength: Yup.string().required("required field"),
  note: Yup.string(),
  payable: Yup.string().required("required field"),
  serviceTypeNo: Yup.string().required("required field"),
  ratio: Yup.string().required("required field"),
  sessionWeekly: Yup.string().required("required field"),
  billingRate: Yup.string().required("required field"),
});

const info = {
  isOpen: true,
  timeout: 3000,
  text: "Assigned services cannot be edited",
  type: "error",
};

export {
  fieldConfig,
  formikInitialValues,
  validationSchema,
  headerConfig,
  info,
};
