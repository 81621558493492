import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const adminProviderSlice = createSlice({
  name: "adminProviderReducer",
  initialState: {
    serviceTypesData: [],
    providerFieldConfig: {},
    citiesList: [],
  },
  reducers: {
    setServiceTypes(state, { payload }) {
      state.serviceTypesData = payload;
    },
    setCitiesList(state, { payload }) {
      state.citiesList = payload;
    },
  },
});

const { setServiceTypes, setCitiesList } = adminProviderSlice.actions;

export default adminProviderSlice.reducer;

export const getServiceTypes = () => async (dispatch) => {
  try {
    dispatch(setServiceTypes([]));
    const response = await axios.get("/service/service-types");
    const result = response.data.map((item) => ({
      id: item.serviceTypeNo + item.typeName,
      name: String(item.serviceTypeNo),
      label: item.typeName,
      type: "checkbox",
    }));
    dispatch(setServiceTypes(result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getCitiesByState = (id, mask) => async (dispatch) => {
  try {
    const response = await axios.get(`/dictionaries/cities-by-state/${id}`, {
      params: {
        orderField: "addrCityId",
        mask,
        limit: 1999,
      },
    });
    const citiesList = [];
    response.data.result.forEach((item) => {
      citiesList.push({
        id: item.addrCityId,
        title: item.cityName,
        value: item.addrCityId,
      });
    });
    dispatch(setCitiesList({ ...response.data, citiesList }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
