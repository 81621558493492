import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

import { Button } from "../Button";
import { TextField } from "../TextField";
import SvgMaker from "../svgMaker";
import { snackbarOpen } from "../Snackbar/store/snackbar.slice";

import "./styles/index.scss";

function SecureCode({
  setShowSecureCodeModal, setShowPreview, providerId, sessionId, assignmentId,
}) {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const signSessionNote = async () => {
    if (sessionId) {
      try {
        await axios.post("/session/sign-session-note", { securityCode: value, providerId, sessionId });
        setShowSecureCodeModal(false);
        setShowPreview();
        dispatch(snackbarOpen({
          isOpen: true,
          timeout: 6000,
          text: "Session note signed successfully",
          type: "success",
        }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } else if (assignmentId) {
      try {
        const { status } = await axios.post("/session/sign-session-notes-bulk", { securityCode: value, providerId, assignmentId });
        setShowSecureCodeModal(false);
        setShowPreview();
        if (status > 199 && status < 300) {
          dispatch(snackbarOpen({
            isOpen: true,
            timeout: 6000,
            text: "Session notes signed successfully",
            type: "success",
          }));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  return (
    <div style={{
      width: 340,
      position: "fixed",
      top: 60,
      right: 5,
      backgroundColor: "#FFFFFF",
      zIndex: 2000,
    }}>
      <div style={{
        width: "100%",
        backgroundColor: "#F9F9F9",
        height: 36,
      }}>

      </div>
      <div style={{ padding: "32px 20px 30px 20px" }}>
        <span style={{
          display: "inline-block",
          width: 280,
          fontSize: 24,
          color: "#495057",
          fontWeight: 600,
        }}>
            Enter Your Security Code
        </span>
        <div style={{ paddingBottom: 47, paddingTop: 24 }}>
          <TextField
            placeholder='Enter security code'
            value={value}
            onChange={handleChange}
            isPassword
          />
        </div>
        <div className='secure-code-buttons'>
          <Button variant='contained' onClick={signSessionNote}>
            Confirm
          </Button>
          <Button variant='outlined' onClick={() => setShowSecureCodeModal(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function PfdPreviewAll({
  providerId,
  sessionId,
  setShowPreview,
  validation,
  assignmentId,
  stateSigned,
  changeState,
}) {
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [showSecureCodeModal, setShowSecureCodeModal] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [signed, setSigned] = useState(!!stateSigned);

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (assignmentId) {
      try {
        const { data } = await axios.get(`/session/assignment-session-notes-pdf-list/${assignmentId}`, {
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        setFileName(url.split("/")[url.split("/").length - 1]);
        setFile(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, []);

  const signSessionNote = async () => {
    if (assignmentId) {
      try {
        const { status, data } = await axios.post("/session/sign-session-notes-bulk", { providerId, assignmentId });
        if (status > 199 && status < 300) {
          changeState && changeState();
          setSigned(true);
          if (!data.warning) {
            dispatch(snackbarOpen({
              isOpen: true,
              timeout: 6000,
              text: "Session notes signed successfully",
              type: "success",
            }));
          } else {
            dispatch(snackbarOpen({
              isOpen: true,
              timeout: 6000,
              text: data.warning,
              type: "primary",
            }));
          }
          const response = await axios.get(`/session/assignment-session-notes-pdf-list/${assignmentId}`, {
            responseType: "arraybuffer",
          });
          const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
          setFileName(url.split("/")[url.split("/").length - 1]);
          setFile(response.data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  const getPages = () => {
    let content = [];
    for (let i = 0; i < numPages; i++) {
      content.push(<div key={`page-${i}`} style={{ padding: "20px" }}><Page pageNumber={i + 1} /></div>);
    }
    return content;
  };

  const isValid = () => ((validation) ? validation(sessionId) : true);

  const download = (data) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/")[url.split("/").length - 1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadWithOutSigned = async () => {
    if (assignmentId) {
      const { data } = await axios.get(`/session/assignment-session-notes-pdf-list/${assignmentId}`, {
        params: {
          withoutSignature: 1,
        },
        responseType: "arraybuffer",
      });
      download(data);
    }
  };

  const downloadSignedFile = () => {
    if (file) {
      download(file);
    }
  };

  const onDocumentLoadSuccess = (d) => {
    setNumPages(d.numPages);
  };

  return (
    <>
      <div className="react-pdf__header">
        {showSecureCodeModal
          && <SecureCode
            providerId={providerId}
            sessionId={sessionId}
            assignmentId={assignmentId}
            setShowPreview={setShowPreview}
            setShowSecureCodeModal={setShowSecureCodeModal}
          />}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }} onClick={setShowPreview}>
            <SvgMaker name='leftArrow' fill='#ffffff'/>
          </div>
          <div style={{ paddingLeft: 10, color: "#ffffff" }}>{fileName}.pdf</div>
        </div>
        <div className='button-preview'>
          <Button variant="outlined" onClick={() => { !signed ? downloadWithOutSigned() : downloadSignedFile(); } }>
            Download all
          </Button>
          <Button
            variant={ !signed ? "contained" : "outlined" }
            onClick={
              () => { !signed ? isValid() && signSessionNote() : downloadWithOutSigned(); }
            }
          >
            {!signed ? "Sign" : "Download without signature"}
          </Button>
        </div>

      </div>
      <div className="react-pdf__background"/>
      <div className="react-pdf__body">
        <div>
          {file && <Document
            file={file}
            options={{ useWorkerFetch: false }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {getPages()}
          </Document>}
        </div>
      </div>
    </>
  );
}
