import React from "react";
import "./styles/index.scss";
import { config } from "./config";
import HeaderPage from "../../components/HeaderPage";
import DictItem from "./components/DictItem";

function Dicts() {
  return (
    <div>
      <HeaderPage title="Banks of Values" />
      <div className="dict">
        {config.map((item) => <DictItem key={item.id} {...item} />)}
      </div>
    </div>
  );
}

export default Dicts;
