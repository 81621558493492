import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import ProviderProfileComponent from "../../components/ProviderProfileComponent";
import { getServiceTypes } from "../store";
import { getProvidersByIdAction } from "../providerProfile/store";
import { updateProviderProfile } from "./store";

import { fieldConfig, validationShema } from "../configs";
import { admin_providers_url, admin_provider_profile_url, admin_system_users_url } from "../../../../../configs/router_urls";

function UpdateProviderProfile() {
  const history = useHistory();
  const params = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();

  const data = useSelector(({ providersProfileReducer }) => providersProfileReducer.data);
  const serviceTypesData = useSelector(({ adminProviderReducer }) => adminProviderReducer.serviceTypesData);
  const cities = useSelector(({ adminProviderReducer }) => adminProviderReducer.citiesList);
  const dictionaries = useSelector(({ adminReducer }) => adminReducer.providerDict);
  const { name } = useSelector(({ systemUsersReducer }) => systemUsersReducer.providerInformation);

  const [config, setConfig] = useState(fieldConfig);
  const [formikValues, setFormikValues] = useState(null);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    dispatch(getServiceTypes(fieldConfig));
    dispatch(getProvidersByIdAction(params.id));
  }, []);

  const updateProvider = (values) => {
    let { annualRate, payrollRate, ...props } = values;
    const data = { ...props };
    data.payrollRate = Number(payrollRate).toFixed(2);
    if (annualRate !== "") data.annualRate = Number(annualRate).toFixed(2);
    setShowPrompt(false);
    const serviceTypesArr = [];

    Object.keys(values).forEach((item) => {
      if (+item) {
        serviceTypesArr.push({
          id: +item,
          state: values[item],
        });
      }
    });
    if (typeof values.note === "string") data.note = values.note.trim();

    dispatch(updateProviderProfile({
      ...data,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      prProviderServices: serviceTypesArr,
      addrCityId: values.addrCityIdPrv,
      addrStateId: values.addrStateIdPrv,
      address: values.address.trim(),
    }, () => {
      if (!name) {
        history.push(`${admin_provider_profile_url}/${params.id}`);
      } else history.push(admin_system_users_url);
    }));
  };

  useEffect(() => {
    if (serviceTypesData.length && data && Object.keys(dictionaries)) {
      setFormikValues(() => {
        const obj = { ...data };
        const serviceTypes = data.prProviderServices.reduce((a, c) => {
          a[c.serviceTypeNo] = true;
          return a;
        }, {});
        serviceTypesData.forEach((item) => {
          obj[item.name] = !!serviceTypes[item.name];
        });
        return obj;
      });
      const leftSide = fieldConfig.leftSide.map((item) => {
        if (dictionaries[item.beName]) return ({ ...item, data: dictionaries[item.beName] });
        if (cities[item.beName]) return ({ ...item, data: cities[item.beName] });
        return item;
      });
      setConfig((prevConfig) => ({
        ...prevConfig,
        rightSide: [
          ...prevConfig.rightSide.map((item) => {
            if (item.type === "checkboxesContainer") return ({ ...item, data: serviceTypesData });
            if (dictionaries[item.beName]) return ({ ...item, data: dictionaries[item.beName] });
            return item;
          }),
        ],
        leftSide,
      }));
    }
  }, [serviceTypesData, data, dictionaries, cities]);

  useEffect(() => {
    window.addEventListener("click", () => { setShowPrompt(true); });
  }, [showPrompt]);

  return (

    !!serviceTypesData.length && formikValues && <div>
      <HeaderPage
        title='Edit Provider Profile' // for Admin -> "All providers"
        rootPathName={admin_providers_url}
        rootTitleName='All Providers'
        childrenTitleNames={["Provider Profile", "Edit Provider Profile"]}
        childrenPathNames={[`${admin_provider_profile_url}/${params.id}`]}
      />
      <div className='provider_profile__container'>
        <div>
          <Paper papertitle='Edit personal information' iconName='closeIcon' changeMode={() => history.push(`${admin_provider_profile_url}/${params.id}`)}>
            <ProviderProfileComponent
              formRef={formRef}
              validationShema={validationShema}
              fieldConfig={config}
              formikInitialValues={formikValues}
              onSubmit={updateProvider}
              valueIsChange={valueIsChange}
              setValueIsChange={setValueIsChange}
              showPrompt={showPrompt}
              edit={true}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default UpdateProviderProfile;
