import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../../components/Button";
import { TextField } from "../../../../components/TextField";
import Table from "../../../../components/Table/Table";
import {
  changeAssignmentStatusAction,
  getProviderStudentsListAction,
  setProviderStudentsList,
} from "./store";
import tableFormater from "../../../../utils/tableFormater";
import HeaderPage from "../../../../components/HeaderPage";
import { dateFormat } from "../../../../utils/dateFormater";
import Pagination from "../../../../components/Pagination";
import { getStudentOptionsAction } from "../../../../pages/calendar/session/sessionForm/store";

import "./style/style.scss";

import {
  provider_progress_report_list_url,
  provider_student_invoices_url,
  student_calls_log_url,
  provider_student_profile_url,
  provider_student_documents_url,
  provider_students_assigment_view_url,
  provider_enter_session_url,
} from "../../../../configs/router_urls";
import { headerConfig } from "./config";

const ProviderStudentsList = () => {
  const table = useRef();
  const dispatch = useDispatch();
  const data = useSelector(
    ({ providerStudentsListReducer }) => providerStudentsListReducer.students,
  );
  const user = useSelector(
    ({ authorizationReducer }) => authorizationReducer.user,
  );
  const [activeState, setActiveState] = useState(["F"]);
  const [isButtonsShow, setButtonsFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (searchValue === "") {
      dispatch(getStudentOptionsAction((providerId) => {
        dispatch(
          getProviderStudentsListAction({
            providerId,
            status: activeState,
          }),
        );
      }));
    } else if (searchValue.length >= 3 && searchValue.length <= 15) {
      dispatch(getStudentOptionsAction((providerId) => {
        dispatch(
          getProviderStudentsListAction({
            providerId,
            status: activeState,
            searchValue,
          }),
        );
      }));
    }
    setCurrentIndex(0);
  }, [activeState, searchValue]);

  const filterStudents = (type) => {
    dispatch(setProviderStudentsList({ result: [] }));
    setActiveState(type);
    if (type.includes("S")) {
      setButtonsFlag(true);
    } else {
      setButtonsFlag(false);
    }
  };

  const onChangeHandler = (event) => {
    setSearchValue(event.target.value);
  };

  const onAcceptHandler = (id) => {
    dispatch(
      changeAssignmentStatusAction(
        { id, status: ["F"] },
        () => dispatch(
          getProviderStudentsListAction({ providerId: user.providerId, status: ["S"] }),
        ),
      ),
    );
  };

  const onRejectHandler = (id) => {
    dispatch(
      changeAssignmentStatusAction(
        { id, status: ["R"] },
        () => dispatch(
          getProviderStudentsListAction({ providerId: user.providerId, status: ["S"] }),
        ),
      ),
    );
  };

  const paginationClick = (index) => {
    dispatch(
      getProviderStudentsListAction({
        providerId: user.providerId,
        status: activeState,
        offset: index,
      }),
    );
    table.current.scrollTop = 0;
  };

  return (
    <>
      <HeaderPage title="Students" rootTitleName="Students" />
      <div className="provider-students-list">
        <div className="provider-students-list-header">
          <Button
            color={activeState.includes("F") ? "primary" : "secondary"}
            onClick={() => { filterStudents(["F"]); setCurrentIndex(0); }}
          >
            Active
          </Button>
          <Button
            color={activeState.includes("S") ? "primary" : "secondary"}
            onClick={() => { filterStudents(["S"]); setCurrentIndex(0); }}
          >
            Pending
          </Button>
          <Button
            color={activeState.includes("C", "R", "A") ? "primary" : "secondary"}
            onClick={() => { filterStudents(["C", "R", "A"]); setCurrentIndex(0); }}
          >
            Inactive
          </Button>
          <div className="provider__textfield">
            <TextField
              name="goalSearch"
              placeholder="Search"
              value={searchValue}
              onChange={onChangeHandler}
              startIcon={{ name: "searchIcon" }}
            />
          </div>
        </div>
        <Table
          head={Object.values(headerConfig)}
          data={tableFormater(data.result.map((item) => ({
            ...item,
            studentName: <div className="itemBold">{item.studentName}</div>,
            birthDt: dateFormat("mm-dd-yyyy", item.birthDt),
            startDt: (
              <div className="itemBold">
                {dateFormat("mm-dd-yyyy", item.startDt)}
              </div>
            ),
            endDt: (
              <div className="itemBold">
                {dateFormat("mm-dd-yyyy", item.endDt)}
              </div>
            ),
          })),
          headerConfig)}
          hasAcceptButton={isButtonsShow}
          onAccept={onAcceptHandler}
          onReject={onRejectHandler}
          isContext={true}
          contextData={[
            {
              title: "Assignment",
              link: provider_students_assigment_view_url.split("/:")[0],
            },
            {
              title: "Call log",
              link: student_calls_log_url.split("/:")[0],
            },
            {
              title: "Profile",
              link: provider_student_profile_url.split("/:")[0],
            },
            {
              title: "Documents",
              link: provider_student_documents_url.split("/:")[0],
            },
            {
              title: "Progress reports",
              link: provider_progress_report_list_url.split("/:")[0],
            },
            {
              title: "Invoices",
              link: provider_student_invoices_url,
            },
            {
              title: "Enter session",
              link: provider_enter_session_url,
            },
          ]}
          customData="reportList"
          typeOfTable="Provider students"
          tableRef={table}
        />

        <Pagination
          {...data.pagination}
          onClick={paginationClick}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </div>
    </>
  );
};

export default ProviderStudentsList;
