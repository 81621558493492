import { idGenerator } from "./idGenerator";

function tableFormater(data, headerConfig, priorityField) {
  const bodyData = [];
  data.forEach((item) => {
    const values = [];
    Object.keys(item).forEach((el) => {
      if (headerConfig[el]) {
        values.push({
          id: idGenerator(12),
          text: item[el],
          name: el,
          order: headerConfig[el].order,
        });
      }
    });

    bodyData.push({
      id: priorityField ? item[priorityField] : item.reportId || item.parentCallId || item.assignmentId || item.serviceId || item.goalId || item.studentId || item.providerId || item.schoolRepresentativeId || item.schoolId || item.id || item.invoiceId || item.messageId || item.id,
      modalData: item,
      cols: [...values].sort((a, b) => a.order - b.order),
    });
  });
  return bodyData;
}

export default tableFormater;
