export const getEntitie = (roles = []) => {
  if (roles[0] === 1) {
    return "super_admin";
  }
  if (roles[0] === 2) {
    return "admin";
  }
  if (roles[0] === 4) {
    return "provider";
  }
};
