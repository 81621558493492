import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/Table";
import Pagination from "../../../../../components/Pagination";

import tableFormater from "../../../../../utils/tableFormater";
import { dateFormat } from "../../../../../utils/dateFormater";
import HeaderPage from "../../../../../components/HeaderPage";
import { headerConfig } from "./config";
import { getAllCallsLog } from "./store";

function CallLog() {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = useSelector(({ allCallsLogReducer }) => allCallsLogReducer.data);
  useEffect(() => {
    dispatch(getAllCallsLog());
  }, []);
  const paginationClick = (offset) => {
    dispatch(getAllCallsLog(offset));
  };

  return (
    <>
      <HeaderPage title="Call log" />
      <Table
        withoutOption
        head={Object.values(headerConfig)}
        data={tableFormater(data.result.map((item) => ({ ...item, callDt: dateFormat("mm-dd-yyyy", item.callDt) })), headerConfig)}
      />
      <Pagination
        {...data.pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />

    </>
  );
}

export default CallLog;
