import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  configHeadTable, formikInitialValues, validationSchema, validationSchemaForEdit,
} from "./config";
import { banks_of_values_url } from "../../../../configs/router_urls";
import FormikButton, { Button } from "../../../../components/Button";
import FormikTextField from "../../../../components/TextField";
import HeaderPage from "../../../../components/HeaderPage";
import Table from "../../../../components/Table/Table";
import tableFormater from "../../../../utils/tableFormater";
import {
  getSchoolsAction, editSchoolAction, addNewSchoolAction, deleteSchoolAction, clearedSchoolAction,
} from "./store";
import Pagination from "../../../../components/Pagination/Pagination";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { PaperModal } from "../../../../components/modal/Modal";
import "./styles/index.scss";

function School() {
  const table = useRef();
  const dispatch = useDispatch();
  const [openModalDeleteSchool, setOpenModalDeleteSchool] = useState(false);
  const [openModalEditSchool, setOpenModalEditSchool] = useState(false);
  const [openModalAddSchool, setOpenModalAddSchool] = useState(false);
  const [schoolNameForEdit, setSchoolNameForEdit] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [classNameModalWindow, setClassNameModalWindow] = useState("");
  const [schoolID, setSchoolID] = useState(null);
  const [schoolIdForDelete, setSchoolIdForDelete] = useState();
  const [state, setState] = useState(0);

  const { result: data, pagination } = useSelector(
    ({ schoolsReducer }) => schoolsReducer.schools,
  );

  const paginationClick = (offset) => {
    dispatch(getSchoolsAction(offset));
    table.current.scrollTop = 0;
    setState(0);
  };

  const onDelete = (id) => {
    setState(0);
    setSchoolIdForDelete(data?.find((el) => el.schoolId === id)?.schoolId);
    setOpenModalDeleteSchool(true);
  };

  const onClose = () => {
    if (valueIsChange === true) {
      setConfirmModal(true);
      setClassNameModalWindow("hide");
    } else {
      setOpenModalEditSchool(false);
      setOpenModalAddSchool(false);
      setState(0);
    }
  };

  const onEdit = (id) => {
    let schoolNameById = data?.find((el) => el.schoolId === id)?.schoolName;
    setState(0);
    setSchoolID(id);
    setSchoolNameForEdit(schoolNameById);
    setOpenModalEditSchool(true);
    setValueIsChange(false);
  };

  const setDefault = () => {
    setConfirmModal(false);
    setOpenModalEditSchool(false);
    setOpenModalAddSchool(false);
    setClassNameModalWindow("show");
  };

  const saveEditSchool = (values) => {
    dispatch(editSchoolAction({ schoolId: schoolID, schoolName: values?.schoolNameForEdit.trim() }));
    setOpenModalEditSchool(false);
    setCurrentIndex(0);
  };
  const saveNewSchool = (value) => {
    dispatch(addNewSchoolAction({ schoolName: value?.schoolName.trim() }, () => {
      setOpenModalAddSchool(false);
      setCurrentIndex(0);
    }));
    setOpenModalAddSchool(true);
  };

  const deleteSchool = (id) => {
    dispatch(deleteSchoolAction(id));
    setOpenModalDeleteSchool(false);
    setCurrentIndex(0);
  };

  useEffect(() => {
    dispatch(clearedSchoolAction());
    dispatch(getSchoolsAction());
  }, []);

  useEffect(() => {
    valueIsChange && setState(0);
  }, [valueIsChange]);

  return (
    <div className="school">
      <HeaderPage
        title="Schools"
        rootPathName={banks_of_values_url}
        rootTitleName="Banks of Values"
        childrenTitleNames={["Schools"]}
      />
      <div className="school__btn">
        <div style={{ marginRight: "auto" }}>
          <Button variant="contained" onClick={() => { setOpenModalAddSchool(true); setValueIsChange(false); setState(0); }}>
            Add new school
          </Button>
        </div>
      </div>
      {data && (
        <Table
          head={Object.values(configHeadTable)}
          state={state}
          setState={setState}
          data={tableFormater(data, configHeadTable)}
          onDelete={onDelete}
          onEdit={onEdit}
          isContrast
          tableRef={table}
        />
      )}

      <Pagination {...pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />

      <ConfirmModal
        headerTitle="Are you sure deleting the school?"
        open={openModalDeleteSchool}
      >
        <Button variant="contained" color="primary" onClick={() => deleteSchool(schoolIdForDelete)}>
          Yes, Delete
        </Button>
        <Button
          variant="dashed"
          color="primary"
          onClick={() => {
            setOpenModalDeleteSchool(false);
          }}
        >
          Cancel
        </Button>
      </ConfirmModal>
      <div className={classNameModalWindow}>
        <PaperModal
          headerTitle="Edit School"
          open={openModalEditSchool}
          onClose={onClose}
        >
          <Formik initialValues={{ schoolNameForEdit }} validationSchema={validationSchemaForEdit} onSubmit={(values) => { saveEditSchool(values); setCurrentIndex(0); }} >
            {({ dirty }) => (
              <>
                <div className="school__schoolNameField">
                  <FormikTextField name="schoolNameForEdit" label="Name" required/>
                </div>
                <FormikButton variant='contained' color='primary' disabled={!dirty}>
                  Save
                </FormikButton>
                {dirty === true ? setValueIsChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModal>
      </div>
      <div className={classNameModalWindow}>
        <PaperModal
          headerTitle="Add New School"
          open={openModalAddSchool}
          onClose={onClose}
        >
          <Formik initialValues={formikInitialValues} validationSchema={validationSchema} onSubmit={(values) => { saveNewSchool(values); }}>
            {({ dirty, values }) => <>
              <div className="school__schoolNameField">
                <FormikTextField name="schoolName" label="Name" required/>
              </div>
              <FormikButton variant='contained' color='primary' disabled={!values.schoolName}>
                Create
              </FormikButton>
              {dirty === true ? setValueIsChange(true) : null}
            </>
            }
          </Formik>
        </PaperModal>
      </div>
      <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
        <div>
          <Button variant="contained" color="primary" onClick={setDefault}>
            Discard
          </Button>
          <Button
            variant="default"
            color="secondary"
            onClick={() => { setClassNameModalWindow("show"); setConfirmModal(false); }}
          >
            Cancel
          </Button>
        </div>
      </ConfirmModal>
    </div>
  );
}

export default School;
