import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { Formik } from "formik";

import FormikTextField from "../../../../components/TextField";
import FormikDateField from "../../../../components/DateField";
import HeaderPage from "../../../../components/HeaderPage";
import { PaperModal } from "../../../../components/modal/Modal";
import PaperModalCallLog from "./components/modal/PaperModalCallLog";
import FormikButton, { Button } from "../../../../components/Button";
import Table from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";

import tableFormater from "../../../../utils/tableFormater";
import { dateFormat } from "../../../../utils/dateFormater";

import "./styles/index.scss";

import {
  getStudentCallLog, deleteCallLogById, addNewCallLog, editCallLogById,
} from "./store";
import { getProviderStudentsListAction } from "../providerStudentsList/store";
import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";
import { getStudentOptionsAction } from "../../../../pages/calendar/session/sessionForm/store";

import { provider_students_assigment_url } from "../../../../configs/router_urls";
import {
  headerConfig, fieldConfig, formikInitialValues, validationSchema,
} from "./config";

function StudentCallLogs() {
  const dispatch = useDispatch();
  const params = useParams();

  const [studentName, setStudentName] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [assignmentPeriod, setAssignmentPeriod] = useState("");
  const [modalForAddNewCall, setModalForAddNewCall] = useState(false);
  const [modalForEditCall, setModalForEditCall] = useState(false);
  const [modalForDeleteCall, setModalForDeleteCall] = useState({ state: false, id: null });
  const [confirmModal, setConfirmModal] = useState(false);
  const [valueChange, setValueChange] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [className, setClassName] = useState("callLog__open");
  const [state, setState] = useState(0);
  const [initialValues, setInitialValues] = useState({});

  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  const data = useSelector(({ studentCallLogReducer }) => studentCallLogReducer.data);
  useEffect(() => {
    dispatch(getAssignmentByIdAction(params.id, (response) => {
      setStudentName(response.studentName);
      setAssignmentPeriod(`${dateFormat("mm-dd-yyyy", response.startDt)} to ${dateFormat("mm-dd-yyyy", response.endDt)}`);
    }));
  }, []);

  useEffect(() => {
    dispatch(getStudentOptionsAction((providerId) => dispatch(getProviderStudentsListAction({ providerId, status: ["F", "S", "R"] }, (response) => {
      setServiceType(response?.find((el) => +el.assignmentId === +params.id)?.typeName);
    }))));

    dispatch(getStudentCallLog(params.id));
  }, []);

  const paginationClick = (offset) => {
    dispatch((getStudentCallLog(params.id, offset)));
  };

  const onClose = () => {
    if (valueChange) {
      setConfirmModal(true);
      setClassName("callLog__hide");
    } else {
      setModalForAddNewCall(false);
      setModalForEditCall(false);
      setClassName("callLog__open");
      setState(0);
    }
  };

  const onDiscard = () => {
    setConfirmModal(false);
    setModalForAddNewCall(false);
    setModalForEditCall(false);
    setClassName("callLog__open");
    setValueChange(false);
    setState(0);
  };

  return (
    <>
      <HeaderPage title={`Call log ${studentName} ${serviceType} ${assignmentPeriod}`}
        rootPathName={provider_students_assigment_url}
        rootTitleName='Students'
        childrenTitleNames={["Call log"]}/>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
      }}
      >
        <div style={{ marginRight: "auto" }}>
          <Button
            variant='contained'
            onClick={() => {
              setModalForAddNewCall(true);
              setValueChange(false);
            }
            }
          >
            Add new call
          </Button>
        </div>
      </div>
      <Table
        head={Object.values(headerConfig)}
        data={tableFormater(data.result.map((item) => ({ ...item, callDt: dateFormat("mm-dd-yyyy", item.callDt) })), headerConfig)}
        state={state}
        setState={setState}
        onDelete={(id) => setModalForDeleteCall({ state: true, id })}
        onEdit={(id) => {
          setInitialValues(data.result?.find((el) => el.parentCallId === id));
          setModalForEditCall(true);
        }
        }
      />
      <Pagination
        {...data.pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <div className={className}>
        <PaperModalCallLog headerTitle="Add New Call" open={modalForAddNewCall} onClose={onClose}>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(addNewCallLog(
                {
                  assignmentId: +params.id,
                  callDt: format(parseISO(dateFormat("yyyy-mm-dd", values.callDt)), "yyyy-MM-dd'T'HH:mm:ssXXX", "UTC"),
                  note: values.note.trim(),
                }, params.id,
              ));
              setModalForAddNewCall(false);
              setCurrentIndex(0);
              setValueChange(false);
            }
            }
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "40px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <div className="select__label">
                          <div className="callLog__label">{assignment.studentName}</div>
                          <div className="callLog__label">{students?.find((el) => el.assignmentId === +params.id)?.typeName}</div>
                          <div className="callLog__label">{dateFormat("mm-dd-yyyy", assignment.startDt)} to {dateFormat("mm-dd-yyyy", assignment.endDt)}</div>
                        </div>
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikDateField {...item}/>
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <FormikButton variant="contained" color="primary">Add</FormikButton>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModalCallLog>
      </div>

      <div className={className}>
        <PaperModalCallLog headerTitle="Edit Call" open={modalForEditCall} onClose={onClose}>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initialValues, callDt: dateFormat("mm-dd-yyyy", initialValues?.callDt), assignmentId: params.id }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(editCallLogById(
                {
                  ...values,
                  assignmentId: +params.id,
                  callDt: format(parseISO(dateFormat("yyyy-mm-dd", values.callDt)), "yyyy-MM-dd'T'HH:mm:ssXXX", "UTC"),
                  note: values.note.trim(),
                }, params.id,
              ));
              setModalForEditCall(false);
              setValueChange(false);
              setState(0);
              setCurrentIndex(0);
            }}
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "40px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div style={{ marginTop: "4px", marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <div className="select__label">
                          <div className="callLog__label">{assignment.studentName}</div>
                          <div className="callLog__label">{students?.find((el) => el.assignmentId === +params.id)?.typeName}</div>
                          <div className="callLog__label">{dateFormat("mm-dd-yyyy", assignment.startDt)} to {dateFormat("mm-dd-yyyy", assignment.endDt)}</div>
                        </div>
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <FormikButton variant="contained" color="primary" disabled={!dirty}>Save</FormikButton>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModalCallLog>
      </div>

      <PaperModal headerTitle="Are you sure deleting the call log?"
        open={modalForDeleteCall.state}
        onClose={() => {
          setModalForDeleteCall({ state: false });
          setState(0);
        }
        }>
        <div style={{ marginTop: "20px" }} onClick={() => {
          dispatch(deleteCallLogById(modalForDeleteCall.id, params.id));
          setModalForDeleteCall({ state: false, id: null });
          setCurrentIndex(0);
        }}
        >
          <Button variant="contained" color="primary">
            Yes, Delete
          </Button>
        </div>
        <Button variant="dashed" color="primary" onClick={() => {
          setModalForDeleteCall({ state: false, id: null });
          setState(0);
        }
        }>
            Cancel
        </Button>
      </PaperModal>

      <PaperModal headerTitle="Discard unsaved changes?"
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false);
          setClassName("callLog__open");
        }}
      >
        <div style={{ marginTop: "20px" }}/>
        <div onClick={onDiscard}>
          <Button variant="contained" color="primary">
            Discard
          </Button>
        </div>
        <Button variant="dashed" color="primary"
          onClick={() => {
            setConfirmModal(false);
            setClassName("callLog__open");
          }}>
            Cancel
        </Button>
      </PaperModal>

    </>
  );
}

export default StudentCallLogs;
