import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { getDate } from "../../../../functions/calendar_func";
import EventTooltip from "./eventTooltip";
import { getSessionNotesAction } from "../../session/sessionForm/store";

import "./style.scss";

import { session_notes_url, edit_session_url, create_session_url } from "../../../../configs/router_urls";

const EventItem = ({
  item, isSunday, soonest, elemWidth, position,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [hovered, setIsHovered] = useState(false);
  const [coordinate, setCoordinate] = useState(null);

  const elem = useRef();

  const getMarkersHtml = () => {
    let renderMarkers = [];
    const newItemStates = item.sessions.reduce((arr, item) => {
      if (arr.includes(item.state)) {
        return arr;
      }

      arr.push(item.state);

      return arr;
    }, []);

    if (newItemStates.includes("S")) {
      renderMarkers[0] = "S";
    }
    if (newItemStates.includes("C")) {
      renderMarkers[1] = "C";
    }
    if (newItemStates.includes("A")) {
      renderMarkers[2] = "A";
    }
    if (newItemStates.includes("I")) {
      renderMarkers[0] = "I";
    }
    if (moment().valueOf() < moment(item.actualEndDt).valueOf()) {
      renderMarkers = [""];
    }

    return renderMarkers.map((item, index) => {
      if (item === "S") return <span key={index} className='markers-item markers-not-completed'/>;
      if (item === "A") return <span key={index} className='markers-item markers-signed'/>;
      if (item === "I") return <span key={index} className='markers-item markers-invoiced'/>;
      return <span key={index} className='markers-item markers-not-signed'/>;
    });
  };

  function getClass() {
    if (soonest) {
      return "calendar_month-event_list-active";
    }
    if (moment(item.actualEndDt).valueOf() < moment().valueOf()) {
      return "calendar_month-event_list-item";
    }
    if (moment(item.actualStartDt).valueOf() > moment().valueOf()) {
      return "calendar_month-event_list-today_event";
    }

    return "calendar_month-event_list-today_event";
  }

  useEffect(() => {
    setCoordinate({
      top: elem.current.getBoundingClientRect().top,
      left: elem.current.getBoundingClientRect().left,
      width: window.screen.width,
    });
  }, [hovered]);

  function mouseEnter() {
    elem.current.scrollIntoView({ block: "nearest", behavior: "smooth" });

    setIsHovered(true);
  }

  return (
    <div
      ref={elem}
      className={getClass()}
      onMouseEnter={() => mouseEnter()}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (item.state === "R") return history.push(edit_session_url, item);
        if (item.state !== "R") return history.push(`${session_notes_url}/${item.eventId}`, item);
        dispatch(getSessionNotesAction(item.eventId, () => {
          history.push(`${session_notes_url}/${item.eventId}`, item);
        }));
      }}
    >
      <span className="title">{item?.sessions[0]?.studentName}</span>

      <span className="time">{getDate(item?.actualStartDt, "hh:mm A")}</span>

      <EventTooltip
        data={item}
        show={hovered}
        isSunday={isSunday}
        coordinate={coordinate}
        position={position}
        elemWidth={elemWidth}
      />

      {
        item?.sessions.length > 1
          ? <div className="count">
            {`+${item?.sessions.length - 1}`}
          </div>
          : null
      }

      <div className='markers'>
        {getMarkersHtml()}
      </div>
    </div>
  );
};

const EventList = (props) => {
  const {
    data,
    weekDay,
    goToNotes,
    soonest,
    onEmptyDay,
    position,
  } = props;
  const [height, setHeight] = useState(null);
  const [styleHeight, setStyleHeight] = useState("100px");
  const [elemWidth, setElemWidth] = useState(null);

  const list = useRef();
  const wrapper = useRef();

  const history = useHistory();

  useEffect(() => {
    setHeight(list.current.getBoundingClientRect().height);
    setElemWidth(Math.round(wrapper.current.getBoundingClientRect().width));
  });

  useEffect(() => {
    if (data.length <= 7) {
      setStyleHeight(`${270 - height}px`);
    }
    if (data.length === 7 || data.length > 7) {
      setStyleHeight("0");
    }
    if (data.length === 0) {
      setStyleHeight("270px");
    }
  });

  return (
    <div
      key={data.id}
      className="calendar_month-event_list custom-scroll"
      ref={wrapper}
    >
      <div
        onClick={onEmptyDay}
        ref={list}
      >
        {data.map((day_event, index) => {
          if (day_event.eventId === soonest) {
            return (
              <EventItem
                key={index}
                item={day_event}
                isSunday={weekDay === 0}
                goToNotes={goToNotes}
                soonest={day_event.eventId === soonest}
                position={position}
                elemWidth={elemWidth}
              />
            );
          }

          return (
            <EventItem
              key={index}
              item={day_event}
              isSunday={weekDay === 0}
              goToNotes={goToNotes}
              position={position}
              elemWidth={elemWidth}
            />);
        })}
      </div>

      <div
        className={"empty"}
        style={{
          height: styleHeight,
          marginBottom: data.length === 7 || data.length > 7 ? "0" : "17px",
          marginTop: data.length === 7 || data.length > 7 ? "0" : "24px",
        }}
        onClick={() => history.push(create_session_url)}
      />
    </div>
  );
};

export default EventList;
