import React from "react";
import PropTypes from "prop-types";
import { CheckBox } from "../../Checkbox";

const TableHead = ({
  head, hasAcceptButton, hasCheckboxes, isAllChecked, checkAll = () => { },
}) => (
  <div className="table__head">
    {head.map(({ id, title }, index) => (
      <div key={index} className="table__th ">{title}</div>
    ))}
    {hasAcceptButton && <div key="buttons" className="table__th" style={{ minWidth: "190px" }}><br/></div>}
    {hasCheckboxes ? <div key="buttons" className="table__th" style={{ maxWidth: "60px" }}><CheckBox nativeChange={(e) => checkAll(e.target.checked)} checked={isAllChecked} /></div> : null}
  </div>
);

TableHead.propTypes = {
  head: PropTypes.array,
  hasAcceptButton: PropTypes.bool,
  hasCheckboxes: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  checkAll: PropTypes.func,
};

export default TableHead;
