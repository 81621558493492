import * as Yup from "yup";

const fieldConfig = [
  {
    id: "as1",
    name: "studentId",
    label: "Student",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: "as2",
    name: "serviceId",
    label: "Service",
    placeholder: "",
    required: true,
    disabled: true,
    type: "select",
    data: [],
    info: {
      isOpen: true,
      timeout: 2000,
      text: "Enter Student first, please",
      type: "information",
    },
  },
  {
    id: "as3",
    name: "providerId",
    label: "Provider",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: "as4",
    type: "text",
    name: "mandate",
    text: "Weekly mandate:",
    template: "Weekly mandate:",
  },
  {
    id: "as5",
    type: "empty",
  },
  {
    id: "as6",
    name: "timePeriod",
    label: "Time period",
    placeholder: "",
    type: "datefield",
    required: true,
    isRange: true,
  },
  {
    id: "as7",
    name: "makeupAllowed",
    label: "Makeup sessions allowed",
    type: "checkbox",
  },
  {
    id: "as123",
    type: "empty",
  },

  {
    id: "as10",
    name: "sessionAmount",
    label: "Amount of session",
    placeholder: "",
    required: true,
    type: "textfield",
    textfieldType: "textfield",
  },
  {
    id: "as8",
    name: "partial",
    label: "Partial",
    type: "checkbox",
  },
  {
    id: "as11",
    name: "payrollRate",
    label: "Provider payroll rate",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: "as12",
    type: "empty",
  },
  {
    id: "as13",
    type: "empty",
  },
  {
    id: "as22",
    type: "empty",
  },
  {
    id: "as23",
    type: "empty",
  },
  {
    id: "as16",
    name: "substitute",
    label: "Substitute",
    type: "checkbox",
  },
  {
    id: "as17",
    type: "empty",
  },
  {
    id: "as18",
    type: "empty",
  },
  {
    id: "as181",
    type: "empty",
  },
  {
    id: "as182",
    type: "empty",
  },
  {
    id: "as19",
    name: "altProviderId",
    label: "For provider",
    placeholder: "",
    type: "select",
    data: [],
  },
  {
    id: "as20",
    type: "text",
    text: "Allowable sessions: Value",
  },
  {
    id: "as21",
    type: "empty",
  },
  {
    id: "as22",
    type: "empty",
  },
  {
    id: "as221",
    type: "empty",
  },
  {
    id: "as23",
    name: "note",
    label: "Comments",
    placeholder: "",
    textArea: true,
    type: "textfield",
  },
  {
    id: "as24",
    type: "empty",
    class: "gc3",
  },
  {
    id: "as25",
    type: "empty",
  },
  {
    id: "as127",
    type: "empty",
  },
  {
    id: "as124",
    type: "empty",
  },
  {
    id: "as126",
    type: "empty",
  },
  {
    id: "as128",
    type: "empty",
  },
  {
    id: "as129",
    type: "empty",
  },
  {
    id: "as130",
    type: "empty",
  },
  {
    id: "as131",
    type: "empty",
  },
  {
    id: "as132",
    type: "empty",
  },
  {
    id: "as133",
    type: "empty",
  },
  {
    id: "as134",
    type: "empty",
  },
  {
    id: "as135",
    type: "button",
  },
];

const formikInitialValues = {
  studentId: "",
  serviceId: "",
  providerId: "",
  altProviderId: "",
  timePeriod: "",
  payrollRate: "",
  substitute: false,
  partial: false,
  sessionAmount: "",
  makeupAllowed: true,
  note: "",
};

const validationShema = Yup.object({
  studentId: Yup.number().required("Required field").typeError("Must be a number"),
  serviceId: Yup.number().required("Required field").typeError("Must be a number"),
  providerId: Yup.number().required("Required field").typeError("Must be a number"),
  altProviderId: Yup.number().test("altProviderId", "Required field", (v, c) => {
    if (c.parent.substitute) {
      if (typeof v === "undefined") return false;
    }
    return true;
  }),
  payrollRate: Yup
    .number()
    .typeError("Must be a number")
    .moreThan(0, "Payroll rate should be more than 0.01")
    .lessThan(1000, "Payroll rate should be less than 1000.00")
    .required("Required field"),
  timePeriod: Yup.string().required("Required field"),
  substitute: Yup.bool().required("Required field"),
  partial: Yup.bool(),
  sessionAmount: Yup
    .number()
    .typeError("Must be a number")
    .integer("Amount of session number must be an integer")
    .moreThan(0, "Amount of session should be more than 0")
    .lessThan(32768, "Amount of session should be less than 32768")
    .required("Required field"),
  makeupAllowed: Yup.bool().required(),
  note: Yup.string().max(2000, "Comments max length should be 2000 characters"),
});

export {
  fieldConfig, formikInitialValues, validationShema,
};
