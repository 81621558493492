import * as Yup from "yup";

const fieldConfig = {
  leftSide: [
    {
      id: 1,
      name: "firstName",
      label: "First name",
      placeholder: "",
      type: "textfield",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      // name: "apt",
      label: "Last name",
      placeholder: "",
      type: "textfield",
      required: true,
    },
    {
      id: 3,
      name: "ssNo",
      label: "SSN",
      placeholder: "",
      type: "textfield",
      required: true,
    },
    {
      id: 4,
      name: "apt",
      label: "APT",
      placeholder: "",
      type: "textfield",
      required: true,
    },

    {
      id: 5,
      name: "address",
      label: "Address",
      placeholder: "",
      type: "textfield",
      required: true,
    },
    {
      id: 6,
      name: "addrStateIdPrv",
      label: "State",
      placeholder: "",
      type: "select",
      required: true,
      beName: "statesList",
      data: [],
    },
    {
      id: 7,
      name: "addrCityIdPrv",
      label: "City",
      type: "search",
      placeholder: "",
      required: true,
      beName: "citiesList",
      parent: "addrStateIdPrv",
      data: [],
    },
    {
      id: 8,
      name: "addrZipcode",
      label: "ZIP",
      type: "textfield",
      placeholder: "",
      required: true,
    },
    {
      id: 9,
      name: "email",
      label: "Email",
      type: "textfield",
      placeholder: "",
      required: true,
    },
    {
      id: 10,
      name: "payrollRate",
      label: "Payroll rate",
      type: "textfield",
      placeholder: "",
      required: true,
    },
    {
      id: 27,
      name: "annualRate",
      label: "Annual salary",
      type: "textfield",
      placeholder: "",
      required: false,
    },
  ],
  rightSide: [
    {
      id: 11,
      name: "cellPhoneNo",
      label: "Cell phone",
      placeholder: "",
      type: "textfield",
    },

    {
      id: 12,
      name: "homePhoneNo",
      label: "Home phone",
      placeholder: "",
      type: "textfield",
    },

    {
      id: 13,
      name: "emergencyPhoneNo",
      label: "Emergency phone",
      placeholder: "",
      type: "textfield",
    },

    {
      id: 14,
      name: "summerAllowed",
      label: "Work in Summer",
      placeholder: "",
      type: "checkbox",
      required: true,
    },
    {
      id: 14,
      name: "payrollEntity",
      label: "Payroll entity",
      placeholder: "",
      type: "select",
      required: true,
      beName: "payrollEntities",
      data: [],
    },
    {
      id: 12345,
      name: "payrollKind",
      label: "1099/W2",
      required: true,
      mylti: true,
      type: "radio",
    },
    {
      type: "checkboxesContainer",
      id: 17,
      data: [],
    },
    {
      id: 18,
      name: "note",
      label: "Comment",
      textArea: true,
      type: "textfield",
    },
    {
      id: 19,
      name: "save",
      label: "Save",
      type: "button",
    },
    {
      id: 20,
      name: "",
      label: "Cancel",
      type: "cancelBtn",
    },
  ],
};

const formikInitialValues = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  firstName: "",
  lastName: "",
  ssNo: "",
  apt: "",
  address: "",
  addrStateId: "",
  addrStateIdPrv: "35",
  addrCityId: "",
  addrCityIdPrv: "",
  addrZipcode: "",
  email: "",
  payrollRate: "",
  cellPhoneNo: "",
  homePhoneNo: "",
  emergencyPhoneNo: "",
  summerAllowed: false,
  payrollKind: "",
  note: "",
  payrollEntity: "",
  annualRate: "",
};

const validationShema = Yup.object({
  firstName: Yup.string()
    .strict()
    .trim("Name cannot consist of spaces only")
    .max(32, "Name name max length should be 32 characters")
    .required("required field"),
  lastName: Yup.string()
    .strict()
    .trim("Surname cannot consist of spaces only")
    .max(32, "Surname max length should be 32 characters")
    .required("required field"),
  ssNo: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(16, "Ss no max length should be 16 characters")
    .required("required field"),
  apt: Yup.number()
    .typeError("Must be a number")
    .moreThan(0, "Apt should be more than 0")
    .lessThan(32768, "Apt should be less than 32768")
    .required("required field"),
  address: Yup.string()
    .strict()
    .trim("Address cannot consist of spaces only")
    .max(64, "Address max length should be 64 characters")
    .min(1, "Address min length should be 1 character")
    .required("required field"),
  addrStateIdPrv: Yup.string().required("required field"),
  addrCityIdPrv: Yup.string().required("required field"),
  addrZipcode: Yup.string()
    .required("required field")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "min length should be 5 characters")
    .max(9, "max length should be 9 characters"),
  email: Yup.string()
    .email("The email should be like example@example.com")
    .max(128, "Email max length should be 128 characters")
    .required("required field"),
  payrollRate: Yup.number()
    .typeError("Must be a number")
    .moreThan(0, "Payroll rate should be more than 0")
    .lessThan(1000, "Payroll rate should be less than 1000.00")
    .required("required field"),
  cellPhoneNo: Yup.string()
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .nullable(),
  homePhoneNo: Yup.string()
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .nullable(),
  emergencyPhoneNo: Yup.string()
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .nullable(),
  summerAllowed: Yup.bool(),
  payrollKind: Yup.string().required("required field"),
  note: Yup.string().nullable().max(2000, "max length should be 2000 characters"),
  payrollEntity: Yup.string()
    .max(128, "Agency name max length should be 128 characters")
    .required("required field"),
  annualRate: Yup.number().nullable()
    .typeError("Must be a number")
    .moreThan(0, "Annual salary should be more than 0")
    .lessThan(1000000000, "Annual salary should be less than 1000000000.00"),
});

export { fieldConfig, formikInitialValues, validationShema };
