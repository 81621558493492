import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { Formik } from "formik";

import FormikTextField from "../../../../components/TextField";
import FormikSelect from "../../../../components/Select";
import FormikDateField from "../../../../components/DateField";
import HeaderPage from "../../../../components/HeaderPage";
import FormikButton, { Button } from "../../../../components/Button";
import Table from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import PaperModalCallLog from "../studentCallLogs/components/modal/PaperModalCallLog";
import { PaperModal } from "../../../../components/modal/Modal";

import tableFormater from "../../../../utils/tableFormater";
import { dateFormat } from "../../../../utils/dateFormater";

import { getStudentOptionsAction } from "../../../../pages/calendar/session/sessionForm/store";
import {
  getProviderCallLog, addNewCallLog, deleteCallLogById, editCallLogById,
} from "./store";
import { getProviderStudentsListAction } from "../providerStudentsList/store";

import { headerConfig, validationSchema, formikInitialValues } from "./config";
import { fieldConfig } from "../studentCallLogs/config";

function ProviderCallLog() {
  const [state, setState] = useState(0);
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalForAddNewCall, setModalForAddNewCall] = useState(false);
  const [modalForEditCall, setModalForEditCall] = useState(false);
  const [modalForDeleteCall, setModalForDeleteCall] = useState({ state: false, id: null });
  const [confirmModal, setConfirmModal] = useState(false);
  const [valueChange, setValueChange] = useState(false);
  const [className, setClassName] = useState("callLog__open");
  const [initialValues, setInitialValues] = useState({});
  const user = useSelector(
    ({ authorizationReducer }) => authorizationReducer.user,
  );
  const data = useSelector(({ providerCallLogReducer }) => providerCallLogReducer.data);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  const addNewCall = (values) => {
    dispatch(addNewCallLog(
      {
        ...values,
        callDt: format(parseISO(dateFormat("yyyy-mm-dd", values.callDt)), "yyyy-MM-dd'T'HH:mm:ssXXX", "UTC"),
        note: values.note.trim(),
      }, user.providerId,
    ));
    setModalForAddNewCall(false);
    setValueChange(false);
    setCurrentIndex(0);
    setState(0);
  };
  useEffect(() => {
    dispatch(getStudentOptionsAction((providerId) => dispatch(getProviderCallLog(providerId))));
  }, []);

  useEffect(() => {
    dispatch(getStudentOptionsAction((providerId) => dispatch(getProviderStudentsListAction({ providerId, status: ["F", "S", "R"] }))));
  }, []);

  const paginationClick = (offset) => {
    dispatch(getProviderCallLog(user.providerId, offset));
  };

  const onClose = () => {
    if (valueChange) {
      setConfirmModal(true);
      setClassName("callLog__hide");
    } else {
      setModalForAddNewCall(false);
      setModalForEditCall(false);
      setClassName("callLog__open");
      setState(0);
    }
  };

  const onDiscard = () => {
    setConfirmModal(false);
    setModalForAddNewCall(false);
    setModalForEditCall(false);
    setClassName("callLog__open");
    setValueChange(false);
    setState(0);
  };

  return (
    <>
      <HeaderPage title="Call log" />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
      }}
      >
        <div style={{ marginRight: "auto" }}>
          <Button
            variant='contained'
            onClick={() => {
              setModalForAddNewCall(true);
              setValueChange(false);
            }
            }
          >
            Add new call
          </Button>
        </div>
      </div>
      <Table
        head={Object.values(headerConfig)}
        data={tableFormater(data.result.map((item) => ({ ...item, studentName: <div>{`${item.studentName} ${item.typeName} ${item.startDt} to ${item.endDt}`}</div>, callDt: dateFormat("mm-dd-yyyy", item.callDt) })), headerConfig)}
        state={state}
        setState={setState}
        onDelete={(id) => setModalForDeleteCall({ state: true, id })}
        onEdit={(id) => {
          setModalForEditCall(true);
          setInitialValues(data.result?.find((el) => el.parentCallId === id));
        }
        }
      />
      <Pagination
        {...data.pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />

      <div className={className}>
        <PaperModalCallLog headerTitle="Add New Call" open={modalForAddNewCall} onClose={onClose}>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => addNewCall(values)}
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "40px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikSelect {...item}
                          data={students.map((el) => ({ id: el.studentId, title: `${el.studentName} ${el.typeName} ${dateFormat("mm-dd-yyyy", el.startDt)} to ${dateFormat("mm-dd-yyyy", el.endDt)}`, value: el.assignmentId }))}
                        />
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikDateField {...item}/>
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <FormikButton variant="contained" color="primary">Add</FormikButton>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModalCallLog>
      </div>
      <div className={className}>
        <PaperModalCallLog headerTitle="Edit Call" open={modalForEditCall} onClose={onClose}>
          <Formik
            initialValues={{ ...initialValues, callDt: dateFormat("mm-dd-yyyy", initialValues.callDt) }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(editCallLogById(
                {
                  ...values,
                  callDt: format(parseISO(dateFormat("yyyy-mm-dd", values.callDt)), "yyyy-MM-dd'T'HH:mm:ssXXX", "UTC"),
                  note: values.note.trim(),
                }, user.providerId,
              ));
              setModalForEditCall(false);
              setValueChange(false);
              setState(0);
              setCurrentIndex(0);
            }}
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div style={{ marginBottom: "40px", width: "100%" }} key={item.id + item.name}>
                        <FormikTextField {...item}/>
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div style={{ marginTop: "4px", marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikSelect {...item}
                          data={students.map((el) => ({ id: el.studentId, title: `${el.studentName} ${el.typeName} ${dateFormat("mm-dd-yyyy", el.startDt)} to ${dateFormat("mm-dd-yyyy", el.endDt)}`, value: el.assignmentId }))}
                        />
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div style={{ marginBottom: "20px", width: "100%" }} key={item.id + item.name}>
                        <FormikDateField {...item}/>
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <FormikButton variant="contained" color="primary" disabled={!dirty}>Save</FormikButton>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModalCallLog>
      </div>

      <PaperModal headerTitle="Are you sure deleting the call log?"
        open={modalForDeleteCall.state}
        onClose={() => {
          setModalForDeleteCall({ state: false });
          setState(0);
        }
        }>
        <div style={{ marginTop: "20px" }} onClick={() => {
          dispatch(deleteCallLogById(modalForDeleteCall.id, user.providerId));
          setModalForDeleteCall({ state: false, id: null });
          setCurrentIndex(0);
        }}
        >
          <Button variant="contained" color="primary">
            Yes, Delete
          </Button>
        </div>
        <Button variant="dashed" color="primary" onClick={() => {
          setModalForDeleteCall({ state: false, id: null });
          setState(0);
        }
        }>
            Cancel
        </Button>
      </PaperModal>

      <PaperModal headerTitle="Discard unsaved changes?"
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false);
          setClassName("callLog__open");
        }}
      >
        <div style={{ marginTop: "20px" }}/>
        <div onClick={onDiscard}>
          <Button variant="contained" color="primary">
            Discard
          </Button>
        </div>
        <Button variant="dashed" color="primary"
          onClick={() => {
            setConfirmModal(false);
            setClassName("callLog__open");
          }}>
            Cancel
        </Button>
      </PaperModal>

    </>
  );
}

export default ProviderCallLog;
