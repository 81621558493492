import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  type: "",
  text: "",
  timeout: null,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    snackbarOpen(state, { payload }) {
      state = payload;
      return state;
    },
    snackbarClose(state) {
      Object.assign(state, initialState);
    },
  },
});

export default snackbarSlice.reducer;

export const { snackbarOpen, snackbarClose } = snackbarSlice.actions;
