import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const signatureSlice = createSlice({
  name: "signature",
  initialState: {
    signature: null,
  },
  reducers: {
    setSignature(state, { payload }) {
      state.signature = payload;
    },
  },
});

const { setSignature } = signatureSlice.actions;

export const uploadSignature = (id, data, cb) => async (dispatch) => {
  try {
    const response = await axios.post(`/upload/signature/${id}`, data);
    cb();
    dispatch(setSignature(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default signatureSlice.reducer;
