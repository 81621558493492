const createDownloadUrl = (data, type, isCsvConvert) => {
  let csvBuffer = null;
  let result = null;

  if (isCsvConvert) {
    csvBuffer = Buffer.from(data, "base64");
    result = csvBuffer.toString("ascii");
  }

  const url = window.URL.createObjectURL(
    new Blob([result || data], { type }),
  );
  return url;
};

export default createDownloadUrl;
