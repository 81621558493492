import * as Yup from "yup";

export const fieldConfig = [
  {
    id: 1,
    name: "studentsFilter",
    label: "Student name",
    placeholder: "",
    type: "filterStudent",
  },
  {
    id: 2,
    name: "osisNumbers",
    label: "OSIS",
    placeholder: "",
    type: "filterOsis",
  },
  {
    id: 3,
    name: "authorizationNumbers",
    label: "Authorization Number",
    placeholder: "",
    type: "filterAuthorizNum",
  },
  {
    id: 4,
    type: "empty",
  },
  {
    id: 5,
    type: "empty",
  },
  {
    id: 6,
    name: "schoolYear",
    label: "School year",
    placeholder: "",
    type: "datefield",
    showIcon: true,
    isTableYear: true,
    class: "reportForAdmin__schoolYear",
  },
  {
    id: 7,
    name: "mandateTime",
    label: "Mandate time",
    placeholder: "",
    type: "datefield",
    isRange: true,
    showIcon: true,
    class: "reportForAdmin__mandateTime",
  },
  {
    id: 8,
    type: "empty",
  },
];

export const formikInitialValues = {
  studentId: "",
  osis: "",
  authorizationNumber: "",
  schoolYear: "",
  mandateTime: "",
};

export const validationSchema = Yup.object({
  studentId: Yup.string(),
  osis: Yup.string(),
  authorizationNumber: Yup.string(),
  schoolYear: Yup.string(),
  mandateTime: Yup.string(),
});
