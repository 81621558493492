import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { fieldConfig, yupObject } from "./config";
import HeaderPage from "../../../../../../components/HeaderPage";
import { banks_of_values_url, banks_of_values_goals_url } from "../../../../../../configs/router_urls";
import FormikTextField, { TextField } from "../../../../../../components/TextField";
import FormikButton, { Button } from "../../../../../../components/Button";
import { getInfoForGoalById, getListPlaceholder, updateGoalById } from "../../store";
import { PaperModal } from "../../../../../../components/modal/Modal";

function EditDictGoal() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const ref = useRef();
  const inputRef = useRef();
  const { infoGoal, infoPlaceholders } = useSelector(({ dictionaryGoalsReducer }) => dictionaryGoalsReducer);
  const [config, setConfig] = useState();
  const [modalWindow, setModalWindow] = useState(false);
  const [valueBody, setValueBody] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(getListPlaceholder());
    dispatch(getInfoForGoalById(params.id));
  }, []);

  useEffect(() => {
    if (ref.current) {
      setConfig(infoGoal);
      setValueBody(infoGoal.goalBody);
    }
    ref.current = true;
  }, [infoGoal]);

  useEffect(() => {
    if (valueBody?.length < 65536 && valueBody.length) {
      setError("");
      inputRef.current.style.border = "1px solid #ced4da";
    }
    if (valueBody === "") {
      setError("Required field");
      inputRef.current.style.border = "1px solid #ff5454";
    }
    if (valueBody?.length > 65535) {
      setError("Goal max length should be 65535 characters.");
      inputRef.current.style.border = "1px solid #ff5454";
    }
  }, [valueBody]);

  const deletePlaceholder = () => {
    const el = inputRef.current;
    const start = inputRef.current.selectionStart;
    const end = inputRef.current.selectionEnd;
    const substr = el.value.substring(0, start);
    const arr = substr.split(" ");
    const name = arr[arr.length - 1];
    if (name === "~Name~" || name === "~String~" || name === "~X of x~" || name === "~Independency~"
    || name === "~Percent~" || name === "~Measurement~" || name === "~Grade~") {
      arr.splice(arr.length - 1, 1, " ");
      // arr.splice(arr.length - 1, 1, "");
      const newSubstr = arr.join(" ");
      const newStr = `${newSubstr}${el.value.substring(end)}`;
      el.value = newStr;
      inputRef.current.selectionStart = start - name.length;
      inputRef.current.selectionEnd = end - name.length;
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      deletePlaceholder();
    }
  };

  const onChange = (e) => {
    setValueBody(e.target.value);
  };

  return (
    <div>
      <HeaderPage
        title="Edit goal"
        rootPathName={banks_of_values_url}
        rootTitleName="Banks of Values"
        childrenTitleNames={["Goals", "Edit goal"]}
        childrenPathNames={[`${banks_of_values_goals_url}`]}
      />
      <div className="container">
        <div style={{ display: "flex" }}>
          {config && <div className="addNewGoal">
            <Formik
              initialValues={{ ...config }}
              validationSchema={Yup.object(yupObject)}
              onSubmit={(values) => {
                if (valueBody.length && valueBody.length < 65536) {
                  dispatch(updateGoalById(
                    {
                      ...values,
                      goalBody: valueBody.trim(),
                      goalId: params.id,
                    }, infoGoal.goalGroupId, history.push(banks_of_values_goals_url),
                  ));
                }
              }}
            >
              {({ dirty }) => (
                <>
                  {fieldConfig.map((item, key) => {
                    switch (item.type) {
                    case "textfield": {
                      return (
                        <div key={item.id + item.name} className="addNewGoal__title">
                          <FormikTextField {...item}/>
                        </div>
                      );
                    }
                    case "textfieldCode": {
                      return (
                        <div key={item.id + item.name} className="addNewGoal__code">
                          <FormikTextField {...item}/>
                        </div>
                      );
                    }
                    case "textfieldText": {
                      return (
                        <div key={item.id + item.name} className="addNewGoal__text">
                          <TextField value={valueBody} onChange={onChange} inputRef={inputRef} onKeyDown={onKeyDown} {...item} />
                          <span style={{ color: "#ff5454", fontSize: "11px", paddingLeft: "10px" }}>{error}</span>
                        </div>
                      );
                    }
                    case "empty": {
                      return <div key={key.toString()} />;
                    }
                    default: return "default";
                    }
                  })}
                  <Button variant="outlined" color="grey" onClick={() => { infoGoal.goalBody.length !== valueBody.length ? setModalWindow(true) : history.push(`${banks_of_values_goals_url}`); }}>
                    Cancel
                  </Button>
                  <FormikButton variant='contained' color='primary' isGoal onClick={() => {
                    if (!valueBody.length) {
                      setError("Required field");
                      inputRef.current.style.border = "1px solid #ff5454";
                    }
                    if (valueBody.length > 65535) {
                      setError("Goal max length should be 65535 characters.");
                      inputRef.current.style.border = "1px solid #ff5454";
                    }
                  }}>
                    Save
                  </FormikButton>
                </>
              )}
            </Formik>
          </div>}
          <div className="placeholders">
            <div>Placeholders</div>

            {infoPlaceholders.map((el) => (
              <div style={{ margin: "10px 20px" }} key={el.id + el.name}>
                <Button variant="outlined" color="grey" onClick={() => {
                  let elem = inputRef.current;
                  const start = inputRef.current.selectionStart;
                  const end = inputRef.current.selectionEnd;
                  let newStr = `${elem.value.substring(0, start)} ${el.code} ${elem.value.substring(end).trim()}`;
                  setValueBody(newStr);
                  elem.value = newStr;
                  inputRef.current.selectionStart = start + el.code.length + 1;
                  inputRef.current.selectionEnd = end + el.code.length + 1;
                  elem.focus();
                }}>
                  <span>{el.name}</span>
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modalWindowGoals">
        <PaperModal headerTitle="Discard unsaved changes?" open={modalWindow} onClose={() => setModalWindow(false)}>
          <div style={{ marginTop: "20px" }}></div>
          <div onClick={() => history.push(`${banks_of_values_goals_url}`)}>
            <Button variant="contained" color="primary">
            Discard
            </Button>
          </div>
          <Button variant="dashed" color="primary" onClick={() => setModalWindow(false)}>
            Cancel
          </Button>
        </PaperModal>
      </div>

    </div>
  );
}

export default EditDictGoal;
