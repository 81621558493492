import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const editAssignmentSlice = createSlice({
  name: "editAssignment",
  initialState: {
    data: "",
    service: {},
  },
  reducers: {
    setAssignment(state, { payload }) {
      state.data = payload;
    },
    setService(state, { payload }) {
      state.service = payload;
    },
  },
});

export const { setAssignment, setService } = editAssignmentSlice.actions;

export default editAssignmentSlice.reducer;

export const updateStudentsAssignment = (id, data, callback) => async () => {
  try {
    await axios.put(`/assignment/update-assignment/${id}`, {
      ...data,
    });
    callback && callback();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getInfoForAssignment = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/assignment/assignment/${id}`);
    const response = await axios.get(`/service/service-by-id/${data.serviceId}`);
    const student = response.data.studentId;
    dispatch(setAssignment({
      ...data,
      studentId: student,
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getInfoForService = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/service/service-by-id/${id}`);
    dispatch(setService(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
