import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import removeEmptyFields from "../../../../../../utils/removeEmptyFields";

const studentServiceReducer = createSlice({
  name: "studentService",
  initialState: {
    services: {
      result: [],
      pagination: {},
      isFetching: true,
    },
  },

  reducers: {
    getStudentServices(state, { payload }) {
      state.services = { ...payload, isFetching: false };
    },
  },
});

const { getStudentServices } = studentServiceReducer.actions;

const getStudentServicesAction = (id, offset = null, limit = 1000) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const response = await axios(`/service/service-list-by-student/${id}`, {
      params: {
        offset,
        limit,
      },
    });
    dispatch(getStudentServices(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const createStudentServiceAction = (data, successCb) => async () => {
  try {
    await axios.post("/service/create-service", removeEmptyFields(data));
    successCb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateStudentServiceAction = (data, id, successCb) => async () => {
  try {
    await axios.patch(`/service/update-service/${id}`, removeEmptyFields(data));
    successCb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const deleteStudentServiceAction = (id, studentId) => async (dispatch) => {
  try {
    await axios.delete(`/service/delete-service/${id}`);
    dispatch(getStudentServicesAction(studentId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default studentServiceReducer.reducer;
export {
  createStudentServiceAction,
  updateStudentServiceAction,
  getStudentServicesAction,
  deleteStudentServiceAction,
  getStudentServices,
};
