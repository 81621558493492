import React from "react";
import HeaderPage from "../../../../../../components/HeaderPage";
import { ReportsButton } from "./components/ReportsButton";

import {
  admin_reports_create_gusto_csv_url,
  admin_reports_create_quickbook_csv_url,
  admin_reports_create_report_for_admin_url,
} from "../../../../../../configs/router_urls";

import "./styles/style.scss";

const ReportsPage = () => (
  <div className="reports">
    <HeaderPage
      title='Reports'
    />
    <div className="reports-wrapper">
      <ReportsButton link={admin_reports_create_gusto_csv_url}>
        Gusto CSV
      </ReportsButton>
      <ReportsButton link={admin_reports_create_quickbook_csv_url}>
        QuickBook CSV
      </ReportsButton>
      <ReportsButton link={admin_reports_create_report_for_admin_url}>
        Report #1 For Admin Department
      </ReportsButton>
    </div>
  </div>
);

export default ReportsPage;
