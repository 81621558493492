function transformUsersData(data) {
  const rebuildItem = data.map((el) => {
    if (el.type === "I") {
      return { ...el, type: "INTERNAL" };
    }
    if (el.type === "E") {
      return { ...el, type: "EXTERNAL" };
    }
    if (el.type === "S") {
      return { ...el, type: "SYSTEM" };
    }
    return { ...el };
  }).map((item) => {
    if (item.state === "E") {
      return { ...item, state: "ENABLED" };
    }
    if (item.state === "D") {
      return { ...item, state: "DISABLED" };
    }
    if (item.state === "L") {
      return { ...item, state: "LOCKED" };
    }
    return { ...item };
  });

  return rebuildItem.map((d) => {
    if (!d.role) d.role = "";
    return d;
  });
}

export {
  transformUsersData,
};
