import axios from "axios";
import { setProvidersProfileData } from "../../providerProfile/store";

function removeEmptyFields(values) {
  return Object.keys(values).reduce((a, c) => {
    if (values[c] !== null || Array.isArray(values[c])) {
      a[c] = values[c];
    }
    return a;
  }, {});
}

export const updateProviderProfile = (data, cb) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    if (data.annualRate === "0.00") {
      data.annualRate = null;
    }
    await axios.patch("/provider/update-provider", removeEmptyFields(data));
    dispatch(setProvidersProfileData(data));
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
