import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { useHistory } from "react-router-dom";

import EventList from "./eventList";
import {
  week_days_full,
  getDateMatrix,
  isEqualsDates,
  getDate,
  getCurrentDate,
} from "../../../functions/calendar_func";
import "./style.scss";

import { create_session_url } from "../../../configs/router_urls";

const CalendarMonthTable = (props) => {
  const {
    currentDate,
    startDate,
    events: providerEvents,
    goToNotes,
    selectDay,
  } = props;

  const history = useHistory();
  const today = moment().format("MM-DD-YYYY");
  const grid = getDateMatrix(currentDate);

  const [soonest, setSoonest] = useState(null);

  function getActiveClass(date) {
    switch (true) {
    case getDate(date, "MM") !== getDate(currentDate, "MM"):
      return "disabled";
    case isEqualsDates(
      getCurrentDate("MMMM YYYY D"),
      getDate(date, "MMMM YYYY D"),
    ):
      return "today";
    case isEqualsDates(date, startDate):
      return "active";
    default:
      return "default";
    }
  }
  const fixProviderEvents = Object.keys(providerEvents).reduce((result, key) => {
    result[getDate(key, "MM-DD-YYYY")] = providerEvents[key];
    return result;
  }, {});
  const newEvents = {};
  const getEvents = (day) => {
    let arr = [];
    Object.keys(providerEvents).forEach((key) => {
      providerEvents[key].map((el) => arr.push(el));
    });
    newEvents[getDate(day, "MM-DD-YYYY")] = arr.reduce((prev, el) => {
      const date = moment(el.actualStartDt).format();
      if (moment(date).isSame(day, "day")) {
        prev.push(el);
      }
      return prev;
    }, []);

    if (newEvents[getDate(day, "MM-DD-YYYY")]) {
      return newEvents[getDate(day, "MM-DD-YYYY")];
    } return [];
  };

  useEffect(() => {
    let todayDay = +today.split("-")?.[1];
    let arr = [];

    for (let i = todayDay; i < 32; i++) {
      if (fixProviderEvents[`${today.split("-")?.[0]}-${i < 10 ? `0${i}` : i}-${today.split("-")?.[2]}`]) {
        arr.push(fixProviderEvents[`${today.split("-")?.[0]}-${i < 10 ? `0${i}` : i}-${today.split("-")?.[2]}`]);
      }
    }

    let reduceArr = arr.flat().reduce((arr, it) => {
      if (moment(it.actualEndDt) < moment()) {
        return arr;
      }

      arr.push(it);
      return arr;
    }, []);

    if (reduceArr.length > 0) {
      setSoonest(+reduceArr?.[0].eventId);
    } else {
      setSoonest(null);
    }
  }, []);

  return (
    <table className="calendar_month-table">
      <thead>
        <tr>
          {week_days_full.map((week_day, index) => (
            <th key={index}>{week_day}</th>
          ))}
        </tr>

      </thead>
      <tbody>
        {grid.map((week, index) => (
          <tr key={index}>
            {week.map((day, index) => {
              const date = new Date(day.date);
              const cd = new Date(currentDate);
              const format = date.getDate() !== 1 ? "D" : "D MMM";
              const activeClass = getActiveClass(day.date);
              const isToday = activeClass.includes("today");
              const notCurrentMonth = date.getMonth() !== cd.getMonth() ? "not-current-month" : "";
              const events = getEvents(day.date);

              return (
                <td key={index}>
                  <div className={clsx("day", activeClass)}>
                    <button
                      className={`day_cell-header ${notCurrentMonth}`}
                      onClick={() => selectDay(getDate(day.date))}
                    >
                      {isToday && <span className='day_cell-today'>Today</span>}
                      <span> {getDate(day.date, format)}</span>
                    </button>

                    <EventList
                      data={events}
                      goToNotes={goToNotes}
                      soonest={soonest}
                      position={index}
                      onEmptyDay={() => {
                        if (!events.length) {
                          history.push(create_session_url);
                        }
                      }}
                    />
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CalendarMonthTable;
