export const headerConfig = {
  studentName: {
    id: 1,
    title: "Student",
    value: "studentName",
    order: 1,
  },
  providerName: {
    id: 2,
    title: "Provider",
    value: "providerName",
    order: 2,
  },
  caseNo: {
    id: 3,
    title: "Case number",
    value: "caseNo",
    order: 3,
  },
  serviceType: {
    id: 4,
    title: "Service type",
    value: "serviceType",
    order: 4,
  },
  invoiceMonth: {
    id: 5,
    title: "Month",
    value: "invoiceMonth",
    order: 5,
  },
  minutesAmount: {
    id: 6,
    title: "Hours amount",
    value: "minutesAmount",
    order: 6,
  },
  billingRate: {
    id: 7,
    title: "Rate",
    value: "billingRate",
    order: 7,
  },
};

export const headerConfigState = {
  studentName: {
    id: 1,
    title: "Student",
    value: "studentName",
    order: 1,
  },
  providerName: {
    id: 2,
    title: "Provider",
    value: "providerName",
    order: 2,
  },
  caseNo: {
    id: 3,
    title: "Case number",
    value: "caseNo",
    order: 3,
  },
  serviceType: {
    id: 4,
    title: "Service type",
    value: "serviceType",
    order: 4,
  },
  invoiceMonth: {
    id: 5,
    title: "Month",
    value: "invoiceMonth",
    order: 5,
  },
  minutesAmount: {
    id: 6,
    title: "Hours amount",
    value: "minutesAmount",
    order: 6,
  },
  billingRate: {
    id: 7,
    title: "Rate",
    value: "billingRate",
    order: 7,
  },
  state: {
    id: 8,
    title: "Status",
    value: "state",
    order: 8,
  },
};
export const headerConfigDate = {
  studentName: {
    id: 1,
    title: "Student",
    value: "studentName",
    order: 1,
  },
  providerName: {
    id: 2,
    title: "Provider",
    value: "providerName",
    order: 2,
  },
  caseNo: {
    id: 3,
    title: "Case number",
    value: "caseNo",
    order: 3,
  },
  serviceType: {
    id: 4,
    title: "Service type",
    value: "serviceType",
    order: 4,
  },
  invoiceMonth: {
    id: 5,
    title: "Month",
    value: "invoiceMonth",
    order: 5,
  },
  minutesAmount: {
    id: 6,
    title: "Hours amount",
    value: "minutesAmount",
    order: 6,
  },
  billingRate: {
    id: 7,
    title: "Rate",
    value: "billingRate",
    order: 7,
  },
  paidDate: {
    id: 8,
    title: "Payment date",
    paidDate: "paymentDate",
    order: 8,
  },
};
