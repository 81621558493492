import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280 + theme.spacing(3) * 2,
    margin: "0 auto",
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#EDEDED",

    height: 6,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#FF9500",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 6,
    backgroundColor: "#EDEDED",
    borderRadius: 4,
  },
  rail: {
    height: 6,
    backgroundColor: "#EDEDED",
    borderRadius: 4,
  },
})(Slider);

export default function CustomizedSlider({ onChange, value, name }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      {/* valueLabelDisplay="auto" aria-label="pretto slider" */}
      <PrettoSlider name={name} step={10} onChange={(e, d) => onChange(e, d, name)} value={value}/>
    </div>
  );
}
