import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const schoolRepresentativesSlice = createSlice({
  name: "schoolRepresentativesSlice",
  initialState: {
    schoolRepresentatives: {
      result: [],
      pagination: {},
    },
    schools: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setSchoolsRepresentatives: (state, { payload }) => {
      state.schoolRepresentatives = payload;
    },
    setSchools: (state, { payload }) => {
      state.schools = payload;
    },
  },
});

export default schoolRepresentativesSlice.reducer;

const { setSchoolsRepresentatives, setSchools } = schoolRepresentativesSlice.actions;

export const getSchools = (offset = null, limit = 100) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const response = await axios("/dictionaries/schools", { params: { offset, limit } });
    dispatch(setSchools(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getSchoolsRepresentativesAction = (offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const response = await axios("/dictionaries/school-representatives", { params: { offset, limit } });
    dispatch(setSchoolsRepresentatives(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createSchoolRepresentativeAction = (data, success, fail) => async (dispatch) => {
  try {
    await axios.put("/dictionaries/school-representative", data);
    success();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    fail();
  } finally {
    dispatch(getSchoolsRepresentativesAction());
  }
};

export const updateSchoolRepresentativeAction = (data, success, fail) => async (dispatch) => {
  try {
    await axios.put("/dictionaries/school-representative", data);
    success();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    fail();
  } finally {
    dispatch(getSchoolsRepresentativesAction());
  }
};

export const deleteSchoolRepresentativeAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/dictionaries/school-representative/${id}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    dispatch(getSchoolsRepresentativesAction());
  }
};

export const clearedSchoolRepresentativeAction = () => async (dispatch) => {
  try {
    dispatch(setSchoolsRepresentatives({ result: [], pagination: {} }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
