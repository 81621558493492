import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import SvgMaker from "../../../../../components/svgMaker";
import { getGoalForCreationGoalAction } from "./store";

import "./styles/index.scss";

import {
  admin_students_edit_goal_url, admin_students_gererate_goal_url,
  admmin_students_goals_url, admmin_students_profile_url, admmin_students_url,
} from "../../../../../configs/router_urls";

function Goal({
  goalTitle, goalId, open = () => { }, goalBody, goalCd,
}) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const selectGoal = () => {
    const [o, t] = admin_students_gererate_goal_url.split(":id");
    history.push(`${o}${params.id}${t}`, {
      goalTitle, goalBody, goalId, studentName: location.state.studentName,
    });
  };

  const parse = (str) => {
    const name = location.state.studentName;
    let newStr = str.replaceAll("~Name~", name);
    newStr = newStr.replaceAll("~Percent~", "________");
    newStr = newStr.replaceAll("~String~", "________");
    newStr = newStr.replaceAll("~X", "________");
    newStr = newStr.replaceAll("x~", "________");
    newStr = newStr.replaceAll("~Independency~", "________");
    newStr = newStr.replaceAll("~Measurement~", "________");
    newStr = newStr.replaceAll("~Grade~", "________");
    return newStr;
  };

  return (
    <div className={`goal ${goalId}-${goalTitle}`}>
      <div className='goal__container'>
        <div className='goal__container_text'>
          <span className='title'>{`${goalTitle} ${goalCd}`}</span>
          <span className='descr'>
            <div>{goalBody.split("\n").map((it) => <p>{parse(it)}</p>)}</div>
          </span>
        </div>
        <div className='select-btn' onClick={(event) => open(event, `${goalId}-${goalTitle}`, true)}>
          <Button onClick={(event) => {
            event.stopPropagation();
            selectGoal({
              goalTitle, goalId, selectGoal, goalBody, goalCd,
            });
          }} style={{ width: "115px" }} color="primary" variant="contained">Select</Button>
        </div>
      </div>
    </div>
  );
}

function SubGroup({
  subGroups, name, id, open, selectGoal, goals,
}) {
  return (
    <React.Fragment>
      <div className='sub-group'>
        <div className='sub-group__container' onClick={(event) => open(event, `${id} ${name}`, true)}>
          <span className='sub-group__title' style={subGroups.length || goals.length ? { cursor: "pointer" } : { cursor: "auto" }}>{name}</span>
          {subGroups.length || goals.length ? <div className='arrow'>
            <SvgMaker width='20' height='20' name='arrowDown' stroke="#495057"/>
          </div> : null}
        </div>
        {goals.map((goal, key) => (
          <div className={`${id} ${name}`} key={key} style={{ display: "none" }}> <Goal {...goal} selectGoal={selectGoal} /> </div>
        ))}
        {!subGroups.length ? null : subGroups.map((subgroup) => <div className={`${id} ${name}`} key={subgroup.id} style={{ display: "none" }}> <SubGroup {...subgroup} open={open} /></div>)}
      </div>
    </React.Fragment>

  );
}

function NdAddNewGoal() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const goals = useSelector(({ ndAddNewGoalReducer }) => ndAddNewGoalReducer.goals);

  const openParent = (event, id, isPadding) => {
    const elem = document.getElementById(id);
    elem.style["transition-duration"] = "222ms";
    elem.style.transition = "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
    if (elem.style.display === "none") {
      elem.style["min-height"] = "0px";
      elem.style.height = "auto";
      elem.style.display = "block";
      const coll = elem.parentElement.children[1];
      [...coll.children].map((el) => el.style.display = "block");
      event.currentTarget.childNodes[1].style.transform = "rotate(180deg)";
    } else {
      if (isPadding) {
        elem.style.padding = "20px 24px";
      }
      elem.style.minHeight = "0px";
      elem.style.height = "0px";
      elem.style.display = "none";
      const coll = elem.parentElement.children[1];
      [...coll.children].map((el) => el.style.display = "none");
      event.currentTarget.childNodes[1].style.transform = "rotate(0deg)";
    }
  };

  const openChild = (event, className) => {
    const elements = document.getElementsByClassName(className);
    [...elements].forEach((elem) => {
      if (elem.style.display === "none") {
        elem.style.display = "block";
        event.currentTarget.childNodes[1].style.transform = "rotate(180deg)";
      } else {
        elem.style.display = "none";
        event.currentTarget.childNodes[1].style.transform = "rotate(0deg)";
      }
    });
  };

  useEffect(() => {
    dispatch(getGoalForCreationGoalAction());
  }, []);

  const cancel = () => history.push(`${admmin_students_goals_url}/${params.id}`);

  const goToFreeForm = () => {
    const [o, t] = admin_students_edit_goal_url.split(":id");
    history.push(`${o}${params.id}${t}`, {
      ...location.state,
      goalBody: "",
    });
  };

  return (
    <div>
      <HeaderPage
        title={`Select a Goal for ${location.state.studentName}`}
        rootTitleName="All Students"
        rootPathName={admmin_students_url}
        childrenTitleNames={[" Student Profile", "Student Goals", "Select a Goal for Student"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`, `${admmin_students_goals_url}/${params.id}`]}
      />
      <div className='goal-select-btns' style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
        <Button
          style={{ width: 120 }}
          color="grey"
          variant="contained"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 135, marginLeft: 15 }}
          color="primary"
          variant="contained"
          onClick={goToFreeForm}
        >
          Free form
        </Button>
      </div>
      <Paper>
        {goals.map((group, key) => (
          <div className='goalGroup' key={key}>
            <div className='goalGroup__container'
              style={group.goals.length || group.subGroups.length ? { cursor: "pointer" } : { cursor: "auto" }}
              onClick={(event) => (group.goals.length || group.subGroups.length) && openParent(event, group.id + group.name)}
            >
              <span className='goalGroup__title'>{`${group.name} ${group.groupCd}`}</span>
              {group.goals.length || group.subGroups.length ? <div className='arrow'>
                <SvgMaker width='20' height='20' name='arrowDown' stroke="#495057"/>
              </div> : null}
            </div>
            <div>
              {group.goals.length !== 0 && group.goals.map((goal, i) => (
                <div key={i} style={{ display: "none" }}><Goal {...goal} selectGoal={goal}/></div>
              ))}
            </div>
            <div id={group.id + group.name} style={{ display: "none" }}>
              {group.subGroups.map((subgroup) => (
                <SubGroup key={subgroup.id} {...subgroup} open={openChild} />
              ))}
            </div>
          </div>
        ))}
      </Paper>
    </div>
  );
}

export default NdAddNewGoal;
