function searchGroup(data, id) {
  let group = data.find((el) => el.id === id);
  if (!group) {
    let i = 0;
    while (!group && i < data.length) {
      if (data[i].subGroups && data[i].subGroups.length) {
        group = searchGroup(data[i].subGroups, id);
      }
      i++;
    }
  }
  return group;
}

function getChildIds(group, result = []) {
  if (!group) return;
  if (!Array.isArray(group.subGroups)) return;
  result.push(group.id);
  for (let sub of group.subGroups) {
    result.push(sub.id);
    getChildIds(sub, result);
  }
  return result;
}

export { searchGroup, getChildIds };
