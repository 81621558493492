import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "login",
    label: "Login",
    placeholder: "",
    required: true,
    type: "textfield",
    autoFocus: true,
  },
  {
    id: 2,
    name: "password",
    label: "Password",
    isPassword: true,
    placeholder: "",
    required: true,
    type: "textfield",
    autoFocus: false,
  },
  {
    id: 3,
    name: "isRememberMe",
    label: "Remember me",
    required: true,
    type: "checkbox",
  },
];

const formikInitialValues = {
  login: "",
  password: "",
  isRememberMe: false,
};

const validationSchema = Yup.object({
  login: Yup.string().required("required field"),
  password: Yup.string().required("required field"),
  isRememberMe: Yup.bool(),
});

export {
  fieldConfig,
  formikInitialValues,
  validationSchema,
};
