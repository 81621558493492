import { configureStore, combineReducers } from "@reduxjs/toolkit";

import thunk from "redux-thunk";
import authorizationReducer, { setUserDataAction } from "../pages/login/store";
import adminReducer from "../entities/admin/store";
import systemRolesReducer from "../pages/systemRole/store";
import systemUsersReducer from "../pages/systemUsers/store";
import providersReducer from "../entities/admin/provider/pages/providersList/store";
import providersProfileReducer from "../entities/admin/provider/pages/providerProfile/store";
import studentsReducer from "../entities/admin/student/pages/students/store";
import createStudentReducer from "../entities/admin/student/pages/createStudent/store";
import studentsGoalReducer from "../entities/admin/student/pages/studentGoal/store";
import studentProfileReducer from "../entities/admin/student/pages/studentProfile/store";
import snackbarReducer from "../components/Snackbar/store/snackbar.slice";
import studentServiceReducer from "../entities/admin/student/pages/studentServices/store";
import adminProviderReducer from "../entities/admin/provider/pages/store";
import providerStudentsListReducer from "../entities/provider/pages/providerStudentsList/store";
import adminAssignmentsReducer from "../entities/admin/student/pages/studentAssignmentsList/store";
import editAssignmentReducer from "../entities/admin/student/pages/editStudentAssignment/store";
import editStudentGoalReducer from "../entities/admin/student/pages/editStudentGoal/store";
import editStudentServiceReducer from "../entities/admin/student/pages/editStudentService/store";
import ndAddNewGoalReducer from "../entities/admin/student/pages/ndAddNewGoal/store";
import schoolsReducer from "../pages/dict/components/school/store";
import schoolRepresentativesReducer from "../pages/dict/components/schoolRepresentatives/store";
import sessionReducer from "../pages/calendar/session/sessionForm/store";
import calendarReducer from "../pages/calendar/store";
import documentsReducer from "../entities/admin/student/pages/documents/store";
import documentsProviderReducer from "../entities/admin/provider/pages/documents/store";
import signatureReducer from "../entities/provider/pages/providerProfile/store";
import serviceTypesReducer from "../entities/admin/student/pages/addNewStudentServices/store";
import studentInvoicesReducer from "../entities/provider/pages/studentInvoices/store";
import progressReportListReducer from "../entities/provider/pages/ProgressReportList/store";
import dictionaryGoalsReducer from "../pages/dict/components/goals/store";
import studentInvoicesValidationReducer from "../entities/provider/pages/studentInvoicesValidations/store";
import adminInvoicesListReducer from "../pages/invoices/store";
import adminMessagesReducer from "../entities/admin/adminMessages/store";
import providerHomePage from "../entities/provider/pages/home/store";
import loadingReducer from "../configs/store";
import studentCallLogReducer from "../entities/provider/pages/studentCallLogs/store";
import providerCallLogReducer from "../entities/provider/pages/providerCallLog/store";
import providerSessionsReducer from "../entities/provider/pages/providerSessions/store";
import providerInvoicesListReducer from "../entities/provider/pages/invoices/store";
import adminSessionsReducer from "../entities/admin/sessions/store";
import allCallsLogReducer from "../entities/admin/provider/pages/callLog/store";
import adminReportReducer from "../entities/admin/provider/pages/reports/createAdminReport/store";
import axios from "../configs/axios";

export const rootReducer = combineReducers({
  // auth: authSlice.reducer,
  adminSessionsReducer,
  authorizationReducer,
  adminReducer,
  systemRolesReducer,
  systemUsersReducer,
  providersReducer,
  providersProfileReducer,
  studentsReducer,
  createStudentReducer,
  studentsGoalReducer,
  studentProfileReducer,
  studentServiceReducer,
  snackbarReducer,
  adminProviderReducer,
  providerStudentsListReducer,
  adminAssignmentsReducer,
  editAssignmentReducer,
  editStudentGoalReducer,
  editStudentServiceReducer,
  schoolsReducer,
  schoolRepresentativesReducer,
  ndAddNewGoalReducer,
  sessionReducer,
  calendarReducer,
  documentsReducer,
  documentsProviderReducer,
  signatureReducer,
  serviceTypesReducer,
  studentInvoicesReducer,
  progressReportListReducer,
  dictionaryGoalsReducer,
  studentInvoicesValidationReducer,
  adminInvoicesListReducer,
  adminMessagesReducer,
  providerHomePage,
  loadingReducer,
  providerCallLogReducer,
  studentCallLogReducer,
  providerSessionsReducer,
  providerInvoicesListReducer,
  allCallsLogReducer,
  adminReportReducer,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

axios();

export default store;

if (!localStorage.getItem("@token")) {
  if (sessionStorage.getItem("@token")) {
    store.dispatch(setUserDataAction(sessionStorage.getItem("@token")));
  }
} else {
  store.dispatch(setUserDataAction(localStorage.getItem("@token")));
}
