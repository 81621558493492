import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getStudentGoalListSlice = createSlice({
  name: "studentsGoal",
  initialState: {
    studentsGoal: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    getStudentGoalList(state, { payload }) {
      state.studentsGoal = { ...payload };
    },
    pushNewStudentsGoal(state, { payload }) {
      state.studentsGoal.push(payload);
    },
  },
});
const { getStudentGoalList } = getStudentGoalListSlice.actions;

export const createStudentGoalAction = (data, id, callback) => async () => {
  const newData = {
    ...data,
    studentId: +id,
  };
  try {
    await axios.post("/goals/create-student-goal", {
      ...Object.keys(newData).reduce((a, c) => {
        if (newData[c]) {
          a[c] = newData[c];
        }
        return a;
      }, {}),
    });
    callback(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateStudentGoalAction = (data, callback) => async () => {
  try {
    await axios.patch("/goals/update-student-goal", {
      ...data,
    });
    callback(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const fetchStudentsGoalList = (id, bodyMask) => async (dispatch) => {
  try {
    const params = formatterParams({
      bodyMask,
    });
    const response = await axios.get(`/goals/all-student-goals/${id}?${params}limit=50`);
    dispatch(getStudentGoalList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
};
function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null && data[b] !== "") return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}
export const deleteGoalById = (id, studentsId) => async (dispatch) => {
  try {
    await axios.delete(`/goals/delete-student-goal/${id}`);
    dispatch(fetchStudentsGoalList(studentsId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
};

export default getStudentGoalListSlice.reducer;
