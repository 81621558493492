import React from "react";
import { useSelector } from "react-redux";

function Authorization({ children }) {
  const user = useSelector(({ authorizationReducer }) => authorizationReducer.user);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { roles: user.role, isAuth: !!Object.keys(user).length });
    }
    return child;
  });

  return <React.Fragment>{childrenWithProps}</React.Fragment>;
}

export default Authorization;
