import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  documents: [],
};

const documentsProviderSlice = createSlice({
  name: "documentsProvider",
  initialState,
  reducers: {
    getDocuments(state, { payload }) {
      state.documents = payload;
    },
    resetState() {
      return initialState;
    },
  },
});

const { getDocuments, resetState } = documentsProviderSlice.actions;

export const reset = () => async (dispatch) => {
  dispatch(resetState());
};

export const fetchDocumentsList = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/provider/documents-list-by-provider/${id}`);
    dispatch(getDocuments(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const deleteDocument = (id, providerId) => async (dispatch) => {
  try {
    await axios.delete(`/provider/delete-document/${id}`);
    dispatch(fetchDocumentsList(providerId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const uploadNewFileProvider = (id, data) => async (dispatch) => {
  try {
    await axios.post(`/provider/upload-document-by-provider/${id}`, data);
    dispatch(fetchDocumentsList(id));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(fetchDocumentsList(id));
  }
};

export default documentsProviderSlice.reducer;
