import clsx from "clsx";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { snackbarClose, snackbarOpen } from "./store/snackbar.slice";

import { sign_in_url } from "../../configs/router_urls";

import "./styles/index.scss";

const Snackbar = () => {
  const {
    isOpen, text, type, timeout = 3000,
  } = useSelector(({ snackbarReducer }) => snackbarReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  let timer = null;

  const handleTimeout = () => {
    timer = setTimeout(() => {
      dispatch(snackbarClose());
    }, timeout);
  };

  const handleClose = () => {
    clearTimeout(timer);
    dispatch(snackbarClose());
  };

  useEffect(() => {
    if (isOpen) {
      handleTimeout();
    }
    return () => clearTimeout(timer);
  }, [isOpen, timer]);

  useEffect(() => {
    if (type === "error") {
      if (text === "Invalid token") {
        history.push(sign_in_url);
        dispatch(snackbarOpen({
          isOpen: true,
          timeout: 6000,
          text: "Session is expired",
          type: "error",
        }));
      }
    }
  }, [text]);

  return (
    isOpen && (
      <div
        style={{ animationDuration: `${timeout + 100}ms` }}
        onClick={handleClose}
        className={clsx("snackbar", type, { open: isOpen })}
      >
        {text}
      </div>
    )
  );
};

export default Snackbar;
