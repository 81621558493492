import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderPage from "../../../../components/HeaderPage";
import { Button } from "../../../../components/Button";
import { DateField } from "../../../../components/DateField";
import { dateFormat } from "../../../../utils/dateFormater";
import { Select, SelectFilter } from "../../../../components/Select";
import { getSessionStatusForSelect, getCurrentSessionStatus } from "../../../../pages/calendar/getCurrentSessionStatus";
import Table from "../../../../components/Table";
import tableFormater from "../../../../utils/tableFormater";
import Pagination from "../../../../components/Pagination/Pagination";
import headerConfig from "./config";
import { getStudentOptionsAction } from "../../../../pages/calendar/session/sessionForm/store";
import { getProviderStudentsListAction } from "../providerStudentsList/store";
import { getSessionsList } from "./store";
import {
  session_notes_url,
  provider_student_invoices_url,
  provider_enter_session_url,
} from "../../../../configs/router_urls";
import "./styles/index.scss";
import { getUnique } from "../../../../utils/getUnique";

function ProviderSessions() {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const statuses = ["S", "C", "A", "I"];
  const table = useRef();
  const history = useHistory();
  const [period, setPeriod] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [date, setDate] = useState({});
  const [state, setState] = useState(0);
  const data = useSelector(({ providerSessionsReducer }) => providerSessionsReducer.data);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  useEffect(() => {
    dispatch(getStudentOptionsAction((providerId) => {
      dispatch(getSessionsList());
      dispatch(getProviderStudentsListAction({ providerId, status: ["F"] }));
    }));
  }, []);
  useEffect(() => {
    setFilters({
      ...filters,
      date: `${date.startDate} to ${date.endDate}`,
      eventStart: date.startDate,
      eventEnd: date.endDate,
    });
  }, [date]);
  useEffect(() => {
    const { date, ...params } = filters;
    dispatch(getSessionsList(params));
    setCurrentIndex(0);
  }, [filters]);

  const resetFilters = () => {
    setFilters({});
    setPeriod("");
    setState(0);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };
  const paginationClick = (offset) => {
    const { date, ...params } = filters;
    dispatch(getSessionsList(params, offset));
    setState(0);
    table.current.scrollTop = 0;
  };
  const getStudentLocation = (value) => {
    switch (value) {
    case "S":
      return "School";
    case "H":
      return "Home";
    default: return "Remote";
    }
  };

  const resetOneFilter = (obj) => {
    const newObj = { ...filters };
    delete newObj[obj];

    if (obj === "date") {
      setPeriod("");
      delete newObj.eventStart;
      delete newObj.eventEnd;
    }
    setFilters(newObj);
    setState(0);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };

  return (
    <>
      <HeaderPage title="My sessions" />
      <div className="provider-sessions-list">
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          alignItems: "center",
        }}
        >
          <div style={{ marginLeft: "auto", width: "156px" }}>
            <Button
              variant='contained'
              onClick={() => history.push(provider_enter_session_url)}>
            Create
            </Button>
          </div>
        </div>
        <div className="provider-sessions-list-header">
          <div className="provider-sessions-list-header__right">
            <div className="provider-sessions-list-header__filters">
              <div className="provider-sessions-list-header__datefield">
                <DateField
                  name="date"
                  showIcon
                  isRange
                  placeholder="Dates range"
                  setDate = {setDate}
                  nativeClick={(event) => {
                    if (!event.target.value) return;
                    setPeriod(event.target.value);
                  }}
                  value={period}
                />
                {filters.date && (
                  <div
                    className="select-reset"
                    onClick={() => resetOneFilter("date")}
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                        fill="#192A53"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="provider-sessions-list-header__textfield">
                <SelectFilter
                  name="studentName"
                  placeholder="Student"
                  data={getUnique(students.map((el) => ({ value: el.studentName, title: el.studentName })), "title")}
                  changeFilter={(value) => setFilters({ ...filters, studentName: value })}
                  filters={filters}
                  resetFilter={resetOneFilter} />
              </div>
              <div className="provider-sessions-list-header__textfield">
                <Select
                  name="sessionState"
                  placeholder="Status"
                  data={statuses.map((el) => ({ value: el, title: getSessionStatusForSelect(el) }))}
                  value={filters.sessionsState}
                  onNativeChange={(e) => setFilters({ ...filters, sessionsState: [e.target.value] })}/>
                {filters.sessionsState && (
                  <div
                    className="select-reset"
                    onClick={() => resetOneFilter("sessionsState")}
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                        fill="#192A53"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="reset-filter">
              <Button
                variant="outlined"
                color="secondary"
                onClick={resetFilters}
              >
              Refresh filters
              </Button>
            </div>
          </div>
        </div>
        <Table
          head={Object.values(headerConfig)}
          data={tableFormater(
            data.result.map((item) => ({
              ...item,
              location: <div>{getStudentLocation(item.location)}</div>,
              actualStartDt: <div>{dateFormat("mm-dd-yyyy", item.actualStartDt) }</div>,
              timePeriod: <div>{dateFormat("LT", item.actualStartDt) } - {dateFormat("LT", item.actualEndDt) }</div>,
              state: <div className="status-container">{getCurrentSessionStatus(item.state)}</div>,
            })),
            headerConfig, "assignmentId",
          )}
          state={state}
          setState={setState}
          isContrast
          typeOfTable="Provider sessions"
          isContext={true}
          customData={data.result}
          contextData={[
            {
              title: "View",
              link: session_notes_url,
            },
            {
              title: "Invoice",
              link: provider_student_invoices_url,
            },
          ]}
          tableRef={table}
        />
        <Pagination {...data.pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
      </div>
    </>
  );
}

export default ProviderSessions;
