import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import {
  getAllProvidersAction, getAllStudentsAction, getProviderDictionariesAction, getAllDictionariesAction,
} from "../../entities/admin/store";
import PrivatePage from "./PrivatePage";
import Students from "../../entities/admin/student/pages/students";
import StudentProfile from "../../entities/admin/student/pages/studentProfile";
import CreateStudent from "../../entities/admin/student/pages/createStudent";
import EditStudentProfile from "../../entities/admin/student/pages/editStudentProfile";
import StudentServices from "../../entities/admin/student/pages/studentServices";
import StudentGoal from "../../entities/admin/student/pages/studentGoal";
import StudentAssignment from "../../entities/admin/student/pages/studentAssignment";
import ProviderList from "../../entities/admin/provider/pages/providersList";
import CreateProvider from "../../entities/admin/provider/pages/createProvider";
import UpdateProviderProfile from "../../entities/admin/provider/pages/updateProvider";
import ProviderProfile from "../../entities/admin/provider/pages/providerProfile";
import ProviderStudentsList from "../../entities/provider/pages/providerStudentsList";
import Calendar from "../../pages/calendar";
import Session from "../../pages/calendar/session/sessionForm";
import ReportsPage from "../../entities/admin/provider/pages/reports/reportsPage";
import CreateGustoCSV from "../../entities/admin/provider/pages/reports/createGustoCsv";
import CreateQuickBookCsv from "../../entities/admin/provider/pages/reports/createQuickBookCsv";
import CreateAdminReport from "../../entities/admin/provider/pages/reports/createAdminReport";
import StudentAssignmentsList from "../../entities/admin/student/pages/studentAssignmentsList";
import Dict from "../../pages/dict";
import School from "../../pages/dict/components/school";
import SchoolRepresentatives from "../../pages/dict/components/schoolRepresentatives";
import GoalsDictionaries from "../../pages/dict/components/goals";
import CreateNewGoal from "../../pages/dict/components/goals/components/createNewGoal";
import EditDictGoal from "../../pages/dict/components/goals/components/editGoal";

import NdEditGoal from "../../entities/admin/student/pages/ndEditGoal";
import EditStudentGoal from "../../entities/admin/student/pages/editStudentGoal";
import AddNewStudentServices from "../../entities/admin/student/pages/addNewStudentServices/AddNewStudentServices";
import EditStudentService from "../../entities/admin/student/pages/editStudentService";
import NdAddNewGoal from "../../entities/admin/student/pages/ndAddNewGoal";
import NdGenerateGoal from "../../entities/admin/student/pages/ngGenerateGoal";

import StudentDocuments from "../../entities/admin/student/pages/documents/studentDocuments";
import ProviderDocuments from "../../entities/admin/provider/pages/documents/providerDocuments";
import EditStudentAssignment from "../../entities/admin/student/pages/editStudentAssignment";
import NotFound from "../../pages/404";
import Invoices from "../../pages/invoices/Invoices";
import EmailPreview from "../../pages/invoices/emailPreview/EmailPreview";
import InvoiceView from "../../pages/invoices/invoiceView";
import Messages from "../../entities/admin/adminMessages/Messages";
import SystemRole from "../../pages/systemRole/SystemRole";
import SystemRoleEdit from "../../pages/systemRole/components/superadmin/Superadmin";
import SystemUsers from "../../pages/systemUsers/SystemUsers";
import AllSessions from "../../entities/admin/sessions";
import CallLog from "../../entities/admin/provider/pages/callLog/CallLog";

function AdminPage({ isAuth, roles, urls }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderDictionariesAction());
    dispatch(getAllProvidersAction("A"));
    dispatch(getAllStudentsAction());
    dispatch(getAllDictionariesAction());
  }, []);

  return (
    <Switch>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.students.permittedRoles}
        path={urls.config.students.url}
        exact
      >
        <Students />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.sessions.permittedRoles}
        path={urls.config.sessions.url}
        exact
      >
        <AllSessions/>
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.generate_goal.permittedRoles}
        path={`${urls.config.generate_goal.url}`}
        exact
      >
        <NdGenerateGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.edit_goal.permittedRoles}
        path={`${urls.config.edit_goal.url}`}
        exact
      >
        <NdEditGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.add_new_goal.permittedRoles}
        path={urls.config.add_new_goal.url}
        exact
      >
        <NdAddNewGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.edit_goal.permittedRoles}
        path={urls.config.edit_goal.url}
        exact
      >
        <EditStudentGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.add_new_services.permittedRoles}
        path={urls.config.add_new_services.url}
        exact
      >
        <AddNewStudentServices />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.edit_service.permittedRoles}
        path={urls.config.edit_service.url}
        exact
      >
        <EditStudentService />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.calendar.permittedRoles}
        path={urls.config.calendar.url}
        exact
      >
        <Calendar />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.create_session.permittedRoles}
        path={urls.config.create_session.url}
        exact
      >
        <Session />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_invoices.permittedRoles}
        path={urls.config.admin_invoices.url}
        exact
      >
        <Invoices />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_email_preview.permittedRoles}
        path={urls.config.admin_email_preview.url}
        exact
      >
        <EmailPreview />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values.permittedRoles}
        path={urls.config.banks_of_values.url}
        exact
      >
        <Dict />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values_school.permittedRoles}
        path={urls.config.banks_of_values_school.url}
        exact
      >
        <School />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values_school_representatives.permittedRoles}
        path={urls.config.banks_of_values_school_representatives.url}
        exact
      >
        <SchoolRepresentatives />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values_goals.permittedRoles}
        path={urls.config.banks_of_values_goals.url}
        exact
      >
        <GoalsDictionaries />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values_add_new_goal.permittedRoles}
        path={urls.config.banks_of_values_add_new_goal.url}
        exact
      >
        <CreateNewGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.banks_of_values_edit_goal.permittedRoles}
        path={urls.config.banks_of_values_edit_goal.url}
        exact
      >
        <EditDictGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_system_users.permittedRoles}
        path={urls.config.admin_system_users.url}
        exact
      >
        <SystemUsers />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_system_roles.permittedRoles}
        path={urls.config.admin_system_roles.url}
        exact
      >
        <SystemRole />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_system_roles_edit.permittedRoles}
        path={urls.config.admin_system_roles_edit.url}
        exact
      >
        <SystemRoleEdit />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.create_students.permittedRoles}
        path={urls.config.create_students.url}
        exact
      >
        <CreateStudent />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.students_goals.permittedRoles}
        path={urls.config.students_goals.url}
        exact
      >
        <StudentGoal />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.students_profile.permittedRoles}
        path={urls.config.students_profile.url}
        exact
      >
        <StudentProfile />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.students_edit_profile.permittedRoles}
        path={urls.config.students_edit_profile.url}
        exact
      >
        <EditStudentProfile />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.students_services.permittedRoles}
        path={urls.config.students_services.url}
        exact
      >
        <StudentServices />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_students_assigments_list.permittedRoles}
        path={urls.config.admin_students_assigments_list.url}
        exact
      >
        <StudentAssignmentsList />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_create_students_assigment.permittedRoles}
        path={urls.config.admin_create_students_assigment.url}
        exact
      >
        <StudentAssignment />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_students_assigments_list_edit.permittedRoles}
        path={urls.config.admin_students_assigments_list_edit.url}
        exact
      >
        <EditStudentAssignment />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.providers.permittedRoles}
        path={urls.config.providers.url}
        exact
      >
        <ProviderList />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_provider_documents_url.permittedRoles}
        path={urls.config.admin_provider_documents_url.url}
        exact
      >
        <ProviderDocuments />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.create_provider.permittedRoles}
        path={urls.config.create_provider.url}
        exact
      >
        <CreateProvider />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_profile.permittedRoles}
        path={urls.config.provider_profile.url}
        exact
      >
        <ProviderProfile />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_edit_profile.permittedRoles}
        path={urls.config.provider_edit_profile.url}
        exact
      >
        <UpdateProviderProfile />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_students.permittedRoles}
        path={urls.config.provider_students.url}
        exact
      >
        <ProviderStudentsList />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_reports.permittedRoles}
        path={urls.config.admin_reports.url}
        exact
      >
        <ReportsPage />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.admin_reports_create_gusto_csv.permittedRoles
        }
        path={urls.config.admin_reports_create_gusto_csv.url}
        exact
      >
        <CreateGustoCSV />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.admin_reports_create_quick_csv.permittedRoles
        }
        path={urls.config.admin_reports_create_quick_csv.url}
        exact
      >
        <CreateQuickBookCsv />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.admin_reports_create_report_for_admin.permittedRoles
        }
        path={urls.config.admin_reports_create_report_for_admin.url}
        exact
      >
        <CreateAdminReport />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_students_documents.permittedRoles}
        path={urls.config.admin_students_documents.url}
        exact
      >
        <StudentDocuments />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_invoice_view.permittedRoles}
        path={urls.config.admin_invoice_view.url}
        exact
      >
        <InvoiceView />
      </PrivatePage>

      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_messages.permittedRoles}
        path={urls.config.admin_messages.url}
        exact
      >
        <Messages />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.admin_provider_calls.permittedRoles}
        path={urls.config.admin_provider_calls.url}
        exact
      >
        <CallLog />
      </PrivatePage>

      <Route path="*">
        {" "}
        <NotFound/>
      </Route>

    </Switch>
  );
}

export default AdminPage;
