import moment from "moment";
import * as Yup from "yup";

const fieldConfigStudents = [
  {
    id: 0,
    name: "student",
    label: "Student",
    placeholder: "",
    required: true,
    type: "myltiSelect",
    title: "student",
    selected: [],
    setSelected: () => {},
    data: [],
    setValueChange: () => {},
  },
  {
    id: 1,
    type: "empty",
  },
  {
    id: 2,
    type: "empty",
  },
  {
    id: "2a",
    type: "empty",
  },
];

const fieldConfigDate = [
  {
    id: 22,
    name: "date",
    label: "Scheduled date",
    placeholder: "Scheduled date",
    required: true,
    type: "datefield",
  },
  {
    id: 33,
    name: "start_time",
    label: "Scheduled start",
    required: true,
    step: 15,
    placeholder: "-- : -- PM",
    type: "timerange",
  },
  {
    id: 45,
    name: "end_time",
    label: "Scheduled end",
    required: true,
    step: 15,
    placeholder: "-- : -- PM",
    type: "timerange",
  },
  {
    type: "empty",
  },
  {
    type: "empty",
  },
];

const formikInitialValues = {
  date: moment().format("MM-DD-YYYY"),
};

const yupObject = {
  date: Yup.date("Invalid Date").required("required field"),
  student: Yup.string().required("required field"),
  start_time: Yup.date("Invalid Time").required("required field"),
  end_time: Yup.date("Invalid Time").required("required field"),
};

const validationSchema = Yup.object(yupObject);

export {
  formikInitialValues,
  validationSchema,
  fieldConfigStudents,
  fieldConfigDate,
};
