import React from "react";
import SvgMaker from "../../../../../components/svgMaker/SvgMaker";
import FormikButton, { Button } from "../../../../../components/Button";
import { CheckBox } from "../../../../../components/Checkbox";
import PaperModalGoals from "./modal/PaperModal";

const ViewCheckbox = ({
  val, id, name, setVal, isFirstLevel,
}) => (
  <>
    {!isFirstLevel
    && <div style={val[val.length - 1] !== id ? { display: "none" } : { display: "block" }}
      className={`${id}/${name}`}
      onClick={() => {
        setVal([...val, id]);
      }}>
      <CheckBox checked={val[val.length - 1] === id}/>
    </div>
    }
    {isFirstLevel
    && <div className="checkBoxView" style={val[val.length - 1] !== "firstLevel" ? { display: "none" } : { display: "block" }} >
      <CheckBox checked={val[val.length - 1] === "firstLevel"} />
    </div>
    }
  </>
);

function SubGroup({
  subGroups, name, id, open, openChild, val, setVal, idGroup, isCreate, withFormik, isUpdate, arrayGroupIds, setType,
}) {
  return (
    <div className="goalsSubGroupContainer">
      <div id={`${id + name}1`} style={{ display: "flex", alignItems: "center", padding: "10px 0" }} className="openSubgroup"
        onClick={() => openChild(`${id}&${name}`, `${id + name}1`)}>
        <ViewCheckbox val={val} id={id} name={name} setVal={setVal} isFirstLevel={false}/>

        {val[val.length - 1] !== id && !isCreate && !isUpdate
            && <div>
              <div className="goalsSubGroup__icon" onClick={(e) => {
                if (id !== idGroup) {
                  e.currentTarget.parentElement.previousElementSibling.style.display = "flex";
                  setVal([...val, id]);
                } else {
                  return null;
                }
              } }>
                {subGroups.length
                  ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                  : <SvgMaker width='18' height='18' name='dotIcon'/>
                }
              </div>
            </div>
        }

        {val[val.length - 1] !== id && isCreate && !isUpdate
            && <div>
              <div className="goalsSubGroup__icon" onClick={() => {
                setVal([...val, id]);
                setType("subGroup");
              }}>
                {subGroups.length
                  ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                  : <SvgMaker width='18' height='18' name='dotIcon'/>
                }
              </div>
            </div>
        }

        {val[val.length - 1] !== id && isUpdate && !isCreate
            && <div>
              <div className="goalsSubGroup__icon" onClick={(e) => {
                if (id !== arrayGroupIds?.find((el) => el === id)) {
                  e.currentTarget.parentElement.previousElementSibling.style.display = "flex";
                  setVal([...val, id]);
                  setType("subGroup");
                } else {
                  return null;
                }
              } }>
                {subGroups.length
                  ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                  : <SvgMaker width='18' height='18' name='dotIcon'/>
                }
              </div>
            </div>
        }

        {id === idGroup && !isCreate && !isUpdate
          ? <div className="goalsSubGroupContainer__name" style={{ opacity: "0.4" }}>{name}</div>
          : !isCreate && !isUpdate
          && <div style={val[val.length - 1] === id ? { fontWeight: "bold" } : { fontWeight: "normal" }} className="goalsSubGroupContainer__name">
            {name}
          </div>
        }
        {isCreate && withFormik && !isUpdate && val[val.length - 1] !== id
          ? <div className="goalsSubGroupContainer__name">{name}</div>
          : isCreate && withFormik && !isUpdate && val[val.length - 1] === id
          && <div className="goalsSubGroupContainer__name" style={{ fontWeight: "bold" }}>{name}</div>
        }
        {id === arrayGroupIds?.find((el) => el === id) && !isCreate && isUpdate
          ? <div className="goalsSubGroupContainer__name" style={{ opacity: "0.4" }}>{name}</div>
          : !isCreate && isUpdate && <div style={val[val.length - 1] === id ? { fontWeight: "bold" } : { fontWeight: "normal" }} className="goalsSubGroupContainer__name">
            {name}
          </div>
        }

      </div>
      {!subGroups.length ? null
        : subGroups.map((subgroup) => <div className={`${id}&${name}`} key={subgroup.id} style={{
          fontFamily: "Poppins", color: "#495057", fontSize: "16px", display: "none",
        }}>
          <SubGroup {...subgroup} open={open} val={val} setVal={setVal} idGroup={idGroup} withFormik={withFormik} isCreate={isCreate} isUpdate={isUpdate}
            openChild={openChild} arrayGroupIds={arrayGroupIds} setType={setType}
          />
        </div>)}
    </div>
  );
}

function ModalWindow({
  val, setVal, open, onCancel, dictionaryGoals, onClose, infoGoal, onConfirm, headerTitle, children,
  buttonTitle, withFormik, isCreate, text, isUpdate, arrayGroupIds, isHide, setType,
}) {
  const openGroup = (event, id) => {
    const elem = document.getElementById(id);

    if (elem.style.display === "none") {
      elem.style.display = "block";
    } else {
      elem.style.display = "none";
    }
  };

  const openSubGroup = (className, id) => {
    const elements = document.getElementsByClassName(className);
    const elem = document.getElementById(id);
    if (elem.className === "openSubgroup") {
      [...elements].forEach((elem) => {
        elem.style.height = "auto";
        [...elements].forEach((el) => el.style.display = "block");
      });
      elem.className = "hideSubgroup";
    } else {
      elem.className = "openSubgroup";
      [...elements].forEach((elem) => {
        elem.style.height = "0px";
        [...elements].forEach((el) => el.style.display = "none");
      });
    }
  };

  return (
    <div className="goalsModalWindow">
      <PaperModalGoals headerTitle={headerTitle} open={open} onClose={onClose} isHide={isHide}>
        <div>
          {children}

          {(isUpdate || isCreate) && <div style={{ display: "flex", marginBottom: "20px" }} >
            <ViewCheckbox val={val} setVal={setVal} isFirstLevel={true}/>
            <div
              style={{
                display: "flex", alignItems: "center", marginRight: "5px", cursor: "pointer",
              }}
              onClick={(e) => { e.currentTarget.parentElement.firstElementChild.style.display = "block"; setVal([...val, "firstLevel"]); }}>
              <SvgMaker width='18' height='18' name='dotIcon' />
            </div>
            {val[val.length - 1] !== "firstLevel"
              ? <div className="goalsModalWindow__firstLevel">{`${text} to the first layer`}</div>
              : val[val.length - 1] === "firstLevel" && <div className="goalsModalWindow__firstLevel" style={{ fontWeight: "bold" }}>{`${text} to the first layer`}</div>
            }
          </div>}

          {dictionaryGoals.map((group) => (
            <div key={group.id + group.name}>
              <div className="goalsGroup">
                <div
                  className={group.subGroups.length ? "goalsGroup__arrowDown" : "goalsGroup__dotIcon"}
                  onClick={(e) => openGroup(e, `${group.id + group.name}1`)}
                >
                  <ViewCheckbox val={val} id={group.id} name={group.name} setVal={setVal} isFirstLevel={false}/>

                  {val[val.length - 1] !== group.id && !withFormik && !isCreate && !isUpdate
                        && <div className="goalsGroup__icon" style={{ display: "flex" }} onClick={() => {
                          if (infoGoal.goalGroupId !== group.id) {
                            setVal([...val, group.id]);
                          } else {
                            return null;
                          }
                        } }>
                          {group.subGroups.length
                            ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                            : <SvgMaker width='18' height='18' name='dotIcon' />}
                        </div>
                  }

                  {val[val.length - 1] !== group.id && isCreate && !isUpdate
                        && <div className="goalsGroup__icon" style={{ display: "flex" }} onClick={() => {
                          setVal([...val, group.id]);
                          setType("group");
                        }}>
                          {group.subGroups.length
                            ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                            : <SvgMaker width='18' height='18' name='dotIcon' />}
                        </div>
                  }

                  {val[val.length - 1] !== group.id && isUpdate && !isCreate
                        && <div className="goalsGroup__icon" style={{ display: "flex" }} onClick={() => {
                          if (group.id !== arrayGroupIds?.find((el) => el === group.id)) {
                            setVal([...val, group.id]);
                            setType("group");
                          } else {
                            return null;
                          }
                        }}>
                          {group.subGroups.length
                            ? <SvgMaker width='18' height='18' name='arrowDown' stroke="#495057" />
                            : <SvgMaker width='18' height='18' name='dotIcon' />}
                        </div>
                  }

                  {isCreate && !isUpdate && val[val.length - 1] !== group.id
                    ? <div className="goalsGroup__titleGroup">{group.name} {group.groupCd || ""}</div>
                    : isCreate && !isUpdate && val[val.length - 1] === group.id
                    && <div className="goalsGroup__titleGroup" style={{ fontWeight: "bold" }}>{group.name} {group.groupCd || ""}</div>
                  }

                  {infoGoal.goalGroupId === group.id && !isCreate && !isUpdate
                    ? <div className="goalsGroup__titleGroup" style={{ opacity: "0.4" }}>{group.name} {group.groupCd || ""}</div>
                    : !isCreate && !isUpdate
                    && <div style={val[val.length - 1] === group.id ? { fontWeight: "bold" } : { fontWeight: "normal" }} className="goalsGroup__titleGroup">
                      {group.name} {group.groupCd || ""}
                    </div>
                  }

                  {(arrayGroupIds?.find((el) => el === group.id)) && isUpdate && !isCreate
                    ? <div className="goalsGroup__titleGroup" style={{ opacity: "0.4" }}>{group.name} {group.groupCd || ""}</div>
                    : !isCreate && isUpdate
                    && <div style={val[val.length - 1] === group.id ? { fontWeight: "bold" } : { fontWeight: "normal" }} className="goalsGroup__titleGroup">
                      {group.name} {group.groupCd || ""}
                    </div>
                  }

                </div>

                <div id={`${group.id + group.name}1`} className="openGroup" style={{ display: "none" }}>
                  {group.subGroups.map((subgroup) => (
                    <SubGroup key={subgroup.id}
                      {...subgroup}
                      val={val}
                      setVal={setVal}
                      idGroup={infoGoal.goalGroupId}
                      withFormik={withFormik}
                      isCreate={isCreate}
                      isUpdate={isUpdate}
                      arrayGroupIds={arrayGroupIds}
                      openChild={openSubGroup}
                      setType={setType}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div><div className="goalsModalWindow__btn">
          {withFormik ? <FormikButton variant="contained" color="primary" >
            {buttonTitle}
          </FormikButton> : <Button variant="contained" color="primary"
            disabled={!val.length}
            onClick={onConfirm}>
            {buttonTitle}
          </Button>}
          <Button variant="dashed" color="primary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </PaperModalGoals>
    </div>
  );
}

export default ModalWindow;
