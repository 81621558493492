export const headerConfig = {
  studentName: {
    title: "Student name",
    value: "studentName",
    order: 1,
  },
  caseNo: {
    title: "Case number",
    value: "caseNo",
    order: 2,
  },
  serviceType: {
    title: "Service type",
    value: "serviceType",
    order: 3,
  },
  invoiceMonth: {
    title: "Invoice month",
    value: "invoiceMonth",
    order: 4,
  },
  minutesAmount: {
    title: "Hours amount",
    value: "minutesAmount",
    order: 5,
  },
  billingRate: {
    title: "Provider rate",
    value: "billingRate",
    order: 6,
  },
};
