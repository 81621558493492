const headerConfig = {
  actualStartDt: {
    id: 1,
    title: "Date",
    value: "actualStartDt",
    order: 1,
  },
  timePeriod: {
    id: 2,
    title: "Time period",
    value: "timePeriod",
    order: 2,
  },
  typeName: {
    id: 3,
    title: "Service type",
    value: "typeName",
    order: 3,
  },
  sessionLength: {
    id: 4,
    title: "Length of session",
    value: "sessionLength",
    order: 4,
  },
  payrollRate: {
    id: 5,
    title: "Payroll",
    value: "payrollRate",
    order: 5,
  },
  location: {
    id: 6,
    title: "Location",
    value: "location",
    order: 6,
  },
  studentName: {
    id: 7,
    title: "Students",
    value: "studentName",
    order: 7,
  },
  state: {
    id: 8,
    title: "Status",
    value: "state",
    order: 8,
  },
};
export default headerConfig;
