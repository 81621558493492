import * as urls from "./router_urls";

export const config = {
  admin: [
    {
      title: "All students",
      img: "drawerStudents",
      roles: urls.config.students.permittedRoles,
      url: urls.admmin_students_url,
      exact: false,
    },
    {
      title: "All sessions",
      img: "drawerSessions",
      roles: urls.config.sessions.permittedRoles,
      url: urls.admmin_sessions_url,
      exact: false,
    },
    {
      title: "Invoices",
      img: "drawerInvoices",
      roles: urls.config.admin_invoices.permittedRoles,
      url: urls.admin_invoices_url,
      exact: false,
    },
    {
      title: "All providers",
      img: "drawerAllProviders",
      roles: urls.config.providers.permittedRoles,
      url: urls.admin_providers_url,
      exact: false,
    },
    {
      title: "Messages for providers",
      img: "drawerMessages",
      roles: urls.config.admin_messages.permittedRoles,
      url: urls.admin_messages_url,
      exact: false,
    },
    {
      title: "All assignments",
      img: "drawerStudentsAssignments",
      roles: urls.config.admin_students_assigments_list.permittedRoles,
      url: urls.admin_students_assigments_list_url,
      exact: false,
    },
    {
      title: "Call log",
      img: "drawerCallLog",
      roles: urls.config.admin_provider_calls.permittedRoles,
      url: urls.admin_provider_calls_url,
      exact: false,
    },
    {
      title: "Banks of values",
      img: "banksOfValuesIcon",
      roles: urls.config.banks_of_values.permittedRoles,
      url: urls.banks_of_values_url,
      exact: false,
    },
    {
      title: "Reports",
      img: "drawerReports",
      roles: urls.config.admin_reports.permittedRoles,
      url: urls.admin_reports_url,
      exact: false,
    },
    // {
    //   title: "Tutorials",
    //   img: "drawerTutorials",
    //   roles: urls.config.tutorials.permittedRoles,
    //   url: urls.tutorials,
    //   exact: false,
    // },
    // {
    //   title: "Help",
    //   img: "drawerHelp",
    //   roles: urls.config.help.permittedRoles,
    //   url: urls.help_url,
    //   exact: false,
    // },
    // {
    //   title: "Settings",
    //   img: "drawerSettings",
    //   roles: urls.config.settings.permittedRoles,
    //   url: urls.settings_url,
    //   exact: false,
    // },
    // {
    //   title: "Internal company contacts",
    //   img: "drawerInternalContacts",
    //   roles: urls.config.internal_company_contacts.permittedRoles,
    //   url: urls.internal_company_contacts_url,
    //   exact: false,
    // },
  ],
  provider: [
    {
      title: "Home",
      img: "drawerHome",
      roles: urls.config.home.permittedRoles,
      url: urls.home_url,
      exact: true,
    },
    {
      title: "My students",
      img: "drawerStudents",
      roles: urls.config.provider_students_assigment.permittedRoles,
      url: urls.provider_students_assigment_url,
      exact: false,
    },
    // {
    //   title: "Personal documents",
    //   img: "drawerDocuments",
    //   roles: urls.config.personal_documents.permittedRoles,
    //   url: urls.personal_documents_url,
    //   exact: false,
    // },
    {
      title: "Calendar",
      img: "drawerCalendar",
      roles: urls.config.calendar.permittedRoles,
      url: urls.calendar_url,
      exact: false,
    },
    {
      title: "My sessions",
      img: "drawerSessions",
      roles: urls.config.provider_sessions.permittedRoles,
      url: urls.provider_sessions_url,
      exact: false,
    },
    {
      title: "Enter session",
      img: "drawerEnterSession",
      roles: urls.config.provider_enter_session.permittedRoles,
      url: urls.provider_enter_session_url,
      exact: false,
      preItem: true,
    },
    {
      title: "Provider profile",
      img: "drawerProvider",
      roles: urls.config.providers.permittedRoles,
      url: `${urls.provider_profile_url}`,
      exact: false,
    },
    {
      title: "Call log",
      img: "drawerCallLog",
      roles: urls.config.provider_calls_log.permittedRoles,
      url: urls.config.provider_calls_log.url,
      exact: false,
    },
    {
      title: "My invoices",
      img: "drawerInvoices",
      roles: urls.config.provider_invoices.permittedRoles,
      url: urls.provider_invoices_url,
      exact: false,
    },
    // {
    //   title: "Assignments",
    //   img: "drawerStudentsAssignments",
    //   roles: urls.config.provider_students_assigment.permittedRoles,
    //   url: urls.provider_students_assigment_url,
    //   exact: false,
    // },
    {
      title: "My progress reports",
      img: "drawerReports",
      roles: urls.config.provider_progress_report_list.permittedRoles,
      // url: urls.provider_progress_report_list_url,
      url: "/reports", // temporary!!!
      exact: false,
    },
    // {
    //   title: "Tutorials",
    //   img: "drawerTutorials",
    //   roles: urls.config.tutorials.permittedRoles,
    //   url: urls.tutorials,
    //   exact: false,
    // },
    // {
    //   title: "Help",
    //   img: "drawerHelp",
    //   roles: urls.config.help.permittedRoles,
    //   url: urls.help_url,
    //   exact: false,
    // },
    // {
    //   title: "Settings",
    //   img: "drawerSettings",
    //   roles: urls.config.settings.permittedRoles,
    //   url: urls.settings_url,
    //   exact: false,
    // },
    // {
    //   title: "Internal company contacts",
    //   img: "drawerInternalContacts",
    //   roles: urls.config.internal_company_contacts.permittedRoles,
    //   url: urls.internal_company_contacts_url,
    //   exact: false,
    // },
  ],
  super_admin: [
    {
      title: "All students",
      img: "drawerStudents",
      roles: urls.config.students.permittedRoles,
      url: urls.admmin_students_url,
      exact: false,
    },
    {
      title: "All sessions",
      img: "drawerSessions",
      roles: urls.config.sessions.permittedRoles,
      url: urls.admmin_sessions_url,
      exact: false,
    },
    {
      title: "Invoices",
      img: "drawerInvoices",
      roles: urls.config.admin_invoices.permittedRoles,
      url: urls.admin_invoices_url,
      exact: false,
    },
    {
      title: "All providers",
      img: "drawerAllProviders",
      roles: urls.config.providers.permittedRoles,
      url: urls.admin_providers_url,
      exact: false,
    },
    {
      title: "Messages for providers",
      img: "drawerMessages",
      roles: urls.config.admin_messages.permittedRoles,
      url: urls.admin_messages_url,
      exact: false,
    },
    {
      title: "All assignments",
      img: "drawerStudentsAssignments",
      roles: urls.config.admin_students_assigments_list.permittedRoles,
      url: urls.admin_students_assigments_list_url,
      exact: false,
    },
    {
      title: "Call log",
      img: "drawerCallLog",
      roles: urls.config.admin_provider_calls.permittedRoles,
      url: urls.admin_provider_calls_url,
      exact: false,
    },
    {
      title: "System users",
      img: "systemUsersIcon",
      roles: urls.config.admin_system_users.permittedRoles,
      url: urls.admin_system_users_url,
      exact: false,
    },
    {
      title: "Banks of values",
      img: "banksOfValuesIcon",
      roles: urls.config.banks_of_values.permittedRoles,
      url: urls.banks_of_values_url,
      exact: false,
    },
    {
      title: "System role",
      img: "systemRoleIcon",
      roles: urls.config.admin_system_roles.permittedRoles,
      url: urls.admin_system_roles_url,
      exact: false,
    },
    {
      title: "Reports",
      img: "drawerReports",
      roles: urls.config.admin_reports.permittedRoles,
      url: urls.admin_reports_url,
      exact: false,
    },
    // {
    //   title: "Tutorials",
    //   img: "drawerTutorials",
    //   roles: urls.config.tutorials.permittedRoles,
    //   url: urls.tutorials,
    //   exact: false,
    // },
    // {
    //   title: "Help",
    //   img: "drawerHelp",
    //   roles: urls.config.help.permittedRoles,
    //   url: urls.help_url,
    //   exact: false,
    // },
    // {
    //   title: "Settings",
    //   img: "drawerSettings",
    //   roles: urls.config.settings.permittedRoles,
    //   url: urls.settings_url,
    //   exact: false,
    // },
    // {
    //   title: "Internal company contacts",
    //   img: "drawerInternalContacts",
    //   roles: urls.config.internal_company_contacts.permittedRoles,
    //   url: urls.internal_company_contacts_url,
    //   exact: false,
    // },
  ],
};
