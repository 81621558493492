import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivatePage from "./components/PrivatePage";
import ProviderStudentsList from "../entities/provider/pages/providerStudentsList";
import Calendar from "../pages/calendar";
import CreateSession from "../pages/calendar/session/sessionForm";
import CreateProgressReport from "../entities/provider/pages/createProgressReport";
import UpdateProgressReport from "../entities/provider/pages/updateProgressReport";
import ProgressReportView from "../entities/provider/pages/progressReportView";
import ProgressReportList from "../entities/provider/pages/ProgressReportList";

import AdminPage from "./components/AdminPage";
import SessionNotes from "../pages/calendar/session/sessionNotesForm";
import ProviderProf from "../entities/provider/pages/providerProfile";
import UpdateProviderProfile from "../entities/provider/pages/updateProviderProfile";
import Invoices from "../entities/provider/pages/invoices";
import StudentInvocesList from "../entities/provider/pages/studentInvoices";
import StudentInvoicesValidations from "../entities/provider/pages/studentInvoicesValidations";
import StudentCallLogs from "../entities/provider/pages/studentCallLogs/StudentCallLogs";
import ProviderCallLog from "../entities/provider/pages/providerCallLog/ProviderCallLog";
import Documents from "../entities/provider/pages/providerDocuments/Documents";
import ProviderSessions from "../entities/provider/pages/providerSessions/ProviderSessions";
import ProviderStudentProfile from "../entities/provider/pages/studentProfile/ProviderStudentProfile";
import ProviderStudentDocuments from "../entities/provider/pages/studentDocuments/ProviderStudentDocuments";
import StudentPreviewInvoice from "../entities/provider/pages/studentPreviewInvoice/StudentPreviewInvoice";
import InvoiceView from "../entities/provider/pages/studentInvoices/invoiceView/InvoiceView";
import NotFound from "../pages/404";
import EditSession from "../pages/calendar/session/sessionForm/EditSession";
import ProviderStudentAssignmentView from "../entities/provider/pages/providerStudentAssignmentView";
import EnterSession from "../pages/calendar/session/enterSession";

const entitesRoutes = {
  admin: ({ isAuth, roles, urls }) => (
    <AdminPage isAuth={isAuth} roles={roles} urls={urls} />
  ),
  super_admin: ({ isAuth, roles, urls }) => (
    <AdminPage isAuth={isAuth} roles={roles} urls={urls} />
  ),
  provider: ({ isAuth, roles, urls }) => (
    <Switch>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.calendar.permittedRoles}
        path={urls.config.calendar.url}
        exact
      >
        <Calendar />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_profile_url.permittedRoles}
        path={urls.config.provider_profile_url.url}
        exact
      >
        <ProviderProf />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_edit_profile_url.permittedRoles}
        path={urls.config.provider_edit_profile_url.url}
        exact
      >
        <UpdateProviderProfile/>
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.create_session.permittedRoles}
        path={urls.config.create_session.url}
        exact
      >
        <CreateSession />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.edit_session.permittedRoles}
        path={urls.config.edit_session.url}
        exact
      >
        <EditSession/>
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.session_notes.permittedRoles}
        path={urls.config.session_notes.url}
      >
        <SessionNotes />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_students_assigment.permittedRoles}
        path={urls.config.provider_students_assigment.url}
        exact
      >
        <ProviderStudentsList />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_invoices.permittedRoles}
        path={urls.config.provider_invoices.url}
        exact
      >
        <Invoices />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_student_invoices.permittedRoles}
        path={urls.config.provider_student_invoices.url}
        exact
      >
        <StudentInvocesList />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_student_invoices_validation.permittedRoles}
        path={urls.config.provider_student_invoices_validation.url}
        exact
      >
        <StudentInvoicesValidations />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_create_progress_report.permittedRoles
        }
        path={urls.config.provider_create_progress_report.url}
        exact
      >
        <CreateProgressReport />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_progress_report_view.permittedRoles
        }
        path={urls.config.provider_progress_report_view.url}
        exact
      >
        <ProgressReportView />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_update_progress_report.permittedRoles
        }
        path={urls.config.provider_update_progress_report.url}
        exact
      >
        <UpdateProgressReport />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_progress_report_list.permittedRoles
        }
        path={"/reports"} // temp!!!
        exact
      >
        <div > all reports </div>
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_progress_report_list.permittedRoles
        }
        path={urls.config.provider_progress_report_list.url}
        exact
      >
        <ProgressReportList />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.student_calls_log.permittedRoles
        }
        path={urls.config.student_calls_log.url}
        exact
      >
        <StudentCallLogs />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_calls_log.permittedRoles
        }
        path={urls.config.provider_calls_log.url}
        exact
      >
        <ProviderCallLog />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={
          urls.config.provider_students_assigment_view.permittedRoles
        }
        path={urls.config.provider_students_assigment_view.url}
        exact
      >
        <ProviderStudentAssignmentView />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_documents.permittedRoles}
        path={urls.config.provider_documents.url}
        exact
      >
        <Documents />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_sessions.permittedRoles}
        path={urls.config.provider_sessions.url}
        exact
      >
        <ProviderSessions />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_student_profile.permittedRoles}
        path={urls.config.provider_student_profile.url}
        exact
      >
        <ProviderStudentProfile />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_student_documents.permittedRoles}
        path={urls.config.provider_student_documents.url}
        exact
      >
        <ProviderStudentDocuments />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_student_invoice_preview.permittedRoles}
        path={urls.config.provider_student_invoice_preview.url}
        exact
      >
        <StudentPreviewInvoice />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_invoice_view.permittedRoles}
        path={urls.config.provider_invoice_view.url}
        exact
      >
        <InvoiceView />
      </PrivatePage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.provider_enter_session.permittedRoles}
        path={urls.config.provider_enter_session.url}
      >
        <EnterSession />
      </PrivatePage>
      <Route path="*">
        {" "}
        <NotFound/>
      </Route>
    </Switch>
  ),
};

export default entitesRoutes;
