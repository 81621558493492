import React, { useRef, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import useForceUpdate from "../../../hooks/useForceUpdate";
import SvgMaker from "../../../components/svgMaker";

import "./style.scss";

function DrawerItem({
  title, img, url, exact, preItem,
}) {
  const linkRef = useRef(null);
  const location = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(forceUpdate, [location]);

  const style = () => {
    if (linkRef.current?.className.includes("aсtive") && title.length > 17) {
      return { background: "#FF9500", height: "62px" };
    }
    if (linkRef.current?.className.includes("aсtive") && title.length <= 17) {
      return { background: "#FF9500", height: "42px" };
    }
    return {};
  };

  return (
    <div className={`drawer_item ${preItem ? "pre-item" : ""}`} style={style()}>
      <NavLink
        ref={linkRef}
        to={url}
        exact = {exact}
        className="drawer_item-link"
        activeClassName="aсtive"
      >
        <SvgMaker
          fill={
            linkRef.current?.className.includes("aсtive") ? "#FFFFFF" : null
          }
          name={img}
        />
        <span className="drawer_item-link-name">{title}</span>
      </NavLink>
    </div>
  );
}
export default DrawerItem;
