import { dateFormat } from "../../../../../../utils/dateFormater";

function transformServicesData(data) {
  const rebuildData = data.map((item) => {
    const rebuildItem = {};
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === "boolean") {
        rebuildItem[key] = item[key] ? "Yes" : "No";
      } else {
        rebuildItem[key] = item[key];
      }
    });
    return { ...rebuildItem, authorizationPeriod: `${dateFormat("mm-dd-yyyy", rebuildItem.authorizationStartDt)} to ${dateFormat("mm-dd-yyyy", rebuildItem.authorizationEndDt)}` };
  });
  return rebuildData;
}

function rebuildService(service) {
  const rebuildItem = {};
  Object.keys(service).forEach((key) => {
    if (typeof service[key] === "boolean") {
      rebuildItem[key] = service[key] ? "true" : "false";
    } else {
      rebuildItem[key] = service[key];
    }
  });
  return rebuildItem;
}

function transformValueField(data) {
  const dollar = "$";
  const rebuildItem = data.map((el) => {
    if (el.serviceTypeNo === 1) {
      return { ...el, serviceTypeNo: "SETSS" };
    }
    if (el.serviceTypeNo === 2) {
      return { ...el, serviceTypeNo: "SEIT" };
    }
    if (el.serviceTypeNo === 3) {
      return { ...el, serviceTypeNo: "Speech" };
    }
    if (el.serviceTypeNo === 4) {
      return { ...el, serviceTypeNo: "OT" };
    }
    if (el.serviceTypeNo === 5) {
      return { ...el, serviceTypeNo: "PT" };
    }
    if (el.serviceTypeNo === 6) {
      return { ...el, serviceTypeNo: "Counseiling" };
    }
    if (el.serviceTypeNo === 7) {
      return { ...el, serviceTypeNo: "Para" };
    }
    if (el.serviceTypeNo === 8) {
      return { ...el, serviceTypeNo: "BCBA" };
    }
    if (el.serviceTypeNo === 9) {
      return { ...el, serviceTypeNo: "Teacher" };
    }
    if (el.serviceTypeNo === 10) {
      return { ...el, serviceTypeNo: "Specialty teacher" };
    }
    return { ...el };
  }).map((item) => {
    if (item.ratio === "1") {
      return { ...item, ratio: "1:1" };
    }
    if (item.ratio === "G") {
      return { ...item, ratio: "Group" };
    }
    if (item.ratio === "I") {
      return { ...item, ratio: "Indirect" };
    }
    return { ...item };
  }).map((el) => {
    if (el.billingType === "E") {
      return { ...el, billingType: "Enhanced" };
    }
    if (el.billingType === "I") {
      return { ...el, billingType: "IHOIU" };
    }
    if (el.billingType === "V") {
      return { ...el, billingType: "Vendor" };
    }
    return { ...el };
  }).map((item) => {
    let price = item.billingRate ? `${dollar}${item.billingRate}` : "";
    return { ...item, billingRate: price };
  });
  return rebuildItem;
}

export {
  transformServicesData,
  rebuildService,
  transformValueField,
};
