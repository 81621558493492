import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import HeaderPage from "../../../../components/HeaderPage";
import {
  provider_student_invoices_url,
  provider_students_assigment_url,
  provider_sessions_url,
} from "../../../../configs/router_urls";
import Paper from "../../../../components/Paper";
import { headerConfig } from "./config";
import { Button } from "../../../../components/Button";
import { getInvoiceValidationInformationById } from "../studentInvoices/store";
import InvoiceValidationPDF from "../../../../components/PDF/invoceValidationPDF";
import { getAssignmentByIdAction } from "./store";

function StudentInvoicesValidations() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { invoiceErrors, invoiceSummary, emailsErrors } = useSelector(({ studentInvoicesReducer }) => studentInvoicesReducer.invoiceValidationInfo);
  const { providerId } = useSelector(({ authorizationReducer }) => authorizationReducer.user);
  const student = useSelector(
    ({ providerStudentsListReducer }) => providerStudentsListReducer.student,
  );
  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);
  const [config, setConfig] = useState(headerConfig);
  const [showPreview, setShowPreview] = useState(false);
  const [data, setData] = useState(null);
  const [emailError, setEmailError] = useState([]);

  useEffect(() => {
    if (Object.keys(invoiceSummary).length) {
      setConfig(() => config.map((c) => {
        c.value = invoiceSummary[c.key];
        return c;
      }));
    } else {
      dispatch(getInvoiceValidationInformationById(params.id, params.date));
    }
  }, [invoiceSummary]);

  useEffect(() => {
    if (!Object.keys(assignment).length && params.id) dispatch(getAssignmentByIdAction(params.id));
  }, []);

  useEffect(() => {
    const { parentEmailError, schoolRepresentativeEmailError } = emailsErrors;
    if (!!parentEmailError || !!schoolRepresentativeEmailError) {
      setEmailError(() => {
        const result = [];
        if (parentEmailError) result.push(parentEmailError);
        if (schoolRepresentativeEmailError) result.push(schoolRepresentativeEmailError);
        return result;
      });
    }
  }, [emailsErrors]);

  const onMySessions = () => {
    history.push(provider_sessions_url);
  };

  const onCancel = () => {
    history.push(`${provider_student_invoices_url}/${params.id}`);
  };

  const sortToDate = (arr) => arr.slice().sort((a, b) => {
    if (moment(a.startTime) > moment(b.startTime)) return 1;
    if (moment(a.startTime) < moment(b.startTime)) return -1;
    return 0;
  });

  const hidePdfPreview = () => {
    setShowPreview(false);
  };

  const showPdfPreview = async () => {
    if (params.date && params.id) {
      try {
        const { data } = await axios(`/reports/invoice-preview/${params.id}?invoiceStartDate=${moment(params.date).format("YYYY-MM-DD")}`, {
          method: "get",
          responseType: "arraybuffer",
        });
        setData(data);
        setShowPreview(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <HeaderPage
          title={"Invoices"}
          rootPathName={provider_students_assigment_url}
          rootTitleName='Students'
          childrenTitleNames={["Student invoices", "Validations"]}
          childrenPathNames={[`${provider_student_invoices_url}/${params.id}`]}
        />
      </div>
      {showPreview && <InvoiceValidationPDF
        providerId={providerId}
        setShowPreview={hidePdfPreview}
        assignmentId={params.id}
        invoicePeriod={params.date}
        data={data}
      />}
      <Paper>
        <div className="student_invoice_validation">
          <span className="text__h1">{student?.studentName || assignment?.studentName}</span>
          <div className="student_invoice_validation__header">
            {headerConfig.map((el) => (
              <div key={el.id} className="student_invoice_validation__header_item">
                <div>
                  <span className="">
                    {el.title}{el.title2 ? "" : ":"}{" "}
                  </span>
                  <span className="">
                    {el.key === "payrollRate" ? "$" : ""}{el.value}
                  </span>
                </div>
                <div>
                  {el.title2}{el.title2 && ":"}
                </div>
              </div>
            ))}
          </div>
          <div className="student_invoice_validation__body_errors">
            {invoiceErrors.length || emailError.length ? <div className="student_invoice_validation__label error">Errors</div> : null}
            <div className="student_invoice_validation__content">
              {emailError.length
                ? emailError.map((el, index) => <div key={`email-${index}`} className="student_invoice_validation__item">
                  <div/><div/><div>{el}</div></div>)
                : null}
              {invoiceErrors.length
                ? sortToDate(invoiceErrors).map((el, index) => <div key={`invoice-${index}`} className="student_invoice_validation__item">
                  <div>{moment.utc(el.startTime).format("MM-DD-YYYY")}</div>
                  <div>{`${moment(el.startTime).format("hh:mmA")} - ${moment(el.endTime).format("hh:mmA")}`}</div>
                  <div>{el.errorMessage}</div></div>)
                : null}
            </div>
          </div>
          <div>
            <div className="student_invoice_validation_horizont-line"/>
            <div className="student_invoice_validation__buttons">
              <div className="student_invoice_validation_button column-4">
                <Button variant="outlined" color="grey" onClick={onCancel}>Cancel</Button>
              </div>
              {invoiceErrors.length
                ? <div className="student_invoice_validation_button"><Button variant="contained" onClick={onMySessions}>Go to the sessions</Button></div>
                : <div className="student_invoice_validation_button"><Button variant="contained" onClick={showPdfPreview}>Preview document</Button></div>
              }
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default StudentInvoicesValidations;
