function removeEmptyFields(values) {
  return Object.keys(values).reduce((a, c) => {
    if (values[c] !== null) {
      a[c] = values[c];
    }
    return a;
  }, {});
}

export default removeEmptyFields;
