import * as Yup from "yup";

export const headerConfig = {
  messageId: {
    id: 1,
    title: "ID",
    value: "messageId",
    order: 1,
  },
  sendDt: {
    id: 2,
    title: "Date",
    value: "sendDt",
    order: 2,
  },
  providers: {
    id: 3,
    title: "Receiver",
    value: "providers",
    order: 3,
  },
  message: {
    id: 4,
    title: "Message",
    value: "message",
    order: 4,
  },
};

export const fieldConfig = [
  {
    id: 1,
    name: "message",
    label: "Message text",
    placeholder: "",
    type: "textfieldNote",
    textArea: true,
  },
];

export const validationSchema = Yup.object({
  message: Yup.string()
    .required("Required field")
    .strict()
    .trim("This field cannot consist of spaces only")
    .max(256, "Max length should be 256 characters"),
  providerId: Yup.string()
    .when("isAllProviders", {
      is: false,
      then: Yup.string().required("Required field"),
    }),
});
