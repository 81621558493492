import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HeaderPage from "../../components/HeaderPage";
import { SelectFilter } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { Button } from "../../components/Button";
import { ButtonPaper } from "../../components/Paper/Paper";
import Table from "../../components/Table/Table";
import { headerConfig, headerConfigState, headerConfigDate } from "./config";
import tableFormater from "../../utils/tableFormater";
import Pagination from "../../components/Pagination/Pagination";
import { getAdminInvoicesList, changeStatusToPaid, getAllInvoicesList } from "./store";
import { DateField } from "../../components/DateField";
import { admin_email_preview_url, admin_invoice_view_url } from "../../configs/router_urls";
import CalendarModal from "../../components/CalendarModal/index.jsx";
import "./styles/index.scss";
import { dateFormat } from "../../utils/dateFormater";
import getLabelTitleInvoices from "../../utils/getLabelTitleInvoices";
import getLabelClassInvoices from "../../utils/getLabelClassInvoices";
import { monthNames } from "../../constants/monthNames";
import { getStartOfDay } from "../../functions/calendar_func";

function Invoices() {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const table = useRef();
  const history = useHistory();
  const [checkboxAmount, setAmount] = useState(0);
  const [checkboxes, setCheckboxes] = useState({});
  const [tabName, setTabName] = useState("all");
  const [config, setConfig] = useState(headerConfigState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hideModal, setHideModal] = useState(false);
  const [isAllChecked, setAllChecked] = useState(false);
  const [activeState, setActiveState] = useState(["C", "E", "S", "D", "P"]);
  const [caseNo, setCaseNo] = useState("");
  const allStudents = useSelector(
    ({ adminReducer }) => adminReducer.allStudents.result,
  );
  const data = useSelector(
    ({ adminInvoicesListReducer }) => adminInvoicesListReducer.invoices,
  );
  const allInvoices = useSelector(
    ({ adminInvoicesListReducer }) => adminInvoicesListReducer.allInvoices,
  );
  const allProviders = useSelector(
    ({ adminReducer }) => adminReducer.allProviders.result,
  );
  useEffect(() => {
    if (tabName === "all") setConfig(headerConfigState);
    if (tabName === "pending" || tabName === "signed" || tabName === "sent") setConfig(headerConfig);
    if (tabName === "paid") setConfig(headerConfigDate);
  }, [tabName]);
  useEffect(() => {
    if (caseNo.length > 1) dispatch(getAdminInvoicesList({ status: activeState, ...filters, searchByCaseNo: caseNo }));
    else dispatch(getAdminInvoicesList({ status: activeState, ...filters }));
    if (tabName === "signed" || tabName === "sent") {
      if (caseNo.length > 1) dispatch(getAllInvoicesList({ status: activeState, ...filters, searchByCaseNo: caseNo }));
      else dispatch(getAllInvoicesList({ status: activeState, ...filters }, 1000));
    }
    setCurrentIndex(0);
  }, [activeState, filters, caseNo]);
  const checkAll = (checked) => {
    setAllChecked(checked);
    const result = {};
    allInvoices.invoices.forEach(({ invoiceId }) => {
      result[invoiceId] = checked;
    });
    setCheckboxes(result);
    !isAllChecked ? setAmount(allInvoices.invoices.length) : setAmount(0);
  };
  const changeInvoiceStatus = (date) => {
    dispatch(changeStatusToPaid({ invoiceIds: Object.keys(checkboxes), paidDate: getStartOfDay(date) }, () => {
      dispatch(getAdminInvoicesList({ status: activeState }));
    }));
    setAmount(0);
    setHideModal(true);
  };
  const paginationClick = (offset) => {
    dispatch(getAdminInvoicesList({ status: activeState, ...filters }, offset));
  };

  const checkCheckboxes = (id, checked) => {
    setCheckboxes({ ...checkboxes, [id]: checked });
    setAllChecked(false);
    checked ? setAmount(checkboxAmount + 1) : setAmount(checkboxAmount - 1);
  };

  const getDateById = (id) => {
    const invoice = data.invoices.find((item) => item.invoiceId === +id);
    return invoice?.invoiceMonth ? invoice.invoiceMonth : null;
  };

  const sendInvoices = () => {
    const id = Object.keys(checkboxes);
    history.push(`${admin_email_preview_url}/${id}/${getDateById(id)}`);
  };

  const showData = (id) => {
    history.push(`${admin_invoice_view_url.split("/:id")[0]}/${id}`);
  };

  const renderRightSide = () => {
    if (tabName === "signed") {
      return (
        <div className="admin-invoices-list-header__right">
          <ButtonPaper>
            <p className="rightSide-button-description">Send selected invoices to DOE {`(${checkboxAmount})`}</p>
            <Button variant='contained' color='primary' disabled={!data.invoices.length || !checkboxAmount} onClick={() => sendInvoices()}>Send</Button>
          </ButtonPaper>
        </div>
      );
    }
    if (tabName === "sent") {
      return (
        <div className="admin-invoices-list-header__right">
          <ButtonPaper>
            <p className="rightSide-button-description">Mark selected invoices as paid {`(${checkboxAmount})`}</p>
            <div onClick={(e) => { if (e.nativeEvent.path.find((el) => el.className?.includes("popup-overlay"))) setHideModal(true); }}>
              <CalendarModal
                hideModal={hideModal}
                setHideModal={setHideModal}
                onSave={({ startDate }) => changeInvoiceStatus(startDate)}
                customTrigger={<Button variant='contained' color='primary' disabled={!data.invoices.length || !checkboxAmount}>Mark as paid</Button>}
              />
            </div>
          </ButtonPaper>
        </div>
      );
    }
  };
  const changeState = (state, name) => {
    setActiveState(state);
    setTabName(name);
    setCheckboxes({ });
    setCurrentIndex(0);
    setAllChecked(false);
    setAmount(0);
  };
  const resetFilters = () => {
    setFilters({});
    setCaseNo("");
    setCurrentIndex(0);
    setActiveState(["C", "E", "S", "D", "P"]);
    setTabName("all");
    table.current.scrollTop = 0;
  };

  const resetOneFilter = (obj) => {
    const newObj = { ...filters };
    delete newObj[obj];
    setFilters(newObj);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };

  return (
    <>
      <HeaderPage title="Invoices" />
      <div className="admin-invoices-list">
        <div className="admin-invoices-list-header">
          <div className="admin-invoices-list-header__left">
            <div className={`admin-invoices-list-header__filters ${tabName === "signed" || tabName === "sent" ? "" : "admin-invoices-list-header__filters-row"}`}>
              <div className="admin-invoices-list-header__textfield">
                <SelectFilter name="studentId" placeholder="Student" data={allStudents} changeFilter={(value) => setFilters({ ...filters, studentId: value })} filters={filters} resetFilter={resetOneFilter}/>
              </div>
              <div className="admin-invoices-list-header__textfield">
                <SelectFilter name="providerId" placeholder="Provider" data={allProviders} changeFilter={(value) => setFilters({ ...filters, providerId: value })} filters={filters} resetFilter={resetOneFilter}/>
              </div>
              <div className="admin-invoices-list-header__textfield">
                <TextField type="number" name="searchByCaseNo" placeholder="Case number" value={caseNo} onChange={(e) => setCaseNo(e.target.value)}/>
              </div>
              <div className="admin-invoices-list-header__textfield">
                <DateField
                  name="month"
                  isBirthDay
                  finish="month"
                  showIcon
                  placeholder="Month"
                  nativeClick={(event) => {
                    if (!event.target.value) return;
                    setFilters({
                      ...filters,
                      date: event.target.value,
                    });
                  }}
                  value={filters.date}
                />
                {filters.date && (
                  <div
                    className="select-reset"
                    onClick={() => resetOneFilter("date")}
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                        fill="#192A53"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="reset-filter">
              <Button
                variant="outlined"
                color="secondary"
                onClick={resetFilters}
              >
                Refresh filters
              </Button>
            </div>
          </div>
          {renderRightSide()}
        </div>
        <div className="admin-invoices-list__status">
          <Button
            color={tabName === "all" ? "primary" : "secondary"}
            onClick={() => changeState(["C", "E", "S", "D", "P"], "all")}
          >
            All
          </Button>
          <Button
            color={tabName === "pending" ? "primary" : "secondary"}
            onClick={() => changeState(["C", "E"], "pending")}
          >
            Signatures pending
          </Button>
          <Button
            color={tabName === "signed" ? "primary" : "secondary"}
            onClick={() => changeState(["S"], "signed") }
          >
            Signed
          </Button>
          <Button
            color={tabName === "sent" ? "primary" : "secondary"}
            onClick={() => changeState(["D"], "sent")}
          >
            Sent to DOE
          </Button>
          <Button
            color={tabName === "paid" ? "primary" : "secondary"}
            onClick={() => changeState(["P"], "paid")}
          >
            Paid
          </Button>
        </div>
        <Table
          head={Object.values(config)}
          data={tableFormater(
            data.invoices.map((item) => ({
              ...item,
              studentName: <div className="itemBold">{item.studentName}</div>,
              providerName: <div className="itemBold">{item.providerName}</div>,
              invoiceMonth: <div>{`${monthNames[moment(item.invoiceMonth).month()]} ${moment(item.invoiceMonth).year()}`}</div>,
              billingRate: <div>{`$${item.billingRate}`}</div>,
              paidDate: <div>{dateFormat("yyyy-mm-dd", item.paidDate)}</div>,
              state: <div className={`status-label ${getLabelClassInvoices(item.state)}`}>{getLabelTitleInvoices(item.state)}</div>,
            })),
            config, "invoiceId",
          )}
          hasCheckboxes = {tabName === "signed" || tabName === "sent"}
          showPreview
          checkAll={checkAll}
          checkCheckboxes={checkCheckboxes}
          checkboxes={checkboxes}
          showData={showData}
          isAllChecked={isAllChecked}
          isContrast
          tableRef={table}
        />
        <Pagination {...data.pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>

      </div>
    </>
  );
}

export default Invoices;
