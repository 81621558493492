import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

export default function NotFound() {
  const history = useHistory();

  useEffect(() => {
    history.push("/sign-in");
  }, []);

  return (
    <div>{"404"}</div>
  );
}
