import React, { useEffect, useRef, useState } from "react";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { banks_of_values_url } from "../../../../configs/router_urls";
// eslint-disable-next-line import/named
import {
  configHeadTable, formikInitialValues, validationSchema, fieldConfig,
} from "./config";
import HeaderPage from "../../../../components/HeaderPage";
import Table from "../../../../components/Table/Table";
import tableFormater from "../../../../utils/tableFormater";
import FormikButton, { Button } from "../../../../components/Button";
import {
  getSchoolsRepresentativesAction, createSchoolRepresentativeAction, updateSchoolRepresentativeAction, deleteSchoolRepresentativeAction, getSchools, clearedSchoolRepresentativeAction,
} from "./store";
import Pagination from "../../../../components/Pagination/Pagination";
import { PaperModal } from "../../../../components/modal/Modal";
import FormikSelect from "../../../../components/Select";
import FormikTextField from "../../../../components/TextField";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";

function SchoolRepresentatives() {
  const table = useRef();
  const dispatch = useDispatch();
  const { result: dataSchoolsRepresentatives, pagination } = useSelector(({ schoolRepresentativesReducer }) => schoolRepresentativesReducer.schoolRepresentatives);
  const { result: dataSchools } = useSelector(({ schoolRepresentativesReducer }) => schoolRepresentativesReducer.schools);

  const [initalValues, setInitalValues] = useState(formikInitialValues);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalWindowForCreate, setModalWindowForCreate] = useState(false);
  const [modalWindowForEdit, setModalWindowForEdit] = useState(false);
  const [classNameModalWindow, setClassNameModalWindow] = useState("");
  const [valueChange, setValueChange] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [schoolRepresentativeId, setSchoolRepresentativeId] = useState(null);
  const [state, setState] = useState(0);

  const paginationClick = (offset) => {
    dispatch(getSchoolsRepresentativesAction(offset));
    table.current.scrollTop = 0;
    setState(0);
  };

  useEffect(() => {
    dispatch(clearedSchoolRepresentativeAction());
    dispatch(getSchoolsRepresentativesAction());
    dispatch(getSchools());
  }, []);

  const createSchoolRepresentative = (data) => {
    dispatch(createSchoolRepresentativeAction(
      { ...data, schoolId: data?.schoolName, schoolRepresentativeName: data?.schoolRepresentativeName.trim() },
      () => setModalWindowForCreate(false),
      () => setModalWindowForCreate(true),
    ));
    setState(0);
    setCurrentIndex(0);
  };

  const editSchoolRepresentative = (data) => {
    dispatch(updateSchoolRepresentativeAction(
      {
        ...data, schoolRepresentativeId, schoolRepresentativeName: data?.schoolRepresentativeName.trim(), schoolId: +data.schoolName,
      },
      () => setModalWindowForEdit(false),
      () => setModalWindowForEdit(true),
    ));
    setCurrentIndex(0);
  };

  const deleteSchoolRepresentative = (id) => {
    dispatch(deleteSchoolRepresentativeAction(id));
    setOpenModalDelete(false);
    setCurrentIndex(0);
  };

  const onEdit = (id) => {
    let data = dataSchoolsRepresentatives?.find((el) => el.schoolRepresentativeId === id);
    setState(0);
    setSchoolRepresentativeId(data?.schoolRepresentativeId);
    setModalWindowForEdit(true);
    setInitalValues({ ...data, schoolName: data?.schoolId });
    setValueChange(false);
  };

  const onDelete = (id) => {
    setOpenModalDelete(true);
    setState(0);
    let data = dataSchoolsRepresentatives?.find((el) => el.schoolRepresentativeId === id);
    setSchoolRepresentativeId(data?.schoolRepresentativeId);
  };

  const onClose = () => {
    if (valueChange === true) {
      setConfirmModal(true);
      setClassNameModalWindow("hide");
    } else {
      setModalWindowForEdit(false);
      setModalWindowForCreate(false);
    }
  };

  const setDefault = () => {
    setConfirmModal(false);
    setModalWindowForEdit(false);
    setModalWindowForCreate(false);
    setClassNameModalWindow("show");
  };

  return (
    <div className="schoolRepresentatives">
      <HeaderPage
        title="School Representatives"
        rootPathName={banks_of_values_url}
        rootTitleName="Banks of Values"
        childrenTitleNames={["School Representatives"]}
      />
      <div className="schoolRepresentatives__btn">
        <Button variant="contained" color="primary"
          onClick={() => { setModalWindowForCreate(true); setValueChange(false); setState(0); }}
        >
          Add new school representatives
        </Button>
      </div>
      {dataSchoolsRepresentatives && <Table
        head={Object.values(configHeadTable)}
        state={state}
        setState={setState}
        data={tableFormater(dataSchoolsRepresentatives, configHeadTable)}
        onEdit={onEdit}
        onDelete={onDelete}
        isContrast
        tableRef={table}
      />}

      <Pagination
        {...pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <div className={classNameModalWindow}>
        <PaperModal headerTitle="Add New School Representative" open={modalWindowForCreate} onClose={onClose}>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => createSchoolRepresentative(values) }
          >
            {({ dirty, values }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "select": {
                    return (
                      <div className="schoolRepresentatives__field" key={item.id + item.name}>
                        <FormikSelect {...item}
                          data={dataSchools.map((el) => ({ id: el.schoolId, title: el.schoolName, value: el.schoolId }))}
                        />
                      </div>
                    );
                  }
                  case "textfield": {
                    return (
                      <div className="schoolRepresentatives__field" key={item.id + item.name}>
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <div className="schoolRepresentatives__save">
                  <FormikButton variant="contained" color="primary" disabled={!(values.schoolName && values.schoolRepresentativeName && values.email && values.cellPhoneNo)}>
                    {(values.schoolName && values.schoolRepresentativeName && values.email && values.cellPhoneNo) ? "Save" : "Create"}
                  </FormikButton>
                </div>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModal>
      </div>

      <div className={classNameModalWindow}>
        <PaperModal headerTitle="Edit School Representative" open={modalWindowForEdit} onClose={onClose}>
          <Formik
            initialValues={initalValues}
            validationSchema={validationSchema}
            onSubmit={(values) => editSchoolRepresentative(values) }
          >
            {({ dirty }) => (
              <>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "select": {
                    return (
                      <div className="schoolRepresentatives__field" key={item.id + item.name}>
                        <FormikSelect {...item}
                          data={dataSchools.map((el) => ({ id: el.schoolId, title: el.schoolName, value: el.schoolId }))} />
                      </div>
                    );
                  }
                  case "textfield": {
                    return (
                      <div className="schoolRepresentatives__field" key={item.id + item.name}>
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}
                <div className="schoolRepresentatives__save">
                  <FormikButton variant="contained" color="primary" disabled={!dirty}>
                    Save
                  </FormikButton>
                </div>
                {dirty ? setValueChange(true) : null}
              </>
            )}
          </Formik>
        </PaperModal>
      </div>

      <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
        <div>
          <Button variant="contained" color="primary" onClick={setDefault}>
            Discard
          </Button>
          <Button
            variant="default"
            color="secondary"
            onClick={() => { setClassNameModalWindow("show"); setConfirmModal(false); }}
          >
            Cancel
          </Button>
        </div>
      </ConfirmModal>

      <ConfirmModal
        headerTitle="Are you sure deleting the school representative?"
        open={openModalDelete}
      >
        <Button variant="contained" color="primary" onClick={() => deleteSchoolRepresentative(schoolRepresentativeId) }>
          Yes, Delete
        </Button>
        <Button
          variant="dashed"
          color="primary"
          onClick={() => {
            setOpenModalDelete(false);
          }}
        >
          Cancel
        </Button>
      </ConfirmModal>

    </div>
  );
}

export default SchoolRepresentatives;
