import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button } from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import Pagination from "../../../../../components/Pagination";
import Table from "../../../../../components/Table/Table";
import { TextField } from "../../../../../components/TextField";
import tableFormater from "../../../../../utils/tableFormater";
import { providersListAction } from "./store";
import { reset } from "../documents/store";
import { clearProviderInformation } from "../../../../../pages/systemUsers/store";

import "./styles/index.scss";

import { headerConfig } from "./configs";
import { admin_create_providers_url, admin_provider_profile_url, admin_provider_documents_url } from "../../../../../configs/router_urls";

function ProviderList() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { result: data, pagination } = useSelector(({ providersReducer }) => providersReducer.providers);
  const [value, setValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeState, setActiveState] = useState("A");
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value && value.length < 3) {
      setError("The search value length should be min 3");
    } else if (value.length > 15) {
      setError("The search value length should be max 15");
    } else setError("");
  }, [value]);

  const tableRowClick = (id) => {
    history.push(`${admin_provider_profile_url}/${id}`);
  };

  const paginationClick = (offset) => {
    dispatch(providersListAction({ status: activeState }, value, offset));
  };

  const filterProviderByState = (state) => {
    setActiveState(state);
  };

  useEffect(() => {
    if (value === "") {
      dispatch(providersListAction({ status: activeState }));
    } else if (value.length >= 3 && value.length <= 15) {
      dispatch(providersListAction({ status: activeState }, value));
    }
    setCurrentIndex(0);
  }, [value, activeState]);

  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <div>
      <HeaderPage
        title='All Providers'
      />
      <div className='provider__nav' style={error ? { paddingBottom: "0" } : {}}>
        <div className='provider__nav__left'>
          <Button
            color={activeState.includes("A") ? "primary" : "secondary"}
            onClick={() => { filterProviderByState("A"); setCurrentIndex(0); }}
          >
            Active
          </Button>
          <Button
            color={activeState.includes("I") ? "primary" : "secondary"}
            onClick={() => { filterProviderByState("I"); setCurrentIndex(0); }}
          >
            Inactive
          </Button>
        </div>
        <div className='provider__nav__right'>
          <div className='provider__nav__right__search'>
            <TextField
              name='providerSearch'
              value={value}
              error={error}
              placeholder='Search'
              onChange={onChangeHandler}
              startIcon={{ name: "searchIcon" }}
            />
          </div>
          <div>
            <Button variant='contained' color='primary' onClick={() => { history.push(admin_create_providers_url, "providers"); dispatch(clearProviderInformation()); }}>
              Add new provider
            </Button>
          </div>
        </div>
      </div>
      <Table
        isContrast
        typeOfTable = "Providers list"
        head={Object.values(headerConfig)}
        data={tableFormater(data.map((el) => ({
          ...el,
          firstName: <div className="itemBold">{`${el.firstName} ${el.lastName}`}</div>,
        })), headerConfig, data.providerId)}
        isContext={true}
        contextData={[
          {
            title: "Profile",
            link: admin_provider_profile_url,
          },
          {
            title: "Documents",
            link: admin_provider_documents_url,
          },
        ]}
        isTableForClick
        onClick={tableRowClick}
      />
      <Pagination
        {...pagination}
        onClick={paginationClick}
        currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}
      />
    </div >
  );
}

export default ProviderList;
