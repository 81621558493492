import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const editStudentGoalSlice = createSlice({
  name: "editStudentGoal",
  initialState: {
    data: null,
  },
  reducers: {
    setStudentGoal(state, { payload }) {
      state.data = payload;
    },
    cleanStudentGoal(state) {
      state.data = null;
    },
  },
});

export const { setStudentGoal, cleanStudentGoal } = editStudentGoalSlice.actions;

export default editStudentGoalSlice.reducer;

export const getStudentGoalByIdAction = (id) => async (dispatch) => {
  try {
    const response = await axios(`/goals/student-goal/${id}`);
    dispatch(setStudentGoal(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
