import React from "react";
import PropTypes from "prop-types";
import { dateFormat } from "../../../utils/dateFormater";

const Time = ({ dateFormatString }) => (
  <div className="header__time">
    {dateFormat(dateFormatString)}
  </div>
);

Time.propTypes = {
  dateFormatString: PropTypes.string,
};

export default Time;
