import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const providerInvoicesSlice = createSlice({
  name: "adminInvoicesSlice",
  initialState: {
    invoice: {},
  },
  reducers: {
    setInvoice: (state, { payload }) => {
      state.invoice = payload;
    },
  },
});

export default providerInvoicesSlice.reducer;

export const { setInvoice } = providerInvoicesSlice.actions;

export const getInvoice = (id, cb = () => { }) => async (dispatch) => {
  try {
    const response = await axios(`reports/info-invoice/${id}`);
    dispatch(setInvoice(response.data));
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
