import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Table from "../../../../../components/Table/Table";
import { Button } from "../../../../../components/Button";
import { fetchStudentList } from "./store";
import tableFormater from "../../../../../utils/tableFormater";
import { reset } from "../documents/store";
import HeaderPage from "../../../../../components/HeaderPage";
import Pagination from "../../../../../components/Pagination/Pagination";
import { dateFormat } from "../../../../../utils/dateFormater";
import { TextField } from "../../../../../components/TextField";

import { headerConfig } from "./config";
import {
  admmin_create_student_url,
  admmin_students_profile_url,
  admmin_students_services_url,
  admin_students_documents_url,
} from "../../../../../configs/router_urls";

import "./styles/index.scss";

function StudentsContent() {
  const dispatch = useDispatch();
  const table = useRef();
  const { result: data, pagination } = useSelector(({ studentsReducer }) => studentsReducer.students);
  const history = useHistory();
  const [value, setValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeState, setActiveState] = useState("active");
  const studentsData = { active: data, inactive: [] };

  const addNewStudent = () => {
    history.push(admmin_create_student_url);
  };
  const tableRowClick = (id) => {
    history.push(`${admmin_students_profile_url}/${id}`);
  };
  const paginationClick = (offset) => {
    dispatch(fetchStudentList(value, offset));
    table.current.scrollTop = 0;
  };
  const onSearchChangeHandler = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value === "" || value?.length < 3) {
      dispatch(fetchStudentList());
    } else if (value?.length >= 3) {
      dispatch(fetchStudentList(value));
    }
    setCurrentIndex(0);
  }, [value, activeState]);

  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <>
      <HeaderPage title='All students' />
      <div className='student-list-header'>
        <div className='student-list-header-left'>
          <Button color={activeState === "active" ? "primary" : "secondary"} onClick={() => { setActiveState("active"); setCurrentIndex(0); }}>
            Active
          </Button>
          <Button color={activeState === "inactive" ? "primary" : "secondary"} onClick={() => { setActiveState("inactive"); setCurrentIndex(0); }}>
            Inactive
          </Button>
        </div>

        <div className='student-list-header-right'>
          <div className='student-list-header-search'>
            <TextField name='goalSearch' placeholder='Search' value={value} onChange={onSearchChangeHandler} startIcon={{ name: "searchIcon" }} />
          </div>

          <div>
            <Button variant='contained' onClick={addNewStudent}>
              Add new student
            </Button>
          </div>
        </div>
      </div>
      <Table
        head={Object.values(headerConfig)}
        typeOfTable="Students list"
        data={tableFormater(
          studentsData[activeState].map((item) => ({
            ...item,
            studentName: <div className='itemBold'>{`${item.studentName}`}</div>,
            birthDt: dateFormat("mm-dd-yyyy", item.birthDt),
          })),
          headerConfig, "studentId",
        )}
        isContext
        contextData={[
          {
            title: "Profile",
            link: admmin_students_profile_url,
          },
          {
            title: "Documents",
            link: `${admin_students_documents_url}`,
          },
          {
            title: "Services",
            link: admmin_students_services_url,
          },
        ]}
        isTableForClick
        onClick={tableRowClick}
        tableRef={table}
      />
      {studentsData[activeState]?.length > 0 ? <Pagination {...pagination} onClick={paginationClick} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/> : null}
    </>
  );
}

function Students() {
  return (
    <React.Fragment>
      <StudentsContent />
    </React.Fragment>
  );
}

export default Students;
