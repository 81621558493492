import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const studentInvoiceValidationSlice = createSlice({
  name: "studentInvoiceValidation",
  initialState: {
    assignment: {},
  },
  reducers: {
    setAssignment(state, { payload }) {
      state.assignment = payload;
    },
  },
});

const { setAssignment } = studentInvoiceValidationSlice.actions;

export const getAssignmentByIdAction = (id, cb = () => { }) => async (dispatch) => {
  try {
    const response = await axios(`/assignment/assignment/${id}`);
    dispatch(setAssignment(response.data));
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default studentInvoiceValidationSlice.reducer;
