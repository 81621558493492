import React, { useEffect, useState } from "react";

import { Button } from "../../../../../../components/Button";
import { PaperModal } from "../../../../../../components/modal/Modal";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import MyltiSelect from "../components/AutoComplete";

import "../../styles/index.scss";

const FieldArrayModalContent = (props) => (
  <div className="field_array_modal-content">
    {props.children}
  </div>
);

function SessionMaterials({
  selected, setSelected, materialsDict, hideEdit,
}) {
  const [open, setOpen] = useState(false);
  const [localSelected, setLocalSelected] = useState(transform(selected));
  const [isValueChange, setValueChange] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  function transform(selected) {
    return selected.map((el) => ({ title: el.attrValue, value: el.attrType, id: el.attributeId }));
  }
  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    setLocalSelected(transform(selected));
  }, [selected]);

  const onSave = () => {
    setValueChange(false);
    const materials = localSelected.map(({ id, value, title }) => ({ attributeId: id, attrValue: title, attrType: value }));
    setSelected({ ...selected, materials });
    setOpen(false);
  };

  const checkOpen = (e) => {
    if (e.nativeEvent.path.find((el) => el.className === "grey-screen")) setOpen(false);
    else if (e.nativeEvent.path.find((el) => el.className === "modal__closeicon")) setOpen(false);
    else if (e.target.textContent === "Save") setOpen(false);
    else if (e.target.textContent === "Discard") setOpen(false);
    else setOpen(true);
  };

  return (
    <div className='session_notes_form_horizont-line' onClick={checkOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div className="session_notes_form_section-title">
          <span>Materials</span>
          <div
            className={hideEdit && "hide"}>
            <SvgMaker name="editIcon" /></div>
          {!confirmModal && !hideEdit && <PaperModal headerTitle="Materials" open={open} onClose={checkClosure}>
            <FieldArrayModalContent>
              <MyltiSelect
                title='materials'
                data={materialsDict.map((el) => ({ title: el.attrValue, value: el.attrType, id: el.attributeId }))}
                selected={localSelected}
                setSelected={setLocalSelected}
                setValueChange={setValueChange}
              />
              <div style={{ marginTop: 50 }}>
                <Button variant="contained" color="primary" onClick={(e) => { onSave(); checkOpen(e); }}>Save</Button>
              </div>
            </FieldArrayModalContent>
          </PaperModal>}
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => {
                setConfirmModal(false);
                setValueChange(false);
                setOpen(false);
                setLocalSelected(transform(selected));
              }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
        <div style={{ flexDirection: "column" }} className="session_notes_form_section-content">
          {transform(selected).map((el) => <span key={el.id} style={{ display: "inline-block", paddingTop: 5 }}>
            {el.title}
          </span>)}
        </div>
      </div>
    </div>
  );
}

export default SessionMaterials;
