const config = [
  {
    id: 1,
    title: "Schools",
    name: "schools",
  },
  {
    id: 2,
    title: "School Representatives",
    name: "school-representatives",
  },
  {
    id: 3,
    title: "Goals",
    name: "goals",
  },
];

export { config };
