import React, { useEffect, useRef } from "react";
import "./styles/index.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderPage from "../../../../../components/HeaderPage";
import { providersListAction } from "../providersList/store/index";
import Table from "../../../../../components/Table";
import { headerConfig } from "./config";
import { iconDoc } from "../../../student/pages/documents/config";
import tableFormater from "../../../../../utils/tableFormater";
import { dateFormat } from "../../../../../utils/dateFormater";
import { Button } from "../../../../../components/Button";
import { fetchDocumentsList, deleteDocument, uploadNewFileProvider } from "./store";
import { dowloadNewFile } from "../../../../../utils/dowloadNewFile";
import { admin_providers_url, admin_provider_profile_url } from "../../../../../configs/router_urls";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import SvgMaker from "../../../../../components/svgMaker";
import { getProvidersByIdAction } from "../providerProfile/store";

function ProviderDocuments() {
  const ref = useRef();
  const refJsx = useRef(false);
  const params = useParams();
  const dispatch = useDispatch();
  const provider = useSelector(({ providersProfileReducer }) => providersProfileReducer.data);
  const providerDocuments = useSelector(({ documentsProviderReducer }) => documentsProviderReducer.documents);

  const onFileChange = (e) => {
    const fileSize = e.target.files[0]?.size / 1024 / 1024;
    if (fileSize > 10) {
      dispatch(snackbarOpen({
        isOpen: true, timeout: 3000, text: "The file cannot be larger than 10MB", type: "error",
      }));
    } else if (fileSize <= 10) {
      const formData = new FormData();
      formData.append("doc", e.target.files[0], e.target.files[0].name);
      dispatch(uploadNewFileProvider(params.id, formData));
    }
  };
  useEffect(() => {
    dispatch(providersListAction());
    dispatch(fetchDocumentsList(params.id));
    dispatch(getProvidersByIdAction(params.id));
  }, []);

  const onDelete = (docId, studentId) => {
    dispatch(deleteDocument(docId, studentId));
    ref.current.value = "";
  };

  return (
    <> {refJsx.current
      ? <><HeaderPage
        title={`${provider?.firstName} ${provider?.lastName} Documents`}
        rootPathName={admin_providers_url}
        rootTitleName='All Providers'
        childrenTitleNames={["Provider Profile", "Provider Documents"]}
        childrenPathNames={[`${admin_provider_profile_url}/${params.id}`]}
      />
      <div className="btn">
        <Button variant='contained' color='primary'
          onClick={() => { ref.current.value = ""; ref.current.click(); }}>
              Upload new file
        </Button>
      </div>
      <label htmlFor="files"/>
      <input ref={ref} id="files" className="uploadFile" type="file" onChange={onFileChange} />
      <div className="providerDocuments">
        <Table
          withoutOption
          head={Object.values(headerConfig)}
          data={tableFormater(providerDocuments.map((el) => ({
            ...el,
            uploadDt: <div className="providerDocuments__date">{dateFormat("mm-dd-yyyy", el.uploadDt)}</div>,
            originalName: <div className='providerDocuments__name'>
              {iconDoc[el.docType]}
              <span>{el.originalName}</span>
            </div>,
            actions: <div className="actions">
              <div className="actions__dowload"
                onClick={() => { dowloadNewFile(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}/${el.filePath}/${el.docName}`, el.originalName); }}>
                <SvgMaker name="dowloadIconDoc" />
              </div>
              <div className="actions__delete"
                onClick={() => onDelete(el.docId, params.id)}>
                <SvgMaker name="deleteIconDoc" />
              </div>
            </div>,
          })), headerConfig)}
        />
      </div>
      </>
      : null}
    {refJsx.current = true}
    </>
  );
}

export default ProviderDocuments;
