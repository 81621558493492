import React from "react";
import moment from "moment";
import clsx from "clsx";
import { getDate, getMonthMatrix } from "../../../functions/calendar_func";
import "./style.scss";

const MonthTable = (props) => {
  const {
    currentDate, setCurrentDate, tooggleMode, updateStartDate, finish, startDate,
  } = props;

  function getActiveClass(date) {
    if (getDate(date, "MMM") === getDate(startDate, "MMM")) {
      return "active";
    }
    return "default";
  }

  function onSelectHandler(date) {
    if (finish && finish === "month") {
      setCurrentDate(date);
      updateStartDate(moment(date).clone().set("date", 1));
    } else {
      setCurrentDate(date);
      tooggleMode("date");
    }
  }

  const grid = getMonthMatrix(currentDate);
  return (
    <table className="date_picker-calendar-month_table">
      <tbody>
        {grid.map((row, index) => (
          <tr key={index}>
            {row.map((column, index) => (
              <td key={index}>
                <div
                  className={clsx("month", getActiveClass(column.date))}
                  onClick={() => onSelectHandler(column.date)}
                >
                  {getDate(column.date, "MMM")}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MonthTable;
