import * as Yup from "yup";
import { dateFormat } from "../../../../../utils/dateFormater";

const fieldConfig = [
  {
    id: 1,
    name: "studentName",
    label: "Student name",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 2,
    name: "osisNo",
    label: "Student OSIS#",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 3,
    name: "birthDt",
    label: "Date of birth",
    placeholder: "",
    required: true,
    type: "datefield",
    isBirthDay: true,
  },
  {
    id: 4,
    name: "gender",
    label: "Gender",
    placeholder: "",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "Male",
        value: "M",
      },
      {
        id: 2,
        title: "Female",
        value: "F",
      },
    ],
  },
  {
    id: 90,
    type: "empty",
  },
  {
    id: 5,
    name: "address",
    label: "Address",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 7,
    name: "addrStateId",
    label: "State",
    placeholder: "",
    required: true,
    type: "select",
    beName: "states",
    data: [],
  },
  {
    id: 6,
    name: "addrCityId",
    label: "City",
    placeholder: "",
    required: true,
    beName: "cities",
    type: "search",
    data: [],
    parent: "addrStateId",
    info: {
      isOpen: true,
      timeout: 2000,
      text: "Enter state first, please",
      type: "information",
    },
  },
  {
    id: 8,
    name: "addrZipcode",
    label: "ZIP code",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 91,
    type: "empty",
  },
  {
    id: 9,
    name: "motherName",
    label: "Mother name",
    placeholder: "N/A",
    type: "textfield",
    key: "M",
    state: "M",
  },
  {
    id: 10,
    name: "motherCellPhoneNo",
    label: "Mother Cell",
    placeholder: "N/A",
    type: "textfield",
    key: "M",
    state: "M",
  },
  {
    id: 11,
    name: "motherEmail",
    label: "Mother email",
    placeholder: "N/A",
    type: "textfield",
    key: "M",
    state: "M",
  },
  {
    id: 92,
    type: "empty",
  },
  {
    id: 93,
    type: "empty",
  },
  {
    id: 13,
    name: "fatherName",
    label: "Father name",
    placeholder: "N/A",
    type: "textfield",
    key: "F",
    state: "F",
  },
  {
    id: 14,
    name: "fatherCellPhoneNo",
    label: "Father Cell",
    placeholder: "N/A",
    type: "textfield",
    key: "F",
    state: "F",
  },
  {
    id: 15,
    name: "fatherEmail",
    label: "Father email",
    placeholder: "N/A",
    type: "textfield",
    key: "F",
    state: "F",
  },
  {
    id: 19,
    name: "primaryResponsiblePerson",
    label: "Responsible person",
    placeholder: "",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "Mother",
        value: "M",
      },
      {
        id: 2,
        title: "Father",
        value: "F",
      },
    ],
  },
  {
    id: 95,
    type: "empty",
  },
  {
    id: 24,
    name: "schoolId",
    label: "School",
    placeholder: "",
    required: true,
    type: "select",
    beName: "schools",
    data: [
      // {
      //   id: 1,
      //   title: "test school",
      //   value: "1",
      // },
    ],
  },
  {
    id: 197,
    name: "schoolRepresentativeId",
    label: "Principal",
    placeholder: "N/A",
    type: "select",
    data: [],
    beName: "principals",
    deps: "withoutPrincipal",
    key: "P",
    parent: "schoolId",
    info: {
      isOpen: true,
      timeout: 2000,
      text: "Enter school first, please",
      type: "information",
    },
  },
  {
    id: 95,
    type: "empty",
  },
  {
    id: 95,
    type: "empty",
  },
  {
    id: 12,
    name: "homePhoneNo",
    label: "Home phone number",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 16,
    name: "emergencyPhoneNo",
    label: "Emergency phone number",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 16,
    beName: "alternateAgencies",
    name: "alternateAgencyId",
    label: "Alternate agency",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: 99,
    type: "empty",
  },
  {
    id: 100,
    type: "empty",
  },
  {
    id: 20,
    name: "languageId",
    label: "Language",
    placeholder: "",
    required: true,
    type: "select",
    beName: "languages",
    data: [],
  },
  {
    id: 21,
    name: "familyLanguageId",
    label: "Family Language",
    placeholder: "",
    type: "select",
    beName: "languages",
    data: [],
  },
  {
    id: 22,
    name: "districtId",
    label: "District",
    placeholder: "",
    required: true,
    type: "select",
    beName: "districts",
    data: [],
  },
  {
    id: 23,
    name: "iepCoordinatorId",
    label: "IEP Coordinator",
    placeholder: "",
    type: "select",
    beName: "coordinators",
    data: [],
  },
  {
    id: 101,
    type: "empty",
  },
  {
    id: 25,
    name: "classType",
    label: "Type of class",
    placeholder: "",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "School",
        value: "S",
      },
      {
        id: 2,
        title: "Private student",
        value: "P",
      },
      {
        id: 3,
        title: "Center-Based Class",
        value: "C",
      },
    ],
  },
  {
    id: 103,
    type: "empty",
  },
  {
    id: 104,
    type: "empty",
  },
  {
    id: 101,
    type: "empty",
  },
  {
    id: 101,
    type: "empty",
  },
  {
    id: 26,
    name: "dueIepDt",
    label: "Anticipated IEP Date",
    placeholder: "",
    required: true,
    type: "datefield",
  },
  {
    id: 27,
    name: "dueStartDt",
    label: "Anticipated Start Date",
    placeholder: "",
    required: true,
    type: "datefield",
  },
  {
    id: 105,
    type: "empty",
  },
];

const formikInitialValues = {
  addrCityId: " ",
  addrStateId: "35",
  addrZipcode: "",
  address: "",
  birthDt: "",
  classType: "",
  districtId: "",
  dueIepDt: dateFormat("mm-dd-yyyy", new Date()),
  dueStartDt: dateFormat("mm-dd-yyyy", new Date()),
  emergencyPhoneNo: "",
  familyLanguageId: "2",
  fatherCellPhoneNo: "",
  fatherEmail: "",
  fatherName: "",
  gender: "",
  homePhoneNo: "",
  iepCoordinatorId: "",
  languageId: "2",
  motherCellPhoneNo: "",
  motherEmail: "",
  motherName: "",
  osisNo: "",
  primaryResponsiblePerson: "",
  schoolId: "",
  studentName: "",
  schoolRepresentativeId: "",
  alternateAgencyId: "1",
};

const yupObject = {
  addrCityId: Yup.string().required("required field"),
  addrStateId: Yup.string().required("required field"),
  addrZipcode: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("required field")
    .min(5, "min length should be 5 characters")
    .max(9, "max length should be 9 characters"),
  address: Yup.string()
    .strict(false)
    .required("required field")
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .max(64, "max length should be 64 characters"),
  birthDt: Yup.date("Invalid Date").max(new Date(), "The date of birth should be in the past").required("required field"),
  classType: Yup.string().required("required field"),
  districtId: Yup.string().required("required field"),
  dueIepDt: Yup.date().required("required field"),
  dueStartDt: Yup.date().required("required field"),
  emergencyPhoneNo: Yup.string()
    .required("required field")
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .max(16, "max length should be 16 characters"),
  familyLanguageId: Yup.string(),
  fatherCellPhoneNo: Yup.string()
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .max(16, "max length should be 16 characters"),
  fatherEmail: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .email("The email should be like example@example.com")
    .max(128, "max length should be 128 characters"),
  fatherName: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .max(64, "max length should be 64 characters"),
  gender: Yup.string().required("required field"),
  homePhoneNo: Yup.string()
    .required("required field")
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .max(16, "max length should be 16 characters"),
  iepCoordinatorId: Yup.string(),
  languageId: Yup.string().required("required field"),
  motherCellPhoneNo: Yup.string()
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .max(16, "max length should be 16 characters"),
  motherEmail: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .email("The email should be like example@example.com")
    .max(128, "max length should be 128 characters"),
  motherName: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .max(64, "max length should be 64 characters"),
  osisNo: Yup.number()
    .integer()
    .typeError("Must be a number")
    .moreThan(0, "Student OSIS should be more than 0")
    .lessThan(1000000000, "Student OSIS should be less than 1000000000")
    .required("required field"),
  // .string()
  // .required("required field")
  // .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
  // .max(16, "max length should be 16 characters"),
  primaryResponsiblePerson: Yup.string().required("required field"),
  schoolId: Yup.string().required("required field"),
  studentName: Yup.string()
    .required("required field")
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .max(64, "max length should be 64 characters"),
  schoolRepresentativeId: Yup.string(),
  alternateAgencyId: Yup.string()
    .required("required field"),
};

const changeValidationSchema = (value, schema) => {
  if (value === "F") {
    return {
      ...schema,
      fatherCellPhoneNo: Yup.string()
        .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
        .max(16, "max length should be 16 characters")
        .required("required field"),
      fatherEmail: Yup.string()
        .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces")
        .email("The email should be like example@example.com")
        .max(128, "max length should be 128 characters")
        .required("required field"),
      fatherName: Yup.string()
        .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces")
        .max(64, "max length should be 64 characters")
        .required("required field"),
    };
  }
  if (value === "M") {
    return {
      ...schema,
      motherCellPhoneNo: Yup.string()
        .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
        .max(16, "max length should be 16 characters")
        .required("required field"),
      motherEmail: Yup.string()
        .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces")
        .email("The email should be like example@example.com")
        .max(128, "max length should be 128 characters")
        .required("required field"),
      motherName: Yup.string()
        .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces")
        .max(64, "max length should be 64 characters")
        .required("required field"),
    };
  }
  if (value === "P") {
    return {
      ...schema,
      schoolRepresentativeId: Yup.string().required("required field"),
    };
  }
  return schema;
};

// const validationShema = Yup.object(yupObject);

export {
  fieldConfig, formikInitialValues, yupObject, changeValidationSchema,
};
