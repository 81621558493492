import React from "react";
import moment from "moment";
import clsx from "clsx";

import {
  week_days,
  getDateMatrix,
  isDateBetween,
  getStartOfMonth,
  getEndOfMonth,
  isEqualsDates,
  isDateSameOrBefore,
} from "../../../functions/calendar_func";
import { dateFormat } from "../../../utils/dateFormater";

import "./style.scss";

const DateTable = (props) => {
  const {
    currentDate,
    isRange = false,
    startDate,
    endDate,
    updateStartDate,
    updateEndDate,
    availableStartDate,
    availableEndDate,
    isCalendar,
    isBirthday = false,
  } = props;
  function handleDateClick(date) {
    if (isRange) {
      if (startDate) {
        updateEndDate(date);
        return;
      }
      updateStartDate(date);
      return;
    }
    updateStartDate(date);
  }

  function getStartEndDateObj() {
    if (isDateSameOrBefore(startDate, endDate)) {
      return { start_date: startDate, end_date: endDate };
    }
    return { start_date: endDate, end_date: startDate };
  }
  function getActiveClass(date) {
    if (availableStartDate || availableEndDate) {
      const isAvailableDate = isDateBetween(
        date,
        availableStartDate,
        moment(availableEndDate).add(1, "days"),
      );
      if (!isAvailableDate) return "disabled";
    }
    const startEndDateObj = getStartEndDateObj();
    if (isRange) {
      if (moment(startEndDateObj.start_date).isSame(date, "day")) {
        return "active_range_left";
      }

      if (moment(startEndDateObj.end_date).isSame(date, "day")) {
        return "active_range_right";
      }
      return isDateBetween(
        date,
        startEndDateObj.start_date,
        startEndDateObj.end_date,
      )
        ? "active_range_subitem"
        : "default";
    }
    if (isCalendar) {
      return isEqualsDates(dateFormat("mm-dd-yyyy", date), dateFormat("mm-dd-yyyy", startDate)) ? "active" : "default";
    }
    if (startDate && date === startDate && !isBirthday) {
      return "active";
    }
    if (moment(startDate).isSame(date, "day")) {
      return "active";
    }
    return isEqualsDates(date, startDate) ? "active" : "default";
  }

  const grid = getDateMatrix(currentDate);

  return (
    <table className="date_picker-calendar-table">
      <thead>
        <tr>
          {week_days.map((week_day, index) => (
            <th key={index}>{week_day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {grid.map((week, index) => (
          <tr key={index}>
            {week.map((day, index) => {
              const isValidDate = isDateBetween(
                day.date,
                getStartOfMonth(currentDate),
                getEndOfMonth(currentDate),
              );
              const isAvailableDate = (availableStartDate || availableEndDate) ? isDateBetween(
                day.date,
                availableStartDate,
                moment(availableEndDate).add(1, "days"),
              ) : true;
              return (
                <td key={index}>
                  {isValidDate && (
                    <div
                      className={clsx("day", getActiveClass(day.date))}
                      onClick={() => { if (isAvailableDate) handleDateClick(day.date); }}
                    >
                      {day.key}
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DateTable;
