export const dataConfig = {
  firstName: {
    id: 1,
    name: "First name",
  },
  lastName: {
    id: 2,
    name: "Last name",
  },
  email: {
    id: 3,
    name: "Email",
  },
  cellPhoneNo: {
    id: 3,
    name: "Cell phone",
  },
  homePhoneNo: {
    id: 4,
    name: "Home phone",
  },
  emergencyPhoneNo: {
    id: 4,
    name: "Emergency phone",
  },
  address: {
    id: 5,
    name: "Address",
  },
  apt: {
    id: 5,
    name: "APT",
  },
  stateName: {
    id: 5,
    name: "State",
  },
  cityName: {
    id: 6,
    name: "City",
  },
  addrZipcode: {
    id: 6,
    name: "ZIP",
  },
  payrollRate: {
    id: 7,
    name: "Payroll rate",
  },
  annualRate: {
    id: 7,
    name: "Annual salary",
  },
  payrollEntity: {
    id: 8,
    name: "Payroll entity",
  },
  billingNo: {
    id: 8,
    name: "1099/W2",
  },
  ssNo: {
    id: 11,
    name: "SSN",
  },
  note: {
    id: 13,
    name: "Comment",
  },
  emptyField1: {
    id: 14,
    name: "empty",
  },
  summerAllowed: {
    id: 15,
    name: "Work in Summer",
  },
};
