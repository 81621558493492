import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getStudentByIdAction } from "../../../entities/admin/student/pages/studentProfile/store";
import { getProvidersByIdAction } from "../../../entities/admin/provider/pages/providerProfile/store";
import { getSessionNotesAction } from "../../../pages/calendar/session/sessionForm/store";
import { getInvoicesMonthsByAssignmentId } from "../../../entities/provider/pages/studentInvoices/store";
import { getAssignmentByIdAction } from "../../../entities/provider/pages/studentInvoicesValidations/store";
import { edit_session_url } from "../../../configs/router_urls";
import { getCurrentDate, getEndOfMonth, getStartOfMonth } from "../../../functions/calendar_func";
import { getEventsAction } from "../../../pages/calendar/store";

const TableModal = ({
  contextData,
  modal,
  setModal,
  typeOfTable,
  altContextData,
  onEdit,
  onDelete,
  onChangePassword,
  customData,
  setModalHeight,
  modalHeight,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableModalRef = useRef();
  const currentDate = getCurrentDate();
  const startDate = getStartOfMonth(currentDate);
  const endDate = getEndOfMonth(currentDate);

  useEffect(() => {
    if (modal.open) {
      setModalHeight(tableModalRef.current.clientHeight);
    }
  }, [modal.open]);

  useEffect(() => {
    if (typeOfTable === "Provider sessions") { dispatch(getEventsAction(startDate, endDate)); }
  }, [startDate, endDate]);
  useEffect(() => {
    const handler = (event) => {
      if (modal.open && !tableModalRef.current.contains(event.target)) {
        setModal({
          open: false,
          id: 0,
          top: 270,
        });
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [modal]);

  const setContextData = () => {
    if (altContextData.length > 0) {
      return altContextData.map(({ title, modalType, index }) => (<button
        key={`${index}_${title}`}
        className="table__context-link"
        onClick={() => {
          setModal({
            open: false,
            id: 0,
            top: 270,
          });
          switch (modalType) {
          case "edit": {
            return onEdit(modal.id);
          }
          case "delete": {
            return onDelete(modal.id);
          }
          case "changePassword": {
            return onChangePassword(modal.id);
          }
          default: return "";
          }
        }}
      >
        {title}
      </button>));
    }

    return contextData.map(({ id, title, link }, index) => (
      <button
        key={`${index}_${title}`}
        className="table__context-link"
        onClick={() => onHandlerClick(link, modal.id, title)}
      >
        {title}
      </button>
    ));
  };

  const onHandlerClick = (link, id, title) => {
    if (typeOfTable === "Students list") {
      dispatch(getStudentByIdAction(id));
    } else if (typeOfTable === "Providers list") {
      dispatch(getProvidersByIdAction(id));
    }
    if (typeOfTable === "Provider sessions") {
      if (title === "View") {
        const session = customData.find((el) => modal.modalData.eventId === el.eventId);
        if (session.state === "S") {
          dispatch(getEventsAction(session.actualStartDt, session.actualEndDt, (data) => {
            const event = Object
              .values(data)
              .flat(Infinity)
              .find((item) => item.eventId === session.eventId);
            if (event) history.push(`${edit_session_url}`, event);
          }));
        } else {
          dispatch(getSessionNotesAction(modal.modalData.eventId));
          history.push(`${link}/${modal.modalData.eventId}`);
        }
      } else {
        dispatch(getInvoicesMonthsByAssignmentId(id));
        dispatch(getAssignmentByIdAction(id));
        history.push(`${link}/${id}`);
      }
    } else if (typeOfTable === "Provider students") {
      if (title === "Profile" || title === "Documents") {
        dispatch(getStudentByIdAction(modal.modalData.studentId));
        history.push(`${link}/${modal.modalData.studentId}`);
      } else history.push(`${link}/${id}`);
    } else history.push(`${link}/${id}`);
  };

  return (
    <div ref={tableModalRef}
      style={{ top: `${modal.top}px` }}
      className={clsx("table__context", { open: modal.open })}
    >
      <button className="table__context-link table__context-link_close" onClick={() => setModal({
        open: false,
        id: 0,
        top: 270,
      })}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.8332 5.34166L14.6582 4.16666L9.99984 8.82499L5.3415 4.16666L4.1665 5.34166L8.82484 9.99999L4.1665 14.6583L5.3415 15.8333L9.99984 11.175L14.6582 15.8333L15.8332 14.6583L11.1748 9.99999L15.8332 5.34166Z" fill="#495057"/>
        </svg>
      </button>
      {setContextData()}
    </div>
  );
};

TableModal.propTypes = {
  contextData: PropTypes.array,
  modal: PropTypes.object,
  setModal: PropTypes.func,
  altContextData: PropTypes.array,
  customData: PropTypes.array,
  setModalHeight: PropTypes.func,
};

export default TableModal;
