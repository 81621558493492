const headerConfig = {
  originalName: {
    id: 1,
    title: "Name",
    value: "docName",
    order: 1,
  },
  uploadDt: {
    id: 2,
    title: "Upload time",
    value: "date_of_change",
    order: 2,
  },
  actions: {
    id: 3,
    title: "Actions",
    value: "actions",
    order: 3,
  },
};

export { headerConfig };
