import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../../utils/dateFormater";

import HeaderPage from "../../../../components/HeaderPage";
import { CheckBox } from "../../../../components/Checkbox";
import Paper from "../../../../components/Paper";
import { Button } from "../../../../components/Button";

import {
  provider_students_assigment_url,
  provider_students_assigment_view_url,
  provider_student_profile_url,
} from "../../../../configs/router_urls";
import fieldConfig from "./configs";

import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";
import { getStudentOptionsAction } from "../../../../pages/calendar/session/sessionForm/store";
import { getProviderStudentsListAction } from "../providerStudentsList/store";

import "./styles/index.scss";

function ProviderStudentAssignmentView() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [config, setConfig] = useState(fieldConfig);

  const { assignment } = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer);

  const { students } = useSelector(
    ({ providerStudentsListReducer }) => providerStudentsListReducer,
  );

  const transformData = (elem) => {
    if (elem === "1") {
      return "1:1";
    }
    if (elem === "G") {
      return "Group";
    }
    if (elem === "I") {
      return "Indirect";
    }
  };

  useEffect(() => {
    setConfig(fieldConfig.map((el) => {
      switch (el.name) {
      case "studentName":
        return { ...el, value: `${assignment.studentName} ${assignment.osisNo}` };
      case "weeklyMandate":
        return { ...el, value: `${assignment.sessionWeekly} x ${assignment.sessionLength}` };
      case "serviceTypeName":
        return { ...el, value: `${assignment.serviceTypeName} ${transformData(assignment.ratio)}` };
      default:
        return { ...el, value: assignment[el.name] };
      }
    }));
  }, [assignment, students]);

  useEffect(() => {
    dispatch(getAssignmentByIdAction(params.id));
    dispatch(getStudentOptionsAction((providerId) => {
      dispatch(
        getProviderStudentsListAction({
          providerId,
        }),
      );
    }));
  }, []);

  return (
    <>
      <HeaderPage
        title="Assignment View"
        rootPathName={provider_students_assigment_url}
        rootTitleName="Students"
        childrenTitleNames={["Assignment View"]}
        childrenPathNames={[`${provider_students_assigment_view_url}`]}
      />
      <Paper>
        <div className="studentAssView">
          {config.map((el) => (
            <>
              {el.id === 7 || el.id === 10 || el.id === 16
                ? <div className="studentAssView__item">
                  <div style={{ display: "flex" }}>
                    <CheckBox checked={assignment[el.name]}/>
                    {el.title}
                  </div>
                  {el.name === "substitute"
                  && <div style={{ marginTop: "8px" }}>For provider: <span className="studentAssView__value">{assignment.altProviderName}</span>
                  </div>}
                </div>
                : <div style={el.name === "timePeriod" ? { display: "grid", gridTemplateColumns: "85px 1fr" } : el.name === "note" ? { gridColumn: "1/6" } : null}>
                  {el.title}
                  <span style={el.name === "timePeriod" ? { display: "grid", gridTemplateRows: "1fr 1fr", gridTemplateColumns: "100px" } : null}
                    className="studentAssView__value">
                    {el.name === "timePeriod" ? <>
                      <span>{dateFormat("mm-dd-yyyy", assignment.startDt)} to </span>
                      <span>{dateFormat("mm-dd-yyyy", assignment.endDt)}</span>
                    </> : el.name !== "weeklyMandate" ? el.value : `${el.value} min`
                    }
                  </span>
                </div>
              }
              {el.type === "button" && <div className="studentAssView__btn">
                <Button variant="contained" color="primary"
                  onClick={() => history.push(`${provider_student_profile_url.split("/:")[0]}/${assignment.studentId}`)}
                >
                  Student profile
                </Button>
              </div>}
            </>
          ))}
        </div>
      </Paper>
    </>
  );
}

export default ProviderStudentAssignmentView;
