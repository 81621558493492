import * as Yup from "yup";

const headerConfig = {
  goalBody: {
    id: 1,
    title: "Goal",
    value: "goalBody",
    order: 1,
  },
  percentsCompleted: {
    id: 2,
    title: "Progress",
    value: "percentsCompleted",
    order: 2,
  },
  schedule: {
    id: 3,
    title: "Schedule",
    value: "schedule",
    order: 3,
  },
  state: {
    id: 4,
    title: "Status",
    value: "state",
    order: 4,
  },
};

const fieldConfig = [
  {
    id: 1,
    name: "skill",
    label: "Skill",
    placeholder: "input value",
    type: "select",
    data: [
      {
        id: 1,
        title: "SETSS",
        value: 1,
      },
      {
        id: 2,
        title: "SEIT",
        value: 2,
      },
    ],
  },
  {
    id: 2,
    name: "goalList",
    label: "Choose goal from list",
    placeholder: "input value",
    type: "select",
    data: [
      {
        id: 1,
        title: "SETSS",
        value: 1,
      },
      {
        id: 2,
        title: "SEIT",
        value: 2,
      },
    ],
  },
  {
    // title change only for example
    id: 3,
    name: "goal",
    label: "Goal",
    placeholder: "input value",
    type: "textfield",
    textArea: true,
    required: true,
  },
  {
    id: 4,
    name: "completionCriteria",
    label: "Completion criteria",
    placeholder: "input value",
    type: "textfield",
    textArea: true,
    required: true,
  },
  {
    id: 3,
    name: "schedule",
    label: "Schedule",
    placeholder: "input value",
    type: "datefield",
    required: true,
  },
  {
    id: 3,
    name: "status",
    label: "Status",
    placeholder: "input value",
    type: "select",
    required: true,
    data: [
      {
        id: 1,
        title: "SETSS",
        value: "A",
      },
      {
        id: 2,
        title: "SEIT",
        value: "C",
      },
    ],
  },
];

const formikInitialValues = {
  skill: "",
  goalList: "",
  // title change only for example
  goal: "",
  completionCriteria: "",
  schedule: "",
  status: "",
};

const validationSchema = Yup.object({
  skill: Yup.string(),
  goalList: Yup.string(),
  // title change only for example
  goal: Yup.string().required("required field"),
  completionCriteria: Yup.string().required("required field"),
  schedule: Yup.string().required("required field"),
  status: Yup.string().required("required field"),
});

export {
  headerConfig,
  fieldConfig,
  formikInitialValues,
  validationSchema,
};
