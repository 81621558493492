import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const adminInvoicesSlice = createSlice({
  name: "adminInvoicesSlice",
  initialState: {
    invoices: {
      invoices: [],
      pagination: {},
    },
    allInvoices: {
      invoices: [],
      pagination: {},
    },
    checkedInvoices: [],
    invoiceEmails: [],
    invoice: {},
  },
  reducers: {
    setInvoices: (state, { payload }) => {
      state.invoices = payload;
    },
    setCheckedInvoices: (state, { payload }) => {
      state.checkedInvoices = payload;
    },
    setInvoiceEmails: (state, { payload }) => {
      state.invoiceEmails = payload;
    },
    setInvoice: (state, { payload }) => {
      state.invoice = payload;
    },
    setAllInvoices: (state, { payload }) => {
      state.allInvoices = payload;
    },
  },
});

export default adminInvoicesSlice.reducer;

export const {
  setInvoices, setCheckedInvoices, setInvoiceEmails, setInvoice, setAllInvoices,
} = adminInvoicesSlice.actions;

export const getAdminInvoicesList = (data, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...data, limit, offset,
    });
    const response = await axios(`/reports/invoices-list?${params}`);
    dispatch(setInvoices(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const getAllInvoicesList = (data, limit) => async (dispatch) => {
  try {
    const params = formatterParams({
      ...data, limit,
    });
    const response = await axios(`/reports/invoices-list?${params}`);
    dispatch(setAllInvoices(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (b === "invoiceIds") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}invoiceIds[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}
export const getInvoiceEmail = (invoiceIds) => async (dispatch) => {
  try {
    const params = formatterParams({
      ...invoiceIds,
    });
    const response = await axios(`/reports/invoice-emails?${params}`);
    dispatch(setInvoiceEmails(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const sendEmailsToDOE = (data, ids, date, cb = () => { }) => async (dispatch) => {
  const params = formatterParams({
    ...ids,
  });
  try {
    await axios.post("/reports/send-invoices-on-emails", data);
    const response = await axios(`/reports/invoice-emails?${params}`);
    const info = { invoiceIds: ids.invoiceIds, sentToDoeDate: date };
    await axios.patch("/reports/send-paid-by-doe", info);
    cb();
    dispatch(setInvoiceEmails(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const getInvoice = (id) => async (dispatch) => {
  try {
    const response = await axios(`reports/info-invoice/${id}`);
    dispatch(setInvoice(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const uploadDocument = (data, cb = () => { }) => async () => {
  try {
    const response = await axios.post("/reports/save-pdf-for-email", data);
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const changeSentInvoiceStatus = (data, cb = () => { }) => async () => {
  try {
    await axios.patch("/reports/send-paid-by-doe", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const changeStatusToPaid = (data, cb = () => { }) => async () => {
  try {
    await axios.patch("/reports/send-to-paid", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const changePaidInvoicesStatus = (id, cb = () => { }) => async () => {
  try {
    await axios.patch(`/reports/invoice-change-to-sent/${id}`);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
