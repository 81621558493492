import React from "react";
import { Formik } from "formik";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import FormikButton, { Button } from "../../../../components/Button";
import FormikCheckBox from "../../../../components/Checkbox";
import FormikTextField from "../../../../components/TextField";
import FormikRadioButton from "../../../../components/RadioButton";
import FormikSelect from "../../../../components/Select";
import RouterPrompt from "../../../../components/RouterPromptWindow/RouterPrompt";
import CityInput from "../../../../components/CityInput";

function ProviderProfileComponent({
  formikInitialValues,
  validationShema,
  onSubmit,
  fieldConfig,
  formRef,
  setValueIsChange,
  valueIsChange,
  showPrompt,
  updatePrv,
  edit,
}) {
  const history = useHistory();

  const { name } = useSelector(({ systemUsersReducer }) => systemUsersReducer.providerInformation);

  return (
    <div className='provider_profile__wrapper'>
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize
        validationSchema={validationShema}
        onSubmit={(values) => onSubmit(values)} innerRef={formRef}
      >
        {({
          values, errors, touched, dirty,
        }) => (
          <React.Fragment>
            <RouterPrompt
              when={showPrompt}
              headerTitle="Discard unsaved changes?"
              onOK={() => true}
              onCancel={() => false}
              isChange={valueIsChange}
            />
            <div>
              {fieldConfig.leftSide.map((item) => {
                switch (item.type) {
                case "textfield": {
                  return (
                    <div className='provider_profile__field' key={item.id}>
                      <FormikTextField name={item.name} {...item} />
                    </div>
                  );
                }
                case "select": {
                  return (
                    <div className='createstudent__field' key={item.id + item.name}>
                      <FormikSelect
                        edit={edit}
                        {...item}
                        disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                      />
                    </div>
                  );
                }
                case "checkbox": {
                  return (
                    <div className='provider_profile__field' key={item.id}>
                      <FormikCheckBox name={item.name} {...item} />
                    </div>
                  );
                }
                case "empty": {
                  return (
                    <div className='provider_profile__field' key={item.id}> </div>
                  );
                }
                case "search": {
                  return (
                    <CityInput providerForm={true} {...item} />
                  );
                }
                default: return "default";
                }
              })}
            </div>
            <div>
              {fieldConfig.rightSide.map((item) => {
                switch (item.type) {
                case "textfield": {
                  if (item.name === "note") {
                    if (updatePrv) {
                      return (
                        <div style={{ height: "175px" }}/>
                      );
                    }
                  }

                  return (
                    <div className={clsx("provider_profile__field")} key={item.id}>
                      <FormikTextField name={item.name} {...item} />
                    </div>
                  );
                }
                case "select": {
                  return (
                    <div className='createstudent__field' key={item.id + item.name}>
                      <FormikSelect {...item} />
                    </div>
                  );
                }
                case "checkbox": {
                  return (
                    <div className='provider_profile__field' key={item.id}>
                      <FormikCheckBox name={item.name} {...item} />
                    </div>
                  );
                }
                case "checkboxesContainer": {
                  return (
                    <div className='provider_profile__checkboxes' key={item.id}>
                      {item.data.map((ckb) => (
                        <div className='provider_profile__field' key={ckb.id}>
                          <FormikCheckBox name={ckb.name} {...ckb} />
                        </div>
                      ))}
                    </div>
                  );
                }
                case "radio": {
                  return (
                    <div className='provider_profile__field' key={item.id}>
                      <div className={clsx("modal__wrapper-label", { "modal__wrapper-error": touched[item.name] && !!errors[item.name] })} id={item.name}>* {item.label}</div>
                      <div className='modal__wrapper-radiogroup' role="group" aria-labelledby={item.name}>
                        <FormikRadioButton type="radio" name={item.name} value="1" label='1099' />
                        <FormikRadioButton type="radio" name={item.name} value="W" label='W2' />
                      </div>
                      {touched[item.name] && !!errors[item.name] && <div className='modal__wrapper-errortext'> {errors[item.name]} </div>}
                    </div>
                  );
                }
                case "button": {
                  return (
                    <>
                      <div className='provider_profile__field button' key={item.id}>
                        <FormikButton variant='contained' color='primary' name={item.name} {...item}>
                          {item.label}
                        </FormikButton>
                      </div>
                    </>
                  );
                }
                case "cancelBtn": {
                  return (
                    <>
                      {values.firstName === name && name.length > 0
                        ? <div className='provider_profile__field button' style={{ paddingTop: "10px" }} key={item.id}>
                          <Button
                            variant="outlined"
                            color="grey"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </Button>
                        </div>
                        : null}
                    </>
                  );
                }
                case "search": {
                  return (
                    <CityInput providerForm={true} {...item} />
                  );
                }
                case "empty": {
                  return (
                    <div className='createstudent__field' key={item.id}/>
                  );
                }
                default: return "default";
                }
              })}

            </div>
            {dirty === true ? setValueIsChange(true) : null}
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
}

export default ProviderProfileComponent;
