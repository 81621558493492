import React from "react";
import "../styles/index.scss";
import { useHistory } from "react-router-dom";
import { banks_of_values_url } from "../../../configs/router_urls";
import SvgMaker from "../../../components/svgMaker";

function DictItem({ name, title }) {
  const history = useHistory();
  return (
    <div className="dictItem">
      <div className="dictItem__title">{title}</div>
      <div
        className="dictItem__edit"
        onClick={() => {
          history.push(`${banks_of_values_url}/${name}`);
        }}>
        <SvgMaker name="editIcon" fill="#495057" />
      </div>
    </div>
  );
}

export default DictItem;
