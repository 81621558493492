import React, { useEffect, useState } from "react";
import moment from "moment";

import SvgMaker from "../svgMaker";
import { Button } from "../Button";

import "./styles/index.scss";

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];

function PeriodPanel({
  onInvoice, data,
}) {
  const [months, setMonths] = useState(transformData(data));
  const [actualMonth, setActualMonth] = useState(0);

  useEffect(() => {
    setMonths(transformData(data));
    if (data.length === 2) {
      setActualMonth(1);
    }
  }, [data]);

  function transformData(data) {
    return data.reduce(
      (arr, el, index) => {
        if (index > 1) {
          return arr;
        }

        arr.push({
          id: index,
          title: `${monthNames[moment(el).month()]} ${moment(el).year()}`,
          value: el,
        });

        return arr;
      },
      [],
    );
  }

  const onLeft = () => {
    if (data.length < 2) return;
    setActualMonth(1);
  };

  const onRight = () => {
    setActualMonth(0);
  };

  return <div className="periodPanel">
    <div className="periodPanel_container__month">
      <div>
        <p>
          {months.length ? months[actualMonth].title : `${monthNames[moment().month()]} ${moment().year()}`}
        </p>
      </div>

      <div>
        <span
          style={{ marginRight: "6px" }}
          className={actualMonth === 0 && months.length > 1 ? "" : "disabled"}
          onClick={() => onLeft()}>
          <SvgMaker name="leftArrow"/>
        </span>

        <span
          className={actualMonth > 0 && months.length > 1 ? "" : "disabled"}
          onClick={() => onRight()}>
          <SvgMaker name="rightArrow"/>
        </span>
      </div>
    </div>

    <div className="periodPanel_container__button">
      <Button
        disabled={!months}
        variant='contained'
        color={months?.length ? "primary" : "grey"}
        onClick={() => months.length && onInvoice(months[actualMonth].value)}>
        Invoice the month
      </Button>
    </div>
  </div>;
}

export default PeriodPanel;
