import React from "react";
import { Button } from "../../../components/Button";
import SvgMaker from "../../../components/svgMaker";

const PanelHasSignature = ({ onSign, imgSignature, onDelete }) => (
  <>
    <div className="content">
      <div className="content__wrapper">
        <div className="signatureContainer">
          <div className="signatureContainer__item signer__success">
            <div style={{ marginRight: "15px", display: "flex", alignItems: "center" }}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.37933 5.83333C6.901 4.47417 5.606 3.5 4.0835 3.5C2.15266 3.5 0.583496 5.06917 0.583496 7C0.583496 8.93083 2.15266 10.5 4.0835 10.5C5.606 10.5 6.901 9.52583 7.37933 8.16667H9.91683V10.5H12.2502V8.16667H13.4168V5.83333H7.37933ZM4.0835 8.16667C3.44183 8.16667 2.91683 7.64167 2.91683 7C2.91683 6.35833 3.44183 5.83333 4.0835 5.83333C4.72516 5.83333 5.25016 6.35833 5.25016 7C5.25016 7.64167 4.72516 8.16667 4.0835 8.16667Z" fill="#FFFFFF" />
              </svg>
            </div>
            <span className="signer__signature_downloaded">Signature is uploaded</span>
          </div>
          <div onClick={onDelete} style={{ cursor: "pointer" }}>
            <SvgMaker name="deleteIcon" />
          </div>
        </div>
        <Button variant="contained" onClick={onSign}>Sign</Button>
      </div>
    </div>
    <div className="content" style={{ marginTop: "18px" }}>
      <div className="content__wrapper" style={{ padding: "30px" }}>
        <div className="signer__img">
          <img src={imgSignature} alt=""/>
        </div>
      </div>
    </div>
  </>
);

export default PanelHasSignature;
