import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "goalTitle",
    label: "Title",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 2,
    name: "goalCd",
    label: "Code",
    placeholder: "",
    required: false,
    type: "textfieldCode",
  },
  {
    id: 105,
    type: "empty",
  },
  {
    id: 3,
    name: "goalBody",
    label: "",
    placeholder: "",
    textArea: true,
    type: "textfieldText",
  },
  {
    id: 106,
    type: "empty",
  },
  {
    id: 107,
    type: "empty",
  },
  {
    id: 108,
    type: "empty",
  },
];

const formikInitialValues = {
  goalTitle: "",
  goalCd: "",
};

const yupObject = {
  goalTitle: Yup.string().min(3, "Goal title min length should be 3 characters.").max(512, "Goal title max length should be 512 characters.").required("Required field"),
  goalCd: Yup.string().nullable().max(16, "Code max length should be 16 characters."),
};

export { fieldConfig, formikInitialValues, yupObject };
