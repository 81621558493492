import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const studentInvoicesSlice = createSlice({
  name: "studentInvoices",
  initialState: {
    invoicesMonths: [],
    invoiceValidationInfo: {
      invoiceErrors: [],
      invoiceSummary: {},
      isReadyInvoicePeriod: null,
      emailsErrors: {},
    },
    invoicesList: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setStudentInvoices(state, { payload }) {
      state.invoicesMonths = payload;
    },
    setValidationInfo(state, { payload }) {
      state.invoiceValidationInfo = payload;
    },
    setInvoicesList(state, { payload }) {
      state.invoicesList = payload;
    },
  },
});

export const { setStudentInvoices, setValidationInfo, setInvoicesList } = studentInvoicesSlice.actions;

export const getInvoicesMonthsByAssignmentId = (id) => async (dispatch) => {
  try {
    const response = await axios(`/reports/invoices-months-by-assignment/${id}`);
    dispatch(setStudentInvoices(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
export const getInvoicesList = (id) => async (dispatch) => {
  try {
    dispatch(setInvoicesList({
      result: [],
      pagination: {},
    }));
    const response = await axios(`/reports/assignment-invoices-list/${id}`);
    dispatch(setInvoicesList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getInvoiceValidationInformationById = (id, date) => async (dispatch) => {
  dispatch(setValidationInfo({
    invoiceErrors: [],
    invoiceSummary: {
      absencesNumber: "",
      authorizationNo: "",
      caseNo: "",
      hoursAmount: "",
      invoicePeriod: "",
      invoicedSessionsNumber: "",
      payrollRate: "",
      typeName: "",
    },
    isReadyInvoicePeriod: null,
    emailsErrors: {},
  }));
  try {
    const response = await axios(`/reports/invoice-validation-information/${id}`, {
      params: {
        invoiceStartDate: date,
      },
    });
    dispatch(setValidationInfo(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default studentInvoicesSlice.reducer;
