import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import HeaderPage from "../../../../components/HeaderPage";
import InvoiceViewPDF from "../../../../components/PDF/InvoiceViewPDF";
import { provider_student_invoices_url, provider_students_assigment_url } from "../../../../configs/router_urls";
import SvgMaker from "../../../../components/svgMaker/SvgMaker";
import Loader from "../../../../components/LoadingIndicator";
import { infoConfig } from "./config";
import { getInvoice } from "./store";
import { dateFormat } from "../../../../utils/dateFormater";
import { monthNames } from "../../../../constants/monthNames";
import { signersFormatter } from "../../../../utils/signersFormatter";

function StudentPreviewInvoice() {
  const params = useParams();
  const dispatch = useDispatch();
  const invoice = useSelector(({ adminInvoicesListReducer }) => adminInvoicesListReducer.invoice);
  const [file, setFile] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [fileWithOutSign, setFileWithOutSign] = useState();
  useEffect(() => {
    if (params?.id) dispatch(getInvoice(params.id));
  }, []);
  useEffect(async () => {
    if (invoice?.invoicePdfLink) {
      const response = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${invoice.invoicePdfLink}`, {
        responseType: "blob",
      });
      const withOutSign = await axios(`/reports/invoice-preview/${invoice.assignmentId}?invoiceStartDate=${moment(invoice.invoiceMonth).format("YYYY-MM-DD")}`,
        {
          method: "get",
          responseType: "arraybuffer",
        });
      setFileWithOutSign(withOutSign.data);
      setFile(response.data);
    }
  }, [invoice]);
  const getLabelTitle = (state) => {
    switch (state) {
    case "С":
      return "Emails pending";
    case "E":
      return "Sent to signers";
    case "R":
      return "Rejected";
    default: return "Signed";
    }
  };
  const getInvoiceInfo = (key) => {
    if (key === "paidDt") {
      return invoice[key] ? dateFormat("mm-slash-d-slash-yyyy", invoice[key]) : "-";
    } if (key === "pendingSignatures" || key === "givenSignatures") {
      const last = invoice[key]?.length - 1;
      return invoice[key]?.length ? invoice[key].map((signer, index) => signersFormatter(signer, index, last)) : "-";
    }
    if (key === "invoiceMonth") {
      return `${monthNames[moment(invoice[key]).month()]} ${moment(invoice[key]).year()}`;
    }
    return invoice[key];
  };

  const getLabelClass = (state) => {
    switch (state) {
    case "С":
      return "pending";
    case "E":
      return "sent";
    case "R":
      return "rejected";
    default: return "signed";
    }
  };

  return (
    <div>
      <div className="invoice_view">
        {showPreview
        && <InvoiceViewPDF
          file={file}
          hidePreview={() => setShowPreview(false)}
          fileWithOutSign={fileWithOutSign}
        />
        }
        <div className="invoice_view__left">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <HeaderPage
              title="Invoice" // for Provider login -> "My students" tab
              rootPathName={provider_students_assigment_url}
              childrenPathNames={[`${provider_student_invoices_url.split("/:")[0]}/${invoice?.assignmentId}`]}
              rootTitleName='Students'
              childrenTitleNames={["Student invoices", "View incoming invoice"]}
            />
            {invoice.state && <div className={`invoice_view__label ${getLabelClass(invoice.state)}`}>{getLabelTitle(invoice.state)}</div>}
          </div>
          <div className="content">
            <div className="content__wrapper">
              <span className="text__h1">{invoice?.studentName}</span>
              <div className="invoice_view__container">
                <div className="invoice_view__pdf_preview">
                  <div className="invoice_view__pdf_preview__content">
                    <div className="invoice_view__pdf_preview__header">
                      <div
                        className="invoice_view__pdf_preview__open_icon"
                        onClick={() => setShowPreview(true)}>
                        <SvgMaker name="tv"/>
                      </div>
                    </div>
                    <div className="invoice_view__pdf_preview__body">
                      {file ? <Document
                        file={file}
                        options={{ useWorkerFetch: false }}
                      >
                        <Page pageNumber={1}/>
                      </Document> : <Loader/>
                      }
                    </div>
                  </div>
                  <div
                    className="invoice_view__pdf_preview__footer"
                    onClick={() => setShowPreview(true)}
                  >
                    <p>View on fullscreen</p>
                  </div>
                </div>
                <div className="invoice_view__pdf_info">
                  {infoConfig.map((el) => (
                    <div className={`invoice_view__pdf_info__item ${el.key === "pendingSignatures" || el.key === "givenSignatures" ? "flex" : ""}`}>
                      <span className="text__subtitle1-bold">{el.title}: </span>
                      <span style={{ marginLeft: "8px" }}>{getInvoiceInfo(el.key)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentPreviewInvoice;
