import React from "react";

function Laoyut({ children }) {
  return (
    <div className='main__content-layout'>
      <div className='layout__container'>
        {children}
      </div>
    </div>
  );
}

export default Laoyut;
