import { CircularProgress } from "@material-ui/core";

const Loader = () => (
  <div style={{
    display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: 10, marginBottom: 10,
  }}>
    <CircularProgress color="inherit" />
  </div>
);

export default Loader;
