import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const systemRolesSlice = createSlice({
  name: "systemRolesReducer",
  initialState: {
    systemRoles: [],
    permissions: [],
  },
  reducers: {
    setSystemRoles: (state, { payload }) => {
      state.systemRoles = payload;
    },
    setPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
    clearPermissions: (state) => {
      state.permissions = [];
    },
  },
});

export default systemRolesSlice.reducer;

export const { setSystemRoles, setPermissions, clearPermissions } = systemRolesSlice.actions;

export const getSystemRolesAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/admin-service/system-roles");
    dispatch(setSystemRoles(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getPermissionForRoleAction = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/admin-service/permission-for-role/${id}`);
    dispatch(setPermissions(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updatePermissionForRoleAction = (id, data) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.patch(`/admin-service/change-permission-for-role/${id}`, data);
    dispatch(setPermissions(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
