import React, { useEffect, useState } from "react";

import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import MyltiSelect from "./PromtsSelect";
import { PaperModal } from "../../../../../../components/modal/Modal";
import { Button } from "../../../../../../components/Button";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";

import "../../styles/index.scss";

const FieldArrayModalContent = (props) => (
  <div className="field_array_modal-content">
    {props.children}
  </div>
);

function SessionPrompts({
  promptsDict, promptLevelDict, setSelected, selected, hideEdit,
}) {
  const [promptsSelected, setPromptsSelected] = useState(transform(selected));
  const [open, setOpen] = useState(false);
  const [isValueChange, setValueChange] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  function transform(selected) {
    return selected.map((el) => ({
      viewTitle: `${el.attrValue};${el.promptLevel}`, title: el.attrValue, value: el.attributeId, id: el.attributeId, lvl: el.promptLevel,
    }));
  }
  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    setPromptsSelected(transform(selected));
  }, [selected]);

  const onSave = () => {
    setValueChange(false);
    const prompts = promptsSelected.map(({
      id, value, title, lvl,
    }) => ({
      attributeId: id, attrValue: title, attrType: value, promptLevel: lvl,
    }));
    setSelected({ ...selected, prompts });
    setOpen(false);
  };

  const checkOpen = (e) => {
    if (e.nativeEvent.path.find((el) => el.className === "grey-screen")) setOpen(false);
    else if (e.nativeEvent.path.find((el) => el.className === "modal__closeicon")) setOpen(false);
    else if (e.target.textContent === "Save") setOpen(false);
    else if (e.target.textContent === "Discard") setOpen(false);
    else setOpen(true);
  };

  return (
    <div className='session_notes_form_horizont-line' onClick={checkOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div className="session_notes_form_section-title">
          <span>Prompt types and levels</span>
          <div
            className={hideEdit && "hide"}>
            <SvgMaker name="editIcon" /></div>
          {!confirmModal && !hideEdit && <PaperModal headerTitle="Prompt types and levels" open={open} onClose={checkClosure}>
            <FieldArrayModalContent>
              <MyltiSelect
                data={promptsDict}
                selected={promptsSelected}
                selectData={promptLevelDict}
                setSelected={setPromptsSelected}
                setValueChange={setValueChange}
              />
              <div style={{ marginTop: 50 }}>
                <Button variant="contained" color="primary" onClick={(e) => { onSave(); checkOpen(e); }}>Save</Button>
              </div>
            </FieldArrayModalContent>
          </PaperModal>}
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => {
                setConfirmModal(false);
                setValueChange(false);
                setOpen(false);
                setPromptsSelected(transform(selected));
              }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
        <div className="session_notes_form_section-content-prompt">
          <div style={{ flexDirection: "column" }} className="session_notes_form_section-content">
            {transform(selected).map((el, index) => <div key={index}>
              <span style={{ display: "inline-block", paddingTop: 5, paddingRight: 10 }}>
              Prompt: {el.viewTitle?.split(";")[0]}
              </span>
              <span>
              Level: {el.viewTitle?.split(";")[1]}
              </span>
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionPrompts;
