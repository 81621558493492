import React, { memo, useState, useEffect } from "react";
import moment from "moment";

import Dialog from "../Dialog";
import DatePicker from "../DatePicker";
import { Button } from "../Button";
import { getCurrentDate } from "../../functions/calendar_func";

import "./style.scss";

const CalendarPopupContent = (props) => {
  const {
    isRange,
    onSave,
    isBirthDay,
    availableStartDate,
    availableEndDate,
    finish,
    isTableYear,
    defaultDate,
  } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!isRange) setStartDate(defaultDate ? moment(defaultDate).format() : getCurrentDate());
    if (defaultDate?.startDate) setStartDate(defaultDate.startDate);
    if (isRange && defaultDate) {
      setStartDate(moment(defaultDate.split("to")[0].trim()).format());
      setEndDate(moment(defaultDate.split("to")[1].trim()).format());
    }
  }, []);

  function handleReset() {
    setStartDate(null);
    setEndDate(null);
  }

  return (
    <div className="calendar_popup">
      <DatePicker
        isBirthDay={isBirthDay}
        startDate={startDate}
        updateStartDate={setStartDate}
        updateEndDate={setEndDate}
        endDate={endDate}
        isRange={isRange}
        availableStartDate={availableStartDate}
        availableEndDate={availableEndDate}
        finish={finish}
        isTableYear={isTableYear}
      />
      <div className="calendar_popup-buttons">
        {!isTableYear ? <Button
          disabled = {isRange ? (!startDate || !endDate) : !startDate}
          variant="contained"
          onClick={() => onSave({ startDate, endDate })}
        >
          Save
        </Button>
          : <Button
            disabled = {false}
            variant="contained"
            onClick={() => onSave()}
          >
          Save
          </Button>}
        {startDate && (
          <Button color="grey" onClick={handleReset}>
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

const CalendarPopup = (props) => {
  const {
    customTrigger, isBirthDay, hideModal, setHideModal,
  } = props;

  const trigger = customTrigger || <input placeholder="Click me" type="text" />;
  return (
    <Dialog
      trigger={trigger}
      hideModal={hideModal}
      setHideModal={setHideModal}
    >
      <CalendarPopupContent {...props} isBirthDay={isBirthDay} />
    </Dialog>
  );
};

export default memo(CalendarPopup);
