import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderPage from "../../../../components/HeaderPage";
import Typography from "../../../../components/Typography";
import { Button } from "../../../../components/Button";
import Pagination from "../../../../components/Pagination/Pagination";
import { getNewMessages, makeMessageReaded, getAllMessages } from "./store";
import { dateFormat } from "../../../../utils/dateFormater";

import "./styles/index.scss";

const FeedCard = ({
  message, sendDt, seen, hideMessage, status,
}) => (
  <div className='provider-home__wrapper'>
    <div style={{ width: "50%" }}>
      <div className='provider-home__wrapper-time'>
        <Typography
          variant='subtitle1-bold'
          style={{ color: "#192A53" }}
        >
          {dateFormat("mm-dd-yyyy", sendDt)}
        </Typography>
        {!seen && <div className='provider-home__wrapper-point'><span className='provider-home__wrapper-point-text'>new</span></div>}
      </div>
      <div>
        <Typography
          style={
            seen === false
              ? { fontSize: "14px", fontWeight: 600, color: "#192A53" }
              : { fontSize: "14px", fontWeight: 600, color: "rgba(25, 42, 83, 0.6)" }
          }
          variant='subtitle1'
        >
          {message}
        </Typography>
      </div>
    </div>
    <div className='provider-home__wrapper-status'>
      <div/>
      {status === "new" && <div className="hideBtn" onClick={hideMessage}>Hide</div>}
    </div>
  </div>
);

function Home() {
  const dispatch = useDispatch();

  const [activeState, setActiveState] = useState("active");
  const [status, setStatus] = useState("new");
  const [currentIndex, setCurrentIndex] = useState(0);

  const user = useSelector(({ authorizationReducer }) => authorizationReducer.user?.userId);
  const { result, pagination } = useSelector(({ providerHomePage }) => providerHomePage.allMessages);

  useEffect(() => {
    status === "new"
      ? dispatch(getNewMessages(user))
      : dispatch(getAllMessages(user));
  }, [status]);

  const paginationClick = (offset) => {
    status === "new"
      ? dispatch(getNewMessages(user, offset))
      : dispatch(getAllMessages(user, offset));
  };

  return (
    <div className="providerHomePage">
      <HeaderPage
        title="Updates"
      />
      <div className="providerHomePage__btn">
        <div>
          <Button
            color={activeState === "active" ? "primary" : "secondary"}
            onClick={() => {
              setActiveState("active");
              setStatus("new");
              setCurrentIndex(0);
              dispatch(getNewMessages(user));
            }}
          >
            New
          </Button>
        </div>
        <div>
          <Button
            color={activeState === "inactive" ? "primary" : "secondary"}
            onClick={() => {
              setActiveState("inactive");
              setStatus("all");
              setCurrentIndex(0);
              dispatch(getAllMessages(user));
            }}
          >
            All
          </Button>
        </div>
      </div>
      <div className='provider-home'>
        {result.map((item) => <FeedCard key={item.id} {...item} hideMessage={() => {
          dispatch(makeMessageReaded(item.providerMessageId));
          dispatch(getNewMessages(user));
        }
        } status={status}/>)}
      </div>
      {
        result.length
          ? <Pagination
            {...pagination}
            onClick={paginationClick}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
          : null
      }
    </div>
  );
}

export default Home;
