import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useFormikContext } from "formik";
import moment from "moment";

import SvgMaker from "./svgMaker/SvgMaker";
import CalendarModal from "./CalendarModal/index.jsx";
import { dateFormat } from "../utils/dateFormater";

function FormikDateField({ name, ...props }) {
  const {
    touched, errors, values, handleChange, setFieldValue,
  } = useFormikContext();
  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";

  useEffect(() => {
    if (props.edit) {
      setFieldValue(name, props.eventDt);
    }
  }, []);

  return <DateField
    name={name}
    {...props}
    error={fieldTouched}
    value={values[name]}
    nativeClick={handleChange(name)}
  />;
}

function DateField({
  value = "",
  label = "",
  error = "",
  placeholder = "",
  required,
  disabled,
  name = "",
  isRange,
  isBirthDay = false,
  readOnly = true,
  inputRef = null,
  onClick = () => {},
  nativeClick = () => {},
  showIcon = false,
  inputStyle = {},
  availableStartDate = null,
  availableEndDate = null,
  format = "",
  separator = "",
  diffDate,
  setDiffDate,
  sessionForm,
  finish,
  setDate = () => { },
  date,
  edit,
  isTableYear,
  defaultDate = "",
}) {
  const nativeRef = useRef();

  const handleClick = (event) => {
    onClick(event);
    nativeRef.current.click();
  };

  useEffect(() => {
    if (sessionForm) {
      setDiffDate({ current: diffDate.current, otherDate: value });
    }
  }, [value]);

  const onSave = useCallback((date) => {
    setDate(date);
    if (isRange && !isTableYear) {
      const strD = dateFormat(format || "mm-dd-yyyy", date.startDate);
      const endD = dateFormat(format || "mm-dd-yyyy", date.endDate);
      if (moment(strD).diff(moment(endD), "seconds") > 0) {
        nativeRef.current.value = `${endD}${separator || " to "}${strD}`;
      } else {
        nativeRef.current.value = `${strD}${separator || " to "}${endD}`;
      }

      nativeRef.current.click();
    } else if (isTableYear) {
      const regExp = /[^0-9]/g;
      nativeRef.current.value = document.querySelector("h4 > div.date_picker-header--current_month_year").innerText.replace(regExp, "");
      nativeRef.current.click();
    } else {
      nativeRef.current.value = dateFormat("mm-dd-yyyy", date.startDate);
      nativeRef.current.click();
    }
  }, []);

  return (
    <>
      {!disabled
        ? <CalendarModal
          onSave={onSave}
          isRange={isRange}
          disabled={disabled}
          isBirthDay={isBirthDay}
          finish={finish}
          defaultDate={defaultDate !== value ? value : defaultDate}
          availableStartDate={availableStartDate}
          availableEndDate={availableEndDate}
          date={value}
          isTableYear={isTableYear}
          customTrigger={
            <div className={clsx("datefield", { datefield__disabled: disabled })} ref={inputRef}>
              {label && <span className={clsx("datefield__label", { "datefield__label-error": !!error })}>{required ? `* ${label}` : label}</span>}
              <div className='datefield__container'>
                {showIcon && <span className='datefield__container-icon' onClick={handleClick}>
                  <SvgMaker name='calendaricon' fill={error ? "#ff5454" : "#192A53"}/>
                </span>}
                <input
                  className={clsx("datefield__container-field", { "datefield__container-error": !!error })}
                  name={name}
                  onClick={nativeClick}
                  ref={nativeRef}
                  type='text'
                  value={edit ? value ?? date : value}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  disabled={disabled}
                  style={inputStyle}
                />
              </div>
              {error && <span className='datefield__errortext'>{error}</span>}
            </div>}/>
        : <div>
          {label && <span className={clsx("datefield__label", { "datefield__label-error": !!error })}>{required ? `* ${label}` : label}</span>}
          <div className={clsx("datefield", { datefield__disabled: disabled })} ref={inputRef}>
            <div className='datefield__container'>
              {showIcon && <span className='datefield__container-icon' onClick={handleClick}>
                <SvgMaker name='calendaricon' fill={error ? "#ff5454" : "#192A53"}/>
              </span>}
              <input
                className={clsx("datefield__container-field", { "datefield__container-error": !!error })}
                name={name}
                onClick={nativeClick}
                ref={nativeRef}
                type='text'
                value={value}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                style={inputStyle}
              />
            </div>
            {error && <span className='datefield__errortext'>{error}</span>}
          </div>
        </div>
      }
    </>
  );
}

DateField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.object,
  onClick: PropTypes.func,
  inputStyle: PropTypes.object,
  nativeClick: PropTypes.func,
  finish: PropTypes.string,
};

export default FormikDateField;
export {
  DateField,
};
