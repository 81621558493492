export const headerConfig = {
  creationDate: {
    title: "Creation date",
    value: "creationDate",
    order: 1,
  },
  state: {
    title: "Status",
    value: "state",
    order: 2,
  },
  pendingSignatures: {
    title: "Pending signatures",
    value: "pendingSignatures",
    order: 3,
  },
  appliedSignatures: {
    title: "Applied signatures",
    value: "appliedSignatures",
    order: 4,
  },
};

export const data = [
  {
    id: 1,
    creationDate: "2010-01-28T00:00:00.000Z",
    status: "Sent to admin",
    pendingSignatures: "Parent",
    appliedSignatures: "Provider",
    startData: "2021-10-10T00:00:00.000Z",
    endData: "2021-12-10T00:00:00.000Z",
  },
];
