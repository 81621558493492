import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import moment from "moment";

import Dialog from "../../../../components/Dialog";
import { Button } from "../../../../components/Button";
import { TextField } from "../../../../components/TextField";
import { DateField } from "../../../../components/DateField";
import { RadioButton } from "../../../../components/RadioButton";
import { Select } from "../../../../components/Select";
import SvgMaker from "../../../../components/svgMaker/SvgMaker";

import "./style.scss";

import { week_days } from "../../../../functions/calendar_func";

function CustomRecurrenceDialogContent({
  repeatOn,
  updateEndChecked,
  endChecked,
  updateRepeatOn,
  repeat,
  occurrences,
  setOccurrences,
  handleCloseDialog,
  endOn,
  setEndOn,
  setRepeat,
  isChange,
  setIsChange,
  diffDate,
  defaultDate,
  setDate,
  date,
  edit,
}) {
  const [dialogRepeatOn, setDialogRepeatOn] = useState(repeatOn);
  const [dialogRepeat, setDialogRepeat] = useState(repeat);
  const [dialogEndChecked, setDialogEndChecked] = useState(endChecked);
  const [dialogEndOn, setDialogEndOn] = useState(edit ? date : endOn ?? null);
  const [dialogOccurrences, setDialogOccurrences] = useState(occurrences);
  const [isValid, setIsValid] = useState(true);
  const [errorMessageRepeat, setErrorMessageRepeat] = useState("");
  const [errorMessageOccurrences, setErrorMessageOccurrences] = useState("");
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (!isChange) setIsChange(true);
    }
    didMount.current = true;
  }, [dialogRepeat, dialogEndChecked, dialogEndOn, dialogOccurrences]);

  useEffect(() => {
    setDialogRepeatOn((repeatOn.length) ? repeatOn : [moment(diffDate.otherDate).format("dd")]);
  }, [diffDate]);

  const handleUpdateRepeatOn = (day) => {
    const isExist = dialogRepeatOn.includes(day);
    setDialogRepeatOn(
      isExist
        ? dialogRepeatOn.filter((repeatDay) => repeatDay !== day)
        : [...dialogRepeatOn, day],
    );
  };

  const onChange = (event) => {
    setIsValid(
      validation(event.target.value, setErrorMessageRepeat)
      && validation(dialogOccurrences, setErrorMessageOccurrences),
    );
    setDialogRepeat({ ...dialogRepeat, value: event.target.value });
  };

  const onChangeOccurrences = (event) => {
    setIsValid(
      validation(event.target.value, setErrorMessageOccurrences)
      && validation(dialogRepeat.value, setErrorMessageRepeat),
    );
    setDialogOccurrences(event.target.value);
  };

  const onSave = () => {
    if (!isValid) return;
    (dialogRepeat.title === "day") ? updateRepeatOn([]) : updateRepeatOn(dialogRepeatOn.sort((a, b) => week_days.indexOf(a) - week_days.indexOf(b)));
    setRepeat(dialogRepeat);
    updateEndChecked(dialogEndChecked);
    setEndOn(dialogEndOn);
    setOccurrences(dialogOccurrences);
    handleCloseDialog();
  };

  const validation = (value, setErrorMessage) => {
    if (typeof Number(value) !== "number" || isNaN(Number(value))) {
      setErrorMessage("Must be a number");
      return false;
    }
    if (value <= 0) {
      setErrorMessage("The value should be greater than 0");
      return false;
    }
    if (value > 32767) {
      setErrorMessage("The value should be less than 32768");
      return false;
    }
    if (!Number.isInteger(+value)) {
      setErrorMessage("The number must not be fractional");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  return (
    <div className="custom_reccurence_dialog-content">
      <div className="custom_reccurence_dialog-content_field_repeat-every">
        <span className="custom_reccurence_dialog-content_field__label">
          Repeat every
        </span>
        <div className="custom_reccurence_dialog-content_field_repeat-every_field">
          <div className="custom_reccurence_dialog-content_field_repeat-every_field-count">
            <TextField onChange={onChange} value={dialogRepeat.value} error={errorMessageRepeat ? " " : ""}/>
          </div>
          <div className="custom_reccurence_dialog-content_field_repeat-every_field-unit">
            <Select
              name='repeat'
              data={[{ id: 0, value: "day", title: "day" }, { id: 1, value: "week", title: "week" }]}
              value={dialogRepeat.title}
              onNativeChange={(d) => setDialogRepeat({ ...dialogRepeat, title: d.target.value })}
            />
          </div>
        </div>
        <div className="custom_reccurence_dialog-content_field_repeat-every_field-error-container">
          {!isValid && <p className="custom_reccurence_dialog-content_field_repeat-every_field-error-text">{errorMessageRepeat}</p>}
        </div>
      </div>

      <div className="custom_reccurence_dialog-content_field_repeat-on">
        {dialogRepeat.title === "week" && <>
          <span className="custom_reccurence_dialog-content_field__label">
            Repeat on:
          </span>
          <div className="custom_reccurence_dialog-content_field_repeat-on_field">
            {week_days.map((day, index) => (
              <div
                key={day + index}
                className={clsx(
                  "custom_reccurence_dialog-content_field_repeat-on_field-day",
                  dialogRepeatOn.includes(day) && "active",
                )}
                onClick={() => handleUpdateRepeatOn(day)}
              >
                {day}
              </div>
            ))}
          </div>
        </>}
      </div>

      <div className="custom_reccurence_dialog-content_field_ends">
        <span className="custom_reccurence_dialog-content_field__label">
          Ends
        </span>
        <div className="custom_reccurence_dialog-content_field_ends_field">
          <div className="custom_reccurence_dialog-content_field_ends_field-on">
            <div className="custom_reccurence_dialog-content_field_ends_field-radio">
              <RadioButton
                label="On"
                value={dialogEndChecked}
                checked="on"
                onClick={() => setDialogEndChecked("on")}
              />
            </div>
            <div className={`custom_reccurence_dialog-content_field_ends_field-on_date ${dialogEndChecked !== "on" ? "text__desibled" : ""}`}>
              <DateField
                disabled = {dialogEndChecked !== "on"}
                setDate={setDate}
                defaultDate={defaultDate}
                value={dialogEndChecked === "on" ? dialogEndOn : ""}
                nativeClick={(e) => setDialogEndOn(e.target.value)}
                inputStyle={{
                  backgroundColor: "#FFF",
                  textAlign: "center",
                  textIndent: "0",
                }}
              />
            </div>
          </div>
          <div className="custom_reccurence_dialog-content_field_ends_field-after">
            <div className="custom_reccurence_dialog-content_field_ends_field-radio">
              <RadioButton
                label="After"
                value={dialogEndChecked}
                checked="after"
                onClick={() => setDialogEndChecked("after")}
              />
            </div>
            <div className={`custom_reccurence_dialog-content_field_ends_field-after_occurences ${dialogEndChecked !== "after" ? "text__desibled" : ""}`}>
              <TextField
                onChange={onChangeOccurrences}
                disabled = {dialogEndChecked !== "after"}
                value={dialogOccurrences}
                style={{ backgroundColor: "transparent" }}
                error={errorMessageOccurrences ? " " : ""}
              />
              <span className="custom_reccurence_dialog-content_field_ends_field-after_occurences-label">
                occurrences
              </span>
            </div>
          </div>
          <div className="custom_reccurence_dialog-content_field_repeat-every_field-error-container">
            {!isValid && <p className="custom_reccurence_dialog-content_field_repeat-every_field-error-text">{errorMessageOccurrences}</p>}
          </div>
        </div>
      </div>
      <div className="custom_reccurence_dialog-content_button">
        <Button variant="contained" color="primary" onClick={onSave} disabled={(!isValid)}>
          Save
        </Button>
      </div>
    </div>
  );
}

function CustomRecurrenceDialog(props) {
  const [isChange, setIsChange] = useState(false);
  const trigger = (
    <Button
      variant="contained"
      style={{
        display: "flex",
        alignItems: "end",
        justifyContent: "flex-end",
        backgroundColor: "transparent",
        height: "100%",
      }}
    >
      <SvgMaker name='editIcon'/>
    </Button>
  );

  return (
    <Dialog
      trigger={trigger}
      title="Custom recurrence"
      ConfirmChildren={ConfirmDialog}
      isChange={isChange}
      setIsChange={setIsChange}
      hideModal={props.hideModal}
      setHideModal={props.setHideModal}
      setDate={props.setDate}
    >
      <CustomRecurrenceDialogContent isChange={isChange} setIsChange={setIsChange} { ...props } />
    </Dialog>
  );
}

function ConfirmDialog({ closeParent, closeView }) {
  return (
    <>
      <h3 className="dialog_content-title">Discard unsaved changes?</h3>
      <div style={{ paddingTop: "16px" }}>
        <div style={{ paddingBottom: "2px" }}>
          <Button variant="contained" onClick={closeParent}>Discard</Button>
        </div>
        <div style={{ paddingBottom: "2px" }}>
          <Button variant="default" color="grey" onClick={closeView}>Cancel</Button>
        </div>
      </div>
    </>
  );
}

export default CustomRecurrenceDialog;
