import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import formatterParams from "../../../../../utils/formatterParams";

const providerSessionsSlice = createSlice({
  name: "providerSessionsSlice",
  initialState: {
    data: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setSessions: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export default providerSessionsSlice.reducer;

export const { setSessions } = providerSessionsSlice.actions;

export const getSessionsList = (filters, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...filters, limit, offset,
    }).replace(/\+/g, "%2B");
    const response = await axios(`/session/provider-sessions?${params}`);
    dispatch(setSessions(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
