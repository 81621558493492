/** return ratio by serviceType typeName
 * @name getStudentRatio
 * @param {string} typeName - serviceType.typeName
 * @return [{id: number, title: ("1:1"|"Group"|"Indirect"), value: ("1"|"G"|"I")}]
 * @throws Error
 * */
export function getStudentRatio(typeName) {
  switch (typeName) {
  case "SETSS":
  case "SEIT":
    return [
      {
        id: 1,
        title: "1:1",
        value: "1",
      },
      {
        id: 2,
        title: "Group",
        value: "G",
      },
      {
        id: 3,
        title: "Indirect",
        value: "I",
      },
    ];
  case "Speech":
  case "OT":
  case "PT":
  case "Counseiling":
    return [
      {
        id: 1,
        title: "1:1",
        value: "1",
      },
      {
        id: 2,
        title: "Group",
        value: "G",
      },
    ];
  case "Para":
  case "BCBA":
    return [
      {
        id: 1,
        title: "1:1",
        value: "1",
      },
    ];
  case "Teacher":
  case "Specialty teacher":
    return [];
  default:
    error("getStudentRatio", typeName);
  }
}
/** return billingTypes by serviceType typeName
 * @name getBillingTypes
 * @param {string} typeName - serviceType.typeName
 * @return [{id: number, title: ("E"|"I"|"V"), value: ("E"|"I"|"V")}]
 * @throws Error
 * */
export function getBillingTypes(typeName) {
  switch (typeName) {
  case "SETSS":
    return [
      {
        id: 1,
        title: "Enhanced",
        value: "E",
      },
      {
        id: 2,
        title: "IHOIU",
        value: "I",
      },
    ];
  case "SEIT":
    return [
      {
        id: 2,
        title: "IHOIU",
        value: "I",
      },
    ];
  case "Speech":
  case "OT":
  case "PT":
  case "Counseiling":
  case "Para":
  case "BCBA":
    return [
      {
        id: 2,
        title: "IHOIU",
        value: "I",
      },
      {
        id: 3,
        title: "Vendor",
        value: "V",
      },
    ];
  case "Teacher":
  case "Specialty teacher":
    return [];
  default:
    error("getBillingTypes", typeName);
  }
}
/** Description object ServiceType
 * @typedef {Object} ServiceType
 * @property {number} serviceTypeNo - index
 * @property {("SETSS"|"SEIT"|"Speech"|"OT"|"PT"|"Counseiling"|"Para"|"BCBA"|"Teacher"|"Specialty teacher")} typeName - type name
 * @property {("S"|"R"|"O")} typeKind - type kind
 * */

/** return array serviceTypes for add/edit service
 * @name getServiceTypeForAddEdit
 * @param {[ServiceType]} serviceTypes - serviceTypes
 * @return [ServiceType] - without Teacher and Specialty teacher
 * */
export function getServiceTypeForAddEdit(serviceTypes) {
  return serviceTypes.filter((service) => !(service.typeName === "Teacher" || service.typeName === "Specialty teacher"));
}

function error(functionName, value) {
  throw new Error(` ${functionName} something wrong.
    The value must be, a string and one of the following values: "SETSS", "SEIT", "Speech", "OT", "PT", "Counseiling", "Para", "BCBA", "Teacher", "Specialty teacher". 
    Your value: ${value}`);
}
