import React from "react";
import "../styles/index.scss";
import { useHistory } from "react-router-dom";
import { admin_system_roles_edit_url } from "../../../configs/router_urls";
import SvgMaker from "../../../components/svgMaker";

function SystemRoleItem({ groupName, groupId }) {
  const history = useHistory();
  return (
    <div className="roleItem">
      <div className="roleItem__title">{groupName}</div>
      <div
        className="roleItem__edit"
        onClick={() => {
          history.push(`${admin_system_roles_edit_url}/${groupName}/${groupId}`);
        }}>
        <SvgMaker name="editIcon" fill="#495057" />
      </div>
    </div>
  );
}

export default SystemRoleItem;
