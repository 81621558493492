import axios from "axios";
import { setStudentProfile } from "../../studentProfile/store";
import changeEmptyFieldsToNull from "../../../../../../utils/changeEmptyFieldsToNull";

export const updateProfileAction = (id, data, cb) => async (dispatch) => {
  try {
    await axios.put(`/student/update-student/${id}`, changeEmptyFieldsToNull(data));
    dispatch(setStudentProfile(data));
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
