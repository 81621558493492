import React from "react";
import { Route, Redirect } from "react-router-dom";

function PublicPage({
  children, isAuth, ...route
}) {
  return (
    <React.Fragment>
      <Route
        {...route}
        render={({ match, ...rest }) => {
          if (isAuth) return <Redirect to={"/"}/>;
          return children;
        }}
      />
    </React.Fragment>
  );
}

export default PublicPage;
