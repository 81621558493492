import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const adminAssignmentsSlice = createSlice({
  name: "assignmentsList",
  initialState: {
    data: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setAssigmentsList: (state, { payload }) => {
      state.data = payload;
    },
  },
});

const { setAssigmentsList } = adminAssignmentsSlice.actions;

export default adminAssignmentsSlice.reducer;

export const getAssignmentList = (data, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...data, limit, offset,
    });
    const response = await axios(`/assignment/assignment-list?${params}`);
    dispatch(setAssigmentsList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}

export const deleteAssignment = (id, params, limit) => async (dispatch) => {
  try {
    await axios.delete(`/assignment/delete-assignment/${id}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    dispatch(getAssignmentList(params, limit));
  }
};
