import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderPage from "../../components/HeaderPage";
import FormikButton, { Button } from "../../components/Button";
import ConfirmModal from "../../components/confirmModal/ConfirmModal";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import tableFormater from "../../utils/tableFormater";
import { PaperModal } from "../../components/modal/Modal";
import FormikSelect from "../../components/Select";
import FormikTextField from "../../components/TextField";
import {
  getSystemUsersAction, setProviderInformation, addNewUserAction, editUserAction, deleteUserAction,
} from "./store";
import { getSystemRolesAction } from "../systemRole/store";
import SvgMaker from "../../components/svgMaker/SvgMaker";
import { getProviderDictionariesAction } from "../../entities/admin/store";
import { transformUsersData } from "./helpers/transformUsersData";

import "./styles/index.scss";

import {
  headerConfig, validationSchema, formikInitialValues, fieldConfig, passwordValidation,
} from "./config";
import { admin_create_providers_url } from "../../configs/router_urls";

function SystemUsers() {
  const table = useRef();

  const history = useHistory();

  const dispatch = useDispatch();

  const states = ["E", "D", "L"];

  const systemRoles = useSelector(({ systemRolesReducer }) => systemRolesReducer.systemRoles);
  const { users: data, pagination } = useSelector(({ systemUsersReducer }) => systemUsersReducer.systemUsers);

  const [currentUser, setCurrentUser] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [classNameModalWindow, setClassNameModalWindow] = useState("");
  const [userToEdit, setUserToEdit] = useState({});
  const [state, setState] = useState(0);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [config, setConfig] = useState({
    fieldConfig, validationSchema,
  });

  const onClose = () => {
    if (valueIsChange) {
      setModalConfirm(true);
      setPasswordShow(false);
      setConfirmPasswordShow(false);
      setClassNameModalWindow("hide");
    } else {
      setModalAdd(false);
      setModalEdit(false);
      setPasswordShow(false);
      setConfirmPasswordShow(false);
      setModalChangePassword(false);
      dispatch(getSystemUsersAction());
      setCurrentIndex(0);
    }
  };

  const addUser = (values) => {
    if (values.roles === "Provider") {
      history.push(admin_create_providers_url, "users");
      dispatch(setProviderInformation({ ...values, name: values.name.trim() }));
      dispatch(getProviderDictionariesAction());
    } else {
      dispatch(addNewUserAction({ ...values, name: values.name.trim() }, () => {
        setModalAdd(false);
        dispatch(getSystemUsersAction());
      }));
    }
    dispatch(getSystemUsersAction());
    setCurrentIndex(0);
  };

  useEffect(() => {
    dispatch(getSystemRolesAction());
  }, []);

  const getUserState = (state) => {
    switch (state) {
    case "D":
      return "Disabled";
    case "L":
      return "Locked";
    default:
      return "Enabled";
    }
  };

  const setDefault = () => {
    setModalConfirm(false);
    setModalAdd(false);
    setModalEdit(false);
    setPasswordShow(false);
    setConfirmPasswordShow(false);
    setModalChangePassword(false);
    setValueIsChange(false);
    dispatch(getSystemUsersAction());
    setCurrentIndex(0);
    setClassNameModalWindow("show");
  };

  const deleteUser = () => {
    dispatch(deleteUserAction(currentUser));
    setCurrentIndex(0);
    setModalDelete(false);
  };

  const editUser = (values) => {
    const editValue = { ...values };

    if (userToEdit.email === values.email) {
      delete editValue.email;
    }

    if (values.roles === "Provider" && values.roles !== userToEdit.role) {
      history.push(admin_create_providers_url);
      dispatch(setProviderInformation(values));
      dispatch(getProviderDictionariesAction());
    } else {
      dispatch(editUserAction(editValue, () => {
        setModalEdit(false);
        dispatch(getSystemUsersAction());
      }));
    }
    setCurrentIndex(0);
  };

  const paginationClick = (offset) => {
    dispatch(getSystemUsersAction(offset));
    table.current.scrollTop = 0;
    setState(0);
  };

  useEffect(() => {
    dispatch(getSystemUsersAction());
  }, []);

  const changeValidationSchema = (value) => {
    if (value === "Admin" || value === "Superadmin") {
      setConfig({
        fieldConfig: config.fieldConfig.map((el) => (el.name === "phone" ? { ...el, required: true } : el)),
        validationSchema: {
          ...validationSchema,
          phone: Yup.string().matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits").required("Required field"),
        },
      });
    } else {
      setConfig({
        fieldConfig: config.fieldConfig.map((el) => (el.name === "phone" ? { ...el, required: false } : el)),
        validationSchema: {
          ...validationSchema,
          phone: Yup.string().matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits"),
        },
      });
    }
  };

  return (
    <div>
      <HeaderPage title="System users" />

      <div className='student-service__title'>
        <div className='student-service__button'>
          <Button variant='contained' color='primary' onClick={() => {
            setModalAdd(true);
          }}>
            Add new user
          </Button>
        </div>
      </div>

      <div className="student-service__table">
        <Table
          head={Object.values(headerConfig)}
          state={state}
          setState={setState}
          data={tableFormater(transformUsersData(data), headerConfig, "id")}
          onDelete={(id) => { setModalDelete(true); setCurrentUser(id); setState(0); }}
          onEdit={(id) => {
            setModalEdit(true);
            setCurrentUser(id);
            setUserToEdit(data?.find((el) => el.id === id));
            setState(0);
          }}
          onChangePassword={(id) => {
            setModalChangePassword(true);
            setCurrentUser(id);
            setState(0);
          }}
          tableRef={table}
          isContext
          altContextData={[
            {
              title: "Edit user",
              modalType: "edit",
            },
            {
              title: "Change password",
              modalType: "changePassword",
            },
            {
              title: "Delete user",
              modalType: "delete",
            },
          ]}
        />

        <Pagination
          {...pagination}
          onClick={paginationClick}
          currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}
        />

        <div className={classNameModalWindow}>
          <PaperModal
            headerTitle="Add new user"
            open={modalAdd}
            onClose={onClose}>
            <Formik
              initialValues={formikInitialValues}
              validationSchema={Yup.object(config.validationSchema)}
              onSubmit={(values) => addUser(values)}
            >
              {({ dirty }) => (
                <>
                  {config.fieldConfig.map((item) => {
                    switch (item.type) {
                    case "textfield": {
                      return (
                        <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                          <FormikTextField {...item} />
                        </div>
                      );
                    }
                    case "select": {
                      return (
                        <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                          <FormikSelect {...item}
                            onChange={(e) => changeValidationSchema(e.target.value)}
                            data={item.name === "state" ? states.map((el) => ({ value: el, title: getUserState(el) })) : systemRoles.map((el) => ({ value: el.groupName, title: el.groupName }))}
                          />
                        </div>
                      );
                    }
                    default: return "default";
                    }
                  })}
                  <div className="users_button">

                    <FormikButton
                      variant='contained'
                      color='primary'
                      disabled={!dirty}
                    >
                    Create
                    </FormikButton>
                    <Button
                      color='secondary'
                      className="cancel-button"
                      onClick={onClose}
                    >
                    Cancel
                    </Button>
                  </div>
                  {dirty === true ? setValueIsChange(true) : null}
                </>
              )}
            </Formik>
          </PaperModal>
        </div>

        <div className={classNameModalWindow}>
          <PaperModal
            headerTitle={`Edit ${userToEdit.name}`}
            open={modalEdit}
            onClose={onClose}>
            <Formik
              initialValues={{
                userId: currentUser,
                name: userToEdit.name,
                phone: userToEdit.phone,
                email: userToEdit.email,
                roles: userToEdit.role,
                state: userToEdit.state,
                login: userToEdit.login,
              }}
              validationSchema={Yup.object(config.validationSchema)}
              onSubmit={(values) => editUser(values)}
            >
              {({ dirty }) => (
                <>
                  {config.fieldConfig.map((item) => {
                    switch (item.type) {
                    case "textfield": {
                      return (
                        <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                          <FormikTextField {...item}/>
                        </div>
                      );
                    }
                    case "select": {
                      return (
                        <div style={{ marginBottom: "24px", width: "100%" }} key={item.id + item.name}>
                          <FormikSelect
                            {...item}
                            onChange={(e) => changeValidationSchema(e.target.value)}
                            data={
                              item.name === "state"
                                ? states.map((el) => ({ value: el, title: getUserState(el) }))
                                : systemRoles.map((el) => ({ value: el.groupName, title: el.groupName }))
                            }
                          />
                        </div>
                      );
                    }
                    default: return "default";
                    }
                  })}
                  <div className="users_button">

                    <FormikButton
                      variant='contained'
                      color='primary'
                      disabled={!dirty}
                    >
                    Save
                    </FormikButton>
                    <Button
                      color='secondary'
                      className="cancel-button"
                      onClick={onClose}
                    >
                Cancel
                    </Button>

                  </div>
                  {dirty === true ? setValueIsChange(true) : null}
                </>
              )}
            </Formik>
          </PaperModal>
        </div>

        <div className={classNameModalWindow}>
          <PaperModal
            headerTitle="Change password"
            open={modalChangePassword}
            onClose={onClose}>
            <Formik
              initialValues={{ userId: currentUser, password: "", confirmPassword: "" }}
              validationSchema={passwordValidation}
              onSubmit={(values, { resetForm }) => {
                dispatch(editUserAction(values, () => {
                  setModalSuccess(true);
                  setModalChangePassword(false);
                  setPasswordShow(false);
                  setConfirmPasswordShow(false);
                }));
                resetForm({ password: "", confirmPassword: "" });
              }}
            >
              {({ dirty }) => (
                <React.Fragment>
                  <div className="users_password-textfield">
                    <FormikTextField
                      name='password'
                      isPassword={!isPasswordShow}
                      placeholder=''
                      label='New password'
                      required
                    />
                    <div onClick={() => setPasswordShow(!isPasswordShow)} className="users_password-icon">
                      <SvgMaker name="eye" fill={isPasswordShow ? "#192A53" : "#757f98"} />
                    </div>
                  </div>
                  <div className="users_password-textfield" >
                    <FormikTextField
                      name='confirmPassword'
                      isPassword={!isConfirmPasswordShow}
                      placeholder=''
                      label='Confirm new password'
                      required
                    />
                    <div onClick={() => setConfirmPasswordShow(!isConfirmPasswordShow)} className="users_password-icon">
                      <SvgMaker name="eye" fill={isConfirmPasswordShow ? "#192A53" : "#757f98"} />
                    </div>
                  </div>
                  <div className="users_button">
                    <FormikButton disabled={!dirty} variant='contained' color='primary'>
                        Save
                    </FormikButton>
                    {dirty === true ? setValueIsChange(true) : null}
                  </div>
                </React.Fragment>
              )}

            </Formik>
          </PaperModal>
        </div>

        <ConfirmModal headerTitle="Discard unsaved changes?" open={modalConfirm}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={setDefault}
            >
            Discard
            </Button>
            <Button
              variant="default"
              color="secondary"
              onClick={() => { setClassNameModalWindow("show"); setModalConfirm(false); }}
            >
            Cancel
            </Button>
          </div>
        </ConfirmModal>

        <ConfirmModal headerTitle="New password have just successfully changed" open={modalSuccess}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setModalSuccess(false); setValueIsChange(false); }}
            >
              Good
            </Button>
          </div>
        </ConfirmModal>

        <ConfirmModal headerTitle="Are you sure deleting user?" open={modalDelete}>
          <Button variant='contained' color='primary' onClick={deleteUser}>
              Yes, Delete
          </Button>

          <Button variant='dashed' color='primary' onClick={() => setModalDelete(false)}>
              Cancel
          </Button>
        </ConfirmModal>
      </div>
    </div>
  );
}

export default SystemUsers;
