import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ConfirmModal from "../confirmModal/ConfirmModal";
import { Button } from "../Button";

const DiscardWindow = ({
  headerTitle, open, onOk, onCancel,
}) => (
  <ConfirmModal
    headerTitle={headerTitle}
    open={open}
  >
    <Button variant="contained" onClick={onOk}>Discard</Button>
    <Button variant="default" color="grey" onClick={onCancel}>Cancel</Button>
  </ConfirmModal>
);

const RouterPrompt = (props) => {
  const {
    hidePrompt, when, onOK, onCancel, headerTitle, isChange,
  } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (hidePrompt) {
      return;
    }

    if (isChange && when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, when, isChange]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <DiscardWindow
      headerTitle={headerTitle}
      open={showPrompt}
      onOk={handleOK}
      onCancel={handleCancel}
    />
  ) : null;
};

export default RouterPrompt;
