import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import HeaderPage from "../../../components/HeaderPage";
import { DateField } from "../../../components/DateField";
import { SelectFilter } from "../../../components/Select";
import { Button } from "../../../components/Button";
import { sessionsListAction } from "./store";
import { getAllProvidersForSessionAction } from "../store";
import tableFormater from "../../../utils/tableFormater";
import { dateFormat } from "../../../utils/dateFormater";
import Pagination from "../../../components/Pagination";
import Table from "../../../components/Table";
import { getCurrentSessionStatus } from "../../../pages/calendar/getCurrentSessionStatus";

import "./style/index.scss";

import { headerConfig } from "./config";

function AllSessionsContent() {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const table = useRef();

  const { result: data, pagination } = useSelector(({ adminSessionsReducer }) => adminSessionsReducer.sessions);
  const allStudents = useSelector(
    ({ adminReducer }) => adminReducer.allStudents.result,
  );
  const allProviders = useSelector(
    ({ adminReducer }) => adminReducer.allProviders.result,
  );

  const paginationClick = (offset) => {
    dispatch(sessionsListAction({ ...filters, orderField: "actualStartDt", orderDirection: "DESC" }, offset));
  };

  const resetOneFilter = (obj) => {
    const copyFilters = { ...filters };
    if (obj === "date") {
      delete copyFilters.startDate;
      delete copyFilters.endDate;
    }
    delete copyFilters[obj];
    setFilters(copyFilters);
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };
  const resetFilters = () => {
    setFilters({});
    setCurrentIndex(0);
    table.current.scrollTop = 0;
  };

  function replaceAt(str, index, replacement) {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  }

  useEffect(() => {
    dispatch(getAllProvidersForSessionAction());
  }, []);

  useEffect(() => {
    dispatch(sessionsListAction({ ...filters, orderField: "actualStartDt", orderDirection: "DESC" }));
    setCurrentIndex(0);
  }, [filters]);

  return (
    <div className="allSessionAdmin">
      <HeaderPage title="All Sessions" />

      <div className="sessions-list-header">

        <div className="sessions-list-header-filters">
          <div className="sessions-list-filters-input-date">
            <DateField
              name="date"
              showIcon
              placeholder="Dates range"
              isRange
              nativeClick={(event) => {
                if (!event.target.value) return;

                if (moment(event.target.value.split("to")[0]).format() === moment(event.target.value.split("to")[1]).format()) {
                  setFilters({
                    ...filters,
                    startDate: moment(event.target.value.split("to")[0]).format(),
                    endDate: replaceAt(moment(event.target.value.split("to")[1]).format(), 11, "24"),
                  });
                } else {
                  setFilters({
                    ...filters,
                    startDate: moment(event.target.value.split("to")[0]).format(),
                    endDate: moment(event.target.value.split("to")[1]).format(),
                  });
                }
              }}
              value={filters.startDate || filters.actualEndDt
                ? `${moment(filters.startDate).format("MM-DD-YYYY")} to ${moment(filters.endDate).format("MM-DD-YYYY")}`
                : ""}
            />
            {filters.startDate && filters.endDate && (
              <div
                className="select-reset"
                onClick={() => {
                  resetOneFilter("date");
                }}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                    fill="#192A53"
                  />
                </svg>
              </div>
            )}
          </div>

          <div className="sessions-list-filters-input">
            <SelectFilter
              name="providersFilter"
              placeholder="Provider"
              data={allProviders}
              changeFilter={(value) => setFilters({ ...filters, providersFilter: [value] })}
              filters={filters}
              resetFilter={resetOneFilter}
            />
          </div>

          <div className="sessions-list-filters-input">
            <SelectFilter
              name="studentsFilter"
              placeholder="Student"
              data={allStudents}
              changeFilter={(value) => setFilters({ ...filters, studentsFilter: [value] })}
              filters={filters}
              resetFilter={resetOneFilter}
            />
          </div>

          <div className="sessions-list-filters-input">
            <SelectFilter
              name="sessionsState"
              placeholder="Status"
              data={[
                { title: "Not completed", value: "S" },
                { title: "Not signed", value: "C" },
                { title: "Session signed", value: "A" },
                { title: "Session invoiced", value: "I" },
              ]}
              changeFilter={(value) => setFilters({ ...filters, sessionsState: [value] })}
              filters={filters}
              resetFilter={resetOneFilter}
              value={filters.sessionsState}
              noInput={true}
            />
          </div>

          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={resetFilters}
            >
              Refresh filters
            </Button>
          </div>
        </div>
      </div>

      <Table
        head={Object.values(headerConfig)}
        data={tableFormater(data.map((item) => ({
          ...item,
          date: <div>{dateFormat("mm-dd-yyyy", item.eventDt)}</div>,
          timePeriod: <div>{dateFormat("LT", item.actualStartDt) } - {dateFormat("LT", item.actualEndDt) }</div>,
          status: <div style={{ width: 0 }}>{getCurrentSessionStatus(item.status)}</div>,
        })), headerConfig)}
        isContext
        contextData={[]}
        tableRef={table}
      />

      <Pagination
        {...pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
}

function AllSessions() {
  return (
    <React.Fragment>
      <AllSessionsContent/>
    </React.Fragment>
  );
}

export default AllSessions;
