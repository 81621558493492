import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import formatterParams from "../../../../utils/formatterParams";

const getSessionsSlice = createSlice({
  name: "getAllSessions",
  initialState: {
    sessions: {
      result: [],
      pagination: {},
      isFetching: true,
    },
  },
  reducers: {
    getAllSessions(state, { payload }) {
      state.sessions = { ...payload, isFetching: false };
    },
  },
});

export const { getAllSessions } = getSessionsSlice.actions;

export const sessionsListAction = (data, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;

    const params = formatterParams({
      ...data, limit, offset,
    });

    dispatch(getAllSessions({ result: [] }));
    const response = await axios.get(`/session/session-list?${params.replace(/\+/g, "%2B")}`);
    dispatch(getAllSessions(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default getSessionsSlice.reducer;
