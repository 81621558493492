import * as Yup from "yup";

const headerConfig = {
  id: {
    id: 1,
    title: "ID",
    value: "id",
    order: 1,
  },
  type: {
    id: 2,
    title: "User type",
    value: "type",
    order: 2,
  },
  login: {
    id: 3,
    title: "Login",
    value: "login",
    order: 3,
  },
  name: {
    id: 4,
    title: "Name",
    value: "name",
    order: 4,
  },
  email: {
    id: 5,
    title: "Email",
    value: "email",
    order: 5,
  },
  phone: {
    id: 6,
    title: "Phone",
    value: "phone",
    order: 6,
  },
  role: {
    id: 7,
    title: "Roles",
    value: "role",
    order: 7,
  },
  createdDt: {
    id: 8,
    title: "Creation date",
    value: "createdDt",
    order: 8,
  },
  updatedDt: {
    id: 9,
    title: "Last update date",
    value: "updatedDt",
    order: 9,
  },
  state: {
    id: 10,
    title: "Status",
    value: "state",
    order: 10,
  },
};

const fieldConfig = [
  {
    id: 1,
    name: "login",
    label: "Login",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 2,
    name: "name",
    label: "User name",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 3,
    name: "email",
    label: "User email",
    placeholder: "",
    required: true,
    type: "textfield",
  },
  {
    id: 4,
    name: "phone",
    label: "User phone",
    placeholder: "",
    required: false,
    type: "textfield",
  },
  {
    id: 5,
    name: "roles",
    label: "User roles",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: 6,
    name: "state",
    label: "User state",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
];

const formikInitialValues = {
  state: "E",
  name: "",
  roles: "",
  phone: "",
  email: "",
  login: "",
};

const validationSchema = {
  state: Yup.string().required("Required field"),
  name: Yup.string()
    .strict()
    .trim("Name cannot consist of spaces only")
    .min(3, "User name min length should be 3 characters")
    .max(32, "User name max length should be 32 characters")
    .required("Required field"),
  roles: Yup.string().required("Required field"),
  phone: Yup.string().matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits"),
  email: Yup.string().email("The email should be like example@example.com").max(128, "Email max length should be 128 characters").required("Required field"),
  login: Yup.string().strict().trim("Login cannot consist of spaces only").min(3, "Login min length should be 3 characters")
    .max(32, "Login max length should be 32 characters")
    .required("Required field"),
};

const info = {
  isOpen: true,
  timeout: 3000,
  text: "Assigned services cannot be edited",
  type: "error",
};
const passwordValidation = Yup.object({
  password: Yup
    .string()
    .strict()
    .trim("This field cannot consist of spaces only")
    .min(1, "Password length should be from 1 to 32 characters")
    .max(32, "Password length should be from 1 to 32 characters")
    .required("required field"),
  confirmPassword: Yup.string().required("required field")
    .when("password", {
      is: (val) => (!!(val && val.length > 0)),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same",
      ),
    }),
});
export {
  fieldConfig,
  formikInitialValues,
  validationSchema,
  headerConfig,
  info,
  passwordValidation,
};
