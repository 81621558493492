import React from "react";
import { Route, Redirect } from "react-router-dom";
import usePermission from "../../hooks/usePermission";
import Main from "../../core/Main";

function PrivatePage({
  children,
  isAuth,
  roles = [],
  permittedRoles,
  ...route
}) {
  const isPermitted = usePermission(roles, permittedRoles);

  return (
    <Route
      {...route}
      render={({ match, ...rest }) => {
        if (isAuth) {
          if (isPermitted) {
            return <Main roles={roles}>{children}</Main>;
          }
          return <div> You have not permission to this page </div>;
        }
        return <Redirect to="/sign-in" />;
      }}
    />
  );
}

export default PrivatePage;
