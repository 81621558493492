import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getServiceTypesSlice = createSlice({
  name: "serviceTypes",
  initialState: {
    serviceTypes: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    getServiceTypes(state, { payload }) {
      state.serviceTypes.result = payload;
    },
  },
});
const { getServiceTypes } = getServiceTypesSlice.actions;

export const fetchServiceTypes = () => async (dispatch) => {
  try {
    const response = await axios.get("/service/service-types");
    dispatch(getServiceTypes(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default getServiceTypesSlice.reducer;
