import React from "react";
import "../styles/index.scss";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import Typography from "../../../../../../components/Typography";

function PaperModalCallLog({
  headerTitle, open, onClose, children,
}) {
  if (!open) return null;
  return (
    <>
      <div className="grey-screen" onClick={onClose}/>
      <div className="modalCallLog custom-scroll">
        <div className="modalCallLog__closeicon" onClick={onClose}>
          <SvgMaker name="closeIcon" />
        </div>
        <div className="modalGoals__wrapper">
          <div style={{ paddingBottom: 20 }}>
            <Typography variant="h1"><span style={{ color: "#495057" }}>{headerTitle}</span></Typography>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default PaperModalCallLog;
