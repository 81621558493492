const PaginationPageItem = ({
  className, clickOffset, onClick, children, setCurrentIndex, index,
}) => (
  <li
    className={className}
    onClick={() => {
      onClick(clickOffset);
      setCurrentIndex(index);
    }}
  >
    { children }
  </li>
);

export default PaginationPageItem;
