import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "serviceTypeNo",
    label: "Service type",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: 2,
    name: "ratio",
    label: "Student ratio",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
    parent: "serviceTypeNo",
    info: {
      isOpen: true,
      timeout: 2000,
      text: "Enter service type first, please",
      type: "information",
    },
  },
  {
    id: 3,
    name: "compensatory",
    label: "Compensatory",
    type: "checkbox",
  },
  {
    id: 4,
    name: "sessionWeekly",
    label: "Weekly mandate",
    placeholder: "",
    required: true,
    type: "textfield",
    textfieldType: "number",
  },
  {
    id: 5,
    name: "sessionLength",
    label: "Mandated session Length",
    placeholder: "",
    required: true,
    type: "select",
    data: [
      {
        id: 1,
        title: "30",
        value: "30",
      },
      {
        id: 2,
        title: "45",
        value: "45",
      },
      {
        id: 3,
        title: "60",
        value: "60",
      },
    ],
  },
  {
    id: 126,
    name: "timePeriod",
    label: "Authorization period",
    placeholder: "",
    type: "datefield",
    required: true,
    isRange: true,
  },
  {
    id: 7,
    name: "sessionAmount",
    label: "Amount of sessions",
    placeholder: "",
    required: true,
    type: "textfield",
    textfieldType: "number",
  },
  {
    id: 8,
    name: "billingType",
    label: "Billing type",
    placeholder: "",
    type: "select",
    required: true,
    data: [],
    parent: "serviceTypeNo",
    info: {
      isOpen: true,
      timeout: 2000,
      text: "Enter service type first, please",
      type: "information",
    },
  },
  {
    id: 9,
    name: "authorizationNo",
    label: "Authorization number",
    placeholder: "",
    type: "textfield",
    textfieldType: "number",
  },
  {
    id: 10,
    name: "caseNo",
    label: "Hearing /Case number",
    placeholder: "",
    type: "textfield",
    textfieldType: "number",
  },
  {
    id: 11,
    name: "billable",
    label: "Billable",
    type: "checkbox",
  },
  {
    id: 12,
    name: "payable",
    label: "Payable",
    type: "checkbox",
  },
  {
    id: 13,
    name: "billingRate",
    label: "Rate",
    placeholder: "",
    type: "textfield",
  },
  {
    id: 14,
    type: "empty",
  },
  {
    id: 15,
    name: "note",
    label: "Notes",
    placeholder: "",
    type: "textfieldNote",
    textArea: true,
  },
  {
    id: 16,
    type: "empty",
  },
  {
    id: 17,
    type: "empty",
  },
  {
    id: 18,
    type: "empty",
  },
  {
    id: 19,
    type: "empty",
  },
  {
    id: 20,
    type: "empty",
  },
  {
    id: 21,
    type: "empty",
  },
  {
    id: 22,
    type: "empty",
  },
];

const formikInitialValues = {
  authorization: "",
  timePeriod: "",
  serviceTypeNo: "",
  ratio: "",
  compensatory: false,
  sessionWeekly: "",
  sessionLength: "",
  datefields: "",
  sessionAmount: "",
  billingType: "",
  caseNo: "",
  authorizationNo: "",
  billingRate: "",
  note: "",
  billable: true,
  payable: true,
};

const validationSchema = Yup.object({
  sessionAmount: Yup
    .number()
    .typeError("Must be a number")
    .integer("Amount of sessions must be an integer")
    .min(1, "Amount of sessions must be greater than or equal to 1")
    .max(32767, "Amount of sessions must be less than or equal to 32767")
    .required("Required field"),
  timePeriod: Yup.string().required("Required field"),
  authorizationNo: Yup
    .number()
    .typeError("Must be a number")
    .integer("Authorization number must be an integer")
    .min(1, "Authorization number must be greater than 1")
    .max(999999, "Authorization number must be less than or equal to 999999"),
  billable: Yup.boolean(),
  billingType: Yup.string().required("Required field"),
  compensatory: Yup.boolean(),
  caseNo: Yup
    .number()
    .typeError("Must be a number")
    .integer("Hearing /Case number must be an integer")
    .min(1, "Hearing /Case number must be greater than or equal to 1")
    .max(999999, "Hearing /Case number must be less than or equal to 999999"),
  sessionLength: Yup.string().required("Required field"),
  note: Yup
    .string()
    .min(0)
    .max(2000, "Notes must have a maximum of 2000 characters"),
  payable: Yup.boolean(),
  serviceTypeNo: Yup.string().required("Required field"),
  ratio: Yup.string()
    .required("Required field"),
  sessionWeekly: Yup
    .number()
    .typeError("Must be a number")
    .integer("Weekly mandate must be an integer")
    .min(1, "Weekly mandate must be greater than or equal to 1")
    .max(32767, "Weekly mandate must be less than or equal to 32767")
    .required("Required field"),
  billingRate: Yup
    .number()
    .typeError("Must be a number")
    .min(0.01, "Rate must be greater than 0.01")
    .max(999.99, "Rate must be less than or equal to 999.99"),
});

export { fieldConfig, formikInitialValues, validationSchema };
