import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const createStudentSlice = createSlice({
  name: "createStudent",
  initialState: {
    dictionaries: {},
    principalList: [],
    schoolsList: [],
    languageList: [],
    districtsList: [],
    stateList: [],
    citiesList: [],
    alternateAgenciesList: [],
  },
  reducers: {
    setStudentDictionaries(state, { payload }) {
      state.dictionaries = payload;
    },
    setPrincipalList(state, { payload }) {
      state.principalList = payload;
    },
    setSchoolList(state, { payload }) {
      state.schoolsList = payload;
    },
    setLanguageList(state, { payload }) {
      state.languageList = payload;
    },
    setDistrictsList(state, { payload }) {
      state.districtsList = payload;
    },
    setStatesList(state, { payload }) {
      state.stateList = payload;
    },
    getCitiesList(state, { payload }) {
      state.citiesList = payload;
    },
    setAgenciesList(state, { payload }) {
      state.alternateAgenciesList = payload;
    },
  },
});

const {
  setStudentDictionaries,
  setPrincipalList,
  setSchoolList,
  setLanguageList,
  setDistrictsList,
  setStatesList,
  getCitiesList,
  setAgenciesList,
} = createStudentSlice.actions;

export const createStudentAction = (data, cb) => async () => {
  try {
    const response = await axios.post("/student/create-student", {
      ...Object.keys(data).reduce((a, c) => {
        if (data[c]) {
          a[c] = typeof data[c] === "string" ? data[c].trim() : data[c];
        }
        return a;
      }, {}),
    });
    cb(response.data.id);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getStudentDictionariesAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/student/get-dictionaries");
    dispatch(setStudentDictionaries({ ...response.data }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getStudentSelectOptions = () => async (dispatch) => {
  try {
    const {
      data: {
        schools,
        languages,
        districts,
        states,
        alternateAgencies,
      },
    } = await axios.get("/student/get-dictionaries");

    dispatch(setSchoolList(schools));
    dispatch(setLanguageList(languages));
    dispatch(setDistrictsList(districts));
    dispatch(setStatesList(states));
    dispatch(setAgenciesList(alternateAgencies));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getCitiesByStateId = (stateId, mask) => async (dispatch) => {
  try {
    const response = await axios(`/dictionaries/cities-by-state/${stateId}`, {
      params: {
        orderField: "addrCityId",
        mask,
        limit: 2000,
      },
    });
    dispatch(getCitiesList(response.data.result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getCitiesAllByStateId = (stateId) => async (dispatch) => {
  try {
    const response = await axios(`/dictionaries/cities-by-state/${stateId}`, {
      params: {
        orderField: "addrCityId",
        limit: 1900,
      },
    });

    dispatch(getCitiesList(response.data.result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getPrincipalListBySchoolId = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/student/principal-list-by-school/${id}`);
    dispatch(setPrincipalList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default createStudentSlice.reducer;
