import React from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

const WrapperContent = ({
  documentPdf, imgSignature, file,
}) => (
  <div className="signer__page">
    <div className="signer__container">
      <div>
        {file && <Document ref={documentPdf} file={file}>
          <Page pageNumber={1}/>
        </Document>}
      </div>
      <div>
        <img ref={imgSignature} src="" alt="" className="signer__img-preview"/>
      </div>
    </div>
  </div>
);

export default WrapperContent;
