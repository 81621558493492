import React, { useEffect, useState } from "react";

import DrawerHeader from "./DrawerHeader";
import DrawerItem from "./DrawerItem";
import { getEntitie } from "../../utils/getEntitie";

import "./style.scss";

import { config } from "../../configs/drawer";

function Drawer({ roles, providerID }) {
  const [conf, setConf] = useState([]);
  const entitie = getEntitie(roles);

  useEffect(() => {
    setConf(config[entitie].filter((item) => roles.map((role) => true).some((role) => role)));
  }, []);

  return (
    <div className='main__content-drawer'>
      <div className='drawer__container'>
        <DrawerHeader />
        <div className='drawer__container-items'>
          {conf.map((item, key) => <DrawerItem key={key} {...item} providerID={providerID} />)}
        </div>
      </div>
    </div>
  );
}

export default Drawer;
