import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import SvgMaker from "./svgMaker";

import usePrevious from "../utils/usePrevious";
import { getStudentServiceByIdAction } from "../entities/admin/student/pages/editStudentService/store";

function FormikTextField({ name, ...props }) {
  const dispatch = useDispatch();
  const service = useSelector(({ editStudentServiceReducer }) => editStudentServiceReducer.data);
  const {
    touched, errors, values, handleChange, handleKeyPress, setFieldValue,
  } = useFormikContext();
  const prevPartial = usePrevious(values.partial);
  useEffect(() => {
    if (prevPartial) {
      if (prevPartial !== values.partial) {
        if (typeof values.serviceId !== "undefined" && values.serviceId !== "") {
          dispatch(getStudentServiceByIdAction(values.serviceId));
        }
        setFieldValue("sessionAmount", service?.sessionAmount);
      }
    }
  }, [values.partial]);
  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";

  return (
    <TextField
      name={name}
      handleKeyPress={handleKeyPress}
      error={fieldTouched}
      onChange={handleChange(name)}
      value={values[name]}
      state={props.state}
      primaryResponsiblePerson={values.primaryResponsiblePerson}
      {...props}
    />
  );
}

function TextField({
  value = null,
  label = "",
  error = "",
  placeholder = "",
  required,
  isPassword,
  disabled,
  name = "",
  type = "text",
  inputRef = null,
  startIcon = null,
  onChange = () => { },
  onBlur = () => { },
  onFocus = () => { },
  handleKeyPress = () => { },
  onKeyDown = () => { },
  textArea,
  style = {},
  readOnly,
  autoFocus = false,
  edit,
  state,
  primaryResponsiblePerson,
}) {
  return textArea
    ? <div className='textarea'>
      {label && <span className={clsx("textfield__label", { "textfield__label-error": error })}>{required ? "* " : ""}{label}</span>}
      <textarea
        name={name}
        style={style}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={handleKeyPress}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        ref={inputRef}
        readOnly={readOnly}
        className={clsx("textfield", { "textfield__text-indent": startIcon }, { textfield__error: error }, { textfield__disabled: disabled }, "custom-scroll")}
      />
      {error && <span className='textfield__errortext'>{error}</span>}
    </div>

    : (
      <div className='input'>
        {startIcon
          && <span name='input-icon'>
            {typeof startIcon === "string" ? <img src={startIcon} alt='start icon' /> : <SvgMaker width='16px' height='16px' name={startIcon.name} />}
          </span>
        }
        {value !== null ? <>
          {label && <span className={clsx("textfield__label", { "textfield__label-error": error || (edit && !value && state && state === primaryResponsiblePerson) })}>{required ? "* " : ""}{label}</span>}
          <input // controlled
            ref={inputRef}
            className={clsx("textfield", { "textfield__text-indent": startIcon }, { textfield__error: error || (edit && !value && state && state === primaryResponsiblePerson) }, { textfield__disabled: disabled })}
            name={name}
            type={isPassword ? "password" : type}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={handleKeyPress}
            style={style}
            autoFocus={autoFocus}
          />
          {error && <span className='textfield__errortext'>{error}</span>}
        </>
          : <>
            {label && <span className={clsx("textfield__label", { "textfield__label-error": error })}>{required ? "* " : ""}{label}</span>}
            <input // uncontrolled
              ref={inputRef}
              className={clsx("textfield", { "textfield__text-indent": startIcon }, { textfield__error: error }, { textfield__disabled: disabled })}
              name={name}
              disabled={disabled}
              onKeyPress={handleKeyPress}
              type={isPassword ? "password" : type}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              style={style}
              autoFocus={autoFocus}
              value=""
            />
            {error && <span className='textfield__errortext'>{error}</span>}
          </>
        }
      </div>
    );
}

FormikTextField.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  startIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

TextField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  startIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeypress: PropTypes.func,
};

export default FormikTextField;
export { TextField };
