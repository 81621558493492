const dataConfig = {
  studentName: {
    id: 1,
    name: "Student Name",
  },
  osisNo: {
    id: 1,
    name: "Student OSIS",
  },
  birthDt: {
    id: 1,
    name: "Date of birth",
  },
  gender: {
    id: 1,
    name: "Gender",
  },
  address: {
    id: 2,
    name: "Address",
  },
  cityName: {
    id: 2,
    name: "City",
  },
  stateName: {
    id: 2,
    name: "State",
  },
  addrZipcode: {
    id: 2,
    name: "ZIP code",
  },
  motherName: {
    id: 3,
    name: "Mother name",
  },
  motherCellPhoneNo: {
    id: 3,
    name: "Mother cell",
  },
  motherEmail: {
    id: 3,
    name: "Mother email",
  },

  fatherName: {
    id: 4,
    name: "Father name",
  },

  fatherCellPhoneNo: {
    id: 4,
    name: "Father cell",
  },
  fatherEmail: {
    id: 4,
    name: "Father email",
  },
  principalName: {
    id: 5,
    name: "Principal name",
  },
  principalEmail: {
    id: 5,
    name: "Principal email",
  },
  primaryResponsiblePerson: {
    id: 5,
    name: "Responsible person",
  },
  homePhoneNo: {
    id: 6,
    name: "Home phone number",
  },
  emergencyPhoneNo: {
    id: 6,
    name: "Emergency phone number",
  },
  alternateAgency: {
    id: 6,
    name: "Alternate agency",
  },
  language: {
    id: 7,
    name: "Language",
  },
  familyLanguage: {
    id: 7,
    name: "Family Language",
  },
  districtName: {
    id: 7,
    name: "District",
  },
  iepCoordinatorName: {
    id: 7,
    name: "IEP Coordinator",
  },
  schoolName: {
    id: 7,
    name: "School",
  },
  classType: {
    id: 8,
    name: "Type of class",
  },
  dueIepDt: {
    id: 8,
    name: "Anticipated IEP Date",
  },
  dueStartDt: {
    id: 8,
    name: "Anticipated Start Date",
  },
};

export {
  dataConfig,
};
