import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import SvgMaker from "./svgMaker/SvgMaker";

function FormikButton({
  color = "primary",
  variant = "default",
  buttonRef = null,
  disabled,
  startIcon,
  endIcon,
  onClick,
  children,
  history,
  isGoal,
  handleClick = () => { },
}) {
  const {
    handleSubmit,
  } = useFormikContext();

  const nativeClick = () => {
    handleClick();
    handleSubmit();
    !isGoal && history && history.goBack();
  };

  return (
    <div
      ref={buttonRef}
      className={clsx("button", `button__${variant}-${color}`, { button__disabled: disabled })}
      onClick={disabled ? () => { } : !onClick ? handleSubmit : nativeClick}
    >
      <span>
        {startIcon
          && <span name='icon'>
            <img src={startIcon} alt='start icon' />
          </span>
        }
        <span name='text-content'>{children}</span>
        {endIcon
          && <span name='icon'>
            <img src={endIcon} alt='end icon' />
          </span>
        }
      </span>
    </div>
  );
}

function Button({
  color = "primary",
  variant = "default",
  buttonRef = null,
  disabled,
  startIcon,
  endIcon,
  onClick = () => { },
  style,
  children,
  counter,
  className = "",

}) {
  return (
    <div
      style={style}
      ref={buttonRef}
      className={clsx("button", `button__${variant}-${color}`, { button__disabled: disabled }, className)}
      onClick={disabled ? () => { } : onClick}
    >
      <span className="button-content">
        {startIcon
          && <span name='icon'>
            {typeof startIcon === "string" ? <img src={startIcon} alt='start icon' /> : <SvgMaker width='12px' height='12px' name={startIcon.name} />}
          </span>
        }
        <span name='text-content'>{children}</span>
        {counter
          ? <span name='text-counter'>{counter}</span> : null
        }
        {endIcon
          && <span name='icon'>
            <img src={endIcon} alt='end icon' />
          </span>
        }
      </span>
    </div>
  );
}

FormikButton.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "default", "dashed"]),
  buttonRef: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  endIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

Button.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "grey", "success"]),
  variant: PropTypes.oneOf(["contained", "outlined", "default", "dashed"]),
  buttonRef: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  endIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

export default FormikButton;

export { Button };
