import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import moment from "moment";

import CustomRecurrenceDialog from "../customRecurrence";
import { formikFieldFactory } from "../../../../services/formikService";
import HeaderPage from "../../../../components/HeaderPage";
import { Button } from "../../../../components/Button";
import Paper from "../../../../components/Paper";
import {
  getSessionNotesAction, getStudentOptionsAction, updateSessionAction, clearSessionDataAction,
} from "./store";
import { deleteEventByIdAction } from "../sessionNotesForm/store";
import ConfirmModal from "../../../../components/confirmModal";

import "./styles/index.scss";

import {
  validationSchema,
  fieldConfigStudents,
  fieldConfigDate,
} from "./configs";
import { calendar_url, session_notes_url } from "../../../../configs/router_urls";

const repeatOptions = {
  week: "W",
  day: "D",
};

const weekDayValues = {
  Su: 0,
  Mo: 1,
  Tu: 2,
  We: 3,
  Th: 4,
  Fr: 5,
  Sa: 6,
};

function EditSession() {
  const dispatch = useDispatch();
  const history = useHistory();
  const studentOptions = useSelector(({ sessionReducer }) => sessionReducer.studentOptions);
  const sessionData = useSelector(({ sessionReducer }) => sessionReducer.sessionData);

  const {
    location: {
      state,
      state: {
        eventId,
        startDt,
        endDt,
      },
      fromNotes,
    },
  } = history;
  useEffect(() => {
    dispatch(clearSessionDataAction());
    dispatch(getStudentOptionsAction());
  }, []);

  useEffect(() => {
    dispatch(
      getSessionNotesAction(eventId, () => {}),
    );
  }, [eventId]);

  const [config, setConfig] = useState(fieldConfigStudents);
  const [selectedDate, handleDateChange] = useState({
    start_time: new Date().setHours(0, 0, 0, 0),
    end_time: new Date().setHours(0, 0, 0, 0),
  });
  const [repeatOn, updateRepeatOn] = useState([]);
  const [endChecked, updateEndChecked] = useState("after");
  const [endOn, setEndOn] = useState(moment().format("MM-DD-YYYY"));
  const [occurrences, setOccurrences] = useState(1);
  const [repeat, setRepeat] = useState({ title: "day", value: 1 });
  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState([]);
  const [initValue, setInitValue] = useState(null);
  const [diffDate, setDiffDate] = useState({
    current: moment(startDt).format("MM-DD-YYYY"),
    otherDate: moment(endDt).format("MM-DD-YYYY"),
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [hideModal, setHideModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  function filterStudents(studentOptions) {
    if (
      !selected.length
      || !selected
      || selected.includes(undefined)
    ) {
      return studentOptions;
    }

    return studentOptions.filter((el) => {
      if (selected.find((s) => s.id === el.assignmentId)) return true;
      if (selected[0]?.ratio !== "G") {
        return el.assignmentId === selected[0].id;
      }
      return el.ratio === "G"
          && el.typeName === selected[0].typeName
          && !selected.find((s) => s.studentId === el.studentId);
    });
  }

  useEffect(() => {
    setEndOn(diffDate.otherDate);
  }, [diffDate.otherDate]);

  const validation = () => {
    const inputStudents = {};
    const values = Object.values(inputStudents);
    const assignments = [...new Set(values)];
    if (!selected.length) {
      setErrorMsg("required field");
      return false;
    }
    const studentIds = assignments.map(
      (a) => studentOptions.filter((el) => +el.assignmentId === +a)[0].studentId,
    );
    if (studentIds.length !== [...new Set(studentIds)].length) {
      setErrorMsg("you choice any the same student");
      return false;
    }
    setErrorMsg("");
    return true;
  };

  const goToNotes = (v) => {
    setSubmit(true);
    if (!validation(v)) return;

    if (moment(selectedDate.end_time).unix() < moment(selectedDate.start_time).unix()
        || moment(selectedDate.end_time).unix() === moment(selectedDate.start_time).unix()) {
      return;
    }

    const startDt = moment(`${v.date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
    const endDt = moment(`${v.date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();

    dispatch(updateSessionAction({
      startDt,
      endDt,
      assignmentList: selected.map((s) => s.assignmentId),
      periodLength: repeatOptions[repeat.title],
      periodMultiplier: repeat.value,
      weekDays: repeatOn.map((el) => weekDayValues[el]),
      endCondition: endChecked === "on" ? "O" : "A",
      endConditionDt: moment(endOn).format("YYYY-MM-DD"),
      endConditionOccurNumber: Number(occurrences),
      recurrenceSchemaId: sessionData.recurrenceSchema.recurrenceSchemaId,
      isScheduled: true,
      eventId,
    }, ({ firstRecurrenceEventId }) => {
      history.push(`${session_notes_url}/${firstRecurrenceEventId}`, state);
    }));
  };

  const goToCalendar = (v) => {
    setSubmit(true);
    if (!validation(v)) return;

    if (moment(selectedDate.end_time).unix() < moment(selectedDate.start_time).unix()
        || moment(selectedDate.end_time).unix() === moment(selectedDate.start_time).unix()) {
      return;
    }

    const startDt = moment(`${v.date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
    const endDt = moment(`${v.date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();

    dispatch(updateSessionAction({
      startDt,
      endDt,
      assignmentList: selected.map((s) => s.assignmentId),
      periodLength: repeatOptions[repeat.title],
      periodMultiplier: repeat.value,
      weekDays: repeatOn.map((el) => weekDayValues[el]),
      endCondition: endChecked === "on" ? "O" : "A",
      endConditionDt: moment(endOn).format("YYYY-MM-DD"),
      endConditionOccurNumber: Number(occurrences),
      recurrenceSchemaId: sessionData.recurrenceSchema.recurrenceSchemaId,
      isScheduled: false,
      eventId,
    }, () => {
      history.push(calendar_url);
    }));
  };

  function transformData(studentOptions) {
    return studentOptions.map((el) => ({
      ...el,
      title: el.formattedStr,
      value: el.assignmentId,
      id: el.assignmentId,
    }));
  }

  useEffect(async () => {
    await setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
  }, [selected]);

  useEffect(() => {
    dispatch(getStudentOptionsAction());
  }, []);

  useEffect(() => {
    setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
  }, [studentOptions]);

  const deleteEvent = (id) => {
    setConfirmModal(false);
    dispatch(deleteEventByIdAction(id, () => history.push(calendar_url)));
  };
  useEffect(() => {
    if (sessionData) {
      if (studentOptions.length) {
        handleDateChange({
          start_time: moment(startDt ?? state.actualStartDt).format(),
          end_time: moment(endDt ?? state.actualEndDt).format(),
        });
        const weeks = Object.keys(weekDayValues).reduce((a, c) => {
          a[weekDayValues[c]] = c;
          return a;
        }, {});
        setRepeat({
          title: sessionData.recurrenceSchema?.periodLength === "W"
            ? "week"
            : "day",
          value: sessionData.recurrenceSchema?.periodMultiplier,
        });
        // eslint-disable-next-line no-empty
        if (sessionData?.recurrenceSchema?.weekDays) {
          updateRepeatOn(() => sessionData.recurrenceSchema.weekDays.split(",").map((el) => weeks[+el]));
        }
        updateEndChecked(() => {
          if (sessionData?.recurrenceSchema?.endConditionDt && sessionData?.recurrenceSchema?.endConditionDt !== "Invalid date" && sessionData?.recurrenceSchema?.endCondition === "O") {
            setEndOn(moment(sessionData.recurrenceSchema.endConditionDt).format("MM-DD-YYYY"));
            return "on";
          }
          setOccurrences(sessionData?.recurrenceSchema?.endConditionOccurNumber);
          return "after";
        });
      }
    }
  }, [studentOptions]);

  useEffect(() => {
    if (initValue?.date) {
      const startDt = moment(`${initValue.date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
      const endDt = moment(`${initValue.date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();

      if (fromNotes
          && startDt !== endDt
          && selected !== null
          && selected !== []
          && !selected.includes(undefined)
      ) {
        dispatch(updateSessionAction({
          startDt,
          endDt,
          assignmentList: selected.map((s) => s.assignmentId),
          periodLength: repeatOptions[repeat.title],
          periodMultiplier: repeat.value,
          weekDays: repeatOn.map((el) => weekDayValues[el]),
          endCondition: endChecked === "on" ? "O" : "A",
          endConditionDt: moment(endOn).format("YYYY-MM-DD"),
          endConditionOccurNumber: Number(occurrences),
          recurrenceSchemaId: sessionData.recurrenceSchema.recurrenceSchemaId,
          isScheduled: false,
        }, () => {}));
      }
    }
  }, [selected]);

  function updateConfig() {
    if (selected.length) setErrorMsg("");
    setConfig(() => config.map((item) => {
      switch (item.name) {
      case "student":
        return {
          ...item,
          selected,
          setSelected,
          data: students,
          error: errorMsg,
        };
      default:
        return item;
      }
    }));
  }

  useEffect(() => {
    updateConfig();
  }, [students, selected, errorMsg]);

  useEffect(() => {
    if (!sessionData.eventId) {
      return;
    }
    if (sessionData?.recurrenceSchema?.endConditionDt) {
      setEndOn(sessionData?.recurrenceSchema?.endConditionDt);
    }
    if (!selected || !selected.length || selected.includes(undefined)) {
      if (students || sessionData || !initValue) {
        setInitValue(
          {
            students: sessionData.sessions.map((s) => `${s.assignmentId}`)
              .map((it) => students.find((item) => +it === item.assignmentId)),
            date: moment(sessionData.eventDates.actualStartTime).format("MM-DD-YYYY"),
          },
        );
      }
    }
  }, [sessionData, initValue]);

  useEffect(() => {
    if (initValue) {
      setSelected(initValue.students);
    }
  }, [initValue]);

  return (
    <div>
      <HeaderPage
        title="Schedule Sessions"
        rootPathName={calendar_url}
        rootTitleName="Calendar"
        childrenTitleNames={["Edit session"]}
      />
      <Paper>
        { initValue && <Formik
          enableReinitialize={true}
          initialValues={ initValue }
          validationSchema={validationSchema}
          onSubmit={goToCalendar}
        >
          {({ values }) => (
            <Form>
              <div className="create_session__fields">
                {formikFieldFactory(config, "create_session__field", { edit: true }, selected)}

                {formikFieldFactory(fieldConfigDate, "create_session__field", {
                  selectedDate,
                  handleDateChange,
                  setDiffDate,
                  diffDate,
                  eventDt: diffDate.current,
                  edit: true,
                  initValue,
                }, {}, submit, setSubmit)}

                <div className='create_session__field'>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: 130,
                      padding: "10px 12px",
                    }}
                    onClick={() => setHideModal(true)}
                  >
                    <CustomRecurrenceDialog
                      repeatOn={repeatOn}
                      updateRepeatOn={updateRepeatOn}
                      endChecked={endChecked}
                      updateEndChecked={updateEndChecked}
                      repeat={repeat}
                      setRepeat={setRepeat}
                      occurrences={occurrences}
                      setOccurrences={setOccurrences}
                      endOn={endOn}
                      setEndOn={setEndOn}
                      diffDate={diffDate}
                      hideModal={hideModal}
                      setHideModal={setHideModal}
                      date={values.date}
                      edit={true}
                    />
                  </div>
                  <div style={{ height: 131, borderRadius: 4, border: "1px solid #CED4DA" }}>
                    <div style={{
                      paddingTop: 26, paddingLeft: 12, display: "flex", flexDirection: "column",
                    }}>
                      <span
                        style={{
                          color: "#495057",
                          fontSize: 16,
                          lineHeight: "19px",
                          fontWeight: 500,
                          display: "inline-block",
                          paddingBottom: 10,
                        }}>
                              Recurrence
                      </span>

                      <span
                        style={{
                          fontSize: 13,
                          lineHeight: "19px",
                        }}
                      >
                                Ends: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}>{endChecked} {endChecked === "on" ? endOn : `${occurrences} occurrences`}</span>
                      </span>

                      <span style={{ fontSize: 13, lineHeight: "19px" }}>
                              Repeat every: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}> {repeat.value} {repeat.title} </span>
                      </span>
                      {repeatOn.length ? <span style={{
                        fontSize: 13, lineHeight: "19px", display: "inline-block", paddingTop: 10,
                      }}>
                                Repeat on: <span style={{
                          color: "#495057", fontSize: 13, lineHeight: "19px", fontWeight: 700,
                        }}>{repeatOn.map((item) => item).join(", ")}</span>
                      </span> : null}
                    </div>

                  </div>

                </div>
              </div>
              <div className="create_session__buttons">
                <div className="create_session__button-first">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                  >
                      Delete
                  </Button>
                </div>

                <div className="create_session__button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => goToNotes(values)}
                    disabled={moment().utc().valueOf() < moment(`${values.date} ${moment(selectedDate.end_time).format("HH:MM")}`).valueOf()}
                  >
                      Completed
                  </Button>
                </div>

                <div className="create_session__button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => goToCalendar(values)}
                  >
                      Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
      </Paper>

      <ConfirmModal headerTitle="Are you sure deleting event?" open={confirmModal}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteEvent(eventId)}
          >
            Yes, delete
          </Button>
          <Button
            variant="default"
            color="secondary"
            onClick={() => { setConfirmModal(false); }}>
            Cancel
          </Button>
        </div>
      </ConfirmModal>
    </div>
  );
}

export default EditSession;
