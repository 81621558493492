const parse = (str, template) => {
  let newStr = "";
  let arr = str.split(" ");
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].includes(template)) {
      let elem = arr[i];
      let sub = elem.split("");
      let subArr = [];
      let count = 0;
      sub.forEach((el, a) => {
        if (el === "~" && a !== 0 && count === 0) {
          count = 1;
          subArr.push(" ~");
        } else if (el === "~" && a === 0) {
          count = 1;
          subArr.push(el);
        } else if (el === "~" && count === 1) {
          count = 0;
          subArr.push("~ ");
        } else {
          subArr.push(el);
        }
      });
      let subStr = subArr.join("");
      newArr.push(subStr);
    } else {
      newArr.push(arr[i]);
    }
  }
  newStr = newArr.join(" ");
  return newStr;
};

const addSpaces = (str) => {
  let newStr = "";
  let arr = str.split(" ");
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].includes("~X")) {
      let elem = arr[i];
      let sub = elem.split("");
      let subArr = [];
      sub.forEach((el) => (el === "~" ? subArr.push(" ~") : subArr.push(el)));
      let subStr = subArr.join("");
      newArr.push(subStr);
    } else {
      newArr.push(arr[i]);
    }
  }
  newStr = newArr.join(" ");

  const string = parse(newStr, "~String~");
  const measurement = parse(string, "~Measurement~");
  const persent = parse(measurement, "~Percent~");
  const independency = parse(persent, "~Independency~");
  const result = parse(independency, "~Grade~");

  return result;
};

const transform = (el) => {
  const array = el.split(" ");
  return array.map((el) => (
    el.trim().includes("~Name~") || el.trim().includes("~String~")
      || el.trim().includes("~Percent~") || el.trim().includes("~X") || el.trim().includes("x~")
      || el.trim().includes("~Independency~") || el.trim().includes("~Measurement~")
      || el.trim().includes("~Grade~")
      ? <span style={{ fontWeight: "bold" }}>{el} </span> : <span>{el} </span>));
};

export { addSpaces, transform };
