import * as Yup from "yup";

const dateConfig = [
  {
    id: 0,
    name: "student",
    label: "Student",
    placeholder: "",
    required: true,
    type: "myltiSelect",
    title: "student",
    selected: [],
    setSelected: () => {},
    data: [],
    setValueChange: () => {},
  },
  {
    id: 1,
    type: "empty",
  },
  {
    id: 2,
    type: "empty",
  },
  {
    id: "2a",
    type: "empty",
  },
  {
    id: 3,
    name: "date",
    label: "Session date",
    placeholder: "",
    required: true,
    type: "datefield",
    showIcon: true,
  },
  {
    id: 4,
    name: "start_time",
    label: "Session start",
    placeholder: "-- : -- PM",
    required: true,
    step: 15,
    type: "timerange",
  },
  {
    id: 5,
    name: "end_time",
    label: "Session end",
    placeholder: "-- : -- PM",
    required: true,
    step: 15,
    type: "timerange",
  },
  {
    id: 6,
    type: "empty",
  },
  {
    id: 7,
    type: "empty",
  },
];

const formikInitialValues = {
  student: "",
  date: "",
  start_time: "",
  end_time: "",
};

const yupObject = {
  student: Yup.string().required("required field"),
};

const validationSchema = Yup.object(yupObject);

export {
  formikInitialValues,
  validationSchema,
  dateConfig,
};
