import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import FormikButton from "../../../../../../components/Button";
import { CheckBox } from "../../../../../../components/Checkbox";
import FormikDateField from "../../../../../../components/DateField";
import HeaderPage from "../../../../../../components/HeaderPage";
import Paper from "../../../../../../components/Paper";
import FormikTextField from "../../../../../../components/TextField";
import { admin_reports_url } from "../../../../../../configs/router_urls";
import { fieldConfig, formikInitialValues, validationSchema } from "../createGustoCsv/configs";
import { createQucikBookCsvAction } from "./store";

function CreateQuickBookCsv() {
  const dispatch = useDispatch();

  const save = (values) => {
    // eslint-disable-next-line no-console
    dispatch(createQucikBookCsvAction(values, () => console.log("create")));
  };

  return (
    <>
      <HeaderPage
        title="Create Quickbook CSV"
        rootPathName={admin_reports_url}
        rootTitleName='Reports'
        childrenTitleNames={["Create QuickBook CSV"]}
      />
      <div>
        <Paper>
          <Formik initialValues={formikInitialValues} validationSchema={validationSchema} onSubmit={save}>
            <>
              <div className='createstudent__fields'>
                {fieldConfig.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div className='createstudent__field' key={item.id + item.name}>
                        <FormikTextField {...item} />
                      </div>

                    );
                  }
                  case "datefield": {
                    return (
                      <div className='createstudent__field' key={item.id + item.name}>
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  case "checkbox": {
                    return (
                      <div style={{ paddingTop: 40 }} className='provider_profile__field' key={item.id}>
                        <CheckBox name={item.name} {...item} />
                      </div>
                    );
                  }
                  default: return "default";
                  }
                })}

              </div>
              <div className='createstudent__buttons'>
                <div className='createstudent__field'/>
                <div className='createstudent__field'/>
                <div className='createstudent__field'/>
                <div className='createstudent__field'/>
                <div className='createstudent__field'>
                  <FormikButton variant='contained' color='primary'>
                    Save
                  </FormikButton>
                </div>
              </div>
            </>
          </Formik>
        </Paper>
      </div>
    </>
  );
}

export default CreateQuickBookCsv;
