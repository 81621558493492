import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ProviderHomePage from "../../entities/provider/pages/home";
import Students from "../../entities/admin/student/pages/students";

import { admmin_students_url } from "../../configs/router_urls";

function Home() {
  const history = useHistory();

  const user = useSelector(({ authorizationReducer }) => authorizationReducer.user);

  return (
    <React.Fragment>
      {(() => {
        switch (user.role[0]) {
        case 1: {
          history.push(admmin_students_url);
          return <Students />;
        }
        case 2: {
          history.push(admmin_students_url);
          return <Students />;
        }
        case 4: {
          return <ProviderHomePage />;
        }
        default: return <div>{user.name}</div>;
        }
      })()}
    </React.Fragment>

  );
}

export default Home;
