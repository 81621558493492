import * as Yup from "yup";
import { dateFormat } from "../../../../utils/dateFormater";

export const headerConfig = {
  studentName: {
    id: 1,
    title: "Student",
    value: "studentName",
    order: 1,
  },
  callDt: {
    id: 2,
    title: "Call date",
    value: "callDt",
    order: 2,
  },
  note: {
    id: 3,
    title: "Notes",
    value: "note",
    order: 3,
  },
};

export const formikInitialValues = {
  assignmentId: "",
  callDt: dateFormat("mm-dd-yyyy", new Date()),
  note: "",
};

export const validationSchema = Yup.object({
  assignmentId: Yup.string().required("Required field"),
  callDt: Yup.string().required("Required field"),
  note: Yup.string().matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces").min(3, "Note field min length should be 3 characters").max(2000, "Note field max length should be 2000 characters")
    .required("Required field"),
});
