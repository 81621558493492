import React from "react";
import PropTypes from "prop-types";
import "./styles/index.scss";
import Typography from "../../../components/Typography";

const WrapperHeader = ({ headerTitle, headerText }) => (
  <div className="login__header">
    <Typography style={{ color: "#1F1F1F", fontSize: 16 }} variant='h3'>{headerTitle}</Typography>
    <Typography className="login__text" variant='subtitle2'>{headerText}</Typography>
  </div>
);

WrapperHeader.propTypes = {
  headerTitle: PropTypes.string,
  headerText: PropTypes.string,
};

export default WrapperHeader;
