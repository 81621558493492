import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import FormikTextField from "../../../components/TextField";
import FormikCheckBox from "../../../components/Checkbox";
import FormikButton from "../../../components/Button";
import { signInAction } from "../store";

const WrapperContent = ({
  formikInitialValues,
  validationSchema,
  fieldConfig,
  btnText,
  handleKeyPress,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="login__container">
      <Formik initialValues={formikInitialValues} validationSchema={validationSchema} onSubmit={(values) => {
        dispatch(signInAction(values));
      }}>
        <React.Fragment>
          <div>
            {fieldConfig.map((item) => {
              switch (item.type) {
              case "textfield": {
                return (
                  <div className='login__text-field' key={item.id}>
                    <FormikTextField handleKeyPress={handleKeyPress} {...item} />
                  </div>
                );
              }
              case "checkbox": {
                return (
                  <div className='login__checkbox-field' key={item.id}>
                    <FormikCheckBox {...item}/>
                  </div>
                );
              }
              default: return "default";
              }
            })}
            <div className='login__btn' >
              <FormikButton
                variant='contained'
                color='primary'
              >
                {btnText}
              </FormikButton>
            </div>
            {/* <div className='login__forgot' >
              <Button
                startIcon={forgotBtnImage}
                variant='default'
                color={forgotBtnColor}
                onClick={forgotBtnOnClick}
              >
                {forgotBtnText}
              </Button>
            </div> */}
          </div>
        </React.Fragment>
      </Formik>
    </div>
  );
};

WrapperContent.propTypes = {
  formikInitialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  fieldConfig: PropTypes.array,
  btnText: PropTypes.string,
  forgotBtnText: PropTypes.string,
  forgotBtnOnClick: PropTypes.func,
  handleKeyPress: PropTypes.func,
  forgotBtnColor: PropTypes.string,
  forgotBtnImage: PropTypes.string,
};

export default WrapperContent;
