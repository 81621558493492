import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import formatterParams from "../../../../../../utils/formatterParams";

const allCallsLogSlice = createSlice({
  name: "allCallsLog",
  initialState: {
    data: {
      result: [],
      pagination: {},
    },
  },
  reducers: {
    setCallsLog(state, { payload }) {
      state.data = payload;
    },
  },
});

export default allCallsLogSlice.reducer;

const { setCallsLog } = allCallsLogSlice.actions;

export const getAllCallsLog = (offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      offset, limit,
    });
    const response = await axios.get(`/provider/all-providers-calls?${params}`);
    dispatch(setCallsLog(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
