import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const progressReportListSlice = createSlice({
  name: "progressReportList",
  initialState: {
    reports: {
      result: [],
      pagination: {},
    },
    student: {},
  },
  reducers: {
    setProgressReportList(state, { payload }) {
      state.reports = { ...payload };
    },
    setStudent(state, { payload }) {
      state.student = { ...payload };
    },
  },
});
export const { setProgressReportList, setStudent } = progressReportListSlice.actions;
export default progressReportListSlice.reducer;

export const getProgressReportList = (id, offset = null, limit = 10) => async (dispatch) => {
  try {
    dispatch(setStudent({ studentName: "" }));
    if (offset === 0) offset = null;
    const response = await axios(`/reports/provider-student-reports-list/${id}`, {
      params: {
        offset,
        limit,
      },
    });
    dispatch(setProgressReportList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
export const getStudentById = (id) => async (dispatch) => {
  try {
    dispatch(setStudent({ studentName: "" }));
    const response = await axios.get(`/student/student-by-id/${id}`);
    dispatch(setStudent(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
