/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    MuiOutLinedInput: {
      root: {
        "& Mui-focused": {
          backgroundColor: "red",
        },
      },
    },
  },
  checked: {
    color: "#FF9500 !important",
  },
}));

const promptLevels = [
  {
    title: "None",
    value: "N",
  },
  {
    title: "Significant",
    value: "S",
  },
  {
    title: "Moderate",
    value: "O",
  },
  {
    title: "Mild",
    value: "I",
  },
  {
    title: "Maximum",
    value: "A",
  },
];

export default function MyltiSelect({
  title = "prompts", setSelected, selected, data, setValueChange = () => { },
}) {
  const classes = useStyles();

  const [level, setLevel] = useState({});

  const onChangeHandler = (event, value) => {
    setValueChange(true);
    setSelected(
      value.map((el) => {
        if (!el.lvl) el.lvl = promptLevels[0].value;
        if (!el.id) el.id = el.value;
        if (!el.viewTitle) el.viewTitle = `${el.title};${el.lvl}`;
        return el;
      }),
    );
  };

  const updatePrompt = (id, lvlValue) => {
    setSelected((oldPrompts) => oldPrompts.map((el) => {
      if (el.id === id) {
        el.lvl = lvlValue;
        el.viewTitle = `${el.title};${el.lvl}`;
      }
      return el;
    }));
  };

  useEffect(() => {
    const l = {};
    selected.forEach((el) => {
      l[el.id] = {
        value: el.viewTitle.split(";")[1],
        title: promptLevels.find((item) => item.value === el.viewTitle.split(";")[1]).title,
        viewTitle: el.viewTitle,
      };
    });
    setLevel(l);
  }, []);

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={data}
        // value={selected}
        defaultValue={selected}
        disableCloseOnSelect
        getOptionLabel={(option) => `Prompt: ${option.title}; Level:${level[option.value]?.value || "N"}`}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={onChangeHandler}
        renderOption={(option, state) => (
          <div style={{
            display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center",
          }}>
            <div>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                classes={{
                  checked: classes.checked,
                }}
                style={{ marginRight: 8 }}
                checked={state.selected}
              />
              {option.title}
            </div>
            <div style={{ position: "relative" }} onClick={(event) => {
              event.stopPropagation();
              setLevel((oldState) => {
                const keys = Object.keys(oldState);
                keys.forEach((key) => oldState[key].open = false);
                return { ...oldState, [option.value]: { ...oldState[option.value], open: true } };
              });
            }}>
              <div style={{
                backgroundColor: "#ffffff", width: 100, position: "absolute", left: -100, zIndex: 100000, display: level[option.value]?.open ? "flex" : "none", flexDirection: "column", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
              }}>
                {promptLevels.map((el) => (
                  <span key={el.id} style={{ display: "inline-block", padding: 10 }} onClick={(event) => {
                    updatePrompt(option.value, el.value);
                    event.stopPropagation();
                    setLevel({ ...level, [option.value]: { open: false, value: el.value, title: el.title } });
                  }}>
                    {el.title}
                  </span>
                ))}
              </div>
              <span style={{ display: "inline-block", padding: "7px 20px", backgroundColor: "#E5E5E5" }}>
                {level[option.value]?.title
                 || "None"}
              </span>
            </div>
          </div>
        )}
        renderInput={(params) => (
          <TextField variant='outlined' {...params} label={title}/>
        )}
      />
    </div>
  );
}
