import React, { useState } from "react";
import moment from "moment";

import { Button } from "../../../../../../components/Button";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import { formikFieldFactory } from "../../../../../../services/formikService";
import { PaperModal } from "../../../../../../components/modal/Modal";

import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";

import "./style.scss";

import { fieldConfig } from "../../configs";

function ActualTimeDialogContent({
  selectedDate,
  handleDate,
  setValueChange = () => { },
  checkOpen = () => { },
}) {
  const [date, setDate] = useState({ ...selectedDate });

  const handleDateChange = ({ ...props }) => {
    setValueChange(true);
    setDate({ ...date, ...props });
  };
  const onSave = (e) => {
    setValueChange(false);
    handleDate({
      actualDt: moment(date.actualDt).format("MM-DD-YYYY"),
      actualStartTime: moment(`${date.actualDt} ${moment(date.actualStartTime).format("HH:mm")}`).format(),
      actualEndTime: moment(`${date.actualDt} ${moment(date.actualEndTime).format("HH:mm")}`).format(),
    });
    checkOpen(e);
  };

  return (
    <div className="actual_time_dialog-content">
      {formikFieldFactory(fieldConfig, "actual_time_dialog-content_field_repeat-every", { selectedDate: date, handleDateChange })}
      <div className="custom_reccurence_dialog-content_button">
        <Button variant="contained" color="primary" onClick={onSave} disabled={false} className="save">
          Save
        </Button>
      </div>
    </div>
  );
}

function ActualTimeDialog(props) {
  const {
    open, checkOpen, setOpen, hideEdit,
  } = props;

  const [confirmModal, setConfirmModal] = useState(false);
  const [isValueChange, setValueChange] = useState(false);

  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };

  return (
    <>
      <div
        className={hideEdit && "hide"}
        style={hideEdit ? null : {
          display: "flex", justifyContent: "flex-end", marginRight: "15px",
        }}
        onClick={checkOpen}>
        <SvgMaker name="editIcon" /></div>
      {!confirmModal && !hideEdit && <PaperModal open={open} onClose={() => checkClosure()} headerTitle="Actual Time">
        <ActualTimeDialogContent {...props} setValueChange={setValueChange} checkOpen={checkOpen} />
      </PaperModal>}
      <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
        <div>
          <Button variant="contained" color="primary" onClick={() => { setConfirmModal(false); setValueChange(false); setOpen(false); }}>
          Discard
          </Button>
          <Button
            variant="default"
            color="secondary"
            onClick={() => { setConfirmModal(false); setValueChange(true); }}
          >
          Cancel
          </Button>
        </div>
      </ConfirmModal>
    </>

  );
}

export default ActualTimeDialog;
