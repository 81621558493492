import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeaderPage from "../../../../../components/HeaderPage";
import Table from "../../../../../components/Table/Table";
import { Button } from "../../../../../components/Button";
import { Select } from "../../../../../components/Select";
import { dateFormat } from "../../../../../utils/dateFormater";
import tableFormater from "../../../../../utils/tableFormater";
import { dowloadNewFile } from "../../../../../utils/dowloadNewFile";
import {
  fetchDocumentsList, deleteDocument, updateAccessField, uploadNewFile,
} from "./store";
import { getStudentByIdAction } from "../studentProfile/store";
import SvgMaker from "../../../../../components/svgMaker";

import "./styles/index.scss";

import { headerConfig, iconDoc } from "./config";
import { admmin_students_url, admmin_students_profile_url } from "../../../../../configs/router_urls";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";

function StudentDocuments() {
  const ref = useRef();
  const refJsx = useRef(false);
  const params = useParams();
  const dispatch = useDispatch();
  const dataResult = useSelector(({ documentsReducer }) => documentsReducer.documents);
  const student = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);

  useEffect(() => {
    dispatch(fetchDocumentsList(params.id));
    dispatch(getStudentByIdAction(params.id));
  }, []);

  const onFileChange = (e) => {
    const fileSize = e.target.files[0]?.size / 1024 / 1024;
    if (fileSize > 10) {
      dispatch(snackbarOpen({
        isOpen: true, timeout: 3000, text: "The file cannot be larger than 10MB", type: "error",
      }));
    } else if (fileSize <= 10) {
      const formData = new FormData();
      formData.append("doc", e.target.files[0], e.target.files[0].name);
      dispatch(uploadNewFile(params.id, formData));
    }
    ref.current.value = "";
  };

  const onDelete = (docId, studentId) => {
    dispatch(deleteDocument(docId, studentId));
    ref.current.value = "";
  };

  return (
    <>
      {refJsx.current
        ? <>
          <HeaderPage
            title={`${student?.studentName} Documents`}
            rootTitleName='All Students'
            rootPathName={admmin_students_url}
            childrenTitleNames={["Student Profile", "Student Documents"]}
            childrenPathNames={[`${admmin_students_profile_url}/${params.id}`]}
          />
          <div className="btn">
            <Button variant='contained' color='primary'
              onClick={() => { ref.current.click(); }}
            >
              Upload new file
            </Button>
          </div>
          <label htmlFor="files" />
          <input
            ref={ref}
            id="files"
            className="uploadFile"
            type="file"
            onChange={onFileChange}
          />
          <div className="studentDocuments">
            <Table
              className="without-btns"
              head={Object.values(headerConfig)}
              data={tableFormater(dataResult.map((el) => ({
                ...el,
                uploadDt: <div className="studentDocuments__date">{dateFormat("mm-dd-yyyy", el.uploadDt)}</div>,
                docName: <div className='studentDocuments__name'>
                  {iconDoc[el.docType]}
                  <span>{el.originalName}</span>
                </div>,
                access: <div className="studentDocuments__select"><Select
                  placeholder=""
                  value={el.access}
                  onNativeChange={(e) => { dispatch(updateAccessField(el.docId, e.target.value, params.id)); } }
                  data={[{ id: 1, value: "A", title: "Admin" }, { id: 2, value: "P", title: "Provider" }]} /></div>,
                actions: <div className="actions">
                  <div className="actions__dowload"
                    onClick={() => {
                      dowloadNewFile(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}/${el.filePath}/${el.docName}`, el.originalName);
                    } }>
                    <SvgMaker name="dowloadIconDoc" />
                  </div>
                  <div className="actions__delete"
                    onClick={() => onDelete(el.docId, params.id)}>
                    <SvgMaker name="deleteIconDoc" />
                  </div>
                </div>,
              })), headerConfig)}
              withoutOption />
          </div></>
        : null}
      {refJsx.current = true}
    </>
  );
}

export default StudentDocuments;
