import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/Button";
import HeaderPage from "../../../../components/HeaderPage";
import Table from "../../../../components/Table/Table";
import { headerConfig } from "./config";
import tableFormater from "../../../../utils/tableFormater";
import {
  provider_create_progress_report_url, provider_students_assigment_url, provider_progress_report_view_url, provider_update_progress_report_url,
} from "../../../../configs/router_urls";
import { getProgressReportList, getStudentById } from "./store";
import { dateFormat } from "../../../../utils/dateFormater";
import Pagination from "../../../../components/Pagination";
import { getProviderStudentsListAction } from "../providerStudentsList/store";
import { getAssignmentByIdAction } from "../studentInvoicesValidations/store";

function ProgressReportList() {
  const studentName = "";
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { providerId } = useSelector(({ authorizationReducer }) => authorizationReducer.user);
  const assignment = useSelector(({ studentInvoicesValidationReducer }) => studentInvoicesValidationReducer.assignment);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [state, setState] = useState(0);
  const student = useSelector(({ progressReportListReducer }) => progressReportListReducer.student);
  const students = useSelector(({ providerStudentsListReducer }) => providerStudentsListReducer.students.result);
  const studentData = students.find((el) => el.assignmentId === +params.id);
  const goToCreateReportPage = () => history.push(`${provider_create_progress_report_url.split("/:")[0]}/${params.id}`, students.find((el) => el.studentId === student.studentId));
  const data = useSelector(
    ({ progressReportListReducer }) => progressReportListReducer.reports,
  );

  useEffect(() => {
    dispatch(getProgressReportList(params.id));
    dispatch(getAssignmentByIdAction(params.id));
  }, []);
  useEffect(() => {
    dispatch(
      getProviderStudentsListAction({ ...providerId, status: ["F", "S", "C", "R", "A"] }, () => {
        dispatch(getStudentById(studentData.studentId));
      }),
    );
  }, []);
  const paginationClick = (offset) => {
    dispatch(getProgressReportList(params.id, offset));
  };
  return (
    <div>
      <HeaderPage
        title={`${student?.studentName || assignment?.studentName || studentName} Progress reports`}
        rootPathName={provider_students_assigment_url}
        rootTitleName='Students'
        childrenTitleNames={["List of reports"]}
      />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center",
        paddingRight: "30px",
      }}
      >
        <div style={{ marginRight: "auto" }}>
          <Button
            variant='contained'
            onClick={goToCreateReportPage}
          >
            Create new report
          </Button>
        </div>
      </div>
      <Table
        isContrast
        head={Object.values(headerConfig)}
        state={state}
        setState={setState}
        data={tableFormater(data.result.map((item) => ({ ...item, reportDt: dateFormat("mm-dd-yyyy", item) })), headerConfig)}
        isButtonShow
        showData={(id) => {
          history.push(`${provider_progress_report_view_url.split("/:")[0]}/${params.id}`, { ...students.find((el) => el.studentId === student.studentId), reportId: id });
        }}
        onEdit={(id) => {
          history.push(`${provider_update_progress_report_url.split("/:")[0]}/${params.id}`, id);
        }}
      />
      <Pagination
        {...data.pagination}
        onClick={paginationClick}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
}

export default ProgressReportList;
