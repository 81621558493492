export const headerConfig = {
  date: {
    title: "Date",
    value: "date",
    order: 1,
  },
  timePeriod: {
    title: "Time period",
    value: "timePeriod",
    order: 2,
  },
  serviceType: {
    title: "Service type",
    value: "serviceType",
    order: 3,
  },
  lessonLength: {
    title: "Length of session",
    value: "lessonLength",
    order: 4,
  },
  payroll: {
    title: "Payroll",
    value: "payroll",
    order: 5,
  },
  location: {
    title: "Location",
    value: "location",
    order: 6,
  },
  providerName: {
    title: "Provider",
    value: "providerName",
    order: 7,
  },
  studentName: {
    title: "Students",
    value: "studentName",
    order: 8,
  },
  status: {
    title: "Status",
    value: "status",
    order: 9,
  },
};
