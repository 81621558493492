import axios from "axios";
import createDownloadUrl from "../../../../../../../utils/createDownloadUrl";

export const createQucikBookCsvAction = (values) => async () => {
  try {
    const response = await axios.post("/reports/generate-quickbook-report", values);
    const url = createDownloadUrl(response.data, "text/csv", true);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "quickbook.csv",
    );

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
