import React from "react";
import clsx from "clsx";
import {
  getDate,
  getYearMatrix,
  isDateBefore,
  getCurrentDate,
} from "../../../functions/calendar_func";
import "./style.scss";

const YearTable = (props) => {
  const {
    setCurrentDate, yearRangeStart, tooggleMode, isBirthDay, isCalendar, startDate,
  } = props;

  function dateIsDisabled(date) {
    if (!isCalendar) {
      return isDateBefore(getDate(date, "YYYY"), getCurrentDate("YYYY"));
    }
    return null;
  }

  function getActiveClass(date, isDisabled) {
    if (isDisabled && !isBirthDay) {
      return "disabled";
    }
    if (getDate(date, "YYYY") === getDate(startDate, "YYYY")) {
      return "active";
    }
    return "default";
  }

  function onSelectHandler(isDisabled, column) {
    if (isDisabled && !isBirthDay) {
      return null;
    }
    setCurrentDate(column);
    tooggleMode("month");
  }

  const grid = getYearMatrix(yearRangeStart);
  return (
    <table className="date_picker-calendar-year_table">
      <tbody>
        {grid.map((row, index) => (
          <tr key={index}>
            {row.map((column, index) => {
              const isDisabled = dateIsDisabled(column.date);

              return (
                <td key={index}>
                  <div
                    className={clsx("year", getActiveClass(column.date, isDisabled))}
                    onClick={() => onSelectHandler(isDisabled, column.date)}
                  >
                    {getDate(column.date, "YYYY")}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default YearTable;
