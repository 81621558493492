import React from "react";
import SvgMaker from "../../../components/svgMaker";

import "./style.scss";

function DrawerHeader() {
  return (
    <div className='drawer__container-header'>
      <span className='drawer__container-header-title'>Navigation</span>
      <div className='drawer__container-header-arrow'>
        <SvgMaker name = "drawerArrow" />
      </div>
    </div>
  );
}

export default DrawerHeader;
