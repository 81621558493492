import React from "react";
import PropTypes from "prop-types";

import TableRow from "./TableRow";

const TableBody = ({
  data, onDelete,
  onEdit, isContext,
  contextData, setModal,
  hasAcceptButton, onAccept,
  onReject, withoutOption,
  isButtonShow, showData,
  customData, onClick,
  state, setState,
  isTableForClick, hasCheckboxes,
  isAllChecked, checkCheckboxes,
  checkboxes, showPreview, isService, modalHeight,
}) => (
  data.map((item, index) => (
    <TableRow
      hasAcceptButton={hasAcceptButton}
      key={index}
      onClick={onClick}
      index={index}
      {...item}
      onAccept={onAccept}
      onReject={onReject}
      onDelete={onDelete}
      onEdit={onEdit}
      state={state}
      setState={setState}
      isContext={isContext}
      contextData={contextData}
      setModal={setModal}
      withoutOption={withoutOption}
      isButtonShow={isButtonShow}
      showData={showData}
      customData={customData}
      isTableForClick={isTableForClick}
      hasCheckboxes={hasCheckboxes}
      isAllChecked={isAllChecked}
      checkCheckboxes={checkCheckboxes}
      checkboxes={checkboxes}
      showPreview={showPreview}
      isService={isService}
      modalHeight={modalHeight}
    />
  ))
);

TableBody.propTypes = {
  data: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  isContext: PropTypes.bool,
  contextData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(undefined),
  ]),
  setModal: PropTypes.func,
  hasAcceptButton: PropTypes.bool,
  isButtonShow: PropTypes.bool,
  showData: PropTypes.func,
  onClick: PropTypes.func,
  hasCheckboxes: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  checkCheckboxes: PropTypes.func,
  showPreview: PropTypes.bool,
  modalHeight: PropTypes.number,
};

export default TableBody;
