import React from "react";
import { useHistory } from "react-router-dom";

export const ReportsButton = ({ link, children }) => {
  const history = useHistory();
  return (
    <button
      className="reports-item"
      onClick={() => history.push(link)}
    >
      <span className="reports-text">{children}</span>
    </button>
  );
};
