const headerConfig = [
  {
    id: 0,
    key: "caseNo",
    title: "Case number",
    value: "",
  },
  {
    id: 1,
    key: "typeName",
    title: "Service type",
    value: "",
  },
  {
    id: 2,
    key: "invoicePeriod",
    title: "Month",
    value: "",
  },
  {
    id: 3,
    key: "hoursAmount",
    title: "Hours amount",
    value: "",
  },
  {
    id: 4,
    key: "payrollRate",
    title: "Provider rate",
    value: "",
  },
  {
    id: 5,
    key: "assignmentHoursTotal",
    title: "Assignment",
    title2: "hours total",
    value: "",
  },
  {
    id: 6,
    key: "hoursInvoicedBefore",
    title: "Hours Invoiced before",
    value: "",
  },
];

export {
  headerConfig,
};
