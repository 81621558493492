import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import { Button } from "../../../../../components/Button";
import Typography from "../../../../../components/Typography";
import { getStudentByIdAction, deleteStudentById, setStudentProfile } from "./store";
import { ButtonPaper } from "../../../../../components/Paper/Paper";
import { getStudentServicesAction } from "../studentServices/store";
import { fetchDocumentsList } from "../documents/store";
import { fetchStudentsGoalList } from "../studentGoal/store";
import {
  formaterClassType,
  formaterGender,
  formaterPrimaryResponsiblePerson,
} from "../../../../../utils/studentProfileFormater";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import Loader from "../../../../../components/LoadingIndicator";

import "./styles/index.scss";

import {
  admmin_students_edit_profile_url, admmin_students_goals_url, admmin_students_services_url, admmin_students_url, admin_students_documents_url,
} from "../../../../../configs/router_urls";
import { dataConfig } from "./config";
import { getStudentSelectOptions } from "../createStudent/store";

function StudentProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
  const { pagination } = useSelector(({ studentServiceReducer }) => studentServiceReducer.services);
  const numberDocuments = useSelector(({ documentsReducer }) => documentsReducer.documents);
  const numberGoals = useSelector(({ studentsGoalReducer }) => studentsGoalReducer.studentsGoal.result);
  const [confirmModal, setConfirmModal] = useState(false);
  const { alternateAgenciesList } = useSelector(({ createStudentReducer }) => createStudentReducer);

  const limit = 10;

  useEffect(() => {
    if (!alternateAgenciesList.length) {
      dispatch(getStudentSelectOptions());
    }
  }, []);
  const changeData = () => {
    const mutableData = {};
    Object.keys(data).forEach((el) => {
      if (mutableData[dataConfig[el]?.id]) return;
      mutableData[dataConfig[el]?.id] = {
        group: Object.keys(dataConfig)
          .filter((item) => dataConfig[item].id === dataConfig[el]?.id)
          .map((item, index) => {
            if (item === "gender") return { id: index, value: formaterGender(data[item]), label: dataConfig[item].name };
            if (item === "primaryResponsiblePerson") return { id: index, value: formaterPrimaryResponsiblePerson(data[item]), label: dataConfig[item].name };
            if (item === "classType") return { id: index, value: formaterClassType(data[item]), label: dataConfig[item].name };
            if (item === "alternateAgency") return { id: index, value: alternateAgenciesList.find((item) => data.alternateAgencyId === item.alternateAgencyId)?.agencyName, label: dataConfig[item].name };
            return { id: index, value: data[item], label: dataConfig[item].name };
          }),
      };
    });
    return Object.values(mutableData);
  };

  const changeMode = () => history.push(`${admmin_students_edit_profile_url}/${params.id}`);

  useEffect(() => {
    if (!data || +data?.studentId !== +params.id || history.location.state?.update) {
      dispatch(setStudentProfile([]));
      dispatch(getStudentByIdAction(params.id));
    }
    dispatch(getStudentServicesAction(params.id, limit));
    dispatch(fetchDocumentsList(params.id));
    dispatch(fetchStudentsGoalList(params.id));
  }, []);

  const goToGoals = () => history.push(`${admmin_students_goals_url}/${params.id}`);
  const goToDocuments = () => history.push(`${admin_students_documents_url}/${params.id}`);
  const goToServices = () => history.push(`${admmin_students_services_url}/${params.id}`);

  const deleteStudent = (id) => {
    setConfirmModal(false);
    dispatch(deleteStudentById(id, () => { history.push(admmin_students_url); }));
  };

  return (
    <div>
      <HeaderPage
        title="Student Profile"
        rootPathName={admmin_students_url}
        rootTitleName='All Students'
        childrenTitleNames={["Student Profile"]}
      />
      {data && <div className='student_profile'>
        <div className='student_profile__container'>
          <Paper papertitle='Personal information' iconName='editIcon' isIconDelete changeMode={changeMode} deleteAction={() => setConfirmModal(true)}>
            {data?.addrCityId || data?.addrStateId ? (
              <div className='info'>
                {changeData().map(({ group }) => (
                  <div className='info__container'>
                    {group.map((item) => (
                      <div className='info__container-data' style={{ marginRight: "50px" }}>
                        <Typography variant='subtitle2'>
                          {item.label}:
                        </Typography>
                        <Typography variant='subtitle2-bold'>
                          {item.value || "N/A"}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <Loader/>
            )}
          </Paper>
        </div>
        <ButtonPaper>
          <div className='buttons__wrapper-field'>
            <Button variant='contained' color='primary' onClick={goToDocuments} counter={numberDocuments.length}>
              {numberDocuments.length > 0
                ? <></> : null
              }
              Documents
            </Button>
          </div>
          <div className='buttons__wrapper-field'>
            <Button variant='contained' color='primary' onClick={goToGoals} counter={numberGoals.length}>
              {numberGoals.length > 0
                ? <></> : null
              }
              Goals
            </Button>
          </div>
          <div className='buttons__wrapper-field'>
            <Button variant='contained' color='primary' onClick={goToServices} counter={pagination?.count}>
              Services
              {pagination?.count > 0
                ? <></> : null
              }
            </Button>
          </div>
        </ButtonPaper>
      </div>
      }
      <ConfirmModal headerTitle="Are you sure deleting student?" open={confirmModal}>
        <div>
          <Button variant="contained" color="primary" onClick={() => deleteStudent(params.id)}>
            Yes, delete
          </Button>
          <Button variant="default"
            color="secondary"
            onClick={() => { setConfirmModal(false); }}>
            Cancel
          </Button>
        </div>
      </ConfirmModal>
    </div>
  );
}

export default StudentProfile;
