import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";
import HeaderPage from "../../../../components/HeaderPage";
import {
  provider_enter_session_url,
  provider_sessions_url,
  session_notes_url,
} from "../../../../configs/router_urls";
import Paper from "../../../../components/Paper";
import {
  formikInitialValues,
  validationSchema,
  dateConfig,
} from "./configs";
import {
  createSessionAction,
  createSessionNote,
  getSessionDictAction,
  getSessionNotesAction,
  getStudentOptionsAction,
} from "../sessionForm/store";
import { formikFieldFactory } from "../../../../services/formikService";
import "./styles/index.scss";
import SessionTitle from "../sessionNotesForm/sections/titleSection";
import SessionGoals from "./sections/goalsSection";
import Section from "./sections/section";
import SessionPrompts from "../sessionNotesForm/sections/promptsSection";
import SessionInfo from "../sessionNotesForm/sections/infoSection";
import SessionComments from "../sessionNotesForm/sections/commentSection";
import { Button } from "../../../../components/Button";
import Loader from "../../../../components/LoadingIndicator";

function EnterSession() {
  const dispatch = useDispatch();
  const history = useHistory();

  const studentOptions = useSelector(({ sessionReducer }) => sessionReducer.studentOptions);
  const sessionDicts = useSelector(({ sessionReducer }) => sessionReducer.sessionDicts);

  const [students, setStudents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [config, setConfig] = useState(dateConfig);
  const [showOptions, setShowOptions] = useState(new Set());
  const [options, setOptions] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [selectedDate, handleDateChange] = useState({
    date: { startDate: moment().format("MM-DD-YYYY") },
    start_time: moment(`${moment(new Date()).format("YYYY-MM-DD")} 00:00`).format(),
    end_time: moment(`${moment(new Date()).format("YYYY-MM-DD")} 00:00`).format(),
  });
  const [loading, setLoading] = useState(false);

  const assignmentId = history.location.pathname.replace(provider_enter_session_url, "").replace("/", "");
  const setAssignment = useRef(false);

  function filterStudents(studentOptions) {
    if (!selected.length) return studentOptions;

    return studentOptions.filter((el) => {
      if (selected.find((s) => s.id === el.assignmentId)) return true;
      if (selected[0]?.ratio !== "G") {
        return el.assignmentId === selected[0].id;
      }
      return el.ratio === "G"
          && el.typeName === selected[0].typeName
          && !selected.find((s) => s.studentId === el.studentId);
    });
  }

  function transformData(studentOptions) {
    return studentOptions.map((el) => ({
      ...el,
      title: el.formattedStr,
      value: el.assignmentId,
      id: el.assignmentId,
    }));
  }

  function updateConfig() {
    setConfig(() => config.map((item) => {
      switch (item.name) {
      case "student":
        return {
          ...item,
          selected,
          setSelected,
          data: students,
        };
      case "date":
        return {
          ...item,
          date: moment().format("MM-DD-YYYY"),
        };
      case "start_time":
      case "end_time":
      default:
        return item;
      }
    }));
  }

  function toggleForm(id) {
    if (showOptions.has(id)) {
      setShowOptions(() => {
        const newOptions = new Set(showOptions);
        newOptions.delete(id);
        return newOptions;
      });
    } else {
      setShowOptions(() => {
        const newOptions = new Set(showOptions);
        newOptions.add(id);
        return newOptions;
      });
    }
  }

  function transformDataSessionTitle(assignment) {
    const {
      studentName,
      typeName,
      assignmentId,
    } = assignment;
    return {
      student: { studentName },
      service: {
        ...assignment,
        serviceTypeName: typeName,
      },
      assignmentId,
    };
  }

  function updateSelected(item, index, fieldName) {
    setOptions((prevState) => {
      const state = [...prevState];
      state[index][fieldName] = item;
      return state;
    });
  }

  function onSubmit() {
    setSubmit(true);
    setLoading(true);
    const date = moment(selectedDate.date.startDate).format("YYYY-MM-DD");
    const startDt = moment(`${date} ${moment(selectedDate.start_time).format("HH:mm")}`).format();
    const endDt = moment(`${date} ${moment(selectedDate.end_time).format("HH:mm")}`).format();
    let date1 = moment(startDt);
    let date2 = moment(endDt);
    let diff = date1.isBefore(date2, "minutes");
    if (!diff) {
      setLoading(false);
      return;
    }

    dispatch(
      createSessionAction({
        startDt,
        endDt,
        assignmentList: options.map(({ service }) => service.assignmentId),
        periodMultiplier: 1,
        periodLength: "D",
        weekDays: [],
        endCondition: "A",
        endConditionDt: date,
        endConditionOccurNumber: 1,
        isScheduled: true,
      }, (eventId) => {
        if (!eventId) return setLoading(false);
        dispatch(getSessionNotesAction(eventId, (sessionNotes) => {
          dispatch(
            createSessionNote(
              eventId,
              options.map((item) => {
                const {
                  location, materialsTransferId, virtualMedium, virtualMediumAddr,
                } = item;
                const sessionNote = sessionNotes.sessions.find((s) => (
                  s.assignmentId === item.assignmentId
                ));
                item.sessionId = sessionNote.sessionId;
                item.options = {
                  location,
                  materialsTransferId,
                  virtualMedium,
                  virtualMediumAddr,
                };
                return item;
              }),
              {
                actualDt: moment(selectedDate.date.startDate).format("MM-DD-YYYY"),
                actualStartTime: startDt,
                actualEndTime: endDt,
              }, () => {
                setTimeout(() => {
                  setLoading(false);
                  history.push(`${session_notes_url}/${eventId}`);
                }, 1000);
              },
            ),
          );
        }));
      }),
    );
  }

  function setDateValue(date) {
    handleDateChange({
      ...selectedDate,
      date,
    });
  }

  useEffect(() => {
    if (!sessionDicts.skills.length) {
      dispatch(getSessionDictAction());
    }
  }, []);

  useEffect(async () => {
    if (assignmentId && !setAssignment.current && students.length) {
      setAssignment.current = true;
      await setSelected(((prevState) => {
        const newSelected = [...prevState];
        const student = students.find((s) => s.assignmentId === +assignmentId);
        if (student) newSelected.push(student);
        return newSelected;
      }));
    }
    updateConfig();
  }, [students]);
  useEffect(() => {
    dispatch(getStudentOptionsAction());
  }, []);
  useEffect(() => {
    setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
  }, [studentOptions]);

  useEffect(async () => {
    await setStudents(() => transformData(
      filterStudents(studentOptions),
    ));
    setOptions(() => selected.map((s) => ({
      ...transformDataSessionTitle(s),
      sessionGoals: [],
      studentGoals: [],
      skills: [],
      activities: [],
      materials: [],
      prompts: [],
      location: null,
      materialsTransferId: null,
      virtualMedium: null,
      virtualMediumAddr: null,
      note: null,
      studentId: s.studentId,
    })));
  }, [selected]);

  return (
    <div>
      <HeaderPage
        title="Enter Session & Notes"
        rootPathName={provider_sessions_url}
        rootTitleName="My Sessions"
        childrenTitleNames={["Enter Session & Notes"]}
      />
      <Paper>
        <Formik
          initialValues={ { ...formikInitialValues, date: selectedDate.date.startDate } }
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          <div>
            <div className="enter_session__fields">
              {formikFieldFactory(config, "enter_session__field", {
                selectedDate,
                handleDateChange,
                setDateValue,
              }, {}, submit, setSubmit)}
            </div>
            {options.map((s, index) => <div key={`enterSession${index}`}>
              <SessionTitle
                selected={{ ...s, show: showOptions.has(s.assignmentId) }}
                toggle={() => toggleForm(s.assignmentId)}
                hidePDFBtn={true}
              />
              { showOptions.has(s.assignmentId)
              && <>
                <SessionGoals
                  key={`goals${index}`}
                  selected={s}
                  setSelected={setOptions}
                />

                <Section
                  title={"Skills"}
                  selected={s.skills}
                  dict={sessionDicts.skills}
                  setSelected={({ item }) => updateSelected(item, index, "skills")}
                />

                <Section
                  title={"Activities"}
                  selected={s.activities}
                  dict={sessionDicts.activities}
                  setSelected={({ item }) => updateSelected(item, index, "activities")}
                />

                <Section
                  title={"Materials"}
                  selected={s.materials}
                  dict={sessionDicts.materials}
                  setSelected={({ item }) => updateSelected(item, index, "materials")}
                />

                <SessionPrompts
                  promptsDict={sessionDicts.prompts.map((el) => ({
                    title: el.attrValue,
                    value: el.attributeId,
                  }))}
                  promptLevelDict={sessionDicts.promptLevel.map((el) => ({
                    title: el,
                    value: el,
                  }))}
                  setSelected={({ prompts }) => updateSelected(prompts, index, "prompts")}
                  selected={s.prompts}
                />

                <SessionInfo
                  selected={s}
                  setSelected={(item) => setOptions((prevState) => {
                    const state = [...prevState];
                    state[index] = item;
                    return state;
                  })}
                  infoDicts={{
                    locations: sessionDicts.location,
                    materialsTransfer: sessionDicts.materialsTransfer,
                    virtualMedium: sessionDicts.virtualMedium,
                  }}
                />

                <SessionComments
                  selected={s.note}
                  setSelected={({ note }) => updateSelected(note, index, "note")}
                />
              </>
              }
            </div>)}
            <div className="enter_session__fields">
              <div className="enter_session__field"/>
              <div className="enter_session__field"/>
              <div className="enter_session__field"/>
              <div className='enter_session__field'>
                { !loading
                  ? <Button
                    disabled={!options.length}
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                  >
                        Save
                  </Button>
                  : <Loader/>
                }
              </div>
            </div>
          </div>
        </Formik>
      </Paper>
    </div>
  );
}

export default EnterSession;
