/* eslint-disable max-len */
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const TableConfigBtns = ({
  id, onDelete, onEdit, state, setState, isButtonShow, showData, showPreview,
}) => {
  const tableModalRef = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (state > 0 && !event.path.find((el) => el.className === "table__sticky")) {
        setState(0);
      }
    };
    document.addEventListener("mousedown", handler);
  }, [state]);
  return (
    <>
      {showPreview ? <button onClick={() => showData(id)} className="table__btn showData__btn">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9857 4.125C6.40234 4.125 2.48818 6.97583 0.902344 11C2.48818 15.0242 6.40234 17.875 10.9857 17.875C15.569 17.875 19.4832 15.0242 21.069 11C19.4832 6.97583 15.569 4.125 10.9857 4.125ZM10.9857 15.5833C8.45568 15.5833 6.40234 13.53 6.40234 11C6.40234 8.47 8.45568 6.41667 10.9857 6.41667C13.5157 6.41667 15.569 8.47 15.569 11C15.569 13.53 13.5157 15.5833 10.9857 15.5833ZM10.9857 8.25C9.46401 8.25 8.23568 9.47833 8.23568 11C8.23568 12.5217 9.46401 13.75 10.9857 13.75C12.5073 13.75 13.7357 12.5217 13.7357 11C13.7357 9.47833 12.5073 8.25 10.9857 8.25Z" />
        </svg>
      </button> : <div className={clsx("table__btns", { open: state === id })}>
        {isButtonShow ? <button onClick={() => showData(id)} className="table__btn table__btn--hide">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99967 4.99992C13.158 4.99992 15.9747 6.77492 17.3497 9.58325C15.9747 12.3916 13.158 14.1666 9.99967 14.1666C6.84134 14.1666 4.02467 12.3916 2.64967 9.58325C4.02467 6.77492 6.84134 4.99992 9.99967 4.99992ZM9.99967 3.33325C5.83301 3.33325 2.27467 5.92492 0.833008 9.58325C2.27467 13.2416 5.83301 15.8333 9.99967 15.8333C14.1663 15.8333 17.7247 13.2416 19.1663 9.58325C17.7247 5.92492 14.1663 3.33325 9.99967 3.33325ZM9.99967 7.49992C11.1497 7.49992 12.083 8.43325 12.083 9.58325C12.083 10.7333 11.1497 11.6666 9.99967 11.6666C8.84967 11.6666 7.91634 10.7333 7.91634 9.58325C7.91634 8.43325 8.84967 7.49992 9.99967 7.49992ZM9.99967 5.83325C7.93301 5.83325 6.24967 7.51659 6.24967 9.58325C6.24967 11.6499 7.93301 13.3333 9.99967 13.3333C12.0663 13.3333 13.7497 11.6499 13.7497 9.58325C13.7497 7.51659 12.0663 5.83325 9.99967 5.83325Z" />
          </svg>
        </button> : null}
        <button onClick={() => onEdit(id)} className="table__btn table__btn--hide">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 14.375V17.5H5.625L14.8417 8.28334L11.7167 5.15834L2.5 14.375ZM17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C14.9833 2.41667 14.4583 2.41667 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667Z" />
          </svg>
        </button>
        {!isButtonShow ? <button onClick={() => onDelete(id)} className="table__btn table__btn--hide">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.00033 15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333H5.00033V15.8333ZM6.66699 7.5H13.3337V15.8333H6.66699V7.5ZM12.917 3.33333L12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333H12.917Z"

            />
          </svg>
        </button> : null}
        <button ref={tableModalRef} onClick={() => setState((stateId) => (stateId === id ? 0 : id))} className="table__btn table__btn-main">
          <svg className="table__btn-dots" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.0007 6.66671C10.9173 6.66671 11.6673 5.91671 11.6673 5.00004C11.6673 4.08337 10.9173 3.33337 10.0007 3.33337C9.08398 3.33337 8.33398 4.08337 8.33398 5.00004C8.33398 5.91671 9.08398 6.66671 10.0007 6.66671ZM10.0007 8.33337C9.08398 8.33337 8.33398 9.08337 8.33398 10C8.33398 10.9167 9.08398 11.6667 10.0007 11.6667C10.9173 11.6667 11.6673 10.9167 11.6673 10C11.6673 9.08337 10.9173 8.33337 10.0007 8.33337ZM10.0007 13.3334C9.08398 13.3334 8.33398 14.0834 8.33398 15C8.33398 15.9167 9.08398 16.6667 10.0007 16.6667C10.9173 16.6667 11.6673 15.9167 11.6673 15C11.6673 14.0834 10.9173 13.3334 10.0007 13.3334Z"
            />
          </svg>
        </button>
      </div>}
    </>
  );
};

TableConfigBtns.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  state: PropTypes.number,
  setState: PropTypes.func,
  isButtonShow: PropTypes.bool,
  showData: PropTypes.func,
  showPreview: PropTypes.bool,
};

export default TableConfigBtns;
