import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getProvidersSlice = createSlice({
  name: "getProviders",
  initialState: {
    providers: {
      result: [],
      pagination: {},
      isFetching: true,
    },
  },
  reducers: {
    getProvidersList(state, { payload }) {
      state.providers = { ...payload, isFetching: false };
    },
  },
});

export const { getProvidersList } = getProvidersSlice.actions;

export const providersListAction = (data, searchByName, offset = null, limit = 10) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      ...data, searchByName, limit, offset,
    });
    dispatch(getProvidersList({ result: [] }));
    const response = await axios.get(`/provider/all-providers?${params}`);
    dispatch(getProvidersList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}=${data[b]}&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null && data[b] !== "") return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}

export default getProvidersSlice.reducer;
