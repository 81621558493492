import * as Yup from "yup";

const configHeadTable = {
  schoolId: {
    id: 1,
    title: "ID",
    value: "schoolId",
    order: 1,
  },
  schoolName: {
    id: 2,
    title: "Name",
    value: "schoolName",
    order: 2,
  },
};

const formikInitialValues = {
  schoolName: "",
};

const validationSchema = Yup.object({
  schoolName: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only").max(128, "Max length should be 128 characters").required("Required field"),
});

const validationSchemaForEdit = Yup.object({
  schoolNameForEdit: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only").max(128, "Max length should be 128 characters").required("Required field"),
});

export {
  configHeadTable, formikInitialValues, validationSchema, validationSchemaForEdit,
};
