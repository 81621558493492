/** returns the opposite value gender
 * @name getGenderTitle
 * @param {"M"|"F"|"Male"|"Female"} value - value
 * @return ("M"|"F"|"Male"|"Female")
 * @throws Error
 * */
export function formaterGender(value) {
  switch (value) {
  case "M":
    return "Male";
  case "F":
    return "Female";
  case "Male":
    return "M";
  case "Female":
    return "F";
  default:
    error("formaterGender", value, "\"M\"|\"F\"|\"Male\"|\"Female\"");
  }
}

/** returns the opposite value primary responsible person
 * @name formaterPrimaryResponsiblePerson
 * @param {"M"|"F"|"P"|"Mother"|"Father"|"Principal"} value - value
 * @return ("M"|"F"|"P"|"Mother"|"Father"|"Principal")
 * @throws Error
 * */
export function formaterPrimaryResponsiblePerson(value) {
  switch (value) {
  case "M":
    return "Mother";
  case "F":
    return "Father";
  case "P":
    return "Principal";
  case "Mother":
    return "M";
  case "Father":
    return "F";
  case "Principal":
    return "P";
  default:
    error("formaterPrimaryResponsiblePerson", value, "\"M\"|\"F\"|\"P\"|\"Mother\"|\"Father\"|\"Principal\"");
  }
}

/** returns the opposite value type of class
 * @name formaterClassType
 * @param {"S"|"P"|"C"|"School"|"Private student"|"Center-Based Class"} value - value
 * @return ("S"|"P"|"C"|"School"|"Private student"|"Center-Based Class")
 * @throws Error
 * */
export function formaterClassType(value) {
  switch (value) {
  case "S":
    return "School";
  case "P":
    return "Private student";
  case "C":
    return "Center-Based Class";
  case "School":
    return "S";
  case "Private student":
    return "P";
  case "Center-Based Class":
    return "C";
  default:
    error("formaterClassType", value, "\"S\"|\"P\"|\"C\"|\"School\"|\"Private student\"|\"Center-Based Class\"");
  }
}

function error(functionName, value, example) {
  throw new Error(` ${functionName} something wrong.
    The value must be, a string and one of the following values: ${(example) && example}. 
    Your value: ${value}`);
}
