import React from "react";
import Wrapper from "./components/Wrapper";
import { fieldConfig, formikInitialValues, validationSchema } from "./configForgot";

function ForgotPage() {
  return (
    <React.Fragment>
      <div className="login__page">
        <Wrapper
          headerTitle="Forgot password"
          headerText="Sign in to continue"
          fieldConfig={fieldConfig}
          formikInitialValues={formikInitialValues}
          validationSchema={validationSchema}
          btnText="Send"
          forgotBtnText="Back to login"
          forgotBtnColor="primary"
          forgotBtnOnClick={() => {}}
        />
      </div>
    </React.Fragment>
  );
}

export default ForgotPage;
