function deleteEmptyFields(values) {
  return Object.keys(values).reduce((a, c) => {
    if (values[c] !== "") {
      a[c] = values[c];
    }
    return a;
  }, {});
}

export default deleteEmptyFields;
