import axios from "axios";
import deleteEmptyFields from "../../../../../../utils/deleteEmptyFields";

export const createProviderAction = (userData, data, cb) => async () => {
  try {
    const user = await axios.post("/admin-service/create-user", deleteEmptyFields(userData));
    const response = await axios.post("/provider/create-provider", {
      ...deleteEmptyFields(data),
      userId: user.data.userId,
    });
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
