import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "skill",
    label: "Skill",
    placeholder: "input value",
    type: "selectField",
    data: [{ id: 1, value: 1, title: "SETSS" }, { id: 2, value: 2, title: "SEIT" }],
  },
  {
    id: 2,
    name: "listGoal",
    label: "Choose goal from list",
    placeholder: "input valu",
    type: "selectField",
    data: [{ id: 1, value: 1, title: "SETSS" }, { id: 2, value: 2, title: "SEIT" }],
  },
  {
    id: 3,
    type: "empty",
  },
  {
    id: 4,
    type: "empty",
  },
  {
    id: 5,
    type: "empty",
  },
  {
    id: 6,
    // name: "goal",
    name: "title",
    label: "Goal",
    placeholder: "input value",
    required: true,
    textArea: true,
    type: "textAreaField",
  },
  {
    id: 7,
    name: "completionCriteria",
    label: "Completion criteria",
    placeholder: "input value",
    required: true,
    textArea: true,
    type: "textAreaFieldRight",
  },
  {
    id: 8,
    type: "empty",
  },
  {
    id: 9,
    name: "schedule",
    label: "Schedule",
    placeholder: "",
    required: true,
    type: "dateField",
  },
  {
    id: 10,
    name: "status",
    label: "Status",
    placeholder: "",
    required: true,
    type: "selectField",
    data: [{ id: 1, value: "A", title: "SETSS" }, { id: 2, value: "C", title: "SEIT" }],
  },
  {
    id: 11,
    type: "empty",
  },
  {
    id: 12,
    type: "empty",
  },
  {
    id: 13,
    type: "empty",
  },
  {
    id: 14,
    type: "empty",
  },
  {
    id: 15,
    type: "empty",
  },
  {
    id: 16,
    type: "empty",
  },
  {
    id: 17,
    type: "empty",
  },
];
const initialValuesGoals = {
  skill: "",
  listGoal: "",
  title: "",
  completionCriteria: "",
  schedule: "",
  status: "",
};

const formSchema = Yup.object().shape({
  skill: Yup.string(),
  listGoal: Yup.string(),
  title: Yup.string()
    .required("Required"),
  completionCriteria: Yup.string().required("Required"),
  schedule: Yup.date().required("Required"),
  status: Yup.string().required("Required"),
});

export { fieldConfig, initialValuesGoals, formSchema };
