import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "adminProviderReducer",
  initialState: {
    providerDict: {},
    allProviders: {
      result: [],
      pagination: {},
    },
    allProvidersForSessions: {
      result: [],
      pagination: {},
    },
    allStudents: {
      result: [],
      pagination: {},
    },
    allDict: {
      result: null,
    },
  },
  reducers: {
    setProviderDictionaries(state, { payload }) {
      state.providerDict = payload;
    },
    setAllProviders(state, { payload }) {
      state.allProviders = payload;
    },
    setAllProvidersForSession(state, { payload }) {
      state.allProvidersForSessions = payload;
    },
    setAllStudents(state, { payload }) {
      state.allStudents = payload;
    },
    setAllDictionaries(state, { payload }) {
      state.allDict = payload;
    },
  },
});

const {
  setProviderDictionaries,
  setAllProviders,
  setAllStudents,
  setAllDictionaries,
  setAllProvidersForSession,
} = adminSlice.actions;

export default adminSlice.reducer;

export const getProviderDictionariesAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/provider/get-dictionaries");
    const statesList = [];
    response.data.states.forEach((item) => {
      statesList.push({
        title: item.stateName,
        value: item.addrStateId,
      });
    });

    dispatch(setProviderDictionaries({ ...response.data, statesList }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getAllProvidersAction = (status, searchByName) => async (dispatch) => {
  try {
    const response = await axios("/provider/all-providers", {
      params: {
        status,
        searchByName,
        limit: 1000,
      },
    });
    dispatch(setAllProviders({
      ...response.data,
      result: response.data.result.map((el) => ({
        id: el.userId,
        value: el.providerId,
        title: `${el.firstName} ${el.lastName}`,
        state: el.state,
        services: el.prProviderServices,
      })),
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getAllProvidersForSessionAction = () => async (dispatch) => {
  try {
    const response = await axios("/provider/all-providers", {
      params: {
        status: "A",
        limit: 1000,
      },
    });
    dispatch(setAllProvidersForSession({
      ...response.data,
      result: response.data.result.map((el) => ({
        id: el.userId, value: el.providerId, title: `${el.firstName} ${el.lastName}`, state: el.state,
      })),
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getAllStudentsAction = (nameMask) => async (dispatch) => {
  try {
    const response = await axios("/student/student-list", { params: { limit: 1000, nameMask } });
    dispatch(setAllStudents({ ...response.data, result: response.data.result.map((el) => ({ id: el.studentId, value: el.studentId, title: el.studentName })) }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getAllDictionariesAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/student/get-dictionaries");
    dispatch(setAllDictionaries({ ...response.data, result: response.data.schools.map((el) => ({ id: el.id, value: el.value, title: el.title })) }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
