import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const providerStudentsListSlice = createSlice({
  name: "providerStudentsList",
  initialState: {
    students: {
      result: [],
      pagination: {},
    },
    student: {},
  },
  reducers: {
    setProviderStudentsList(state, { payload }) {
      state.students = { ...payload };
    },
    setStudent(state, { payload }) {
      state.student = payload;
    },
  },
});

export const { setProviderStudentsList, setStudent } = providerStudentsListSlice.actions;

export const getProviderStudentsListAction = ({
  providerId, status, searchValue, offset = null, limit = 1000,
}, cb = () => {}) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      providerId, status, searchValue, limit, offset,
    });
    const response = await axios(`/assignment/assignment-list?${params}`);
    dispatch(setProviderStudentsList(response.data));
    cb(response.data.result);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}${b}[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null) return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}

export const getStudentByIdAction = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/student/student-by-id/${id}`);
    dispatch(setStudent(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createProgressReportAction = (data, cb) => async () => {
  try {
    await axios.post("/reports/create-progress-report", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateProgressReportAction = (data, cb) => async () => {
  try {
    await axios.patch("/reports/update-progress-report", data);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const changeAssignmentStatusAction = ({ id, status }, cb) => async () => {
  try {
    await axios.patch(`/assignment/change-status/${id}?status=${status}`);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default providerStudentsListSlice.reducer;
