import clsx from "clsx";

import PaginationPageItem from "./PaginationPageItem";

import "./style/style.scss";

const Pagination = ({
  limit, count, onClick, currentIndex, setCurrentIndex,
}) => {
  const lastPageExistence = count % limit !== 0;
  const pageAmount = lastPageExistence
    ? Math.floor(count / limit) + 1
    : Math.floor(count / limit);
  const array = Array.from({ length: pageAmount }, (v, k) => k + 1);
  const paginationVisibility = count && count > limit;

  return (
    <>
      {paginationVisibility ? <ul className='pagination'>
        {(pageAmount > 12) && <PaginationPageItem
          index={0}
          className='pagination-start pagination-btn'
          onClick={onClick}
          setCurrentIndex={setCurrentIndex}
          clickOffset={0}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.306019 8.08633C-0.102007 7.67568 -0.102007 7.00987 0.306019 6.59922L6.21639 0.650765C6.62441 0.24011 7.28595 0.24011 7.69398 0.650765C8.10201 1.06142 8.10201 1.72722 7.69398 2.13788L2.52241 7.34277L7.69398 12.5477C8.10201 12.9583 8.10201 13.6241 7.69398 14.0348C7.28595 14.4454 6.62441 14.4454 6.21639 14.0348L0.306019 8.08633Z" fill="#192A53"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.30602 8.08633C6.89799 7.67568 6.89799 7.00987 7.30602 6.59922L13.2164 0.650765C13.6244 0.24011 14.286 0.24011 14.694 0.650765C15.102 1.06142 15.102 1.72722 14.694 2.13788L9.52241 7.34277L14.694 12.5477C15.102 12.9583 15.102 13.6241 14.694 14.0348C14.286 14.4454 13.6244 14.4454 13.2164 14.0348L7.30602 8.08633Z" fill="#192A53"/>
          </svg>
        </PaginationPageItem>}

        <PaginationPageItem
          className="pagination-prev pagination-btn"
          onClick={onClick}
          setCurrentIndex={setCurrentIndex}
          index={currentIndex !== 0 ? currentIndex - 1 : 0}
          clickOffset={currentIndex !== 0 ? (currentIndex - 1) * limit : 0}
        >
          <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.306019 8.08633C-0.102007 7.67568 -0.102007 7.00987 0.306019 6.59922L6.21639 0.650765C6.62441 0.24011 7.28595 0.24011 7.69398 0.650765C8.10201 1.06142 8.10201 1.72722 7.69398 2.13788L2.52241 7.34277L7.69398 12.5477C8.10201 12.9583 8.10201 13.6241 7.69398 14.0348C7.28595 14.4454 6.62441 14.4454 6.21639 14.0348L0.306019 8.08633Z" fill="#192A53"/>
          </svg>
        </PaginationPageItem>

        {array.map((item, index) => {
          if (pageAmount > 12) {
            const condition = currentIndex < index + 3 && currentIndex > index - 3 && currentIndex >= 0;

            if (index === 0) {
              return (
                <PaginationPageItem
                  index={index}
                  className={clsx("pagination-item", { active: currentIndex === index })}
                  onClick={onClick}
                  key={`p-dot-${index}`}
                  setCurrentIndex={setCurrentIndex}
                  clickOffset={index * limit}
                >
                  {item}
                </PaginationPageItem>
              );
            }
            if (index === 1) {
              return (
                <>
                  <PaginationPageItem
                    index={index}
                    className={clsx("pagination-item", { active: currentIndex === index })}
                    onClick={onClick}
                    key={`p-dot-${index}`}
                    setCurrentIndex={setCurrentIndex}
                    clickOffset={index * limit}
                  >
                    {item}
                  </PaginationPageItem>
                  {currentIndex > 4 && <PaginationPageItem
                    index={currentIndex - 4}
                    className={clsx("pagination-item")}
                    onClick={onClick}
                    key={`dots-${index}`}
                    setCurrentIndex={setCurrentIndex}
                    clickOffset={(currentIndex - 4) * limit}
                  >
                  ...
                  </PaginationPageItem>}
                </>
              );
            }
            if (index === pageAmount - 1) {
              return (
                <PaginationPageItem
                  index={index}
                  className={clsx("pagination-item", { active: currentIndex === index })}
                  onClick={onClick}
                  key={`p-dot-${index}`}
                  setCurrentIndex={setCurrentIndex}
                  clickOffset={index * limit}
                >
                  {item}
                </PaginationPageItem>
              );
            }
            if (index === pageAmount - 2) {
              return (
                <>
                  {currentIndex < pageAmount - 5 && <PaginationPageItem
                    index={currentIndex + 4}
                    className={clsx("pagination-item")}
                    onClick={onClick}
                    key={`dots-${index}`}
                    setCurrentIndex={setCurrentIndex}
                    clickOffset={(currentIndex + 4) * limit}
                  >
                  ...
                  </PaginationPageItem>}
                  <PaginationPageItem
                    index={index}
                    className={clsx("pagination-item", { active: currentIndex === index })}
                    onClick={onClick}
                    key={`p-dot-${index}`}
                    setCurrentIndex={setCurrentIndex}
                    clickOffset={index * limit}
                  >
                    {item}
                  </PaginationPageItem>
                </>
              );
            }

            return (
              condition && <PaginationPageItem
                index={index}
                className={clsx("pagination-item", { active: currentIndex === index })}
                onClick={onClick}
                key={`p-dot-${index}`}
                setCurrentIndex={setCurrentIndex}
                clickOffset={index * limit}
              >
                {item}
              </PaginationPageItem>
            );
          }
          return (
            <PaginationPageItem
              index={index}
              className={clsx("pagination-item", { active: currentIndex === index })}
              onClick={onClick}
              key={`p-dot-${index}`}
              setCurrentIndex={setCurrentIndex}
              clickOffset={index * limit}
            >
              {item}

            </PaginationPageItem>
          );
        })}

        <PaginationPageItem
          index={currentIndex !== pageAmount - 1 ? currentIndex + 1 : pageAmount - 1}
          className='pagination-next pagination-btn'
          onClick={onClick}
          setCurrentIndex={setCurrentIndex}
          clickOffset={currentIndex !== pageAmount - 1 ? (currentIndex + 1) * limit : currentIndex * limit}
        >
          <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.69398 8.08633C8.10201 7.67568 8.10201 7.00987 7.69398 6.59922L1.78361 0.650765C1.37559 0.24011 0.714045 0.24011 0.30602 0.650765C-0.102006 1.06142 -0.102007 1.72722 0.30602 2.13788L5.47759 7.34277L0.30602 12.5477C-0.102007 12.9583 -0.102006 13.6241 0.30602 14.0348C0.714045 14.4454 1.37559 14.4454 1.78361 14.0348L7.69398 8.08633Z" fill="#192A53"/>
          </svg>
        </PaginationPageItem>

        {(pageAmount > 12) && <PaginationPageItem
          index={pageAmount - 1}
          className='pagination-end pagination-btn'
          onClick={onClick}
          setCurrentIndex={setCurrentIndex}
          clickOffset={(pageAmount - 1) * limit}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.694 6.91367C15.102 7.32433 15.102 7.99013 14.694 8.40078L8.78361 14.3492C8.37559 14.7599 7.71405 14.7599 7.30602 14.3492C6.89799 13.9386 6.89799 13.2728 7.30602 12.8621L12.4776 7.65723L7.30602 2.45233C6.89799 2.04168 6.898 1.37587 7.30602 0.965218C7.71405 0.554563 8.37559 0.554563 8.78361 0.965218L14.694 6.91367Z" fill="#192A53"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.69398 6.91367C8.10201 7.32433 8.10201 7.99013 7.69398 8.40078L1.78361 14.3492C1.37559 14.7599 0.714045 14.7599 0.30602 14.3492C-0.102006 13.9386 -0.102007 13.2728 0.30602 12.8621L5.47759 7.65723L0.306021 2.45233C-0.102006 2.04168 -0.102005 1.37587 0.306021 0.965218C0.714047 0.554563 1.37559 0.554563 1.78361 0.965218L7.69398 6.91367Z" fill="#192A53"/>
          </svg>
        </PaginationPageItem>}
      </ul> : ""}
    </>
  );
};

export default Pagination;
