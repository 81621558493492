import * as Yup from "yup";

const configHeadTable = {
  schoolName: {
    title: "School",
    value: "schoolName",
    order: 1,
  },
  schoolRepresentativeName: {
    title: "Name",
    value: "schoolRepresentativeName",
    order: 2,
  },
  email: {
    title: "Email",
    value: "email",
    order: 3,
  },
  cellPhoneNo: {
    title: "Cell phone",
    value: "cellPhoneNo",
    order: 4,
  },
};

const fieldConfig = [
  {
    id: 1,
    name: "schoolName",
    label: "School",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: 2,
    name: "schoolRepresentativeName",
    label: "Name",
    required: true,
    type: "textfield",
  },
  {
    id: 3,
    name: "email",
    label: "E-mail",
    required: true,
    type: "textfield",
  },
  {
    id: 4,
    name: "cellPhoneNo",
    label: "Cell phone",
    required: true,
    type: "textfield",
  },
];

const formikInitialValues = {
  schoolName: "",
  schoolRepresentativeName: "",
  email: "",
  cellPhoneNo: "",
};

const validationSchema = Yup.object({
  schoolName: Yup.string()
    .required("Required field"),
  schoolRepresentativeName: Yup.string()
    .required("Required field")
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .max(64, "Max length should be 64 characters"),
  email: Yup.string()
    .required("Required field")
    .matches(/^\s*\S[\s\S]*$/, "This field cannot consist of spaces only")
    .email("The email should be like example@example.com")
    .max(128, "Max length should be 128 characters"),
  cellPhoneNo: Yup.string()
    .required("Required field")
    .matches(/^[+]?\d{3,12}$/, "Invalid phone format, the value must be from 3 to 12 digits")
    .max(16, "Max length should be 16 characters"),
});

export {
  configHeadTable, formikInitialValues, validationSchema, fieldConfig,
};
