import React, { useEffect, useState, useRef } from "react";

export const arr_100 = [];
for (let i = 0; i < 101; i++) {
  arr_100.push(`${i}`);
}

function PersentPlaceholder({
  value = "",
  label = "",
  required,
  name = "",
  type = "string",
  style,
  onChange = () => { },
  onKeyDown = () => { },
}) {
  const [error, setError] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (!arr_100.includes(value)) {
      setError("This field must contain values from 0 to 100 ");
      inputRef.current.style.border = "1px solid #ff5454";
    }
    if (!value.length) {
      setError("");
      inputRef.current.style.border = "1px solid #757F98";
    }
    if (arr_100.includes(value)) {
      setError("");
      inputRef.current.style.border = "1px solid #757F98";
    }
  }, [value?.length]);

  return (
    <div className='input'>
      {label && <span className="textfield__label">{required ? "* " : ""}{label}</span>}
      <input
        ref={inputRef}
        className="textfield"
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={style}
      />
      {!arr_100.includes(value) && value.length ? <span style={{ display: "inline" }} className='textfield__errortext'>{error}</span> : null}
    </div>
  );
}

export default PersentPlaceholder;
