import React, {
  useEffect, useState, useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";

import FormikButton from "../../../../../components/Button";
import HeaderPage from "../../../../../components/HeaderPage";
import FormikDateField from "../../../../../components/DateField";
import Paper from "../../../../../components/Paper";
import FormkikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import { getStudentByIdAction } from "../studentProfile/store";
import { updateProfileAction } from "./store";
import {
  admmin_students_profile_url,
  admmin_students_url,
} from "../../../../../configs/router_urls";
import { getStudentDictionariesAction } from "../createStudent/store";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";
import CityInput from "../../../../../components/CityInput";

import "./styles/index.scss";

import { fieldConfig, yupObject, changeValidationSchema } from "../createStudent/configs";

function EditStudentProfile() {
  const history = useHistory();
  const params = useParams();
  const formRef = useRef();
  const ref = useRef();
  const dispatch = useDispatch();

  const dictionaries = useSelector(({ createStudentReducer }) => createStudentReducer.dictionaries);
  const principalList = useSelector(({ createStudentReducer }) => createStudentReducer.principalList);
  const data = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
  const schoolList = useSelector(({ createStudentReducer }) => createStudentReducer.schoolsList);
  const languageList = useSelector(({ createStudentReducer }) => createStudentReducer.languageList);
  const districtList = useSelector(({ createStudentReducer }) => createStudentReducer.districtsList);
  const citiesList = useSelector(({ createStudentReducer }) => createStudentReducer.citiesList);
  const alternateAgenciesList = useSelector(({ createStudentReducer }) => createStudentReducer.alternateAgenciesList);

  const [config, setConfig] = useState({
    fieldConfig,
    validationSchema: yupObject,
    formikValue: null,
  });
  const [valueIsChange, setValueIsChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  const save = async (values) => {
    setShowPrompt(false);
    dispatch(updateProfileAction(params.id, {
      ...values,
      osisNo: values.osisNo.trim(),
      studentName: values.studentName.trim(),
      address: values.address.trim(),
      motherName: values.motherName?.trim(),
      fatherName: values.fatherName?.trim(),
      addrStateId: values.addrStateId.split("-")[0],
    }, () => { history.push(`${admmin_students_profile_url}/${params.id}`, { update: true }); }));
  };

  const changeMode = () => {
    history.push(`${admmin_students_profile_url}/${params.id}`);
  };

  useEffect(() => {
    if (Object.keys(citiesList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (item.name === "addrCityId") {
            return { ...item, data: citiesList };
          }
          return item;
        }),
      });
    }
  }, [citiesList]);

  useEffect(() => {
    if (Object.keys(languageList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (languageList[item.beName]) {
            return ({ ...item, data: languageList[item.beName] });
          }
          return item;
        }),
      });
    }
  }, [languageList]);

  useEffect(() => {
    if (Object.keys(principalList)) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (item.name === "schoolRepresentativeId") {
            return ({ ...item, data: principalList.map((el) => ({ ...el, title: `${el.title} ${el.email}` })) });
          }
          return item;
        }),
      });
    }
  }, [principalList]);

  useEffect(() => {
    if (!data) {
      dispatch(getStudentByIdAction(params.id));
    }
  }, [data]);

  useEffect(() => {
    if (!Object.keys(dictionaries).length) {
      dispatch(getStudentDictionariesAction());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(dictionaries) && data) {
      setConfig({
        ...config,
        fieldConfig: config.fieldConfig.map((item) => {
          if (dictionaries[item.beName]) {
            return ({ ...item, data: dictionaries[item.beName] });
          }
          if ("key" in item) {
            if (item.key === data.primaryResponsiblePerson) {
              return ({ ...item, required: true });
            }
            return ({ ...item, required: false });
          }
          return item;
        }),
        formikValue: data,
      });
    }
  }, [dictionaries, districtList, schoolList, alternateAgenciesList]);

  useEffect(() => {
    window.addEventListener("click", () => { setShowPrompt(true); });
  }, [showPrompt]);

  const changeResponsiblePerson = (value) => {
    setConfig({
      ...config,
      fieldConfig: config.fieldConfig.map((item) => {
        if ("key" in item) {
          if (item.key === value) {
            return ({ ...item, required: true });
          }
          return ({ ...item, required: false });
        }
        return item;
      }),
      validationSchema: changeValidationSchema(value, yupObject),
    });
  };

  return (
    <div>
      <HeaderPage
        title="Edit Student"
        rootPathName={admmin_students_url}
        rootTitleName='All Students'
        childrenTitleNames={["Student Profile", "Edit Student"]}
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`]}
      />
      {config.formikValue && <Paper papertitle='Edit personal information' iconName='closeIcon' changeMode={changeMode}>
        <Formik
          initialValues={config.formikValue}
          validationSchema={Yup.object(config.validationSchema)}
          onSubmit={(values) => { save(values); }}
          innerRef={formRef}
        >
          {({ values, dirty }) => (
            <React.Fragment>
              <RouterPrompt
                when={showPrompt}
                headerTitle="Discard unsaved changes?"
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className='student_profile__fields'>
                {config.fieldConfig.map((item, key) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div className='student_profile__field' key={item.id + item.name}>
                        <FormikTextField edit {...item} />
                      </div>
                    );
                  }

                  case "datefield": {
                    return (
                      <div className='student_profile__field' key={item.id + item.name}>
                        <FormikDateField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    if (item.name === "primaryResponsiblePerson") {
                      return (
                        <div
                          className={item.name === "schoolRepresentativeId" ? "createstudent__big_field" : "createstudent__field"}
                          key={item.id + item.name}>
                          <FormkikSelect
                            {...item}
                            onChange={(event) => changeResponsiblePerson(event.target.value)}
                            disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                            edit
                          />
                        </div>
                      );
                    }

                    return (
                      <div
                        className={item.name === "schoolRepresentativeId" ? "createstudent__big_field" : "createstudent__field"}
                        key={item.id + item.name}>
                        <FormkikSelect
                          {...item}
                          edit={true}
                          disabled={(item?.parent && !values[item?.parent]) || item?.disabled}
                        />
                      </div>
                    );
                  }
                  case "search": {
                    return (
                      <CityInput edit={true} {...item}/>
                    );
                  }
                  case "empty": {
                    return <div key={key} className='student_profile__field'/>;
                  }
                  default: return "default";
                  }
                })}

              </div>
              <div className='student_profile__buttons'>
                <div className='student_profile__field'/>
                <div className='student_profile__field'/>
                <div className='student_profile__field'/>
                <div className='student_profile__field'/>
                <div className='student_profile__field'>
                  <FormikButton variant='contained' color='primary'>
                    Save
                  </FormikButton>
                </div>
              </div>
              {dirty === true ? setValueIsChange(true) : null}
            </React.Fragment>
          )}
        </Formik>
      </Paper>}
    </div >
  );
}

export default EditStudentProfile;
