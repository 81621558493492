import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import FormikButton from "../../../../../components/Button";
import FormikDateField from "../../../../../components/DateField";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import FormikRadioButton from "../../../../../components/RadioButton";
import FormikSelect from "../../../../../components/Select";
import FormikTextField from "../../../../../components/TextField";
import { getStudentServicesAction, updateStudentServiceAction } from "../studentServices/store";
import { cleanStudentService, getStudentServiceByIdAction } from "./store";
import FormikCheckBox from "../../../../../components/Checkbox";
import { getStudentByIdAction } from "../studentProfile/store";
import { fetchServiceTypes } from "../addNewStudentServices/store";
import { getBillingTypes, getStudentRatio, getServiceTypeForAddEdit } from "../../../../../utils/serviceType";
import { dateFormat } from "../../../../../utils/dateFormater";
import RouterPrompt from "../../../../../components/RouterPromptWindow/RouterPrompt";

import "../addNewStudentServices/styles/index.scss";

import { validationSchema, fieldConfig } from "../addNewStudentServices/configs";
import { admmin_students_profile_url, admmin_students_services_url, admmin_students_url } from "../../../../../configs/router_urls";

function EditStudentService() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(({ editStudentServiceReducer }) => editStudentServiceReducer.data);
  const student = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
  const { result: services } = useSelector(({ serviceTypesReducer }) => serviceTypesReducer.serviceTypes);

  const [showPrompt, setShowPrompt] = useState(false);
  const [valueIsChange, setValueIsChange] = useState(false);
  const [config, setConfig] = useState(fieldConfig);

  const studentName = `for ${student?.studentName}` || "";

  useEffect(() => {
    window.addEventListener("click", () => {
      setShowPrompt(true);
    });
  }, [showPrompt]);
  useEffect(() => {
    dispatch(getStudentServiceByIdAction(params.serviceId));
    dispatch(getStudentServicesAction(params.id));
    dispatch(fetchServiceTypes());
    if (!student || +student?.studentId !== +params.id) {
      dispatch(getStudentByIdAction(params.id));
    }
    return () => {
      dispatch(cleanStudentService());
    };
  }, []);

  useEffect(() => {
    if (services) {
      setConfig(config.map((item) => {
        if (item.name === "serviceTypeNo") {
          return {
            ...item,
            data: getServiceTypeForAddEdit(services).map((el) => ({ title: el.typeName, value: +el.serviceTypeNo })),
          };
        }
        if (data?.serviceTypeName) {
          if (item.name === "ratio") {
            return { ...item, data: getStudentRatio(data.serviceTypeName) };
          }
          if (item.name === "billingType") {
            return { ...item, data: getBillingTypes(data.serviceTypeName) };
          }
        }
        return item;
      }));
    }
  }, [services, data]);

  const transformData = ({
    authorizationNo, caseNo, note, billingRate, authorizationStartDt, authorizationEndDt, ...props
  }) => {
    const timePeriod = `${dateFormat("mm-dd-yyyy", authorizationStartDt)} to ${dateFormat("mm-dd-yyyy", authorizationEndDt)}`;
    authorizationNo = authorizationNo === null ? "" : authorizationNo;
    caseNo = caseNo === null ? "" : caseNo;
    note = note === null ? "" : note;
    billingRate = billingRate === null ? "" : billingRate;
    return {
      ...props, authorizationNo, caseNo, note, billingRate, timePeriod,
    };
  };

  function onChange(e, values) {
    const selectName = e.target.name;
    const service = services.find((s) => {
      if (selectName === "serviceTypeNo") {
        return s.serviceTypeNo === +e.target.value;
      }
      if (values.serviceTypeNo) {
        return s.serviceTypeNo === +values.serviceTypeNo;
      }
      return false;
    });
    setConfig(fieldConfig.map((item) => {
      if (item.name === "serviceTypeNo") {
        return {
          ...item,
          data: getServiceTypeForAddEdit(services).map(
            (el) => (
              { value: el.serviceTypeNo, title: el.typeName, id: el.serviceTypeNo }
            ),
          ),
        };
      }
      if (service) {
        if (item.name === "ratio") {
          return { ...item, data: getStudentRatio(service.typeName) };
        }
        if (item.name === "billingType") {
          return { ...item, data: getBillingTypes(service.typeName) };
        }
      }
      return item;
    }));
  }

  return (
    <div>
      <HeaderPage
        title={`Edit Authorization ${studentName}`}
        rootPathName={admmin_students_url}
        rootTitleName="All Students"
        childrenPathNames={[`${admmin_students_profile_url}/${params.id}`, `${admmin_students_services_url}/${params.id}`, `/all/students/services/${params.id}`]}
        childrenTitleNames={["Student Profile", "Manage Student Services", "Edit Authorization"]}
      />
      { data && <Paper>
        <Formik
          initialValues={transformData(data)}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setShowPrompt(false);

            dispatch(updateStudentServiceAction(
              {
                ...values,
                authorizationStartDt: moment(values.timePeriod.split(" to ")[0]).format("YYYY-MM-DD"),
                authorizationEndDt: moment(values.timePeriod.split(" to ")[1]).format("YYYY-MM-DD"),
                authorizationNo: values.authorizationNo.toString(),
                caseNo: values.caseNo.toString(),
                note: values.note.trim(),
              },
              params.serviceId, () => {
                history.push(`${admmin_students_services_url}/${params.id}`);
              },
            ));
          }}
        >
          {({
            values, errors, touched, dirty,
          }) => (
            <>
              <RouterPrompt
                when={showPrompt}
                headerTitle='Discard unsaved changes?'
                onOK={() => true}
                onCancel={() => false}
                isChange={valueIsChange}
              />
              <div className="addNewAuthorization">
                {config.map((item) => {
                  switch (item.type) {
                  case "textfield": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikTextField {...item} type={item.textfieldType || item.type}/>
                      </div>
                    );
                  }
                  case "textfieldNote": {
                    return (
                      <div
                        style={{ gridColumn: "1/3" }}
                        key={item.id + item.name}
                      >
                        <FormikTextField {...item} />
                      </div>
                    );
                  }
                  case "select": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <FormikSelect {...item} onChange={(event) => onChange(event, values)}/>
                      </div>
                    );
                  }
                  case "checkbox": {
                    return (
                      <div
                        className="modal__wrapper-field"
                        key={item.id + item.name}
                      >
                        <span
                          name="label"
                          style={{ color: "#192A53", fontSize: "13px" }}
                        >
                          {item.label}
                        </span>
                        <div className='addNewAuthorization-checkbox-wrapper'>
                          <FormikCheckBox name={item.name} {...item} label=''/>
                        </div>
                      </div>
                    );
                  }
                  case "radio": {
                    return (
                      <div className="modal__wrapper-field" key={item.id}>
                        <div
                          className={clsx("modal__wrapper-label", {
                            "modal__wrapper-error":
                              touched[item.name] && !!errors[item.name],
                          })}
                          id={item.name}
                        >
                          * {item.label}
                        </div>
                        <div
                          className="modal__wrapper-radiogroup"
                          role="group"
                          aria-labelledby={item.name}
                        >
                          <FormikRadioButton
                            type="radio"
                            name={item.name}
                            value="true"
                            label="Yes"
                          />
                          <FormikRadioButton
                            type="radio"
                            name={item.name}
                            value="false"
                            label="No"
                          />
                        </div>
                        {touched[item.name] && !!errors[item.name] && (
                          <div className="modal__wrapper-errortext">
                            {" "}
                            {errors[item.name]}{" "}
                          </div>
                        )}
                      </div>
                    );
                  }
                  case "datefield": {
                    return (
                      <div className='modal__wrapper__field double-field' key={item.id + item.name}>
                        <FormikDateField showIcon {...item} format={"mm-dd-yyyy"} separator={" to "}/>
                      </div>
                    );
                  }
                  case "empty": {
                    return <div/>;
                  }
                  default:
                    return "default";
                  }
                })}
                <FormikButton variant="contained">Save</FormikButton>
                {dirty === true ? setValueIsChange(true) : null}
              </div>
            </>
          )}
        </Formik>
      </Paper>}
    </div>
  );
}

export default EditStudentService;
