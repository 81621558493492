import { dateFormat } from "../../../../../../utils/dateFormater";

const transformGoalFields = (data) => {
  const rebuildData = data.map((el) => {
    if (el.state === "A") {
      return { ...el, state: "Active" };
    }
    if (el.state === "C") {
      return { ...el, state: "Completed" };
    }
    if (el.state === "O") {
      return { ...el, state: "Overdue" };
    }
    return {
      ...el,
    };
  }).map((el) => ({
    ...el,
    percentsCompleted: `${el.percentsCompleted}%`,
    schedule: dateFormat("mm-dd-yyyy", el.schedule),
  }));
  return rebuildData;
};

export { transformGoalFields };
