import React from "react";
import Header from "../../core/header";
import Wrapper from "./components/Wrapper";
import { validationSchema, formikInitialValues, fieldConfig } from "./config";
import "./style.scss";

function LoginPage() {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (document.activeElement.name === "login") {
        return document.getElementsByName("password")[0].focus();
      }
      document.querySelector(".login__btn > div").click();
    }
  };
  return (
    <React.Fragment>
      <Header isLogin/>
      <div className="login__page">
        <Wrapper
          headerTitle="Welcome Back !"
          headerText="Sign in to continue"
          fieldConfig={fieldConfig}
          formikInitialValues={formikInitialValues}
          validationSchema={validationSchema}
          btnText="Log In"
          forgotBtnText="Forgot your password?"
          forgotBtnColor="secondary"
          forgotBtnImage="img"
          forgotBtnOnClick={() => {}}
          handleKeyPress={handleKeyPress}
        />
      </div>
    </React.Fragment>
  );
}

export default LoginPage;
