import React from "react";
import Typography from "../Typography";
import "./styles/index.scss";

function ConfirmModal({
  headerTitle, open, children, isActualTime,
}) {
  return open ? (
    <React.Fragment>
      <div className='confirm-modal'>
        <div className='confirm-modal__wrapper'>
          <div className='confirm-modal__title'>
            <Typography variant='h1'>
              <span style={{ color: "#495057" }}>{headerTitle}</span>
            </Typography>
          </div>
          {children}
        </div>
      </div>
      <div className='grey-screen'>

      </div>
    </React.Fragment>

  ) : <> </>;
}

export default ConfirmModal;
