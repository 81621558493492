import React from "react";

import Typography from "./Typography";
import "./styles/index.scss";
import BreadCrumbs from "./BreadCrumbs";

function HeaderPage({
  title, rootTitleName, rootPathName, childrenTitleNames = [], childrenPathNames = [],
}) {
  return (
    <div className='headerpage'>
      <div className='headerpage__breadcrumbs'>
        <BreadCrumbs
          rootTitleName={rootTitleName}
          rootPathName={rootPathName}
          childrenTitleNames={childrenTitleNames}
          childrenPathNames={childrenPathNames}
        />
      </div>

      <Typography variant='h1'>
        {title}
      </Typography>
    </div>
  );
}

export default HeaderPage;
