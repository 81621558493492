import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { snackbarOpen } from "../../../../../../components/Snackbar/store/snackbar.slice";

const providersProfileSlice = createSlice({
  name: "providersProfile",
  initialState: {
    data: null,
  },
  reducers: {
    setProvidersProfileData(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { setProvidersProfileData } = providersProfileSlice.actions;

export default providersProfileSlice.reducer;

export const getProvidersByIdAction = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/provider/get-provider/${id}`);
    dispatch(setProvidersProfileData({
      ...response.data,
      addrStateIdPrv: response.data.addrStateId,
      addrCityIdPrv: response.data.addrCityId,
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
export const deleteProvidersByIdAction = (id, cb, fail) => async () => {
  try {
    await axios.delete(`/provider/delete-provider/${id}`);
    cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    fail();
  }
};

export const changeProviderPassword = (values, userId) => async (dispatch) => {
  try {
    await axios.patch("/admin-service/update-user", { password: values.password, userId });

    dispatch(snackbarOpen({
      isOpen: true,
      type: "success",
      text: "Successfully changed password",
      timeout: 4000,
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
