import React from "react";
import { Route, Switch } from "react-router-dom";
import * as urls from "../configs/router_urls";
import PrivatePage from "./components/PrivatePage";
import PublicPage from "./components/PublicPage";
import Home from "../pages/home";
import Login from "../pages/login";

import ForgotPassword from "../pages/login/ForgotPassword";
import entitesRoutes from "./entitiesRoutes";
import { getEntitie } from "../utils/getEntitie";
import SignerSignature from "../pages/signerSignature";
import NotFound from "../pages/404";

function Router({ isAuth, roles }) {
  const entitie = getEntitie(roles);

  return (
    <Switch>
      <PublicPage isAuth={isAuth} path={urls.sign_in_url} exact>
        <Login />
      </PublicPage>
      <PublicPage isAuth={isAuth} path={"/forgot-password"} exact>
        <ForgotPassword />
      </PublicPage>
      <PublicPage isAuth={isAuth} path={"/signer-signature-upload/:token"} exact>
        <SignerSignature />
      </PublicPage>
      <PrivatePage
        isAuth={isAuth}
        roles={roles}
        permittedRoles={urls.config.home.permittedRoles}
        path={urls.config.home.url}
        exact
      >
        <Home />
      </PrivatePage>
      {entitie && entitesRoutes[entitie]({ isAuth, roles, urls })}
      <Route path="*">
        {" "}
        <NotFound/>
      </Route>
    </Switch>
  );
}

export default Router;
