import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getStudentListSlice = createSlice({
  name: "students",
  initialState: {
    students: {
      result: [],
      pagination: {},
      isFetching: true,
    },
  },
  reducers: {
    getStudentList(state, { payload }) {
      state.students = { ...payload, isFetching: false };
    },
  },
});
const { getStudentList } = getStudentListSlice.actions;

export const fetchStudentList = (nameMask, offset = null, limit = 1000) => async (dispatch) => {
  try {
    if (offset === 0) offset = null;
    const params = formatterParams({
      nameMask, limit, offset,
    });
    const response = await axios.get(`/student/student-list?${params}`);
    dispatch(getStudentList(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

function formatterParams(data) {
  return Object.keys(data).reduce((a, b) => {
    if (b === "status") {
      if (Array.isArray(data[b]) && data[b].length) return a + data[b].reduce((c, d) => `${c}status[]=${d}&`, "");
      return `${a}${b}[]=&`;
    }
    if (typeof data[b] !== "undefined" && data[b] !== null && data[b] !== "") return `${a}${b}=${data[b]}&`;
    return a;
  }, "");
}
export default getStudentListSlice.reducer;
