const info = {
  isOpen: true,
  timeout: 3000,
  text: "The role's permissions are updated",
  type: "success",
};

export {
  info,
};
