import React, { cloneElement, useState } from "react";
import Popup from "reactjs-popup";
import SvgMaker from "../svgMaker";
import ConfirmModal from "../confirmModal/ConfirmModal";
import { Button } from "../Button";
import "./style.scss";

const Dialog = (props) => {
  const {
    children,
    trigger,
    title,
    ConfirmChildren,
    isChange,
    setIsChange,
    checkChangeFormState = () => {},
    isValueChange = false,
    setValueChange = () => { },
    hideModal = false,
    setHideModal = () => { },
    setDate = () => { },
  } = props;

  const [showConfirmView, setConfirmView] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const discardModal = (close) => {
    setConfirmModal(false);
    setValueChange(false);
    close();
  };
  const checkClosure = (close) => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      checkChangeFormState(true);
      if (isChange && ConfirmChildren) return setConfirmView(true);
      close();
    }
  };

  const closeParentView = (close) => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setIsChange(false);
      setConfirmView(false);
      setDate("");
      close();
    }
  };

  const confirmView = (close) => (
    <div className="popup-overlay" style={{
      position: "fixed",
      inset: 0,
      display: "flex",
      zIndex: 1001,
      pointerEvents: "auto",
    }}>
      <div style={{
        position: "relative",
        margin: "auto",
        pointerEvents: "auto",
      }}>
        <div className="dialog_content">
          <div className="dialog_content-header">
          </div>
          <div className="dialog_content-children">
            { ConfirmChildren && <ConfirmChildren closeParent={() => closeParentView(close)} closeView={() => setConfirmView(false)} /> }
          </div>
        </div>
      </div>
    </div>
  );

  const checkOnClose = (close) => {
    if (hideModal) {
      setHideModal(false);
      checkClosure(close);
    }
  };

  return (
    <>
      <Popup trigger={trigger} modal>
        {(close) => {
          checkOnClose(close);
          return (
            <>
              {!confirmModal && <div className="dialog_content">
                <div className="dialog_content-header">
                  <div className="dialog_content-header--close" onClick={() => checkClosure(close)}>
                    <SvgMaker name="popupCloseIcon"/>
                  </div>
                </div>
                <div className="dialog_content-children">
                  {title && <h2 className="dialog_content-title">{title}</h2>}
                  {cloneElement(children, { handleCloseDialog: close })}
                  {showConfirmView && confirmView(close)}
                </div>
              </div>}
              <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
                <div>
                  <Button variant="contained" color="primary" onClick={() => discardModal(close)}>
                      Discard
                  </Button>
                  <Button
                    variant="default"
                    color="secondary"
                    onClick={() => { setConfirmModal(false); setValueChange(true); }}
                  >
                      Cancel
                  </Button>
                </div>
              </ConfirmModal>
            </>);
        }
        }
      </Popup>

    </>
  );
};

export default Dialog;
