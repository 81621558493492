import React from "react";
import svgs from "./svgs";

function SvgMaker({
  name, fill, width, height, stroke,
}) {
  const SVG = svgs[name];
  return <SVG fill={fill} width={width} height={height} stroke={stroke}/>;
}

export default SvgMaker;
