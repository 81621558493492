import * as Yup from "yup";

const fieldConfig = [
  {
    id: 1,
    name: "email",
    label: "Email",
    placeholder: "Enter email",
    required: true,
    type: "textfield",
  },
];

const formikInitialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("required field").required("required field"),
});

export {
  fieldConfig,
  formikInitialValues,
  validationSchema,
};
