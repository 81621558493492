import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../pages/login/store";
import SvgMaker from "../../../components/svgMaker";

function remove() {
  localStorage.removeItem("@token");
  sessionStorage.removeItem("@token");
}

const ProfileOptions = ({ close, open }) => {
  const history = useHistory();
  const ref = useRef();
  const dispatch = useDispatch();
  const options = [
    {
      id: 1,
      title: "Logout",
      click: () => {
        remove();
        history.push("/sign-in");
        dispatch(logout(true));
      },
      icon: "logoutIcon",
    },
  ];

  const blur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) return;
    close();
  };

  useEffect(() => {
    ref.current?.focus();
  }, [open]);
  if (!open) return null;
  return (
    <div ref={ref} tabIndex={1} onBlur={blur} className='header__options'>
      {options.map((el) => (
        <div key={el.id} onClick={el.click}>
          <span>{el.title}</span>
          <SvgMaker name={el.icon}/>
        </div>
      ))}
    </div>
  );
};

const Profile = ({ profileName, profileId }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header__profile" onClick={() => {
      setOpen(true);
    }}>
      <div className="header__photo">
        <div>{profileName.slice(0, 1)}</div>
      </div>
      <div className="header__data">
        <p className="header__name">{profileName}</p>
        <p className="header__id">ID: {profileId}</p>
      </div>
      <ProfileOptions close={() => setOpen(false)} open={open}/>
    </div>
  );
};

Profile.propTypes = {
  profileImgSrc: PropTypes.string,
};

export default Profile;
