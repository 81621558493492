import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderPage from "../../../../components/HeaderPage";
import { getProviderStudentDocuments } from "./store";
import { getStudentByIdAction } from "../../../admin/student/pages/studentProfile/store";
import { provider_students_assigment_url, provider_student_profile_url } from "../../../../configs/router_urls";
import { headerConfig, iconDoc } from "./config";
import Table from "../../../../components/Table";
import tableFormater from "../../../../utils/tableFormater";
import { dateFormat } from "../../../../utils/dateFormater";
import { dowloadNewFile } from "../../../../utils/dowloadNewFile";
import SvgMaker from "../../../../components/svgMaker/SvgMaker";
import "./styles/index.scss";

function ProviderStudentDocuments() {
  const refJsx = useRef(false);
  const params = useParams();
  const dispatch = useDispatch();
  const dataResult = useSelector(({ documentsReducer }) => documentsReducer.documents);
  const student = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);

  useEffect(() => {
    dispatch(getProviderStudentDocuments(params.id));
    dispatch(getStudentByIdAction(params.id));
  }, []);

  return (
    <>
      {refJsx.current
        ? <><HeaderPage
          title={`${student?.studentName} Documents`}
          rootPathName={provider_students_assigment_url}
          rootTitleName='Students'
          childrenTitleNames={["View", "Student Documents"]}
          childrenPathNames={[`${provider_student_profile_url.split("/:")[0]}/${params.id}`]} />
        <div className="studentDocumentsTable">
          <Table
            className="without-btns"
            head={Object.values(headerConfig)}
            data={tableFormater(dataResult.map((el) => ({
              ...el,
              uploadDt: <div className="studentDocumentsTable__date">{dateFormat("mm-dd-yyyy", el.uploadDt)}</div>,
              docName: <div className='studentDocumentsTable__name'>
                {iconDoc[el.docType]}
                <span>{el.originalName}</span>
              </div>,
              actions: <div className="actions">
                <div className="actions__dowload"
                  onClick={() => {
                    dowloadNewFile(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}/${el.filePath}/${el.docName}`, el.originalName);
                  } }>
                  <SvgMaker name="dowloadIconDoc" />
                </div>
              </div>,
            })), headerConfig)}
            withoutOption />
        </div>
        </>
        : null}
      {refJsx.current = true}
    </>
  );
}

export default ProviderStudentDocuments;
