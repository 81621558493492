import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

const createSessionSlice = createSlice({
  name: "sessionReducer",
  initialState: {
    studentOptions: [],
    sessionDicts: {
      goals: [],
      skills: [],
      materials: [],
      activities: [],
      prompts: [],
      promptLevel: [],
      location: [],
      virtualMedium: [],
      materialsTransfer: [],
    },
    sessionData: {
      eventDates: {},
      sessions: [],
    },
    assignmentId: null,
    providerId: null,
    warningMessage: "",
  },
  reducers: {
    getStudentOptions(state, { payload }) {
      state.studentOptions = payload.data;
      state.providerId = payload.providerId;
    },
    getSessionDicts(state, { payload }) {
      state.sessionDicts = payload;
    },
    clearSessionData(state) {
      state.sessionData = {
        eventDates: {},
        sessions: [],
      };
    },
    getSessionData(state, { payload }) {
      state.sessionData = payload;
    },
    setAssignmentId(state, { payload }) {
      state.assignmentId = payload;
    },
    setWarningMessage(state, { payload }) {
      state.warningMessage = payload;
    },
    clearWarningMessage(state) {
      state.warningMessage = "";
    },
  },
});

export const {
  getStudentOptions, getSessionDicts, getSessionData, setAssignmentId, setWarningMessage, clearWarningMessage, clearSessionData,
} = createSessionSlice.actions;

export const clearSessionDataAction = () => async (dispatch) => {
  dispatch(clearSessionData());
};

export const getStudentOptionsAction = (cb = () => {}) => async (dispatch, getState) => {
  try {
    const id = getState().authorizationReducer.user.userId;
    const { data } = await axios.get(`/provider/provider-id/${id}`);
    const response = await axios.get(`/session/session-assignment-list/${data}`);
    dispatch(getStudentOptions({ data: response.data, providerId: data }));
    cb(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createSessionAction = (data, cb) => async (dispatch) => {
  try {
    const response = await axios.post("/session/create", data);
    dispatch(setAssignmentId(data.assignmentList[0].assignmentId));
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    cb(null);
  }
};

export const updateSessionAction = (data, cb) => async (dispatch) => {
  try {
    const response = await axios.patch("/session/update", data);
    dispatch(setAssignmentId(data.assignmentList[0].assignmentId));
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getSessionNotesAction = (id, cb = () => { }) => async (dispatch) => {
  try {
    const response = await axios.get(`/session/session-notes-data/${id}`);
    getSessionDictAction();
    dispatch(getSessionData(response.data));
    cb(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getSessionDictAction = () => async (dispatch) => {
  try {
    const response = await axios.get("/session/session-notes-dicts");
    dispatch(getSessionDicts(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const createSessionNote = (id, data, event, cb) => async (dispatch) => {
  try {
    const newData = {};

    const transformOptions = ({
      location, materialsTransferId, virtualMedium, virtualMediumAddr,
    }) => {
      const newOptions = {};
      if (location !== null) newOptions.location = location;
      if (materialsTransferId !== null && materialsTransferId !== "") newOptions.materialsTransferId = materialsTransferId;
      if (virtualMedium !== null && materialsTransferId !== "") newOptions.virtualMedium = virtualMedium;
      if (typeof virtualMediumAddr === "string" && materialsTransferId !== "") newOptions.virtualMediumAddr = virtualMediumAddr.trim();
      return newOptions;
    };

    newData.studentList = data.map((d) => ({
      sessionId: d.sessionId,
      goals: d.sessionGoals,
      skills: d.skills.map((el) => el.id || el.attributeId),
      activities: d.activities.map((el) => el.id || el.attributeId),
      materials: d.materials.map((el) => el.id || el.attributeId),
      prompts: d.prompts.map((el) => ({ attributeId: el.value || el.attributeId, promptLevel: el.title ? el.title.split(";")[1] : el.promptLevel })),
      options: transformOptions(d.options),
      note: (typeof d.note === "string") ? d.note.trim() : "",
    }));
    const response = await axios.post(`/session/create-session-notes/${id}`, {
      ...newData, ...event, actualEndTime: moment(event.actualEndTime).format(), actualStartTime: moment(event.actualStartTime).format(),
    });
    dispatch(setWarningMessage({ warningMessage: response.data }));
    cb && cb();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default createSessionSlice.reducer;
