const getLabelClassInvoices = (state) => {
  switch (state) {
  case "C":
  case "E":
    return "default";
  case "S":
  case "D":
    return "primary";
  case "P":
    return "success";
  default:
    return "default";
  }
};

export default getLabelClassInvoices;
