import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "../Button";
import { snackbarOpen } from "../Snackbar/store/snackbar.slice";
import Pdf from "./pdf";
import SvgMaker from "../svgMaker";

import "./styles/index.scss";

export default function SessionNotePDF({
  providerId,
  setShowPreview,
  sessionId,
  validation,
  stateSigned,
  changeState,
}) {
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [signed, setSigned] = useState(false);
  const [docSigned, setDocSigned] = useState(stateSigned);
  const [blob, setBlob] = useState("");

  useEffect(() => {
    if (stateSigned) {
      isValid();

      setSigned(true);
    }
  }, []);

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (sessionId && !signed) {
      try {
        const { data } = await axios(`/session/session-note-pdf/${sessionId}`, {
          method: "get",
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        setFileName(url.split("/")[url.split("/").length - 1]);
        setBlob(data);
        setFile(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } else if (sessionId && signed) {
      const response = await axios.get(`/session/signed-session-note-pdf/${sessionId}`);
      if (response.data) {
        const pdf = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${response.data.docPath}`, {
          responseType: "blob",
        });
        setFile(pdf.data);
        setDocSigned(pdf.data);
      }
    }
  }, [signed]);

  const signSessionNote = async () => {
    if (sessionId) {
      try {
        await axios.post("/session/sign-session-note", { providerId, sessionId });
        dispatch(snackbarOpen({
          isOpen: true,
          timeout: 6000,
          text: "Session note signed successfully",
          type: "success",
        }));
        setSigned(true);
        const { data, status } = await axios.get(`/session/signed-session-note-pdf/${sessionId}`);
        if (status > 199 && status < 300) {
          changeState && changeState();
        }
        if (data?.docPath) {
          await downloadPDF(data.docPath);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  async function downloadPDF(url) {
    setSigned(true);
    const pdf = await axios(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_PREFIX}${url}`, {
      responseType: "blob",
    });
    setFile(pdf.data);
    setDocSigned(pdf.data);
  }

  const isValid = () => ((validation) ? validation(sessionId) : true);

  const downloadWithOutSigned = () => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/")[url.split("/").length - 1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadSignedFile = () => {
    if (docSigned) {
      const url = window.URL.createObjectURL(new Blob([docSigned], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/")[url.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div onDoubleClick={() => {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else {
        document.selection.empty();
      }
    }}>
      <div className="react-pdf__header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }} onClick={setShowPreview}>
            <SvgMaker name='leftArrow' fill='#ffffff'/>
          </div>
          <div style={{ paddingLeft: 10, color: "#ffffff" }}>{fileName}.pdf</div>
        </div>
        <div className={"button-preview"}>
          <Button
            variant="outlined"
            onClick={
              () => { !signed ? downloadWithOutSigned() : downloadSignedFile(); }
            }
          >
            Download
          </Button>

          <Button
            variant={ !signed ? "contained" : "outlined" }
            onClick={
              () => { !signed ? isValid() && signSessionNote() : downloadWithOutSigned(); }
            }
          >
            {!signed ? "Sign" : "Download without signature"}
          </Button>
        </div>

      </div>
      <Pdf file={file}/>
    </div>
  );
}
