/* eslint-disable no-async-promise-executor */
/* eslint-disable no-underscore-dangle */

import axios from "axios";
import store from "../store";
import { snackbarOpen } from "../components/Snackbar/store/snackbar.slice";
import { logout } from "../pages/login/store";
import history from "../routing/history";
import { loadingShow } from "./store";

const config = {
  setConfiguration() {
    this.default();
    this._interceptorsRequest();
    this._interceptorsResponse();
  },
  //   http://localhost:8081
  default() {
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_BASE_PREFIX}`;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common.accept = "application/json";
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    //axios.defaults.timeout = 10000;
  },
  _getToken() {
    if (!localStorage.getItem("@token")) return sessionStorage.getItem("@token");
    return localStorage.getItem("@token");
  },
  _clearStorage() {
    localStorage.removeItem("@token");
    sessionStorage.removeItem("@token");
  },
  _interceptorsRequest() {
    axios.interceptors.request.use(
      (config) => {
        // return config;
        const token = this._getToken();
        if (token) {
          if (config.url === "/sign-in") return config;
          config.headers.Authorization = `Bearer ${token}`;
        }
        store.dispatch(loadingShow({ loading: true }));
        return config;
      },
      (error) => Promise.reject(error),
    );
  },
  _interceptorsResponse() {
    axios.interceptors.response.use((response) => response,
      async (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          this._clearStorage();
          store.dispatch(logout(true));
          history.push("/sign-in");
        }
        if (error?.response?.config?.responseType === "arraybuffer") {
          if ("TextDecoder" in window) {
            const enc = new TextDecoder("utf-8");
            const arr = new Uint8Array(error?.response?.data);
            const objError = JSON.parse(enc.decode(arr));
            store.dispatch(snackbarOpen({
              isOpen: true,
              type: "error",
              text: objError?.message,
              timeout: 6000,
            }));
            return Promise.reject(error);
          }
        }
        store.dispatch(snackbarOpen({
          isOpen: true,
          type: "error",
          text: error?.response?.data?.message,
          timeout: 6000,
        }));
        return Promise.reject(error);
      });
  },
};

export default config.setConfiguration.bind(config);
