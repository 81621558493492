import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";

import FormikButton, { Button } from "../../../../../components/Button";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import HeaderPage from "../../../../../components/HeaderPage";
import Paper from "../../../../../components/Paper";
import { ButtonPaper } from "../../../../../components/Paper/Paper";
import FormikTextField from "../../../../../components/TextField";
import Typography from "../../../../../components/Typography";
import { getServiceTypes } from "../store";
import {
  changeProviderPassword, deleteProvidersByIdAction, getProvidersByIdAction, setProvidersProfileData,
} from "./store";
import SvgMaker from "../../../../../components/svgMaker";
import { snackbarOpen } from "../../../../../components/Snackbar/store/snackbar.slice";
import { uploadSignature } from "../../../../provider/pages/providerProfile/store";
import { CheckBox } from "../../../../../components/Checkbox";
import signaturePreview from "../../../../provider/pages/providerProfile/signaturePreview.jpg";
import SignaturePreview from "../../../../provider/pages/providerProfile/components/SignaturePreview";
import Loader from "../../../../../components/LoadingIndicator";

import "./styles/index.scss";

import { dataConfig } from "./configs";
import { admin_providers_url, admin_provider_edit_profile_url, admin_provider_documents_url } from "../../../../../configs/router_urls";
import { fieldConfig } from "../configs";

const formData = new FormData();

function ProviderProfile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const ref = useRef();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDeleteSignature, setOpenModalDeleteSignature] = useState(false);
  const [signature, setSignature] = useState(null);
  const [downloadFile, setDownloadFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [preview, setPreview] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const data = useSelector(({ providersProfileReducer }) => providersProfileReducer.data);
  const serviceTypesData = useSelector(({ adminProviderReducer }) => adminProviderReducer.serviceTypesData);
  const sign = useSelector(({ signatureReducer }) => signatureReducer.signature);

  const onFileChange = (e) => {
    if (!(e.target.files[0] && typeof e.target.files[0].type !== "undefined")) return;
    if (!(e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) return setErrorMessage("Such file type is not supported");
    if (e.target.files[0].size >= 2000000) return setErrorMessage("The file cannot be larger than 2Mb");
    setErrorMessage("");
    formData.append("file", e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      setImageSrc(URL.createObjectURL(e.target.files[0]));
      setPreview(true);
    }
  };

  const onConfirm = () => {
    setPreview(false);
    setOpenModalConfirm(true);
  };

  const onDesline = () => {
    setImageSrc(null);
    formData.delete("file");
    formData.delete("securityCode");
    setPreview(false);
    ref.current.value = "";
  };

  const changeData = () => {
    const mutableData = {};

    const dataConfigWithServices = () => {
      const obj = {};
      serviceTypesData.forEach(({ name, label }, index) => {
        if (label === "Teacher") obj[name] = { id: 9, name: label };
        else if (label === "Specialty teacher") obj[name] = { id: 10, name: label };
        else if (index < 4) obj[name] = { id: 11, name: label };
        else obj[name] = { id: 12, name: label };
      });
      return {
        ...dataConfig,
        ...obj,
      };
    };

    const dataWithServices = () => {
      const obj = {};
      serviceTypesData.forEach(({ name }) => {
        if (data.prProviderServices?.find(({ serviceTypeNo }) => serviceTypeNo === +name)) {
          obj[name] = "Yes";
        } else {
          obj[name] = "No";
        }
      });
      Object.keys(dataConfig).forEach((key) => {
        if (key.indexOf("emptyField") !== -1) obj[key] = "";
      });
      return {
        ...data,
        ...obj,
      };
    };

    const providerData = dataWithServices();
    const providerDataConfig = dataConfigWithServices();
    Object.keys(providerData).forEach((el) => {
      mutableData[providerDataConfig[el]?.id] = {

        group: Object.keys(providerDataConfig)
          .filter((item) => providerDataConfig[item].id === providerDataConfig[el]?.id)
          .map((item, index) => ({ id: index, value: providerData[item], label: providerDataConfig[item].name }))
          .map((item) => {
            if (item.label === "empty") return { ...item, label: "" };
            if (item.label === "1099/W2") {
              if (providerData.payrollKind === "1") {
                return { ...item, value: "1099", label: `${item.label}:` };
              }
              return { ...item, value: "W2", label: `${item.label}:` };
            }
            if (item.label === "Work in Summer") return { ...item, label: `${item.label}`, value: item.value ? "Yes" : "No" };
            return { ...item, label: `${item.label}:` };
          })
          .sort((a) => {
            if (a.label === "SSN:") return -1;
            return 0;
          })
        ,
      };
    });
    return Object.values(mutableData);
  };

  useEffect(() => {
    dispatch(setProvidersProfileData([]));
    dispatch(getServiceTypes());
    dispatch(getProvidersByIdAction(params.id));
  }, []);

  useEffect(() => {
    if (data && data.signature) {
      setSignature(data.signature);
    }
  }, [data]);

  useEffect(() => {
    if (!serviceTypesData.length) {
      dispatch(getServiceTypes(fieldConfig));
    }
  }, []);

  useEffect(() => {
    if (sign) {
      if (downloadFile) {
        dispatch(snackbarOpen({
          isOpen: true,
          timeout: 2000,
          text: "The signature is saved",
          type: "success",
        }));
        setDownloadFile(false);
      }
      setSignature(sign);
    }
  }, [sign]);

  const goToDocuments = () => history.push(`${admin_provider_documents_url}/${params.id}`);
  const goToProviders = () => history.push(admin_providers_url);

  const deleteIconHandler = () => {
    setOpenModalDelete(true);
  };

  const deleteProvider = () => {
    dispatch(deleteProvidersByIdAction(params.id, goToProviders, () => setOpenModalDelete(false)));
  };

  return (
    <div>
      <HeaderPage
        title="Provider Profile" // for Admin
        rootPathName={admin_providers_url}
        rootTitleName='All Providers'
        childrenTitleNames={["Provider Profile"]}
      />
      <div className='student_profile'>
        <div className='student_profile__container'>
          <Paper isIconDelete={true} deleteAction={deleteIconHandler} papertitle='Personal information' iconName='editIcon' changeMode={() => history.push(`${admin_provider_edit_profile_url}/${params.id}`)}>
            {data?.addrCityId || data?.addrStateId ? (
              <div className='info provider__container'>
                {data && serviceTypesData && changeData().map(({ group }, index) => (
                  <div key={index} className='info__container provider__row'>
                    {group.map(({ value, label }, i) => {
                      if (label === "Work in Summer") {
                        return (
                          <div className='info__container-data' style={value === "Yes" ? { opacity: 1 } : { opacity: 0.5 }} key={i + label}>
                            <CheckBox profile style={ { cursor: "default" } } checked={value === "Yes"} label={label} />
                          </div>
                        );
                      }

                      return (
                        <div className='info__container-data' key={i + label}>
                          <Typography variant='subtitle2'>
                            {label}
                          </Typography>
                          <Typography variant='subtitle2-bold'>
                            {value}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : (
              <Loader/>
            )}
          </Paper>
        </div>

        {preview ? <SignaturePreview onConfirm={onConfirm} onDesline={onDesline}>
          <img className="imagePreview" src={signaturePreview} alt="Document" />
          <img className="imagePreview__signature" src={imageSrc} alt="Signature" />
        </SignaturePreview> : null}

        <ConfirmModal headerTitle="Are you sure deleting provider?" open={openModalDelete}>
          <Button variant='contained' color='primary' onClick={deleteProvider}>
            Yes, Delete
          </Button>
          <Button variant='dashed' color='primary' onClick={() => { setOpenModalDelete(false); }}>
            Cancel
          </Button>
        </ConfirmModal>

        <ConfirmModal headerTitle="Are you sure deleting the signature?" open={openModalDeleteSignature}>
          <>
            <Button variant='contained' color='primary' onClick={async () => {
              try {
                await axios.delete(`/upload/signature/${data.providerId}?fileName=${signature}`);
                setSignature(null);
                setOpenModalDeleteSignature(false);
                dispatch(snackbarOpen({
                  isOpen: true,
                  timeout: 2000,
                  text: "The record doesn't exist",
                  type: "success",
                }));
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }
            }
            }>
              Yes, Delete
            </Button>
            <Button variant='dashed' color='primary' onClick={() => { setOpenModalDeleteSignature(false); }}>
              Cancel
            </Button>
          </>
        </ConfirmModal>

        <div className='rightSideOptions'>
          <ButtonPaper>
            {signature
              ? <div className="signatureContainer">
                <div className="signatureContainer__item">
                  <div style={{ marginRight: "15px", display: "flex", alignItems: "center" }}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.37933 5.83333C6.901 4.47417 5.606 3.5 4.0835 3.5C2.15266 3.5 0.583496 5.06917 0.583496 7C0.583496 8.93083 2.15266 10.5 4.0835 10.5C5.606 10.5 6.901 9.52583 7.37933 8.16667H9.91683V10.5H12.2502V8.16667H13.4168V5.83333H7.37933ZM4.0835 8.16667C3.44183 8.16667 2.91683 7.64167 2.91683 7C2.91683 6.35833 3.44183 5.83333 4.0835 5.83333C4.72516 5.83333 5.25016 6.35833 5.25016 7C5.25016 7.64167 4.72516 8.16667 4.0835 8.16667Z" fill="#637282" />
                    </svg>
                  </div>
                  <span className="signatureContainer__name">Signature is uploaded</span>
                </div>
                <div onClick={() => setOpenModalDeleteSignature(true)}>
                  <SvgMaker name="deleteIcon" />
                </div>
              </div>
              : <div>
                <Button variant='contained' color='primary' onClick={() => { ref.current.click(); }}>
                  Upload signature
                </Button>
                <input ref={ref} id="files" style={{ display: "none" }} type="file" onChange={onFileChange} />
                <div className="provider_profile__container-error"><span>{errorMessage}</span></div>
              </div>
            }
            <Button className='rightSideOptions-btn' variant='contained' color='primary' onClick={goToDocuments}>
              Documents
            </Button>
            <Formik
              initialValues={{ secretKey: "" }}
              validationSchema={Yup.object({
                secretKey: Yup.string().required("required field"),
              })}
              onSubmit={(values) => {
                const formd = new FormData();
                formd.append("securityCode", values.secretKey);
                formd.append("file", formData.get("file"));
                setDownloadFile(true);
                dispatch(uploadSignature(data.providerId, formd, () => {
                  setOpenModalConfirm(false);
                  dispatch(getProvidersByIdAction(data.providerId));
                  values.secretKey = "";
                }));
              }}
            >
              {({ values }) => (
                <>

                  <ConfirmModal headerTitle="Enter New Security Code" open={openModalConfirm}>
                    <div style={{ marginBottom: "45px" }}>
                      <FormikTextField
                        name="secretKey"
                        isPassword
                        placeholder='Enter security code'
                      />
                    </div>

                    <FormikButton
                      variant='contained'
                      color='primary'
                    >
                      Confirm
                    </FormikButton>

                    <Button
                      variant='dashed'
                      color='primary'
                      onClick={() => {
                        formData.delete("file");
                        formData.delete("securityCode");
                        setOpenModalConfirm(false);
                        ref.current.value = "";
                        values.secretKey = "";
                      }}
                    >
                      Cancel
                    </Button>
                  </ConfirmModal>
                </>
              )}
            </Formik>
          </ButtonPaper>
          <ButtonPaper>
            <div>
              <Typography variant='h1' style={{ display: "inline-block", paddingBottom: 24 }}>
                Change Password
              </Typography>

              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={Yup.object({
                  password: Yup
                    .string()
                    .strict()
                    .trim("This field cannot consist of spaces only")
                    .min(1, "Password length should be from 1 to 32 characters")
                    .max(32, "Password length should be from 1 to 32 characters")
                    .required("required field"),
                  confirmPassword: Yup.string().required("required field")
                    .when("password", {
                      is: (val) => (!!(val && val.length > 0)),
                      then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same",
                      ),
                    }),
                })}
                onSubmit={(values, { resetForm }) => {
                  dispatch(changeProviderPassword({ ...values, password: values.password.trim() }, data.userId));
                  resetForm({ password: "", confirmPassword: "" });
                }}
              >
                {({ values }) => (
                  <React.Fragment>
                    <div className='rightSideOptions__field'>
                      <FormikTextField
                        name='password'
                        isPassword
                        placeholder=''
                        label='New password'
                        required
                      />
                    </div>
                    <div className='rightSideOptions__field'>
                      <FormikTextField
                        name='confirmPassword'
                        isPassword
                        placeholder=''
                        label='Repeat new password'
                        required
                      />
                    </div>
                    <div className="rightSideOptions__button">
                      <FormikButton
                        disabled={Object.values(values).some((el) => !el)}
                        variant='contained'
                        color='primary'
                      >
                        Save new password
                      </FormikButton>
                    </div>
                  </React.Fragment>
                )}

              </Formik>
            </div>
          </ButtonPaper>
        </div>
      </div>
    </div>
  );
}

export default ProviderProfile;
