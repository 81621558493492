import React from "react";

function Typography({
  style, children, variant, onClick, className = "",
}) {
  return (
    <span onClick={onClick} style={{ ...style }} className={`text__${variant} ${className}`}>
      {children}
    </span>
  );
}

export default Typography;
