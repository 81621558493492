import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import TableModal from "./components/TableModal";
import { loadingHide } from "../../configs/store";
import Loader from "../LoadingIndicator";

import "./styles/index.scss";

const Table = ({
  head,
  data,
  onDelete,
  onEdit,
  isContext,
  contextData = [],
  hasAcceptButton = false,
  onAccept = () => { },
  onReject = () => { },
  withoutOption,
  className = "",
  isButtonShow,
  showData,
  customData,
  onClick,
  state,
  setState,
  isTableForClick,
  typeOfTable,
  tableRef = null,
  hasCheckboxes,
  isAllChecked,
  checkAll = () => { },
  checkCheckboxes = () => { },
  checkboxes,
  showPreview,
  deletedId,
  isService,
  altContextData = [],
  onChangePassword = () => { },
}) => {
  const dispatch = useDispatch();
  let diff = deletedId ? data.find((it) => it.id === deletedId)?.id === deletedId : null;

  const [modal, setModal] = useState({ id: 0, open: false, top: 1000 });
  const [modalHeight, setModalHeight] = useState(0);
  const loading = useSelector(({ loadingReducer }) => loadingReducer.loading);

  useEffect(() => {
    if (loading) {
      dispatch(loadingHide({ loading: false }));
    }
  }, [data]);

  if (deletedId) {
    return (
      <div className={"table__wrapper custom-scroll"} ref={tableRef}>
        <div className={clsx(`table ${className}`, { contrast: true })}>
          {isContext
            && <TableModal
              contextData={contextData}
              modal={modal}
              customData={customData}
              setModal={setModal}
              typeOfTable={typeOfTable}
              altContextData={altContextData}
              onEdit={onEdit}
              state={state}
              setState={setState}
              onDelete={onDelete}
              setModalHeight={setModalHeight}
              onChangePassword={onChangePassword}
            />
          }
          <TableHead
            hasAcceptButton={hasAcceptButton}
            head={head}
            hasCheckboxes={hasCheckboxes}
            isAllChecked={isAllChecked}
            checkAll={checkAll}
          />
          {!diff ? (loading ? <Loader />
            : data.length ? (<TableBody
              hasAcceptButton={hasAcceptButton}
              onClick={onClick}
              onDelete={onDelete}
              onEdit={onEdit}
              data={data}
              isContext={isContext}
              setModal={setModal}
              onAccept={onAccept}
              onReject={onReject}
              withoutOption={withoutOption}
              isButtonShow={isButtonShow}
              showData={showData}
              customData={customData}
              state={state}
              setState={setState}
              isTableForClick={isTableForClick}
              hasCheckboxes={hasCheckboxes}
              isAllChecked={isAllChecked}
              checkCheckboxes={checkCheckboxes}
              checkboxes={checkboxes}
              showPreview={showPreview}
              isService={isService}
              modalHeight={modalHeight}
            />) : (
              <p className="empty">Empty</p>
            )
          ) : data.length ? (
            <TableBody
              hasAcceptButton={hasAcceptButton}
              onClick={onClick}
              onDelete={onDelete}
              onEdit={onEdit}
              data={data}
              isContext={isContext}
              setModal={setModal}
              onAccept={onAccept}
              onReject={onReject}
              withoutOption={withoutOption}
              isButtonShow={isButtonShow}
              showData={showData}
              customData={customData}
              state={state}
              setState={setState}
              isTableForClick={isTableForClick}
              hasCheckboxes={hasCheckboxes}
              isAllChecked={isAllChecked}
              checkCheckboxes={checkCheckboxes}
              checkboxes={checkboxes}
              showPreview={showPreview}
              isService={isService}
              modalHeight={modalHeight}
            />
          ) : (
            <p className="empty">Empty</p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={"table__wrapper custom-scroll"} ref={tableRef}>
      <div className={clsx(`table ${className}`, { contrast: true })}>
        {isContext
          && <TableModal
            contextData={contextData}
            modal={modal}
            customData={customData}
            altContextData={altContextData}
            onEdit={onEdit}
            onDelete={onDelete}
            state={state}
            setState={setState}
            onChangePassword={onChangePassword}
            setModal={setModal}
            modalHeight={modalHeight}
            setModalHeight={setModalHeight}
            typeOfTable={typeOfTable}
          />
        }
        <TableHead
          hasAcceptButton={hasAcceptButton}
          head={head}
          hasCheckboxes={hasCheckboxes}
          isAllChecked={isAllChecked}
          checkAll={checkAll}
        />
        {loading ? (
          <Loader />
        ) : data.length ? (
          <TableBody
            hasAcceptButton={hasAcceptButton}
            onClick={onClick}
            onDelete={onDelete}
            onEdit={onEdit}
            data={data}
            isContext={isContext}
            setModal={setModal}
            onAccept={onAccept}
            onReject={onReject}
            withoutOption={withoutOption}
            isButtonShow={isButtonShow}
            showData={showData}
            customData={customData}
            state={state}
            setState={setState}
            isTableForClick={isTableForClick}
            hasCheckboxes={hasCheckboxes}
            isAllChecked={isAllChecked}
            checkCheckboxes={checkCheckboxes}
            checkboxes={checkboxes}
            showPreview={showPreview}
            isService={isService}
            modalHeight={modalHeight}
          />
        ) : (
          <p className="empty">Empty</p>
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  head: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    title: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    cols: PropTypes.any,
  })),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  className: PropTypes.string,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  isContext: PropTypes.bool,
  hasAcceptButton: PropTypes.bool,
  contextData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(undefined),
  ]),
  isButtonShow: PropTypes.bool,
  showData: PropTypes.func,
  onClick: PropTypes.func,
  hasCheckboxes: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  checkAll: PropTypes.func,
  checkCheckboxes: PropTypes.func,
  altContextData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(undefined),
  ]),
};

export default Table;
