import * as Yup from "yup";
import { dateFormat } from "../../../../utils/dateFormater";

export const headerConfig = {
  callDt: {
    id: 1,
    title: "Call date",
    value: "callDt",
    order: 1,
  },
  note: {
    id: 2,
    title: "Call notes",
    value: "note",
    order: 2,
  },
};

export const fieldConfig = [
  {
    id: 1,
    name: "assignmentId",
    label: "",
    placeholder: "",
    required: true,
    type: "select",
    data: [],
  },
  {
    id: 2,
    name: "callDt",
    label: "Date",
    placeholder: "",
    required: true,
    type: "datefield",
    showIcon: true,
  },
  {
    id: 3,
    name: "note",
    label: "Notes",
    placeholder: "",
    required: true,
    type: "textfield",
    textArea: true,
  },
];

export const formikInitialValues = {
  callDt: dateFormat("mm-dd-yyyy", new Date()),
  note: "",
};

export const validationSchema = Yup.object({
  callDt: Yup.string().required("Required field"),
  note: Yup.string().matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blankspaces").min(3, "Note field min length should be 3 characters").max(2000, "Note field max length should be 2000 characters")
    .required("Required field"),
});
