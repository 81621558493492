import PropTypes from "prop-types";
import TableBtns from "./TableBtns";
import TableContext from "./TableContext";

const TableConfigTd = ({
  id, modalData,
  onDelete, onEdit,
  state, setState,
  isContext, setModal,
  hasAcceptButton, withoutOption,
  isButtonShow, showData,
  customData, showPreview, isService, modalHeight,
}) => (
  <>
    {isService && <div className="table__service"></div>}
    <div className="table__sticky" style={{ right: hasAcceptButton && "0" }}>
      {
        withoutOption ? null : isContext
          ? <TableContext
            id={id}
            modalData={modalData}
            setModal={setModal}
            hasAcceptButton={hasAcceptButton}
            customData={customData}
            modalHeight={modalHeight}
          />
          : <TableBtns
            id={id}
            onDelete={onDelete}
            onEdit={onEdit}
            state={state}
            setState={setState}
            isButtonShow={isButtonShow}
            showData={showData}
            showPreview={showPreview}
          />
      }
    </div>
  </>
);

TableConfigTd.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  isContext: PropTypes.bool,
  contextData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(undefined),
  ]),
  setModal: PropTypes.func,
  setState: PropTypes.func,
  hasAcceptButton: PropTypes.bool,
  isButtonShow: PropTypes.bool,
  showData: PropTypes.func,
  showPreview: PropTypes.bool,
  modalHeight: PropTypes.number,
};

export default TableConfigTd;
