function changeEmptyFieldsToNull(values) {
  return Object.keys(values).reduce((a, c) => {
    if (!values[c] && typeof values[c] !== "boolean") {
      a[c] = null;
      return a;
    }
    a[c] = values[c];
    return a;
  }, {});
}

export default changeEmptyFieldsToNull;
